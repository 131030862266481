/** @format */

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Form, Button, Tab, Badge } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchAutocompleteCodes from "components/searchs/SearchAutocompleteCodes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//Navigate para navegar a otra pantalla
	const navigate = useNavigate();

	//Estado para select tipo de alta de código de producto (Manual,Automático)
	const [selectType, setSelectType] = useState("");

	//Estados para los objetos producto y modelo
	const [productCode, setProductCode] = useState({});
	const [modelCode, setModelCode] = useState({});

	//Códigos seleccionados producto y modelo
	const [productCodeString, setProductCodeString] = useState("");
	const [modelCodeString, setModelCodeString] = useState("");

	//Array de objetos de telas
	const [fabricArray, setFabricArray] = useState([]);

	//Array de objetos de colores
	const [colorArray, setColorArray] = useState([]);

	//Checks talles
	const [allSizes, setAllSizes] = useState([]);
	const [allSizesBackup, setAllSizesBackup] = useState([]);
	const [selectedValuesChecks, setSelectedValuesChecks] = useState([]);

	//Filtro talles
	const [selectFilter, setSelectFilter] = useState(1);

	const toastId = useRef(null);

	useEffect(() => {
		getAllSizes();
	}, []);

	useEffect(() => {
		const filterSizes = () => {
			if (selectFilter !== "") {
				const newData = allSizesBackup.filter(
					(item) => item.filter === parseInt(selectFilter)
				);
				setAllSizes(newData);
			}
		};

		// Filtrar
		filterSizes();
	}, [selectFilter]);

	useEffect(() => {
		setProductCodeString("");
		setModelCodeString("");

		//Array de objetos de telas
		setFabricArray([]);

		//Array de objetos de colores
		setColorArray([]);

		//Array de checks de talles
		setSelectedValuesChecks([]);
	}, [selectType]);

	useEffect(() => {
		setProductCodeString(productCode.code);
	}, [productCode]);

	useEffect(() => {
		setModelCodeString(modelCode.code);
	}, [modelCode]);

	const getAllSizes = async () => {
		const { data } = await axiosWithToken.get("/sizecodes");

		setAllSizes(data.data.filter(({ filter }) => filter === 1));
		setAllSizesBackup(
			data.data.map((code) => {
				return {
					id: code.id,
					code: code.code,
					description: code.description,
					filter: code.filter,
				};
			})
		);
	};

	const handleCancel = () => {
		//Cancelar, simplemente lleva al listado de códigos nuevamente
		navigate(`/codes/compositeProductscodeslist`);
	};

	const handleCheckboxChange = (selectedValue) => {
		setSelectedValuesChecks((selectedValues) => {
			if (selectedValues.some((value) => value.id === selectedValue.id)) {
				return selectedValues.filter((value) => value.id !== selectedValue.id);
			} else {
				return [...selectedValues, selectedValue];
			}
		});
	};

	const insertDataarray = (dato, modelSearch) => {
		switch (modelSearch) {
			case "fabricsCodes":
				if (!fabricArray.some((fabric) => fabric.id === dato.id)) {
					setFabricArray([...fabricArray, dato]);
				}
				break;
			case "colorsCodes":
				if (!colorArray.some((color) => color.id === dato.id)) {
					setColorArray([...colorArray, dato]);
				}
				break;
		}
	};

	const deleteFabric = async (id) => {
		const newData = fabricArray.filter((item) => item.id !== id);
		setFabricArray(newData);
	};

	const deleteColor = async (id) => {
		const newData = colorArray.filter((item) => item.id !== id);
		setColorArray(newData);
	};

	const storeCode = async (e) => {
		e.preventDefault();

		//PP
		if (selectType == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar una forma de dar de alta el código es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (productCodeString == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo producto es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (modelCodeString == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo modelo es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (fabricArray?.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`Debe seleccionar por lo menos 1 tela.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (colorArray?.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Debe seleccionar por lo menos 1 color.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (selectedValuesChecks?.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Debe seleccionar por lo menos 1 talle.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		const codesArray = fabricArray.flatMap((fabric) =>
			colorArray.flatMap((color) =>
				selectedValuesChecks.map((size) => {
					return {
						code:
							productCode.code +
							modelCode.code +
							fabric.code +
							size.code +
							color.code,
						product_id: productCode.id,
						product_descrip: productCode.description,
						model_id: modelCode.id,
						model_descrip: modelCode.description,
						fabric_id: fabric.id,
						fabric_descrip: fabric.description,
						size_id: size.id,
						size_descrip: size.description,
						color_id: color.id,
						color_descrip: color.description,
					};
				})
			)
		);

		try {
			await axiosWithToken.post("/codes/massStorage", {
				codes: codesArray,
				selectType: selectType,
				selectUsability: 1,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/codes/compositeProductscodeslist");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
			// error
			return;
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5 className='mb-0 hover-actions-trigger'>
								Formulario alta de códigos compuestos de productos
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<Card className='mb-2 p-4 h-md-100'>
									<Row>
										<Tab.Content>
											<h6 className='mb-4 text-center'>
												Ingresar campos para códigos de productos
											</h6>

											<Form onSubmit={storeCode}>
												<Row className='mb-3 g-3'>
													<Col xs={12} sm={12} lg={12} md={12}>
														<Row className='mb-3 g-3'>
															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																id='formGridType'
															>
																<Form.Label>
																	Seleccionar forma de dar de alta el código
																	<span className='text-danger'>*</span>
																</Form.Label>
																<Form.Select
																	size='sm'
																	aria-label='select-type'
																	value={selectType}
																	onChange={(e) =>
																		setSelectType(e.target.value)
																	}
																>
																	<option value='' disabled>
																		Seleccionar...
																	</option>
																	<option value={0}>Manual</option>
																	<option value={1}>Automático</option>
																</Form.Select>
															</Form.Group>
														</Row>

														<Row className='mb-2 g-3'>
															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																controlId='formGridProduct'
															>
																<Form.Label>
																	Producto
																	<span className='text-danger'>*</span>
																</Form.Label>
																<SearchAutocompleteCodes
																	setSelect={setProductCode}
																	modelSearch={"productsCodes"}
																	disabled={false}
																/>
																<Form.Control
																	className='mt-2'
																	size='sm'
																	value={productCodeString}
																	onChange={(e) =>
																		setProductCodeString(e.target.value)
																	}
																	type='text'
																	placeholder=''
																	disabled
																/>
															</Form.Group>

															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																controlId='formGridModel'
															>
																<Form.Label>
																	Modelo
																	<span className='text-danger'>*</span>
																</Form.Label>
																<SearchAutocompleteCodes
																	setSelect={setModelCode}
																	modelSearch={"modelsCodes"}
																	disabled={false}
																/>
																<Form.Control
																	className='mt-2'
																	size='sm'
																	value={modelCodeString}
																	onChange={(e) =>
																		setModelCodeString(e.target.value)
																	}
																	type='text'
																	placeholder=''
																	disabled
																/>
															</Form.Group>
														</Row>

														<Row className='mb-2 g-3'>
															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																controlId='formGridFabric'
															>
																<Form.Label>
																	Tela<span className='text-danger'>*</span>
																</Form.Label>

																<SearchAutocompleteCodes
																	insertDat={insertDataarray}
																	modelSearch={"fabricsCodes"}
																	disabled={false}
																/>
																{fabricArray?.length > 0
																	? fabricArray.map((elem) => {
																			return (
																				<div key={elem.id}>
																					<Badge
																						style={{ fontSize: "10px" }}
																						className='mb-2'
																						bg='secondary'
																					>
																						{elem.code} :
																					</Badge>{" "}
																					<span style={{ fontSize: "10px" }}>
																						{elem.description}
																					</span>{" "}
																					<FontAwesomeIcon
																						style={{ fontSize: "8px" }}
																						icon='times'
																						size='sm'
																						className='cursor-pointer text-danger ms-2'
																						onClick={() =>
																							deleteFabric(elem.id)
																						}
																					/>
																				</div>
																			);
																	  })
																	: ""}
															</Form.Group>

															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																controlId='formGridPColor'
															>
																<Form.Label>
																	Color
																	<span className='text-danger'>*</span>
																</Form.Label>
																<SearchAutocompleteCodes
																	insertDat={insertDataarray}
																	modelSearch={"colorsCodes"}
																	disabled={false}
																/>
																{colorArray?.length > 0
																	? colorArray.map((elem) => {
																			return (
																				<div key={elem.id}>
																					<Badge
																						style={{ fontSize: "10px" }}
																						className='mb-2'
																						bg='secondary'
																					>
																						{elem.code} :
																					</Badge>{" "}
																					<span style={{ fontSize: "10px" }}>
																						{elem.description}
																					</span>{" "}
																					<FontAwesomeIcon
																						style={{ fontSize: "8px" }}
																						icon='times'
																						size='sm'
																						className='cursor-pointer text-danger ms-2'
																						onClick={() => deleteColor(elem.id)}
																					/>
																				</div>
																			);
																	  })
																	: ""}
															</Form.Group>
														</Row>

														<Row className='mb-3 g-3'>
															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																id='formGridType'
															>
																<Form.Label>Filtro de talles</Form.Label>
																<Form.Select
																	size='sm'
																	aria-label='select-type'
																	value={selectFilter}
																	onChange={(e) =>
																		setSelectFilter(e.target.value)
																	}
																>
																	<option value='' disabled>
																		Seleccionar...
																	</option>
																	<option value={1}>Prendas</option>
																	<option value={2}>Prendas/Equipos</option>
																	<option value={3}>Equipos</option>
																	<option value={4}>Bebés</option>
																</Form.Select>
															</Form.Group>
														</Row>

														<Row className='mb-2 g-3'>
															<Form.Label>
																Curva de talles
																<span className='text-danger'>*</span>
															</Form.Label>
															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={12}
																lg={12}
																controlId='formGridSizes'
															>
																{allSizes.map((elem) => {
																	return (
																		<Form.Check
																			inline
																			key={elem.id}
																			type='checkbox'
																			id={elem.id}
																			label={elem.code}
																			value={elem}
																			checked={selectedValuesChecks.some(
																				(selectedValue) =>
																					selectedValue.id === elem.id
																			)}
																			onChange={() =>
																				handleCheckboxChange(elem)
																			}
																		/>
																	);
																})}
															</Form.Group>
														</Row>
													</Col>
												</Row>

												<Row>
													<Col className='d-flex justify-content-end'>
														<Button
															id='val-pr'
															size='sm'
															className='mt-3 me-2'
															variant='danger'
															onClick={() => handleCancel()}
														>
															Cancelar
														</Button>

														<Button
															size='sm'
															className='mt-3'
															variant='primary'
															type='submit'
														>
															Guardar
														</Button>
													</Col>
												</Row>
											</Form>
										</Tab.Content>
									</Row>
								</Card>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
