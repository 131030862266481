/** @format */

import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

export const getTopLevelCenters = async () => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get(
		"/centros-de-trabajo/listado-centros/top-levels"
	);
	const topLevelCenters = data.data;
	return topLevelCenters;
};
