/** @format */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Row, Button, Card, Col, Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Divider from "components/common/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import Flex from "components/common/Flex";
const img = `${process.env.REACT_APP_ENPOINT_BASE}/img-products/`;
import cloudUpload from "assets/img/img-dashboard/icons/cloud-upload.svg";
import { useAuthContext } from "hooks/useAuthContext";
import { useProductContext } from "hooks/useProductContext";

const FormularioProducto = ({ setDisabledMold, setKey }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Estados de producto
	const [files, setFiles] = useState([]);
	const [code, setCode] = useState("");
	const [situation, setSituation] = useState("");
	const [typeProduct, setTypeProduct] = useState(false);
	const [selectType, setSelectType] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [codePresea, setCodePresea] = useState("");
	const [selectedFamily, setSelectedFamily] = useState("");
	const [selectedPublic, setSelectedPublic] = useState("");
	const [selectedLine, setSelectedLine] = useState("");
	const [selectedSubline, setSelectedSubline] = useState("");
	const [selectedDetmodp, setSelectedDetmodp] = useState("");
	const [selectedModelDescript, setSelectedModelDescript] = useState("");
	const [selectedQuality, setSelectedQuality] = useState("");
	const [selectedIramsize, setSelectedIramsize] = useState("");
	const [year, setYear] = useState(null);
	const [height, setHeight] = useState(null);
	const [length, setLength] = useState(null);
	const [width, setWidth] = useState(null);
	const [weight, setWeight] = useState(null);
	const [minimumsale, setMinimumsale] = useState(null);
	const [observacion, setObservacion] = useState("");

	const {
		productFamilies,
		getFamilies,
		publics,
		getPublic,
		lines,
		getLines,
		sublines,
		getSublines,
		modelDescripts,
		getModeldescript,
		detmodps,
		getDetmodp,
		qualities,
		getQualities,
		iramsizes,
		getIramsize,
		codeContext,
		product,
	} = useProductContext();

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	//Se llama a las funciones que rellenan los selects cuándo se renderiza por primera vez
	useEffect(() => {
		getFamilies();
		getPublic();
		getLines();
		getSublines();
		getModeldescript();
		getDetmodp();
		getQualities();
		getQualities();
		getIramsize();
	}, []);

	useEffect(() => {
		if (product && Object.entries(product).length > 0) {
			setSituation(product.situation);
			setTypeProduct(product.situation !== 3);

			if (product.stage == 0) {
				if (Object.entries(codeContext).length > 0 && product.situation !== 3) {
					setCode(codeContext.code);
					setName(codeContext.description);
					setDescription(codeContext.description);
				} else {
					setCode("");
					setName("");
					setDescription("");
				}

				setSituation(product.situation);
				setDisabledMold(true);
				setSelectType("");
				setCodePresea("");
				setSelectedFamily("");
				setSelectedPublic("");
				setSelectedLine("");
				setSelectedSubline("");
				setSelectedModelDescript("");
				setSelectedDetmodp("");
				setSelectedQuality("");
				setSelectedIramsize("");
				setYear(null);
				setHeight(null);
				setLength(null);
				setWidth(null);
				setWeight(null);
				setMinimumsale(null);
				setFiles([]);
			} else {
				if (product.situation !== 3) {
					setSelectType(product.product_type_id);
				}

				if (product.image) {
					setFiles([
						{
							name: product.image, // Nombre de la imagen
							preview: img + product.image, // URL de la imagen desde el backend
						},
					]);
				}

				if (codeContext && Object.entries(codeContext).length > 0) {
					setCode(codeContext.code);
				} else {
					setCode("");
				}

				setName(product.name);
				setDescription(product.description);
				if (product.code_presea) {
					setCodePresea(product.code_presea);
				}

				setObservacion(product.comment ? product.comment : "");

				const properties = [
					{ property: "product_family_id", setter: setSelectedFamily },
					{ property: "public_id", setter: setSelectedPublic },
					{ property: "line_id", setter: setSelectedLine },
					{ property: "subline_id", setter: setSelectedSubline },
					{ property: "modeldescript_id", setter: setSelectedModelDescript },
					{ property: "detmodp_id", setter: setSelectedDetmodp },
					{ property: "quality_id", setter: setSelectedQuality },
					{ property: "iramsize_id", setter: setSelectedIramsize },
				];

				properties.forEach(({ property, setter }) => {
					if (product[property]) {
						setter(product[property]);
					} else {
						setter("");
					}
				});

				setYear(product.year);
				setHeight(product.height);
				setLength(product.length);
				setWidth(product.width);
				setWeight(product.weight);
				setMinimumsale(product.minimum_sale);

				//Habilitamos la pestaña de moldes
				setDisabledMold(false);
			}
		} else {
			setCode("");
			setSituation("");
			setTypeProduct(false);
			setSelectType("");
			setName("");
			setDescription("");
			setCodePresea("");
			setSelectedFamily("");
			setSelectedPublic("");
			setSelectedLine("");
			setSelectedSubline("");
			setObservacion("");
			setSelectedModelDescript("");
			setSelectedDetmodp("");
			setSelectedQuality("");
			setSelectedIramsize("");
			setYear(null);
			setHeight(null);
			setLength(null);
			setWidth(null);
			setWeight(null);
			setMinimumsale(null);
			setFiles([]);
		}
	}, [codeContext, product]);

	const handleRemoveImg = (path) => {
		setFiles(files.filter((file) => file.path !== path));
	};

	const handleCancel = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/products/list`);
	};

	const storeUpdate = async (e) => {
		e.preventDefault();
		if (typeProduct) {
			if (selectType == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`Debe seleccionar un tipo de producto a dar de alta.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		if (name == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo nombre es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (situation != 3 && situation != 4) {
			if (codePresea == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo código de presea es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		try {
			const dataForm = new FormData();
			dataForm.append("situation", situation);
			dataForm.append("selectType", selectType);
			dataForm.append("code", code);
			dataForm.append("name", name);
			dataForm.append("description", description);
			dataForm.append("codePresea", codePresea);
			dataForm.append("comment", observacion);
			if (product.stage === 0) {
				dataForm.append("stage", 1);
			}
			dataForm.append("selectedFamily", selectedFamily);
			dataForm.append("selectedPublic", selectedPublic);
			dataForm.append("selectedLine", selectedLine);
			dataForm.append("selectedSubline", selectedSubline);
			dataForm.append("selectedDetmodp", selectedDetmodp);
			dataForm.append("selectedModelDescript", selectedModelDescript);
			dataForm.append("selectedQuality", selectedQuality);
			dataForm.append("selectedIramsize", selectedIramsize);
			dataForm.append("year", year);
			dataForm.append("height", height);
			dataForm.append("length", length);
			dataForm.append("width", width);
			dataForm.append("weight", weight);
			dataForm.append("minimumsale", minimumsale);

			// Agregar la imagen al FormData
			if (files.length > 0) {
				const image = files[0]; // Tomamos solo la primera imagen (si solo se permite una imagen)
				dataForm.append("image", image);
			} else {
				dataForm.append("image", null);
			}

			await axiosWithToken.post(`/product/${product.id}`, dataForm);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				setDisabledMold(false);
				setKey("mold");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			if (product) {
				if (Object.entries(product).length > 0 && product.stage >= 1) {
					setCodePresea(product.code_presea);
				}
			}

			// error
			return;
		}
	};

	return (
		<Form onSubmit={storeUpdate} encType='multipart/form-data'>
			<Row>
				<Col className='mt-3 mb-2' xs={12} sm={12} lg={8} md={8}>
					<Card className='mb-2 p-2 h-md-100'>
						<h6 className='mb-2 text-center fs--2'>
							Cargar campos del producto{" "}
							{code && (
								<span>
									: <Badge bg='secondary'>{code}</Badge>
								</span>
							)}
						</h6>
						<Card.Body>
							<Row className='mb-3'>
								{typeProduct && (
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridProductType'
									>
										<Form.Label className='fs--2'>
											Seleccionar tipo de producto
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Select
											size='sm'
											aria-label='select-type-product'
											value={selectType}
											onChange={(e) => setSelectType(e.target.value)}
										>
											<option value='' disabled>
												Seleccionar...
											</option>
											<option value={1}>
												Prenda producida totalmente en los talleres de Sonder
											</option>
											<option value={2}>
												Prenda mixta, una parte se produce en talleres externos
												y parte en talleres propios
											</option>
											<option value={3}>
												Prenda producida totalmente en talleres externos
											</option>
											<option value={4}>
												Prenda comprada (solo lleva la etiqueta)
											</option>
											<option value={5}>
												Prenda comprada + proceso en taller
											</option>
										</Form.Select>
									</Form.Group>
								)}
							</Row>

							<Row className='mb-3'>
								<Form.Group as={Col} controlId='formGridName'>
									<Form.Label className='fs--2'>
										Nombre<span className='text-danger'>*</span>
									</Form.Label>
									<Form.Control
										size='sm'
										value={name}
										onChange={(e) => setName(e.target.value)}
										type='text'
										placeholder='Ingrese nombre'
									/>
								</Form.Group>

								<Form.Group as={Col} controlId='formGridDescription'>
									<Form.Label className='fs--2'>Descripción</Form.Label>
									<Form.Control
										size='sm'
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										type='text'
										placeholder='Ingrese descripción'
									/>
								</Form.Group>
							</Row>

							<Row className='mb-3'>
								<Form.Group as={Col} controlId='formGridName'>
									<Form.Label className='fs--2'>
										Código Presea
										{situation != 3 && situation != 4 && (
											<span className='text-danger'>*</span>
										)}
									</Form.Label>
									<Form.Control
										size='sm'
										value={codePresea}
										onChange={(e) => setCodePresea(e.target.value)}
										type='text'
										placeholder='Ingrese código'
									/>
								</Form.Group>
								<Form.Group as={Col} controlId='formGridName'>
									<Form.Label className='fs--2'>Año</Form.Label>
									<Form.Control
										size='sm'
										value={year === null ? "" : year}
										onChange={(e) => setYear(e.target.value)}
										type='text'
										placeholder='Ingrese año'
									/>
								</Form.Group>

								<Form.Group as={Col} controlId='formGridDescription'>
									<Form.Label className='fs--2'>Mínimo de venta</Form.Label>
									<Form.Control
										size='sm'
										value={minimumsale === null ? "" : minimumsale}
										onChange={(e) => setMinimumsale(e.target.value)}
										type='text'
										placeholder='Ingrese mínimo de venta'
									/>
								</Form.Group>
							</Row>

							<Row className='mb-3 mt-2 g-2'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									id='formGridObservacion'
								>
									<Form.Label>Observaciones</Form.Label>
									<Form.Control
										size='sm'
										as='textarea'
										placeholder='Observaciones'
										value={observacion}
										onChange={(e) => setObservacion(e.target.value)}
										style={{ height: "100px" }}
									/>
								</Form.Group>
							</Row>

							<Divider className='mb-5 mt-5' />

							<Row className='mb-3'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									id='formGridCode'
								>
									<Form.Label className='fs--2'>
										Seleccionar una familia
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-family'
										onChange={(e) => setSelectedFamily(e.target.value)}
										value={selectedFamily}
									>
										<option value=''>Sin familia</option>
										{productFamilies.map((elem) => {
											return (
												<option key={`opt-famility-${elem.id}`} value={elem.id}>
													{elem.name}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									id='formGridCode'
								>
									<Form.Label className='fs--2'>
										Seleccionar un público
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-public'
										onChange={(e) => setSelectedPublic(e.target.value)}
										value={selectedPublic}
									>
										<option value=''>Sin público</option>
										{publics.map((elem) => {
											return (
												<option key={`opt-public-${elem.id}`} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							</Row>

							<Row className='mb-3'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									id='formGridCode'
								>
									<Form.Label className='fs--2'>
										Descripción de modelo (modelDescript)
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-modeldescript'
										onChange={(e) => setSelectedModelDescript(e.target.value)}
										value={selectedModelDescript}
									>
										<option value=''>Sin descripción</option>
										{modelDescripts.map((elem) => {
											return (
												<option
													key={`opt-modeldescript-${elem.id}`}
													value={elem.id}
												>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									id='formGridCode'
								>
									<Form.Label className='fs--2'>
										Detalle modelo (detmodps)
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-detmmodp'
										onChange={(e) => setSelectedDetmodp(e.target.value)}
										value={selectedDetmodp}
									>
										<option value=''>Sin detalle</option>
										{detmodps.map((elem) => {
											return (
												<option key={`opt-detmod-${elem.id}`} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							</Row>

							<Row className='mb-3'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									id='formGridCode'
								>
									<Form.Label className='fs--2'>Seleccionar calidad</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-quality'
										onChange={(e) => setSelectedQuality(e.target.value)}
										value={selectedQuality}
									>
										<option value=''>Sin calidad</option>
										{qualities.map((elem) => {
											return (
												<option key={`opt-quality-${elem.id}`} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									id='formGridCode'
								>
									<Form.Label className='fs--2'>
										Seleccionar talle IRAM
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-iramsize'
										onChange={(e) => setSelectedIramsize(e.target.value)}
										value={selectedIramsize}
									>
										<option value=''>Sin talle</option>
										{iramsizes.map((elem) => {
											return (
												<option key={`opt-iramsize-${elem.id}`} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col className='mt-3 mb-2' xs={12} sm={12} lg={4} md={4}>
					<Card className='mb-2 p-2'>
						<h6 className='mb-2 text-center fs--2'>Imagen</h6>
						<Card.Body>
							<Row className='mb-3'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									id='formGridImage'
								>
									<div
										style={{ height: "50%" }}
										{...getRootProps({
											className: "dropzone-area pt-4 pb-5",
										})}
									>
										<input {...getInputProps()} />
										<Flex justifyContent='center'>
											<img
												src={cloudUpload}
												alt=''
												width={20}
												className='me-2'
											/>
											<p className='fs--1 mb-0 text-700'>
												Arrastre la imagen aquí
											</p>
										</Flex>
									</div>
									<div className='mt-1'>
										{files.map((file) => (
											<Flex
												alignItems='center'
												className='py-3 border-bottom btn-reveal-trigger'
												key={file.path}
											>
												<img
													width={50}
													height={40}
													src={file.preview}
													alt={file.path}
												/>

												<Button
													style={{ fontSize: "12px" }}
													onClick={() => handleRemoveImg(file.path)}
													variant='transparent'
													size='sm'
													className='text-danger'
												>
													<FontAwesomeIcon icon='trash' /> Eliminar
												</Button>
											</Flex>
										))}
									</div>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>

					<Card className='mb-2 p-2'>
						<h6 className='mb-2 text-center fs--2'>Dimensiones</h6>
						<Card.Body>
							<Row className='mb-3'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									lg={6}
									md={6}
									controlId='formGridHeight'
								>
									<Form.Label className='fs--2'>Alto</Form.Label>
									<Form.Control
										size='sm'
										value={height === null ? "" : height}
										onChange={(e) => setHeight(e.target.value)}
										type='text'
										placeholder='Ingrese alto'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									lg={6}
									md={6}
									controlId='formGridLength'
								>
									<Form.Label className='fs--2'>Largo</Form.Label>
									<Form.Control
										size='sm'
										value={length === null ? "" : length}
										onChange={(e) => setLength(e.target.value)}
										type='text'
										placeholder='Ingrese largo'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									lg={6}
									md={6}
									controlId='formGridWidth'
								>
									<Form.Label className='fs--2'>Ancho</Form.Label>
									<Form.Control
										size='sm'
										value={width === null ? "" : width}
										onChange={(e) => setWidth(e.target.value)}
										type='text'
										placeholder='Ingrese ancho'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									lg={6}
									md={6}
									controlId='formGridWeight'
								>
									<Form.Label className='fs--2'>Peso</Form.Label>
									<Form.Control
										size='sm'
										value={weight === null ? "" : weight}
										onChange={(e) => setWeight(e.target.value)}
										type='text'
										placeholder='Ingrese peso'
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>

					<Card className='mb-2 p-2'>
						<h6 className='mb-2 text-center fs--2'>Linea y sublinea</h6>
						<Card.Body>
							<Row className='mb-3'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									id='formGridLine'
									className='mb-2'
								>
									<Form.Label className='fs--2'>
										Seleccionar una linea
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-family'
										onChange={(e) => setSelectedLine(e.target.value)}
										value={selectedLine}
									>
										<option value=''>Sin linea</option>
										{lines.map((elem) => {
											return (
												<option key={`opt-line-${elem.id}`} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									id='formGridSubline'
									className='mb-2'
								>
									<Form.Label className='fs--2'>
										Seleccionar una sublinea
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-family'
										onChange={(e) => setSelectedSubline(e.target.value)}
										value={selectedSubline}
									>
										<option value=''>Sin sublinea</option>
										{sublines.map((elem) => {
											return (
												<option key={`opt-subline-${elem.id}`} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col
					xs={12}
					sm={12}
					lg={12}
					md={12}
					className='mb-2 mt-3 d-flex justify-content-end'
				>
					<Button size='sm' variant='danger' onClick={() => handleCancel()}>
						Cancelar
					</Button>

					<Button className='ms-1' size='sm' variant='primary' type='submit'>
						Guardar
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

FormularioProducto.propTypes = {
	setDisabledMold: PropTypes.func,
	setKey: PropTypes.func,
};

export default FormularioProducto;
