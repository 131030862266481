/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import {
	Form,
	Row,
	Col,
	Button,
	Card,
	Tab,
	OverlayTrigger,
	Tooltip,
	Badge,
	Modal,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { camelize } from "../../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [searchParams] = useSearchParams();
	const toastId = React.useRef(null);
	const [code, setCode] = useState("");
	const [value, setValue] = useState("");
	const [description, setDescription] = useState("");
	//atributo
	const [idAtributo, setIdAtribuo] = useState("");
	const [descriptionAtributo, setDescriptionAtribuo] = useState("");
	const [fechaAltaAtributo, setFechaAltaAtributo] = useState("");
	const [fechaModificacionAtributo, setFechaModificacionAtributo] =
		useState("");
	//campo
	const [idCampo, setIdCampo] = useState("");
	const [descriptionCampo, setDescriptionCampo] = useState("");
	const [fechaAltaCampo, setFechaAltaCampo] = useState("");
	const [fechaModificacionCampo, setFechaModificacionCampo] = useState("");
	const [idrelacion, setIdRelacion] = useState(null);
	const [editId, setEditId] = useState(null);
	const [data, setValoresCampoAtributo] = useState([]);
	const animatedComponents = makeAnimated();
	const [valores, setValores] = useState(null);
	const [selectAtributoFieldValores, setSelectAtributoFieldValores] =
		useState(null);
	//Modal relacion con materia prima/molde
	const [torelate, setTorelate] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [titleModal, setTitleModal] = useState("");
	const [titleButton, setTitleButton] = useState("Guardar");
	const [codeMain, setCodeMain] = useState("");
	const [idValue, setIdValue] = useState("");
	const [disabledButtonsModal, setDisabledButtonsModal] = useState(true);
	const [titleMsj, setTitleMsj] = useState("agregada");
	const [selectType, setSelectType] = useState(null);
	const [showmp, setShowmp] = useState(false);
	const [showmolde, setShowmolde] = useState(false);
	const [selectMprima, setSelectMprima] = useState(null);
	const [selectMolde, setSelectMolde] = useState(null);
	const [mprimas, setMprimas] = useState([]);
	const [molds, setMolds] = useState([]);
	const [prelationObj, setPrelationObj] = useState({});
	const [mrelationObj, setMrelationObj] = useState({});
	const perPage = 3;
	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(data, perPage);

	useEffect(() => {
		const atributoid = searchParams.get("atributoid");
		const campoid = searchParams.get("campoid");
		const relate = searchParams.get("torelate");

		if (parseInt(relate)) {
			setTorelate(parseInt(relate));
		}

		getAttributeById(atributoid);
		getCampoById(campoid);
		getRelationshipId(atributoid, campoid);
		getAllMprima();
		getAllMoldes();
	}, []);

	useEffect(() => {
		getAllvalores();
	}, [idrelacion]);

	const getRelationshipId = async (atributoid, campoid) => {
		const response = await axiosWithToken.get(
			`/attributefield/attributeid/${atributoid}/fieldid/${campoid}`
		);

		if (response.status == 200) {
			getAllValoresAtributosCampos(response.data.data.id);
			setIdRelacion(response.data.data.id);
		}
	};

	useEffect(() => {
		if (selectType != "") {
			if (
				Object.entries(prelationObj).length === 0 &&
				Object.entries(mrelationObj).length === 0
			) {
				setSelectMolde(null);
				setSelectMprima(null);
				setDisabledButtonsModal(true);
			}
			if (selectType == "1") {
				setShowmp(true);
				setShowmolde(false);
			}
			if (selectType == "2") {
				setShowmolde(true);
				setShowmp(false);
			}
		}
	}, [selectType]);

	useEffect(() => {
		if (selectMprima != null) {
			console.log("prueba select materia prima", selectMprima);
			setSelectMolde(null);
			setDisabledButtonsModal(false);
		}
	}, [selectMprima]);

	useEffect(() => {
		if (selectMolde != null) {
			console.log("prueba select molde", selectMolde);
			setSelectMprima(null);
			setDisabledButtonsModal(false);
		}
	}, [selectMolde]);

	const getAllMprima = async () => {
		const response = await axiosWithToken.get("/rawmaterials");

		if (response.data.data) {
			let mprimas = Object.values(response.data.data);

			setMprimas(
				mprimas.map((mold) => {
					return {
						value: mold.id,
						label: mold.description,
					};
				})
			);
		}
	};

	const getAllMoldes = async () => {
		const response = await axiosWithToken.get("/molds");

		if (response.data.data) {
			let molds = Object.values(response.data.data);

			setMolds(
				molds.map((mold) => {
					return {
						value: mold.id,
						label: mold.description,
					};
				})
			);
		}
	};

	const getAllValoresAtributosCampos = async (id_relacion = null) => {
		if (id_relacion == null) {
			id_relacion = idrelacion;
		}

		const response = await axiosWithToken.get(
			"/attributefieldvalue/values/${id_relacion}"
		);

		if (response.status == 200) {
			setValoresCampoAtributo(
				response.data.data.map((attributefieldvalue) => {
					return {
						id: attributefieldvalue.id,
						value: attributefieldvalue.value,
						code: attributefieldvalue.code,
						description: attributefieldvalue.description,
						attribute_field_id: attributefieldvalue.attribute_field_id,
						relaciones: attributefieldvalue.relaciones,
						prelacion: attributefieldvalue.prelacion,
						mrelacion: attributefieldvalue.mrelacion,
						created_at: dayjs(attributefieldvalue.created_at).format(
							"DD/MM/YYYY HH:mm:ss"
						),
						updated_at: dayjs(attributefieldvalue.updated_at).format(
							"DD/MM/YYYY HH:mm:ss"
						),
					};
				})
			);
		}
	};

	const getAllvalores = async () => {
		const response = await axiosWithToken.get(
			`/attributefieldvalues/distint/${idrelacion}`
		);

		if (response.data.data.valores) {
			let valores = Object.values(response.data.data.valores);

			setValores(
				valores.map((unit) => {
					return {
						value: unit.id,
						label: unit.description,
					};
				})
			);
		}
	};

	const getAttributeById = async (atributoid) => {
		const response = await axiosWithToken.get(`/attribute/${atributoid}`);

		setIdAtribuo(response.data.data.id);
		setDescriptionAtribuo(response.data.data.description);
		setFechaAltaAtributo(
			dayjs(response.data.data.created_at).format("DD/MM/YYYY HH:mm:ss")
		);
		setFechaModificacionAtributo(
			dayjs(response.data.data.updated_at).format("DD/MM/YYYY HH:mm:ss")
		);
	};

	const getCampoById = async (campoid) => {
		const response = await axiosWithToken.get(`/attributefield/${campoid}`);

		setIdCampo(response.data.data.id);
		setDescriptionCampo(response.data.data.description);
		setFechaAltaCampo(
			dayjs(response.data.data.created_at).format("DD/MM/YYYY HH:mm:ss")
		);
		setFechaModificacionCampo(
			dayjs(response.data.data.updated_at).format("DD/MM/YYYY HH:mm:ss")
		);
	};

	const deleteValorAtributoCampo = async (id) => {
		await axiosWithToken.delete(`/attributefieldvalue/${id}`);

		if (!toast.isActive(toastId.current)) {
			toastId.current = toast.success("¡Eliminado correctamente!", {
				role: "alert",
				theme: "dark",
			});
		}
		getAllValoresAtributosCampos();
	};

	const handleShow = (id, code, description, mrelacion, prelacion) => {
		setShowModal(true);
		setCodeMain(code);
		setTitleModal(description);
		setIdValue(id);
		setTitleButton("Guardar");
		setTitleMsj("agregada");

		if (prelacion) {
			setPrelationObj(prelacion);
			setSelectType("1");
			setSelectMprima(prelacion.product_id.toString());
			setShowmp(true);
			setDisabledButtonsModal(true);
			setTitleButton("Editar");
			setTitleMsj("editada");
		}

		if (mrelacion) {
			setMrelationObj(mrelacion);
			setSelectType("2");
			setSelectMolde(mrelacion.mold_id.toString());
			setShowmolde(true);
			setDisabledButtonsModal(true);
			setTitleButton("Editar");
			setTitleMsj("editada");
		}
	};

	const handleAccept = async () => {
		let selected = selectMprima ? selectMprima : selectMolde;

		const respuesta = await axiosWithToken.post(
			"/attributefieldvalue/storeRelation",
			{
				selectType: selectType,
				id: idValue,
				selected: selected,
			}
		);

		if (respuesta.status == 200) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(
					`¡Relacion ${titleMsj} correctamente!`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			setSelectType("");
			setShowModal(false);
			setSelectMprima(null);
			setSelectMolde(null);
			setShowmp(false);
			setShowmolde(false);
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${respuesta.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
			setSelectType("");
			setShowModal(false);
			setSelectMprima(null);
			setSelectMolde(null);
			setShowmp(false);
			setShowmolde(false);
		}
		getAllValoresAtributosCampos();
	};

	const handleCancel = () => {
		setSelectType("");
		setShowModal(false);
		setSelectMprima(null);
		setSelectMolde(null);
		setShowmp(false);
		setShowmolde(false);
	};

	const insertUpdate = async (e) => {
		e.preventDefault();
		if (editId) {
			if (value == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El campo valor es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}
				//Error
				return;
			}

			if (description == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo descripción es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}
				//Error
				return;
			}

			const respuesta = await axiosWithToken.put(
				`/attributefieldvalue/${editId}`,
				{
					code: code,
					value: value,
					description: description,
					attribute_field_id: idrelacion,
					arrobjsrelaciones: selectAtributoFieldValores,
				}
			);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Actualizado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}
				setCode("");
				setValue("");
				setDescription("");
				setEditId(null);
				getAllValoresAtributosCampos();
				setSelectAtributoFieldValores(null);
			} else {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${respuesta.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
				setCode("");
				setDescription("");
				setValue("");
				setSelectAtributoFieldValores(null);
			}
		} else {
			if (value == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El campo valor es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}
				//Error
				return;
			}
			if (description == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo descripción es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}
				//Error
				return;
			}

			const respuesta = await axiosWithToken.post("/attributefieldvalue", {
				code: code,
				value: value,
				description: description,
				attribute_field_id: idrelacion,
				arrobjsrelaciones: selectAtributoFieldValores,
			});

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}
				setCode("");
				setValue("");
				setDescription("");
				setEditId(null);
				getAllValoresAtributosCampos();
				setSelectAtributoFieldValores(null);
			} else {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${respuesta.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
				setCode("");
				setValue("");
				setDescription("");
				setSelectAtributoFieldValores(null);
			}
		}
	};
	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			{/* MODAL */}
			<Modal
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={showModal}
			>
				<Modal.Header>
					<Modal.Title>
						<h6 className='mb-0 hover-actions-trigger'>
							Relacionar una materia prima a:{" "}
							<Badge bg='primary'># {codeMain}</Badge>{" "}
							<Badge bg='secondary'>{titleModal}</Badge>
						</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className='mb-3'>
						<Form.Group as={Col} md={6} lg={6} controlId='formGridUnitsMp'>
							<Form.Label>
								Seleccionar a que relacionar
								<span className='text-danger'>*</span>
							</Form.Label>
							<Form.Select
								size='sm'
								value={selectType || ""}
								aria-label='mp'
								onChange={(e) => setSelectType(e.target.value)}
							>
								<option value=''>Seleccionar...</option>
								<option value='1'>Materia Prima</option>
								<option value='2'>Molde</option>
							</Form.Select>
						</Form.Group>

						{showmp && (
							<Form.Group as={Col} md={6} lg={6} controlId='formGridUnitsMp'>
								<Form.Label>
									Seleccionar una materia prima
									<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Select
									size='sm'
									value={selectMprima || ""}
									aria-label='mp'
									onChange={(e) => setSelectMprima(e.target.value)}
								>
									<option value='0'>Seleccionar...</option>
									{mprimas.map((elem) => {
										return (
											<option key={elem.value} value={elem.value}>
												{elem.label}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
						)}

						{showmolde && (
							<Form.Group as={Col} md={6} lg={6} controlId='formGridUnitsMp'>
								<Form.Label>
									Seleccionar un molde<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Select
									size='sm'
									value={selectMolde || ""}
									aria-label='mp'
									onChange={(e) => setSelectMolde(e.target.value)}
								>
									<option value='0'>Seleccionar...</option>
									{molds.map((elem) => {
										return (
											<option key={elem.value} value={elem.value}>
												{elem.label}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
						)}
					</Row>

					<Row className='mt-3'>
						<Col className='d-flex justify-content-end'>
							<Button
								className='me-2'
								variant='success'
								size='sm'
								disabled={disabledButtonsModal}
								onClick={handleAccept}
							>
								{titleButton}
							</Button>

							<Button variant='danger' size='sm' onClick={handleCancel}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Datos de atributo y campo:{" "}
								<Badge bg='secondary'># {idAtributo}</Badge>{" "}
								<Badge bg='secondary'>{descriptionAtributo}</Badge>{" "}
								<Badge bg='primary'># {idCampo}</Badge>{" "}
								<Badge bg='primary'>{descriptionCampo}</Badge>
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							{/* Atributo */}
							<Col xs={12} sm={12} lg={6} md={6}>
								<Card className='mb-2'>
									<Card.Body className='position-relative'>
										<Row>
											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Descripción: </h6>
												<p className='fs--1'>
													{descriptionAtributo}{" "}
													<Badge bg='secondary'>Atributo</Badge>
												</p>
											</Col>
											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Fecha de alta:</h6>
												<p className='fs--1'>{fechaAltaAtributo}</p>
											</Col>
											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Fecha de última modificación:</h6>
												<p className='fs--1'>{fechaModificacionAtributo}</p>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>

							{/* Campo */}
							<Col xs={12} sm={12} lg={6} md={6}>
								<Card className='mb-2'>
									<Card.Body className='position-relative'>
										<Row>
											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Descripción: </h6>
												<p className='fs--1'>
													{descriptionCampo} <Badge bg='primary'>Campo</Badge>
												</p>
											</Col>
											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Fecha de alta:</h6>
												<p className='fs--1'>{fechaAltaCampo}</p>
											</Col>
											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Fecha de última modificación:</h6>
												<p className='fs--1'>{fechaModificacionCampo}</p>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>

							<Col xs={12} sm={12} lg={6} md={6}>
								<Card className='mb-2 p-4 h-md-100 h-lg-100'>
									<h6 className='mb-4 text-center'>
										Ingresar valores del campo atributo
									</h6>
									<Form className='mt-4' onSubmit={insertUpdate}>
										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												md={12}
												lg={12}
												controlId='formGridCode'
											>
												<Form.Label>Código</Form.Label>
												<Form.Control
													size='sm'
													value={code}
													onChange={(e) => setCode(e.target.value)}
													type='text'
													placeholder='Ingrese código'
												/>
											</Form.Group>

											<Form.Group
												as={Col}
												md={12}
												lg={12}
												controlId='formGridValue'
											>
												<Form.Label>
													Valor<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													value={value}
													onChange={(e) => setValue(e.target.value)}
													type='text'
													placeholder='Ingrese valor'
												/>
											</Form.Group>

											<Form.Group
												as={Col}
												md={12}
												lg={12}
												controlId='formGridDescription'
											>
												<Form.Label>
													Descripción<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													type='text'
													placeholder='Ingrese descripción'
												/>
											</Form.Group>
										</Row>

										{torelate && (
											<Row className='mb-3 g-3'>
												<Col>
													<Form.Label>
														Relaciones entre los valores del atributo
													</Form.Label>
													<Select
														isMulti
														closeMenuOnSelect={true}
														components={animatedComponents}
														options={valores}
														placeholder='Seleccionar...'
														classNamePrefix='react-select'
														value={selectAtributoFieldValores}
														onChange={setSelectAtributoFieldValores}
													/>
												</Col>
											</Row>
										)}

										<Row>
											<Col className='d-flex justify-content-end'>
												<Button size='sm' variant='primary' type='submit'>
													{!editId ? "Agregar" : "Editar"}
												</Button>

												{editId ? (
													<Button
														size='sm'
														className='ms-1'
														variant='danger'
														onClick={() => {
															setCode("");
															setValue("");
															setDescription("");
															setEditId(null);
															setSelectAtributoFieldValores(null);
														}}
													>
														Cancelar
													</Button>
												) : (
													""
												)}
											</Col>
										</Row>
									</Form>
								</Card>
							</Col>

							<Col xs={12} sm={12} lg={6} md={6}>
								<Card className='p-4 h-md-100 h-lg-100'>
									{data.length ? (
										<>
											<Row>
												<h6 className='mb-4 text-center'>
													Valores del campo atributo
												</h6>
												<Col xs={12} sm={12} lg={12} md={12}>
													<Tab.Content>
														{paginatedDetails.map((dt) => (
															<Card
																key={dt.id}
																className='mb-2 square border-start border-start-2 border-primary'
															>
																<Row>
																	<Col xs={12} sm={12} lg={10} md={10}>
																		<p className='m-1 p-1 fs--1'>
																			<strong>Código:</strong>{" "}
																			{dt.code ? dt.code : "--"}{" "}
																			<strong>Valor:</strong> {dt.value}
																		</p>
																		<p className='m-1 p-1 fs--1'>
																			<strong>Descripción:</strong>{" "}
																			{dt.description}
																		</p>
																		<p className='m-1 p-1 fs--1'>
																			<strong>Relaciones:</strong>{" "}
																			{dt.relaciones.map((relacion) => {
																				return (
																					<Badge
																						key={relacion.id}
																						bg='secondary'
																					>
																						{relacion.value}
																					</Badge>
																				);
																			})}
																		</p>
																	</Col>
																	<Col
																		className='d-flex align-items-center justify-content-end'
																		xs={12}
																		sm={12}
																		lg={2}
																		md={2}
																	>
																		<p className='mt-1'>
																			<OverlayTrigger
																				placement='top'
																				overlay={
																					<Tooltip>
																						Relacionar m. prima / molde
																					</Tooltip>
																				}
																			>
																				<Button
																					onClick={() =>
																						handleShow(
																							dt.id,
																							dt.code,
																							dt.description,
																							dt.mrelacion,
																							dt.prelacion
																						)
																					}
																					variant='transparent'
																					size='sm'
																					className='text-primary me-2'
																				>
																					<FontAwesomeIcon icon='crown' />
																				</Button>
																			</OverlayTrigger>

																			<OverlayTrigger
																				placement='top'
																				overlay={<Tooltip>Editar</Tooltip>}
																			>
																				<Button
																					onClick={() => {
																						setCode(dt.code);
																						setValue(dt.value);
																						setDescription(dt.description);
																						setEditId(dt.id);
																						const ids = dt.relaciones.map(
																							(relacion) => relacion.id
																						);
																						setSelectAtributoFieldValores(
																							valores.filter((attr) =>
																								ids.some(
																									(id) => attr.value === id
																								)
																							)
																						);
																					}}
																					variant='transparent'
																					size='sm'
																					className='text-success me-2'
																				>
																					<FontAwesomeIcon icon='edit' />
																				</Button>
																			</OverlayTrigger>

																			<OverlayTrigger
																				placement='top'
																				overlay={<Tooltip>Eliminar</Tooltip>}
																			>
																				<Button
																					onClick={() =>
																						deleteValorAtributoCampo(dt.id)
																					}
																					variant='transparent'
																					size='sm'
																					className='text-danger me-2'
																				>
																					<FontAwesomeIcon icon='trash' />
																				</Button>
																			</OverlayTrigger>
																		</p>
																	</Col>
																</Row>
															</Card>
														))}
													</Tab.Content>

													<div className='d-flex justify-content-center'>
														<div>
															{/* <OverlayTrigger placement="top" overlay={<Tooltip>Prev</Tooltip>}> */}
															<Button
																variant='falcon-default'
																size='sm'
																disabled={!canPreviousPage}
																onClick={prevPage}
																className='me-2'
																trigger='focus'
															>
																<FontAwesomeIcon icon='chevron-left' />
															</Button>
															{/* </OverlayTrigger> */}
														</div>

														<ul className='pagination mb-0'>
															{paginationArray.map((page) => (
																<li
																	key={page}
																	className={classNames({
																		active: currentPage === page,
																	})}
																>
																	<Button
																		size='sm'
																		variant='falcon-default'
																		className='page me-2'
																		onClick={() => goToPage(page)}
																	>
																		{page}
																	</Button>
																</li>
															))}
														</ul>
														<div>
															{/* <OverlayTrigger
                            trigger="click"
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">Next</Tooltip>}
                          > */}
															<Button
																variant='falcon-default'
																size='sm'
																disabled={!canNextPage}
																onClick={nextPage}
																trigger='focus'
															>
																<FontAwesomeIcon icon='chevron-right' />
															</Button>
															{/* </OverlayTrigger> */}
														</div>
													</div>
												</Col>
											</Row>
										</>
									) : (
										<h6 className='font-black text-3xl text-center'>
											¡No hay valores cargados!
										</h6>
									)}
								</Card>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
