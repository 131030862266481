/** @format */

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Form,
	Row,
	Col,
	Button,
	Card,
	Tab,
	FloatingLabel,
	FormGroup,
} from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import { OPTYPES } from "data/operations/opTypes";
import { useOperations } from "hooks/useOperations";
import { getFamilyParents } from "data/operations/familia";
import { getProductCodesByFamily } from "data/operations/productCodes";
import { useAuthContext } from "hooks/useAuthContext";

const customInputStyle = {
	height: "25px",
	fontSize: "0.85rem",
	lineHeight: "0",
};

const OperationForm = ({ operation = null, center_id = null }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [denomination, setDenomination] = useState("");
	const [opType, setOpType] = useState("");
	const [monitoringControl, setMonitoringControl] = useState(0);
	const [description, setDescription] = useState("");
	const [opCenter, setOpCenter] = useState(0);
	const [relationProductCheck, setRelationProductCheck] = useState(0); // Relacion con producto
	const [familiesData, setFamiliesData] = useState([]); // All Product's Families
	const [familyRelations, setFamilyRelations] = useState([]); // Selected product's family
	const [productsData, setProductsData] = useState([]); // All products code
	const [productRelations, setProductRelations] = useState([]); // Selected products
	const [valueTime, setValueTime] = useState("");
	// const [rubroPR, setRubroPR] = useState([]);

	const { centers, getCenters } = useOperations();

	//for document's upload
	// const [uploadedFileName, setUploadedFileName] = useState(null);
	const inputRef = useRef(null);

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	useEffect(async () => {
		//Tremos todos las familias de productos
		try {
			const families = await getFamilyParents();

			//Formateamos la familia para que sirva en el componente de Select
			const familiesFormated = await families.map((family) => {
				const familyObj = {
					label: family.name,
					value: family.id,
					children: family.children,
				};

				return familyObj;
			});
			setFamiliesData(familiesFormated);
		} catch (error) {
			console.log(error);
		}

		if (operation != null) {
			// console.log("Operaciones", operation);
			setDenomination(operation.denomination);
			setOpType(operation.op_type);
			setMonitoringControl(operation.monitoring_control);
			setDescription(operation.description);
			const centerList = operation.centers.map((cent) => cent.id);
			setOpCenter(centerList[0]);
			// if (operation.relation_op_attr.length > 0) {
			// }
			if (operation.relation_op_product.length > 0) {
				setRelationProductCheck(1);
			}
			setValueTime(operation.time);
		}

		getCenters();
	}, []);

	useEffect(async () => {
		if (Number(relationProductCheck) === 1) {
			try {
				const productCodesByFamily = await getProductCodesByFamily(
					familyRelations
				);

				setProductsData(productCodesByFamily);
			} catch (error) {
				console.log(error);
			}
		}
	}, [familyRelations]);

	useEffect(async () => {
		//buscamos todos los productos relacionados con la familia seleccionada
		if (Number(relationProductCheck) === 1) {
			const productCodesByFamily = await getProductCodesByFamily(
				familyRelations
			);

			setProductsData(productCodesByFamily);
		} else {
			setProductRelations([]);
		}
	}, [relationProductCheck]);

	//Funcion para guardar la familia de producto que seleccionamos a relacionar
	const handleSelectFamiliesDataChange = (value) => {
		setFamilyRelations(value);
	};

	//Funcion para guardar los productos que seleccionamos a relacionar
	const handleSelectProductsDataChange = (value) => {
		setProductRelations(value);
	};

	//funcion para formatear y setear el campo de tiempode ejecucion
	const handleTime = (event) => {
		const newValue = event.target.value.replace(/\D/g, ""); // solo se permiten números
		const maxLength = 8;
		const match = newValue.match(/^(\d{1,2})(\d{0,2})(\d{0,4})$/); // separa los numeros en grupos
		if (match) {
			const formattedValue =
				match[1] +
				(match[2] ? ":" + match[2] : "") +
				(match[3] ? ":" + match[3] : ""); // agrega dos puntos
			event.target.value = formattedValue.slice(0, maxLength); // limita la longitud a 8 caracteres
		}
		setValueTime(event.target.value);
	};

	const storeOperation = async (e) => {
		e.preventDefault();
		if ([denomination, opType, monitoringControl, opCenter].includes("")) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("¡Por favor complete todos los datos!", {
					theme: "colored",
					autoClose: 3000,
				});
			}
			return;
		}
		if (valueTime.length <= 7) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					"¡Introduzca el formato correcto del tiempo HH:MM:SS!",
					{
						theme: "colored",
						autoClose: 3000,
					}
				);
			}
			return;
		}

		if (familyRelations.length < 1) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					"¡Debe seleccionar la relación con familia de producto!",
					{
						theme: "colored",
						autoClose: 3000,
					}
				);
			}
			return;
		}

		//TODO save the upload document and make de backend for them.
		console.log(inputRef.current?.files);

		try {
			const respuesta = await axiosWithToken.post("/operations/new-operation", {
				denomination: denomination.toUpperCase(),
				op_type: opType,
				monitoring_control: monitoringControl,
				description,
				time: valueTime,
				opCenter: Number(opCenter),
				rubro: familyRelations, //familia de productos seleccionadas
				products: productRelations, // productos seleccionados
			});

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setDenomination("");
				setOpType("");
				setMonitoringControl(0);
				setDescription("");

				setTimeout(() => {
					navigate(`/operations/${respuesta.data.id}`);
				}, 1300);
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	const updateOperation = async (e) => {
		e.preventDefault();

		//TODO save the upload document and make de backend for them.
		console.log(inputRef.current?.files);

		const data = {
			denomination,
			op_type: opType,
			monitoring_control: monitoringControl,
			description,
			time: valueTime,
			opCenter,
			rubro: familyRelations, //attributes_fields_value
			products: productRelations,
		};

		try {
			const respuesta = await axiosWithToken.put(
				`/operations/update/${operation.id}`,
				data
			);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("Editado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setTimeout(() => {
					navigate(0);
				}, 1300);
			} else {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${respuesta.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	// const handleUpload = () => {
	// 	inputRef.current?.click();
	// };

	// const handleDisplayFileDetails = () => {
	// 	inputRef.current?.files &&
	// 		setUploadedFileName(inputRef.current.files[0].name);
	// };

	const volver = () => {
		navigate(-1);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								{operation ? "Editar " : "Agregar "}Operación
							</h5>
							<p className='mt-2 mb-0'>Ingresá los datos correspondientes.</p>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={operation ? updateOperation : storeOperation}>
							<Row className='mb-3 g-3'>
								{/* DENOMINACION */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={4}
									controlId='formGridDenomination'
								>
									<Form.Label className='fs--1'>Denominación</Form.Label>
									<Form.Control
										value={denomination.toUpperCase()}
										onChange={(e) => setDenomination(e.target.value)}
										type='text'
										placeholder='Ingrese denominación'
										style={customInputStyle}
									/>
								</Form.Group>

								{/* TIPO OPERACION */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={4}
									controlId='formGridSelectOpType'
								>
									<Form.Label className='fs--1'>Tipo de Operación</Form.Label>
									<Form.Select
										size='sm'
										value={opType}
										aria-label='Tipo de Operación'
										onChange={(e) => setOpType(e.target.value)}
									>
										<option value={0}>-- Seleccione un tipo --</option>
										{OPTYPES.map((type) => {
											return (
												<option key={type.id} value={type.item}>
													{type.item}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								{/* DOCUMENTACION */}
								{/* <Form.Group
                  as={Col}
                  sm={12}
                  md={6}
                  lg={4}
                  controlId="formGridSelectDocuments"
                >
                  <div className="d-flex h-100 align-items-center">
                    <div>
                      <label className="mx-3">Seleccionar Documentación:</label>
                      <input
                        ref={inputRef}
                        onChange={handleDisplayFileDetails}
                        className="d-none"
                        type="file"
                      />
                      <button
                        onClick={handleUpload}
                        className={`btn btn-outline-${
                          uploadedFileName ? 'success' : 'primary'
                        }`}
                      >
                        {uploadedFileName ? uploadedFileName : 'Cargar'}
                      </button>
                    </div>
                  </div>
                </Form.Group> */}

								{/* CONTROL DE SEGUIMIENTO */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={4}
									controlId='formGridSelectMonitoringControl'
								>
									<Form.Label className='fs--1'>
										Control de Seguimiento
									</Form.Label>
									<Form.Select
										size='sm'
										value={monitoringControl}
										aria-label='Control de seguimiento'
										onChange={(e) =>
											setMonitoringControl(Number(e.target.value))
										}
									>
										<option value={0}>NO</option>
										<option value={1}>SI</option>
									</Form.Select>
								</Form.Group>

								{/* CENTRO */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridSelectPadre'
								>
									<Form.Label className='fs--1'>Centro</Form.Label>
									<Form.Select
										size='sm'
										value={opCenter}
										aria-label='Center'
										onChange={(e) => setOpCenter(e.target.value)}
									>
										<option disabled value={0}>
											-- Seleccione centro al que pertenece --
										</option>
										{centers.map((center) => {
											return (
												<option key={center.id} value={center.id}>
													#{center.id} {center.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								{/* TIEMPO */}
								<FormGroup as={Col} xs={12} sm={12} md={6} lg={6}>
									<Form.Label className='fs--1'>Tiempo de ejecución</Form.Label>
									<Form.Control
										type={"text"}
										style={customInputStyle}
										value={valueTime}
										onChange={handleTime}
										placeholder={"hh : mm : ss"}
									/>
								</FormGroup>

								{/* RELACION STEP 1 - FAMILIA DE PRODUCTO*/}
								<Form.Group
									as={Col}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridSelectRelacionStep1'
								>
									<Col xs={12} sm={12} md={12} lg={12}>
										<Form.Label className='fs--1'>
											PASO 1 (relación Familia de productos)
										</Form.Label>
										<Select
											options={familiesData}
											value={familyRelations}
											isMulti
											onChange={handleSelectFamiliesDataChange}
											placeholder={"Seleccione Familia"}
										/>
									</Col>
								</Form.Group>

								{familyRelations.length ? (
									<>
										{/* RELACION STEP 1 - PRODUCTO*/}
										<Form.Group
											as={Col}
											sm={12}
											md={12}
											lg={12}
											controlId='formGridSelectRelacionStep1'
										>
											<Form.Label className='fs--1'>
												PASO 2 - (relacion Productos - opcional)
											</Form.Label>
											<Form.Select
												size='sm'
												defaultValue={0}
												aria-label='Relation Productrelation'
												onChange={(e) =>
													setRelationProductCheck(e.target.value)
												}
											>
												<option value={0}>NO</option>
												<option value={1}>SI</option>
											</Form.Select>
										</Form.Group>

										{/* RELACION STEP 2 - SELECCIONAR PRODUCTO */}

										{relationProductCheck != 0 && (
											<Col xs={12} sm={12} md={12} lg={12}>
												<Form.Label className='fs--1'>
													PASO 3 - Seleccionar Productos
												</Form.Label>
												<Select
													options={productsData}
													value={productRelations}
													isMulti
													onChange={(e) => handleSelectProductsDataChange(e)}
													placeholder={"Seleccione los productos"}
												/>
											</Col>
										)}
									</>
								) : (
									""
								)}

								{/* DESCRIPCION */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridDescription'
								>
									<FloatingLabel
										controlId='floatingTextarea'
										label='Breve descripción'
										className='mb-3'
									>
										<Form.Control
											as='textarea'
											placeholder='Ingrese una breve descripción'
											value={description}
											onChange={(e) => setDescription(e.target.value)}
										/>
									</FloatingLabel>
								</Form.Group>
							</Row>
							<Row>
								{!center_id && (
									<Col>
										<Button variant='warning' onClick={() => volver()}>
											Volver
										</Button>
									</Col>
								)}

								<Col>
									<Button className='float-end' variant='primary' type='submit'>
										{operation ? "Editar" : "Guardar"}
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

OperationForm.propTypes = {
	operation: PropTypes.object,
	center_id: PropTypes.number,
};

export default OperationForm;
