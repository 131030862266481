/** @format */

export const tipoEventosDataObj = {
	laboral: {
		id: 1,
		name: "Días Laborales",
	},
	finde: {
		id: 2,
		name: "Fin de Semana",
	},
	feriado: {
		id: 3,
		name: "Feriado",
	},
};
