/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useEffect } from "react";

const NineDotMenu = ({ handleEditionMode, handleDelete }) => {
	const [show, setShow] = useState(null);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			window.innerWidth < 1200 && setShow(false);
		});
	}, []);

	return (
		<Dropdown navbar={true} show={show} onToggle={() => setShow(!show)}>
			<Dropdown.Toggle
				bsPrefix='toggle'
				as={Link}
				to='#!'
				className='nav-link px-2 nine-dots'
			>
				{/* <span className="nine-dots"></span> */}
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='16'
					height='37'
					viewBox='0 0 16 16'
					fill='none'
				>
					<circle cx='2' cy='2' r='2' fill='#6C6E71'></circle>
					<circle cx='2' cy='8' r='2' fill='#6C6E71'></circle>
					<circle cx='2' cy='14' r='2' fill='#6C6E71'></circle>
					<circle cx='8' cy='8' r='2' fill='#6C6E71'></circle>
					<circle cx='8' cy='14' r='2' fill='#6C6E71'></circle>
					<circle cx='14' cy='8' r='2' fill='#6C6E71'></circle>
					<circle cx='14' cy='14' r='2' fill='#6C6E71'></circle>
					<circle cx='8' cy='2' r='2' fill='#6C6E71'></circle>
					<circle cx='14' cy='2' r='2' fill='#6C6E71'></circle>
				</svg>
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item className='mb-1' onClick={() => handleEditionMode()}>
					<b>Editar</b>
				</Dropdown.Item>
				<Dropdown.Item onClick={handleDelete}>Eliminar</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

NineDotMenu.propTypes = {
	handleEditionMode: PropTypes.func,
	handleDelete: PropTypes.func,
};

export default NineDotMenu;
