/** @format */

import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import classNames from "classnames";
// import usePagination from "hooks/usePagination";

const StageThreeDetailPagination = ({ stageTwoDetails, handleShowModal }) => {
	// const perPage = 5;
	const [data, setData] = useState([]);

	useEffect(() => {
		if (stageTwoDetails && stageTwoDetails.length > 0) {
			setData(stageTwoDetails);
		}
	}, [stageTwoDetails]);

	const renderTooltip = (data) => {
		return (
			<Tooltip id='button-tooltip'>
				<span className='text-left fs--2'>
					<strong>Familia: {data.name}</strong>
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Cantidad planificada: {data.quantity}</strong>
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Apertura: {data.opening}</strong>
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Tiempo promedio: {data.averagetime}</strong>
				</span>
				<br />
			</Tooltip>
		);
	};

	// const {
	// 	paginationState: {
	// 		data: paginatedDetails,
	// 		currentPage,
	// 		canNextPage,
	// 		canPreviousPage,
	// 		paginationArray,
	// 	},
	// 	nextPage,
	// 	prevPage,
	// 	goToPage,
	// } = usePagination(data, perPage);

	return (
		<>
			{data.map((dt, index) => {
				return (
					<tr key={`indexId-${index}`} className='border-200'>
						<td className='text-start'>
							<OverlayTrigger
								placement='top'
								delay={{ show: 100, hide: 200 }}
								overlay={renderTooltip(dt)}
							>
								<span className='fs--2'>
									{dt.familyName} - {dt.name}
								</span>
							</OverlayTrigger>
						</td>
						<td className='text-center'>
							<OverlayTrigger
								placement='top'
								overlay={<Tooltip>Abrir en temporadas</Tooltip>}
							>
								<Button
									onClick={() => handleShowModal(dt)}
									variant='transparent'
									size='sm'
									style={{
										fontSize: "11px",
									}}
									className='text-primary'
								>
									<FontAwesomeIcon icon='plus' />
								</Button>
							</OverlayTrigger>
						</td>
					</tr>
				);
			})}
			{/* <tr className='d-flex justify-content-center mt-3 w-100'>
					<td>
						<Button
							variant='falcon-default'
							size='sm'
							disabled={!canPreviousPage}
							onClick={prevPage}
							className='me-2'
							trigger='focus'
						>
							<FontAwesomeIcon icon='chevron-left' />
						</Button>
					</td>
					<td>
						<ul className='pagination mb-0'>
							{paginationArray.map((page) => (
								<li
									key={page}
									className={classNames({ active: currentPage === page })}
								>
									<Button
										size='sm'
										variant='falcon-default'
										className='page me-2'
										onClick={() => goToPage(page)}
									>
										{page}
									</Button>
								</li>
							))}
						</ul>
					</td>
					<td>
						<Button
							variant='falcon-default'
							size='sm'
							disabled={!canNextPage}
							onClick={nextPage}
							trigger='focus'
						>
							<FontAwesomeIcon icon='chevron-right' />
						</Button>
					</td>
				</tr> */}
		</>
	);
};

StageThreeDetailPagination.propTypes = {
	stageTwoDetails: PropTypes.array,
	handleShowModal: PropTypes.func,
};

export default StageThreeDetailPagination;
