/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Form, Button, Tabs, Tab } from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const toastId = React.useRef(null);

	//Estados para unidades de conversión

	const codeConvert = "conversion_unit";

	//Perímetro de prenda
	const keygarmenttopthread = "garment_convert_topthread";
	const [valuegarmenttopthread, setValuegarmenttopthread] = useState(0.0);

	const keygarmentlowerthread = "garment_convert_lowerthread";
	const [valuegarmentlowerthread, setValuegarmentlowerthread] = useState(0.0);

	//Perímetro de flatseamer
	const keyflatseamertopthread = "flatseamer_convert_topthread";
	const [valueflatseamertopthread, setValueflatseamertopthread] = useState(0.0);

	const keyflatseamerlowerthread = "flatseamer_convert_lowerthread";
	const [valueflatseamerlowerthread, setValueflatseamerlowerthread] =
		useState(0.0);

	//Perímetro de cintura
	const keywaisttopthread = "waist_convert_topthread";
	const [valuewaisttopthread, setValuewaisttopthread] = useState(0.0);

	const keywaistlowerthread = "waist_convert_lowerthread";
	const [valuewaistlowerthread, setValuewaistlowerthread] = useState(0.0);

	//Costos indirectos por tipos de productos

	const codePercentage = "percentage_unit";

	const keyfullyproduced = "fully_produced";
	const [fullyproduced, setFullyproduced] = useState(0.0);

	const keymixedProduced = "mixed_produced";
	const [mixedProduced, setMixedProduced] = useState(0.0);

	const keyexternalworkshop = "external_workshop";
	const [externalworkshop, setExternalworkshop] = useState(0.0);

	const keypurchased = "purchased";
	const [purchased, setPurchased] = useState(0.0);

	const keymixedPurchased = "mixed_purchased";
	const [mixedPurchased, setMixedPurchased] = useState(0.0);

	useEffect(() => {
		getSettings();
	}, []);

	const getSettings = async () => {
		try {
			const response = await axiosWithToken.get("/setting");

			response.data.data.forEach((element) => {
				switch (element.key) {
					case "garment_convert_topthread":
						setValuegarmenttopthread(element.value);
						break;
					case "garment_convert_lowerthread":
						setValuegarmentlowerthread(element.value);
						break;
					case "flatseamer_convert_topthread":
						setValueflatseamertopthread(element.value);
						break;
					case "flatseamer_convert_lowerthread":
						setValueflatseamerlowerthread(element.value);
						break;
					case "waist_convert_topthread":
						setValuewaisttopthread(element.value);
						break;
					case "waist_convert_lowerthread":
						setValuewaistlowerthread(element.value);
						break;
					case "fully_produced":
						setFullyproduced(element.value);
						break;
					case "mixed_produced":
						setMixedProduced(element.value);
						break;
					case "external_workshop":
						setExternalworkshop(element.value);
						break;
					case "purchased":
						setPurchased(element.value);
						break;
					case "mixed_purchased":
						setMixedPurchased(element.value);
						break;
				}
			});
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	const store = async (e) => {
		e.preventDefault();

		/* Hilo arriba perímetro de prenda */
		if (valuegarmenttopthread == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo valor hilo de arriba para perímetro de prenda es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		/* Hilo abajo perímetro de prenda */
		if (valuegarmentlowerthread == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo valor hilo de abajo para perímetro de prenda es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		/* Hilo arriba perímetro flatseamer */
		if (valueflatseamertopthread == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo valor hilo de arriba para perímetro de flatseamer es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		/* Hilo abajo perímetro de prenda */
		if (valueflatseamerlowerthread == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo valor hilo de abajo para perímetro de flatseamer es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		/* Hilo arriba perímetro cintura */
		if (valuewaisttopthread == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo valor hilo de arriba para perímetro de cintura es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		/* Hilo abajo perímetro de cintura */
		if (valuewaistlowerthread == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo valor hilo de abajo para perímetro de cintura es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		const newObjectGarmentTop = {
			code: codeConvert,
			key: keygarmenttopthread,
			value: valuegarmenttopthread,
		};

		const newObjectGarmentLower = {
			code: codeConvert,
			key: keygarmentlowerthread,
			value: valuegarmentlowerthread,
		};

		const newObjectFlatseamerTop = {
			code: codeConvert,
			key: keyflatseamertopthread,
			value: valueflatseamertopthread,
		};

		const newObjectFlatseamerLower = {
			code: codeConvert,
			key: keyflatseamerlowerthread,
			value: valueflatseamerlowerthread,
		};

		const newObjectWaistTop = {
			code: codeConvert,
			key: keywaisttopthread,
			value: valuewaisttopthread,
		};

		const newObjectWaistLower = {
			code: codeConvert,
			key: keywaistlowerthread,
			value: valuewaistlowerthread,
		};

		const newObjectFullyproduced = {
			code: codePercentage,
			key: keyfullyproduced,
			value: fullyproduced,
		};

		const newObjectMixedProduced = {
			code: codePercentage,
			key: keymixedProduced,
			value: mixedProduced,
		};

		const newObjectExternalworkshop = {
			code: codePercentage,
			key: keyexternalworkshop,
			value: externalworkshop,
		};

		const newObjectPurchased = {
			code: codePercentage,
			key: keypurchased,
			value: purchased,
		};

		const newObjectMixedPurchased = {
			code: codePercentage,
			key: keymixedPurchased,
			value: mixedPurchased,
		};

		const objectsArray = [
			newObjectGarmentTop,
			newObjectGarmentLower,
			newObjectFlatseamerTop,
			newObjectFlatseamerLower,
			newObjectWaistTop,
			newObjectWaistLower,
			newObjectFullyproduced,
			newObjectMixedProduced,
			newObjectExternalworkshop,
			newObjectPurchased,
			newObjectMixedPurchased,
		];

		try {
			const respuesta = await axiosWithToken.post("/setting", {
				settings: objectsArray,
			});

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success(
						"Configuraciones agregadas correctamente!",
						{
							role: "alert",
							theme: "dark",
						}
					);
				}
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario de configuraciones
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={store}>
							<Row>
								<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
									<Tabs defaultActiveKey='convert' id='tabs-viewmp'>
										<Tab eventKey='convert' title='Unidades de conversión'>
											<Row>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={12}
													md={12}
												>
													<Card className='h-md-100 p-1'>
														<Card.Body className='position-relative'>
															<Row>
																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>Perímetro de prenda</h6>

																	{/* top thread */}
																	{/* <Row className="mb-2">
                                  <Form.Group as={Col} xs={12} sm={12} md={12} lg={12} controlId="formGridKeyGarment">
                                    <Form.Label>key Hilo de arriba<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size="sm" value={keygarmenttopthread} onChange={ (e)=> setKeygarmenttopthread(e.target.value)} type="text" disabled/>
                                  </Form.Group>
                                </Row> */}

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueGarment'
																		>
																			<Form.Label>
																				Hilo de arriba
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={valuegarmenttopthread}
																				onChange={(e) =>
																					setValuegarmenttopthread(
																						e.target.value
																					)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>

																	{/* lower thread */}
																	{/* <Row className="mb-2">
                                  <Form.Group as={Col} xs={12} sm={12} md={12} lg={12} controlId="formGridKeyGarment">
                                    <Form.Label>key Hilo de abajo<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size="sm" value={keygarmentlowerthread} onChange={ (e)=> setKeygarmentlowerthread(e.target.value)} type="text" disabled/>
                                  </Form.Group>
                                </Row> */}

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueGarment'
																		>
																			<Form.Label>
																				Hilo de abajo
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={valuegarmentlowerthread}
																				onChange={(e) =>
																					setValuegarmentlowerthread(
																						e.target.value
																					)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>

																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>Perímetro de flatseamer</h6>

																	{/* top thread */}
																	{/* <Row className="mb-2">
                                  <Form.Group as={Col} xs={12} sm={12} md={12} lg={12} controlId="formGridKeyGarment">
                                    <Form.Label>key Hilo de arriba<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size="sm" value={keyflatseamertopthread} onChange={ (e)=> setKeyflatseamertopthread(e.target.value)} type="text" disabled/>
                                  </Form.Group>
                                </Row> */}

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueGarment'
																		>
																			<Form.Label>
																				Hilo de arriba
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={valueflatseamertopthread}
																				onChange={(e) =>
																					setValueflatseamertopthread(
																						e.target.value
																					)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>

																	{/* lower thread */}
																	{/* <Row className="mb-2">
                                  <Form.Group as={Col} xs={12} sm={12} md={12} lg={12} controlId="formGridKeyGarment">
                                    <Form.Label>key Hilo de abajo<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size="sm" value={keyflatseamerlowerthread} onChange={ (e)=> setKeyflatseamerlowerthread(e.target.value)} type="text" disabled/>
                                  </Form.Group>
                                </Row> */}

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueGarment'
																		>
																			<Form.Label>
																				Hilo de abajo
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={valueflatseamerlowerthread}
																				onChange={(e) =>
																					setValueflatseamerlowerthread(
																						e.target.value
																					)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>

																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>Perímetro de cintura</h6>

																	{/* top thread */}
																	{/* <Row className="mb-2">
                                  <Form.Group as={Col} xs={12} sm={12} md={12} lg={12} controlId="formGridKeyGarment">
                                    <Form.Label>key Hilo de arriba<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size="sm" value={keywaisttopthread} onChange={ (e)=> setKeywaisttopthread(e.target.value)} type="text" disabled/>
                                  </Form.Group>
                                </Row> */}

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueGarment'
																		>
																			<Form.Label>
																				Hilo de arriba
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={valuewaisttopthread}
																				onChange={(e) =>
																					setValuewaisttopthread(e.target.value)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>

																	{/* lower thread */}
																	{/* <Row className="mb-2">
                                  <Form.Group as={Col} xs={12} sm={12} md={12} lg={12} controlId="formGridKeyGarment">
                                    <Form.Label>key Hilo de abajo<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size="sm" value={keywaistlowerthread} onChange={ (e)=> setKeywaistlowerthread(e.target.value)} type="text" disabled/>
                                  </Form.Group>
                                </Row> */}

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueGarment'
																		>
																			<Form.Label>
																				Hilo de abajo
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={valuewaistlowerthread}
																				onChange={(e) =>
																					setValuewaistlowerthread(
																						e.target.value
																					)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Tab>

										<Tab eventKey='internalcost' title='Costo interno'>
											<Row>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={12}
													md={12}
												>
													<Card className='h-md-100 p-1'>
														<Card.Body className='position-relative'>
															<Row>
																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>
																		Prenda producida totalmente en los talleres
																		de Sonder
																	</h6>

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueFullyproduced'
																		>
																			<Form.Label>
																				Porcentaje en decimales (20% = 0.2)
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={fullyproduced}
																				onChange={(e) =>
																					setFullyproduced(e.target.value)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>

																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>
																		Prenda mixta, una parte se produce en
																		talleres externos y parte en talleres
																		propios
																	</h6>

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueMixed'
																		>
																			<Form.Label>
																				Porcentaje en decimales (20% = 0.2)
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={mixedProduced}
																				onChange={(e) =>
																					setMixedProduced(e.target.value)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>

																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>
																		Prenda producida totalmente en talleres
																		externos
																	</h6>

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValueFullyExternalworkshop'
																		>
																			<Form.Label>
																				Porcentaje en decimales (20% = 0.2)
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={externalworkshop}
																				onChange={(e) =>
																					setExternalworkshop(e.target.value)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>

																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>
																		Prenda comprada (solo lleva la etiqueta)
																	</h6>

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValuePurchased'
																		>
																			<Form.Label>
																				Porcentaje en decimales (20% = 0.2)
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={purchased}
																				onChange={(e) =>
																					setPurchased(e.target.value)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>

																<Col xs={12} sm={12} lg={4} md={4}>
																	<h6>Prenda comprada + proceso en taller</h6>

																	<Row className='mb-2'>
																		<Form.Group
																			as={Col}
																			xs={12}
																			sm={12}
																			md={12}
																			lg={12}
																			controlId='formGridValuePurchased'
																		>
																			<Form.Label>
																				Porcentaje en decimales (20% = 0.2)
																				<span className='text-danger'>*</span>
																			</Form.Label>
																			<Form.Control
																				size='sm'
																				value={mixedPurchased}
																				onChange={(e) =>
																					setMixedPurchased(e.target.value)
																				}
																				type='number'
																				placeholder='Ingrese valor'
																			/>
																		</Form.Group>
																	</Row>
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Tab>
									</Tabs>
								</Col>

								<Col
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									className='d-flex justify-content-end mt-2'
								>
									<Button size='sm' variant='primary' type='submit'>
										Guardar
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
