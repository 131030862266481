/** @format */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Row, Card, Col, Badge, Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";
import { useProductContext } from "hooks/useProductContext";

const FormularioHdr = ({ setDisabledSemi, setKey }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Valida botón, habilita/deshabilita
	const [disabled, setDisabled] = useState(true);

	const [selectedRoadmap, setSelectedRoadmap] = useState({});

	const [roadmapOperations, setRoadmapOperations] = useState([]);

	const {
		handleProductById,
		product,
		selectRoadmap,
		handleRoadmapByProductId,
	} = useProductContext();

	useEffect(() => {
		if (Object.entries(selectRoadmap).length > 0) {
			setDisabledSemi(false);
			setDisabled(false);
			setSelectedRoadmap(selectRoadmap);
		} else {
			setDisabled(true);
			setSelectedRoadmap({});
		}
	}, [selectRoadmap]);

	const handleCancel = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/products/list`);
	};

	useEffect(() => {
		if (Object.entries(selectedRoadmap).length > 0) {
			setDisabled(false);

			setRoadmapOperations(
				selectedRoadmap.operations.map((operation) => {
					return {
						id: operation.id,
						denomination: operation.denomination,
						description: operation.description,
						time: operation.time,
					};
				})
			);
		} else {
			setDisabled(true);
			setRoadmapOperations([]);
		}
	}, [selectedRoadmap]);

	const deleteRoadmap = () => {
		//Borramos la selección de hoja de ruta
		setSelectedRoadmap({});
		setRoadmapOperations([]);
	};

	const handleAddHdr = async () => {
		if (Object.entries(selectedRoadmap).length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`Debe seleccionar una hoja de ruta.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.post("/hoja-ruta/addtoproduct", {
				product_id: product.id,
				hdr_id: selectedRoadmap.id,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			handleProductById(data.data.product_id);
			handleRoadmapByProductId(data.data.hoja_ruta_id);

			if (product.situation == 1) {
				setTimeout(() => {
					setDisabledSemi(false);
					setKey("semifinished");
				}, 800);
			} else {
				navigate(`/products/products/view/${product.id}`);
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	return (
		<>
			{/* FORM */}
			<Row>
				<Col className='mb-2' xs={12} sm={12} lg={5} md={5}>
					<Card className='mb-2 p-1 h-md-100'>
						<span>
							<p className='mb-1 fs--2 text-center'>
								¡Asignar una hoja de ruta al producto!
							</p>
						</span>

						<Card.Body>
							<Row>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridRoadmap'
								>
									<Form.Label className='fs--2'>
										Buscar hoja de ruta
										<span className='text-danger'>*</span>
									</Form.Label>
									<SearchAutocomplete
										setSelect={setSelectedRoadmap}
										modelSearch={"roadmap"}
										disabled={false}
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridRoadmapDenomination'
								>
									<span className='fs--2'>
										<Badge className='mt-1' bg='secondary'>
											{selectedRoadmap.denominacion}
										</Badge>
										{selectedRoadmap.denominacion ? (
											<FontAwesomeIcon
												icon='times'
												size='sm'
												className='cursor-pointer text-danger ms-2'
												onClick={() => deleteRoadmap()}
											/>
										) : (
											""
										)}
									</span>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Col>

				<Col className='mb-2' xs={12} sm={12} lg={7} md={7}>
					<Card className='mb-2 p-1 h-md-100'>
						<Card.Body>
							{Object.entries(selectedRoadmap).length > 0 && (
								<Row>
									<span>
										<p className='mb-3 fs--2 text-center'>
											¡Datos de la hoja de ruta seleccionada!
										</p>
									</span>

									<Col xs={12} sm={12} lg={12} md={12}>
										<p className='mb-2 fs--1'>
											<strong className='fs--2 text-600'>Código:</strong>{" "}
											{selectedRoadmap.codigo}
										</p>

										<p className='mb-2 fs--1'>
											<strong className='fs--2 text-600'>Denominación:</strong>{" "}
											{selectedRoadmap.denominacion}
										</p>
									</Col>

									<Col className='mt-3 mb-2' xs={12} sm={12} lg={12} md={12}>
										<>
											<p className='mb-2 fs--1'>
												<strong className='mb-3 fs--2 text-600'>
													Detalle de operaciones
												</strong>
											</p>
											<div className='table-responsive fs--2'>
												<Table striped className='border-bottom'>
													<thead className='bg-200 text-900'>
														<tr>
															<th className='border-0 text-start'>
																Denominación
															</th>
															<th className='border-0 text-start'>
																Descripción
															</th>
															<th className='border-0 text-start'>Tiempo</th>
														</tr>
													</thead>
													<tbody>
														{roadmapOperations.map((operation) => (
															<tr key={operation.id} className='border-200'>
																<td className='align-middle text-start'>
																	<strong>{operation.denomination}</strong>
																</td>

																<td className='align-middle text-start'>
																	{operation.description}
																</td>

																<td className='align-middle text-start'>
																	{operation.time}
																</td>
															</tr>
														))}
													</tbody>
												</Table>
											</div>
										</>
									</Col>
								</Row>
							)}

							{Object.entries(selectedRoadmap).length == 0 && (
								<Row>
									<Col xs={12} sm={12} lg={12} md={12}>
										<span>
											<p className='mb-1 fs--2 text-center'>
												¡No se seleccionó una hoja de ruta!
											</p>
										</span>
									</Col>
								</Row>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col className='d-flex justify-content-end'>
					<Button size='sm' variant='danger' onClick={() => handleCancel()}>
						Cancelar
					</Button>

					<Button
						className='ms-1'
						variant='primary'
						size='sm'
						disabled={disabled}
						onClick={() => {
							handleAddHdr();
						}}
					>
						Guardar
					</Button>
				</Col>
			</Row>
		</>
	);
};

FormularioHdr.propTypes = {
	setDisabledSemi: PropTypes.func,
	setKey: PropTypes.func,
};

export default FormularioHdr;
