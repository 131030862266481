/** @format */

import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

export const getMachine = async (cod_maq) => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get(
		`/work-center/machine-by-id/${cod_maq}`
	);

	const machine = data.data;
	return machine;
};

export const getMachineTypes = async () => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get("/work-center/machine-types");
	const types = data.data;
	const typesFormatted = types.map((type) => {
		const typeObj = {
			value: type.id,
			label: type.description,
		};
		return typeObj;
	});

	return typesFormatted;
};

export const getProveedores = async () => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get("/suppliers");

	const suppliers = data.data;
	const suppliersFormatted = suppliers.map((supplier) => {
		const obj = {
			label: supplier.description,
			value: supplier.id,
		};
		return obj;
	});

	return suppliersFormatted;
};
