/** @format */

import React, { useEffect } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import EstamperiaProductTable from "./EstamperiaProductTable";
import { useEstamperia } from "hooks/useEstamperia";

const EstamperiaProductList = () => {
	const { relacionProdEst, getRelacionProdEst } = useEstamperia();

	useEffect(() => {
		getRelacionProdEst();
	}, []);

	// useEffect(() => {
	//     if(!!relacionProdEst.length){
	//         const atributos = relacionProdEst.map(elem => elem.atributos)

	//         setAtributos(atributos)
	//     }

	// }, [relacionProdEst])

	return (
		<Card className='mb-3 border-bottom border-200'>
			<Card.Header className='bg-light border-bottom border-200'>
				<Row>
					<Col>
						<h5 className='mb-0'>Listado de relación Productos/Estampas</h5>
					</Col>
					<Col className='d-flex justify-content-end'>
						<Button
							as={Link}
							to='/estamperia/relacion-producto-estampa'
							variant='success'
							size='sm'
							className='border-300'
						>
							<FontAwesomeIcon icon='plus' /> Nuevo
						</Button>
					</Col>
				</Row>
			</Card.Header>

			<Card.Body className='bg-light'>
				<Tab.Content>
					{relacionProdEst.length > 0 && (
						<EstamperiaProductTable relacionProdEst={relacionProdEst} />
					)}
				</Tab.Content>
			</Card.Body>
		</Card>
	);
};

export default EstamperiaProductList;
