/** @format */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { v4 as uuid } from "uuid";
import DatePicker from "react-datepicker";

import Error from "components/error/Error";
import { year } from "data/calendar/year";
import { getTemporadas } from "data/calendar/temporadas";
import { tipoEventosDataObj } from "./data/tipoEventos";
import { updateEvento } from "data/calendar/eventos";
// import { use } from "echarts";
import { formValidations } from "data/calendar/formValidations";

const CalendarFormEdit = ({
	eventContent,
	handleOverlap,
	eventEdit,
	setEventEdit,
	handleEventEdited,
	setIsOpenEventModal,
	isOpenEventModal,
	editFromEventListComponent = false,
}) => {
	// const [formData, setFormData] = useState({});
	const [error, setError] = useState({});
	const [temporadas, setTemporadas] = useState([]);
	const [tipoEventos, setTipoEventos] = useState([]);

	const [eventId, setEventId] = useState("");
	const [newName, setNewName] = useState("");
	const [newStartDate, setNewStartDate] = useState();
	const [newEndDate, setNewEndDate] = useState();
	const [newYear, setNewYear] = useState(0);
	const [newTemporada, setNewTemporada] = useState(0);
	const [newTipoEvento, setNewTipoEvento] = useState(0);
	const [newDescription, setNewDescription] = useState("");

	const toastId = React.useRef(null);

	useEffect(async () => {
		// setFormData({});
		const temp = await getTemporadas();
		setTemporadas(temp);
		//seteamos los tipos de eventos
		const tipoEventosArrayTransform = Object.values(tipoEventosDataObj);
		setTipoEventos(tipoEventosArrayTransform);
	}, []);

	useEffect(() => {
		//Completamos los campos del formulario

		if (!editFromEventListComponent) {
			setEventId(eventContent.id);
			setNewName(eventContent.title);
			setNewStartDate(eventContent.start);
			setNewEndDate(eventContent.end);
			setNewYear(eventContent.extendedProps.year);
			setNewTemporada(eventContent.extendedProps.temporadas.id);
			setNewTipoEvento(eventContent.extendedProps.tipo_evento);
			setNewDescription(eventContent.extendedProps.description);
		}

		if (editFromEventListComponent) {
			setEventId(eventContent.id);
			setNewName(eventContent.title);

			const startDate = new Date(eventContent.start);
			setNewStartDate(startDate);

			const endDate = new Date(eventContent.end);
			setNewEndDate(endDate);
			setNewYear(eventContent.year);
			setNewTemporada(eventContent.temporadas.id);
			setNewTipoEvento(eventContent.tipo_evento);
			setNewDescription(eventContent.description);
		}
	}, [eventContent.title]);

	const handleEditSubmit = async (e) => {
		e.preventDefault();

		const eventData = {
			id: eventId,
			title: newName,
			start: newStartDate,
			end: newEndDate,
			year: newYear,
			temporada: newTemporada,
			tipoEvento: newTipoEvento,
			description: newDescription,
			allDay: true,
		};

		const errors = await formValidations(eventData, handleOverlap, eventEdit);

		if (errors.status) return setError(errors);

		//funcion para actualizar evento
		const updateEvent = await updateEvento(eventData);

		if (updateEvent.status === "ok") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(updateEvent.message, {
					role: "alert",
					theme: "dark",
				});
			}
			handleEventEdited();
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}

		if (eventEdit) setEventEdit(!eventEdit);

		if (isOpenEventModal) setIsOpenEventModal(!isOpenEventModal);
	};

	const handleClose = () => {
		setEventEdit(!eventEdit);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Form onSubmit={(e) => handleEditSubmit(e)}>
				<Error status={error.status} message={error.message} />
				<Row className='justify-content-center'>
					<Form.Group
						className='mb-3'
						as={Col}
						xs={12}
						sm={12}
						md={12}
						lg={12}
						controlId='titleInput'
					>
						<Form.Label className='fs-0'>Titulo</Form.Label>
						<Form.Control
							size='md'
							type='text'
							name='title'
							value={newName}
							required
							onChange={(e) => setNewName(e.target.value)}
						/>
					</Form.Group>
					<Form.Group
						className='mb-3'
						as={Col}
						xs={12}
						sm={12}
						md={6}
						lg={6}
						controlId='startDate'
					>
						<Form.Label className='fs-0'>Fecha inicio</Form.Label>
						<DatePicker
							selected={newStartDate}
							onChange={(date) => {
								setNewStartDate(date);
							}}
							className='form-control'
							placeholderText='DD-MM-YYYY'
							dateFormat='dd-MM-yyyy'
							// showTimeSelect
						/>
					</Form.Group>
					<Form.Group
						className='mb-3'
						as={Col}
						xs={12}
						sm={12}
						md={6}
						lg={6}
						controlId='endDate'
					>
						<Form.Label className='fs-0'>Fecha fin</Form.Label>
						<DatePicker
							selected={newEndDate}
							onChange={(date) => {
								setNewEndDate(date);
							}}
							className='form-control'
							placeholderText='DD-MM-YYYY'
							dateFormat='dd-MM-yyyy'
							// showTimeSelect
						/>
					</Form.Group>

					<Form.Group
						className='mb-3'
						controlId='year'
						as={Col}
						xs={12}
						sm={12}
						md={6}
						lg={6}
					>
						<Form.Label className='fs-0'>Año</Form.Label>
						<Form.Select
							name='year'
							value={newYear}
							onChange={(e) => {
								setNewYear(e.target.value);
							}}
							required
						>
							<option value={0} disabled>
								-- Seleccione año --
							</option>
							<option value={year()}>{year()}</option>
							<option value={year() + 1}>{year() + 1}</option>
						</Form.Select>
					</Form.Group>

					<Form.Group
						className='mb-3'
						controlId='temporada'
						as={Col}
						xs={12}
						sm={12}
						md={6}
						lg={6}
					>
						<Form.Label className='fs-0'>Temporada</Form.Label>
						<Form.Select
							name='temporada'
							value={newTemporada}
							onChange={(e) => {
								setNewTemporada(e.target.value);
							}}
							required
						>
							<option value={"0"} disabled>
								-- Seleccione temporada --
							</option>
							{temporadas.map((temporada) => {
								return (
									<option key={temporada.id} value={temporada.id}>
										{temporada.name}
									</option>
								);
							})}
						</Form.Select>
					</Form.Group>

					<Form.Group
						className='mb-3'
						controlId='tipoEvento'
						as={Col}
						xs={12}
						sm={12}
						md={12}
						lg={12}
					>
						<Form.Label className='fs-0'>Tipo de evento</Form.Label>
						<Form.Select
							name='tipoEvento'
							value={newTipoEvento}
							onChange={(e) => {
								setNewTipoEvento(e.target.value);
							}}
							required
						>
							<option value={"0"} disabled>
								-- Seleccione tipo de evento --
							</option>
							{tipoEventos.map((tipoEvento) => {
								return (
									<option key={tipoEvento.id} value={tipoEvento.name}>
										{tipoEvento.name}
									</option>
								);
							})}
						</Form.Select>
					</Form.Group>

					<Form.Group
						className='mb-3'
						controlId='description'
						as={Col}
						xs={12}
						sm={12}
						md={12}
						lg={12}
					>
						<Form.Label className='fs-0'>Description</Form.Label>
						<Form.Control
							size='sm'
							as='textarea'
							rows={3}
							name='description'
							defaultValue={newDescription}
							onChange={(e) => setNewDescription(e.target.value)}
						/>
					</Form.Group>

					<Row className='justify-content-center'>
						<Col className='text-center'>
							<Button
								variant='danger'
								type='button'
								onClick={handleClose}
								className='px-4 mx-1'
							>
								Cerrar
							</Button>
						</Col>
						<Col className='text-center'>
							<Button variant='primary' type='submit' className='px-4 mx-0'>
								Editar
							</Button>
						</Col>
					</Row>
				</Row>
			</Form>
		</>
	);
};
CalendarFormEdit.propTypes = {
	setInitialEvents: PropTypes.func,
	initialEvents: PropTypes.array,
	handleOverlap: PropTypes.func,
	eventEdit: PropTypes.bool,
	setEventEdit: PropTypes.func,
	eventContent: PropTypes.object,
	handleEventEdited: PropTypes.func,
	setIsOpenEventModal: PropTypes.func,
	isOpenEventModal: PropTypes.bool,
	editFromEventListComponent: PropTypes.bool,
};

export default CalendarFormEdit;
