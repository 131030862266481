/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import {
	Row,
	Col,
	Card,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import { useCentros } from "hooks/useCentros";
import { BsGear } from "react-icons/bs";
import { useAuthContext } from "hooks/useAuthContext";

const MachinePagination = ({ machines, centerId }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const perPage = 5;
	const data = machines;

	const { getCenter } = useCentros();
	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(data, perPage);

	const deleteMachine = async (dt) => {
		const confirmar = confirm("Está a punto de eliminar una máquina!");

		if (confirmar) {
			try {
				await axiosWithToken.delete(
					`/work-center/delete-machine/${dt.cod_maq}`
				);
				getCenter(centerId);
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<>
			<h6 className='text-center'>Máquinas asociadas</h6>
			<Row className='mt-3'>
				<Col lg={12}>
					{paginatedDetails.map((dt, index) => (
						<Card
							key={index}
							className='mb-2 square border-start border-start-2 border-primary'
						>
							<span>
								<Row>
									<Col xs={8} sm={8} lg={8} md={8}>
										<p className='m-1 p-1 fs--1'>
											<strong>
												<BsGear /> #{dt.cod_maq}: {dt.description}
											</strong>
										</p>
									</Col>
									<Col xs={4} sm={4} md={4} lg={4}>
										<div className='d-flex justify-content-end'>
											<OverlayTrigger
												placement='top'
												overlay={<Tooltip>Ver</Tooltip>}
											>
												<Button
													//onClick={() => getCenter(dt.id)}
													as={Link}
													to={`/work-center/machine-list/view/${dt.cod_maq}`}
													variant='transparent'
													size='sm'
													className='text-primary me-2'
												>
													<FontAwesomeIcon icon='eye' />
												</Button>
											</OverlayTrigger>
											{/*  <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Editar</Tooltip>}
                        >
                            <Button
                            as={Link}
                            to={`/centros-de-trabajo/listado-centros/formCentroEdit/${dt.id}`}
                            variant="transparent"
                            size="sm"
                            className="text-success me-2"
                            >
                            <FontAwesomeIcon icon="edit" />
                            </Button>
                        </OverlayTrigger> */}
											<OverlayTrigger
												placement='top'
												overlay={<Tooltip>Eliminar</Tooltip>}
											>
												<Button
													onClick={() => deleteMachine(dt)}
													variant='transparent'
													size='sm'
													className=' text-danger me-2'
												>
													<FontAwesomeIcon icon='trash' />
												</Button>
											</OverlayTrigger>
										</div>
									</Col>
								</Row>
							</span>
						</Card>
					))}
				</Col>
			</Row>
			<div className='d-flex justify-content-center'>
				<div>
					<Button
						variant='falcon-default'
						size='sm'
						disabled={!canPreviousPage}
						onClick={prevPage}
						className='me-2'
						trigger='focus'
					>
						<FontAwesomeIcon icon='chevron-left' />
					</Button>
				</div>

				<ul className='pagination mb-0'>
					{paginationArray.map((page) => (
						<li
							key={page}
							className={classNames({ active: currentPage === page })}
						>
							<Button
								size='sm'
								variant='falcon-default'
								className='page me-2'
								onClick={() => goToPage(page)}
							>
								{page}
							</Button>
						</li>
					))}
				</ul>
				<div>
					<Button
						variant='falcon-default'
						size='sm'
						disabled={!canNextPage}
						onClick={nextPage}
						trigger='focus'
					>
						<FontAwesomeIcon icon='chevron-right' />
					</Button>
				</div>
			</div>
		</>
	);
};

MachinePagination.propTypes = {
	machines: PropTypes.array,
	centerId: PropTypes.number,
};

export default MachinePagination;
