import { useMemo } from "react";

export default function useColumnsMolds() {
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: '#'
      },
      {
        accessor: 'code',
        Header: 'Código'
      },
      {
        accessor: 'description',
        Header: 'Descripción'
      },
      {
      accessor: 'created_at',
      Header: 'Fecha de alta'
      },
      {
      accessor: 'acciones',
      Header: 'Acciones'
      }
    ],
    []
  );

  return columns;
}
