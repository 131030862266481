/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Nav, Row, Col } from "react-bootstrap";
import { getFlatRoutes } from "helpers/utils";
import NavbarNavLink from "./NavbarNavLink";

const NavbarDropdownProducts = ({ items }) => {
	const routes = getFlatRoutes(items);
	return (
		<Row>
			<Col xs={6} xxl={6}>
				<Nav className='flex-column'>
					<NavbarNavLink title='Códigos' />
					{routes.ingenieriaDeProductos.slice(0, 3).map((route) => (
						<NavbarNavLink key={route.name} route={route} />
					))}
					<NavbarNavLink title='Moldes' />
					{routes.ingenieriaDeProductos.slice(9, 10).map((route) => (
						<NavbarNavLink key={route.name} route={route} />
					))}
				</Nav>
			</Col>
			<Col xs={6} xxl={6}>
				<Nav className='flex-column'>
					<NavbarNavLink title='Productos' />
					{routes.ingenieriaDeProductos.slice(3, 6).map((route) => (
						<NavbarNavLink key={route.name} route={route} />
					))}
					<NavbarNavLink title='Materias primas' />
					{routes.ingenieriaDeProductos.slice(6, 9).map((route) => (
						<NavbarNavLink key={route.name} route={route} />
					))}
				</Nav>
			</Col>
		</Row>
	);
};

NavbarDropdownProducts.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
			name: PropTypes.string.isRequired,
			to: PropTypes.string,
			children: PropTypes.array,
		}).isRequired
	).isRequired,
};

export default NavbarDropdownProducts;
