/** @format */

//React
import React, { useEffect, useState } from "react";
import { useParams /* , useNavigate */ } from "react-router-dom";
import PropTypes from "prop-types";

//Components
import {
	Row,
	Col,
	Card,
	Tab,
	// Button,
	Badge,
} from "react-bootstrap";
import Spinner from "../../spinner/Spinner";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from "components/common/Background";

//Icons
import {
	BsTools,
	BsFillFilePersonFill,
	BsJournalCode,
	BsFillRecord2Fill,
	BsHouse,
} from "react-icons/bs";

//Helpers
import { camelize } from "../../../helpers/utils";

//Styles
import "react-toastify/dist/ReactToastify.css";

//Hooks
import { useCentros } from "hooks/useCentros";

const Cards = ({ title, mtm = null, info = null, icon = null }) => {
	return (
		<React.Fragment key={`key-${title}`}>
			{mtm != null ? (
				<div key={title}>
					<h6
						className={`mb-0 hover-actions-trigger ${
							!mtm && "mt-2"
						} d-flex align-items-center gap-1`}
						id={camelize(`View`)}
					>
						{icon} {title}
					</h6>
					{mtm.map((elem) => {
						return (
							<Card.Text
								className='fs--1 mt-1 mb-0 px-2'
								style={{ lineHeight: "normal" }}
								key={elem.id}
							>
								{elem.description}{" "}
								<Badge bg='primary'>
									{elem.center?.padre && `#${elem.center.id}`}
								</Badge>
							</Card.Text>
						);
					})}
				</div>
			) : (
				<h6
					key={title}
					className={`mb-0 hover-actions-trigger ${
						!mtm && "mt-2"
					} d-flex align-items-center gap-1`}
					id={camelize(`View`)}
				>
					{icon} {title}
				</h6>
			)}
			<Card.Text
				className='fs--1 mt-1 mb-0 px-2'
				style={{ lineHeight: "normal" }}
			>
				{info}
			</Card.Text>
		</React.Fragment>
	);
};

const ManPowerView = () => {
	const [mo, setMo] = useState({});
	const [loading, setLoading] = useState(true);

	const { id_mo } = useParams();

	const { getManPower, manPower } = useCentros();

	/* const navigate = useNavigate(); */

	useEffect(() => {
		getManPower(id_mo);
	}, []);

	useEffect(() => {
		setMo(manPower);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
		// console.log('cent', mo);
	}, [manPower]);

	return (
		<>
			{!loading ? (
				<Card className='mb-3 border-bottom border-200'>
					<Card.Header className='bg-light border-bottom border-200'>
						<Row className='align-items-end g-2'>
							<Col className='d-flex justify-content-between'>
								<h5
									className='mb-0 hover-actions-trigger'
									id={camelize(`View`)}
								>
									Vista de Mano de Obra
								</h5>
								{/* <Button variant="warning" onClick={() => navigate(`/work-center/manpower-list/edit-manpower/${id_mo}`)}>
                    <FontAwesomeIcon icon="edit" />
                    {" "} EDITAR
                  </Button> */}
							</Col>
						</Row>
					</Card.Header>

					<Card.Body className='bg-light'>
						<Tab.Content>
							<Card className='mb-2'>
								<Background
									image=''
									className='bg-card'
									style={{
										borderTopRightRadius: "0.375rem",
										borderBottomRightRadius: "0.375rem",
									}}
								/>
								<Card.Body className='position-relative'>
									<Row>
										<Col>
											<h5>
												{mo.description} #{mo.id}{" "}
												<Badge bg='primary'>Mano de Obra</Badge>
											</h5>
										</Col>
									</Row>
								</Card.Body>
							</Card>

							<Row>
								<Col lg={4} md={4} sm={5} xs={12}>
									<Card className='my-1 border-bottom border-100'>
										<Card.Header className='bg-light border-bottom border-200'>
											<Row className='align-items-end g-2'>
												<Col>
													{mo.machine_type.length ? (
														<Cards
															title='Tipo de Máquina:'
															mtm={mo.machine_type}
															icon={<BsTools />}
														/>
													) : (
														"No cuenta con tipo de máquinas asociadas"
													)}
													{mo.centers.length ? (
														<Cards
															title='Centro de trabajo asociado:'
															mtm={mo.centers}
															icon={<BsHouse />}
														/>
													) : (
														""
													)}
													<Cards
														title='Tipo MO:'
														info={mo.manpower_type?.description}
														icon={<BsFillFilePersonFill />}
													/>
													<Cards
														title='Cod.Recurso:'
														info={mo.cod_recurso}
														icon={<BsJournalCode />}
													/>
													{/* <Cards
														title='Eficiencia:'
														info={mo.eficiencia}
														icon={<BsGraphUp />}
													/> */}
													<Cards
														title='Estado:'
														info={mo.status === 1 ? "Activo" : "Inactivo"}
														icon={<BsFillRecord2Fill />}
													/>
												</Col>
											</Row>
										</Card.Header>
									</Card>
								</Col>
							</Row>
						</Tab.Content>
					</Card.Body>
				</Card>
			) : (
				<Spinner />
			)}
		</>
	);
};

Cards.propTypes = {
	title: PropTypes.string,
	mtm: PropTypes.array,
	info: PropTypes.any,
	icon: PropTypes.node,
};

export default ManPowerView;
