import { useMemo } from "react";

export default function useColumnsEstamperia() {
  const columns = useMemo(
    () => [
        {
        accessor: 'imagen',
        Header: 'Imagen'
        },
        {
        accessor: 'nombre',
        Header: 'Nombre'
        },
        {
          accessor:'obs',
          Header:'Observaciones'
        },
        {
        accessor: 'acciones',
        Header: 'Acciones'
        }
    ],
    []
  );

  return columns;
}
