/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
	Form,
	Row,
	Col,
	Button,
	Card,
	Tab,
	ListGroup,
	Badge,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [status, setStatus] = useState("");
	//const [titulo, setTitulo] = useState('')
	const [accion, setAccion] = useState("");

	const [selectAttribute, setSelectAttribute] = useState("");
	const [selectField, setSelectField] = useState("");
	//const [valueId, setValueId] = useState(null)

	const [attributes, setAttributes] = useState([]);
	const [attributesBackup, setAttributesBackup] = useState([]);
	const [fields, setFields] = useState([]);
	const [values, setValues] = useState([]);
	const [row, setRow] = useState(null);

	//const [disabled, setDisabled] = useState(true)

	const [selectedAttributeFieldValue, setSelectedAttributeFieldValue] =
		useState([]);

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			//setTitulo('Modificar')
			setAccion("Editar");
			getCombinationById();
		} else {
			//setTitulo('Agregar')
			setAccion("Guardar");
		}

		getAttributes();
	}, []);

	const getCombinationById = async () => {
		const response = await axiosWithToken.get(`/combination/view/${id}`);

		setCode(response.data.data.combination.code);
		setDescription(response.data.data.combination.description);
		setStatus(response.data.data.combination.status);

		if (response.data.data.detail) {
			let details = Object.values(response.data.data.detail);

			setSelectedAttributeFieldValue(
				details.map((detail) => {
					return {
						id: detail.value.id,
						description: `${detail.attribute.description} > ${detail.field.description} > ${detail.value.description}`,
					};
				})
			);
		}
	};

	const getAttributes = async () => {
		const response = await axiosWithToken.get("/attributes");

		if (response.data.data) {
			setAttributes(
				Object.values(response.data.data).map((r) => ({ ...r, valor: false }))
			);
			setAttributesBackup(Object.values(response.data.data));
		} else {
			setAttributes([]);
			setAttributesBackup([]);
		}
	};

	const getFields = async (attibute_id, i) => {
		setSelectAttribute(attibute_id);
		setValues([]);
		const response = await axiosWithToken.get(
			`/attributefields/byattribute/${attibute_id}`
		);

		if (response.data.data) {
			setFields(Object.values(response.data.data));
			setRow(i);
		} else {
			setFields([]);
		}
	};

	const getValues = async (field_id) => {
		console.log(
			"imprimimos para ver el arreglo del los valores seleccionados",
			selectedAttributeFieldValue
		);

		setSelectField(field_id);

		const responseRelation = await axiosWithToken.get(
			`/attributefield/attributeid/${selectAttribute}/fieldid/${field_id}`
		);

		let parametro = selectedAttributeFieldValue.length
			? selectedAttributeFieldValue
			: null;

		const response = await axiosWithToken.post("/attributefieldvalues/same", {
			relation_id: responseRelation.data.data.id,
			arrayParam: parametro,
		});

		console.log(
			"valoreeeeeeeeees tengo que friltrar",
			response.data.data.valores
		);

		if (response.data.data.valores) {
			setValues(Object.values(response.data.data.valores));
		} else {
			setValues([]);
		}
	};

	const store = async (e) => {
		e.preventDefault();

		//console.log('prueba datos a enviar', 'code: ', code, 'descripcion: ', description, 'image: ', files , 'status: ', status, 'obj: ',selectedAttributeFieldValue  );

		if (selectedAttributeFieldValue.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					"¡No tiene valores agregados a la combinación!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//error
			return;
		}

		const data = new FormData();

		data.append("code", code);
		data.append("description", description);
		data.append("status", status);
		data.append("objDetails", JSON.stringify(selectedAttributeFieldValue));

		console.log("imprimimos data para ver que tiene", data);

		try {
			const respuesta = await axiosWithToken.post("/combination", data);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setTimeout(() => {
					navigate("/configurador/combinaciones/listado");
				}, 1000);
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	const update = async (e) => {
		e.preventDefault();

		if (selectedAttributeFieldValue.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					"¡No tiene valores agregados a la combinación!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//error
			return;
		}

		const respuesta = await axiosWithToken.put(`/combination/${id}`, {
			code: code,
			description: description,
			status: status,
			objDetails: selectedAttributeFieldValue,
		});

		if (respuesta.status == 200) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Modificado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/configurador/combinaciones/listado");
			}, 1000);
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${respuesta.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	const loadSelectedValues = async (objeto) => {
		console.log("que tiene objeto al pasarse a la funcion", objeto);

		const attribute = await axiosWithToken.get(`/attribute/${selectAttribute}`);
		const field = await axiosWithToken.get(`/attributefield/${selectField}`);

		const array = attributes;

		const newObject = {
			id: objeto.id,
			description: `${attribute.data.data.description} > ${field.data.data.description} > ${objeto.description}`,
		};

		const found = selectedAttributeFieldValue.find(
			(element) => element.id == objeto.id
		);

		if (!found) {
			setSelectedAttributeFieldValue([
				...selectedAttributeFieldValue,
				newObject,
			]);
			//setValueId(objeto.id)
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("¡El valor ya fue agregado!", {
					role: "alert",
					theme: "dark",
				});
			}
		}

		array[row] = { ...attributes[row], valor: true };
		setAttributes(array);
		setFields([]);
		setValues([]);
	};

	const remover = (id) => {
		console.log("remover id: ", id);
		const newObject = selectedAttributeFieldValue.filter(
			(select) => select.id !== id
		);
		setSelectedAttributeFieldValue(newObject);
		setFields([]);
		setValues([]);
	};

	const handleSearch = (e) => {
		var text = e.target.value;
		const data = attributesBackup;

		const newData = data.filter(function (item) {
			const itemDataDescp = item.description.toUpperCase();
			const textData = text.toUpperCase();
			return itemDataDescp.indexOf(textData) > -1;
		});

		setAttributes(newData);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={id ? update : store} encType='multipart/form-data'>
							<Row>
								<Col className='mb-3' xs={12} sm={12} lg={8} md={8}>
									<Card className='mb-3 border-bottom border-200 h-md-100'>
										<Row className='g-3 p-4'>
											<h6 className='mb-4 text-center'>
												Ingresar datos de la combinación
											</h6>

											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={6}
												lg={6}
												controlId='formGridCod'
											>
												<Form.Label>Código</Form.Label>
												<Form.Control
													size='sm'
													value={code}
													onChange={(e) => setCode(e.target.value)}
													type='text'
													placeholder='Código'
												/>
											</Form.Group>

											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={6}
												lg={6}
												controlId='formGridDescription'
											>
												<Form.Label>Descripción</Form.Label>
												<Form.Control
													size='sm'
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													type='text'
													placeholder='Ingrese descripción'
												/>
											</Form.Group>

											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={12}
												lg={12}
												controlId='formGridStatus'
											>
												<Form.Label>Estado</Form.Label>
												<Form.Select
													value={status}
													size='sm'
													onChange={(e) => setStatus(e.target.value)}
													aria-label='select-status'
												>
													<option value='' /* disabled */>
														Seleccionar...
													</option>
													<option value='0'>Deshabilitado</option>
													<option value='1'>Habilitado</option>
												</Form.Select>
											</Form.Group>
										</Row>
									</Card>
								</Col>
							</Row>

							<Row className='g-3 mb-3'>
								<Col xs={12} sm={12} lg={4} md={4}>
									<Card className='h-md-100'>
										<Card.Header className='pb-0'>
											<h6 className='mb-0 mt-2'>Atributos</h6>
										</Card.Header>

										<Card.Body as={Flex} justifyContent='between'>
											<Row>
												<Col xs={12} sm={12} lg={12} md={12} className='mb-2'>
													<Form.Control
														size='sm'
														onChange={handleSearch}
														type='text'
														placeholder='Buscador...'
													/>
												</Col>

												<Col xs={12} sm={12} lg={12} md={12}>
													<ListGroup
														style={{ width: 100 + "%" }}
														as='ol'
														numbered
													>
														{attributes.map((attribute, i) => (
															<ListGroup.Item key={attribute.id} as='li'>
																<OverlayTrigger
																	placement='top'
																	overlay={
																		<Tooltip>Seleccionar atributo</Tooltip>
																	}
																>
																	<Button
																		/* disabled={i!= 0 && attributes[i-1].valor == false} */ size='sm'
																		variant='primary'
																		onClick={() => getFields(attribute.id, i)}
																	>
																		{attribute.description}
																		{"  >"}
																	</Button>
																</OverlayTrigger>
															</ListGroup.Item>
														))}
													</ListGroup>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>

								<Col xs={12} sm={12} lg={4} md={4}>
									<Card className='h-md-100'>
										<Card.Header className='pb-0'>
											<h6 className='mb-0 mt-2'>Campos</h6>
										</Card.Header>

										<Card.Body as={Flex} justifyContent='between'>
											<ListGroup style={{ width: 100 + "%" }} as='ol' numbered>
												{fields.map((field) => (
													<ListGroup.Item key={field.id} as='li'>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Seleccionar campo</Tooltip>}
														>
															<Button
																size='sm'
																variant='primary'
																onClick={() => getValues(field.id)}
															>
																{field.description}
																{"  >"}
															</Button>
														</OverlayTrigger>
													</ListGroup.Item>
												))}
											</ListGroup>
										</Card.Body>
									</Card>
								</Col>

								<Col xs={12} sm={12} lg={4} md={4}>
									<Card className='h-md-100'>
										<Card.Header className='pb-0'>
											<h6 className='mb-0 mt-2'>Valores</h6>
										</Card.Header>

										<Card.Body as={Flex} justifyContent='between'>
											<ListGroup style={{ width: 100 + "%" }} as='ol' numbered>
												{values.map((value) => (
													<ListGroup.Item key={value.id} as='li'>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Seleccionar valor</Tooltip>}
														>
															<Button
																size='sm'
																variant='primary'
																onClick={() => loadSelectedValues(value)}
															>
																{value.description}
																{"  >"}
															</Button>
														</OverlayTrigger>
													</ListGroup.Item>
												))}
											</ListGroup>
										</Card.Body>
									</Card>
								</Col>
							</Row>

							<Row>
								<Col xs={12} sm={12} lg={12} md={12}>
									<Card className='mb-3 border-bottom border-200'>
										<Card.Header className='bg-light border-bottom border-200'>
											<Row className='align-items-end g-2'>
												<Col>
													<h6
														className='mb-0 hover-actions-trigger'
														id='Formulario'
													>
														Detalle de la combinación
													</h6>
												</Col>
											</Row>
										</Card.Header>
										<Card.Body className='bg-light'>
											<Tab.Content>
												<Row as={Flex} justifyContent='between'>
													<Col md={12} lg={12} className='mb-4 mb-lg-0'>
														{selectedAttributeFieldValue.length ? (
															selectedAttributeFieldValue.map((safv, index) => (
																<Card
																	key={safv.id}
																	className='mb-3 p-3 square border-start border-start-2 border-primary'
																>
																	<Row as={Flex} justifyContent='between'>
																		<Col xs={12} sm={12} lg={8} md={8}>
																			<span key={index}>
																				<p className='mb-1 fs--1'>
																					<Badge pill bg='primary'>
																						{index}
																					</Badge>{" "}
																					<strong>Descripción:</strong>{" "}
																					{safv.description}
																					<OverlayTrigger
																						placement='top'
																						overlay={
																							<Tooltip>Eliminar</Tooltip>
																						}
																					>
																						<Button
																							onClick={() => remover(safv.id)}
																							variant='transparent'
																							size='sm'
																							className=' text-danger me-2'
																						>
																							<span className='badge bg-danger'>
																								<FontAwesomeIcon icon='trash' />{" "}
																								Eliminar
																							</span>
																						</Button>
																					</OverlayTrigger>
																				</p>
																			</span>
																		</Col>
																	</Row>
																</Card>
															))
														) : (
															<h6 className='font-black text-3xl text-center'>
																¡No hay valores seleccionados aún!
															</h6>
														)}
													</Col>
												</Row>
											</Tab.Content>
										</Card.Body>
									</Card>
								</Col>
							</Row>

							<Row>
								<Col className='d-flex justify-content-end'>
									<Button size='sm' variant='primary' type='submit'>
										{accion}
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};
export default Formulario;
