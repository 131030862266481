/** @format */

import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

export const getEmployees = async () => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get("/work-center/manpower-list");
	const employees = data.data;
	return employees;
};
