/** @format */

import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const CentrosContext = createContext();

const CentrosProvider = ({ children }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [centros, setCentros] = useState([]);
	const [lastLevels, setLastLevels] = useState([]);
	const [center, setCenter] = useState([]);
	const [machines, setMachines] = useState([]);
	const [machine, setMachine] = useState([]);
	const [machine_types, setMachineType] = useState([]);
	const [manPower, setManPower] = useState([]);
	const [manPower_types, setManPowerType] = useState([]);
	const [machinesById, setMachinesById] = [];
	const [manPowerByMachine, setManPowerByMachine] = useState([]);
	const [addOp, setAddOp] = useState(false);

	useEffect(() => {
		setCentros([]);
		setCenter([]);
		setMachines([]);
		setMachine([]);
		setMachineType([]);
		setManPower([]);
		setManPowerType([]);
		setManPowerByMachine([]);
	}, []);

	const handleAddOp = (status) => {
		setAddOp(status);
	};

	const toastId = React.useRef(null);

	/* Centros */
	const getAllCentros = async () => {
		const { data } = await axiosWithToken.get(
			"/centros-de-trabajo/listado-centros"
		);
		setCentros(data.data);
	};

	const getLastLevels = async () => {
		const { data } = await axiosWithToken.get(
			"/centros-de-trabajo/listado-centros/last-levels"
		);
		setLastLevels(data.data);
	};

	const getCenter = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/centros-de-trabajo/listado-centros/${id}`
			);
			data.data.countChilds = data.countChilds;
			//data.data[0].countMachines = data.countMachines;

			setCenter(data.data);
		} catch (error) {
			console.log(error.response.data.message);
		}
	};

	/* Maquina */
	const getAllMachines = async () => {
		try {
			const { data } = await axiosWithToken.get("/work-center/machine-list");
			setMachines(data.data);
		} catch (error) {
			console.log(error.response.data.message);
		}
	};

	const getAllMachineTypes = async () => {
		try {
			const { data } = await axiosWithToken.get("/work-center/machine-types");
			setMachineType(data.data);
		} catch (error) {
			console.log("Error", error.response.data);
		}
	};

	const getMachinesById = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/work-center/machines-by-padre/${id}`
			);
			setMachinesById(data.data);
		} catch (error) {
			console.log(error.response.data.message);
		}
	};

	const getMachine = async (cod_maq) => {
		try {
			const { data } = await axiosWithToken.get(
				`/work-center/machine-by-id/${cod_maq}`
			);

			//data.data.center = (data.data).center.id;
			//data.data.center_name = data.machineCenter.description;
			//data.data.tipo_maq_name = data.machine_type.description;
			data.data.tipo_maq_name = data.data.machine_type.description;

			//console.log('DATA',data.data)

			setMachine(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	/* Mano de obra */
	const getAllManPower = async () => {
		try {
			const { data } = await axiosWithToken.get("/work-center/manpower-list");
			setManPower(data.data);
		} catch (error) {
			console.log(error.response.data.message);
		}
	};

	const getAllManPowerTypes = async () => {
		try {
			const { data } = await axiosWithToken.get("/work-center/manPower-types");
			setManPowerType(data.data);
		} catch (error) {
			console.log("Error", error.response.data);
		}
	};

	const getAllManPowerByMachine = async (cod_maq) => {
		try {
			const { data } = await axiosWithToken.get(
				`/work-center/manpower-by-machine/${cod_maq}`
			);
			setManPowerByMachine(data.data);
		} catch (error) {
			console.log("Error", error.response.data);
		}
	};

	const getManPower = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/work-center/manpower-list/${id}`
			);

			/* if(data.data[0].machineType_id === undefined || data.data[0].machineType_id === "" || data.data[0].machineType_id === null ){
                data.data[0].manToMachineType = [];
            }else{
                data.data[0].manToMachineType = data.data.map(elem => {
                    return elem.machineType_id
                })
                data.data[0].machTypeName = data.data.map(elem => {
                    const objeto = {
                        'machineType_id':elem.machineType_id,
                        'nom_machineType':elem.nom_machineType
                    }
                    return objeto;
                 })

            } */
			setManPower(data.data);
		} catch (error) {
			console.log(error.response);
		}
	};

	/* Eliminar general*/
	const deleteInCenter = async (ruta, id) => {
		const confirmar = confirm("¿Desea continuar?");

		if (confirmar) {
			try {
				await axiosWithToken.delete(`${ruta}/${id}`);
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Eliminado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}
			} catch (error) {
				console.log(error.response.data);
			}

			getAllCentros();
			getAllMachines();
			getAllManPower();
		}
	};

	return (
		<CentrosContext.Provider
			value={{
				centros,
				deleteInCenter,
				getAllCentros,
				lastLevels,
				getLastLevels,
				center,
				setCenter,
				getCenter,
				machines,
				getAllMachines,
				machine,
				getMachine,
				machine_types,
				getAllMachineTypes,
				machinesById,
				getMachinesById,
				manPower,
				setManPower,
				getAllManPower,
				manPower_types,
				getAllManPowerTypes,
				manPowerByMachine,
				getAllManPowerByMachine,
				getManPower,
				addOp,
				handleAddOp,
			}}
		>
			{children}
		</CentrosContext.Provider>
	);
};

CentrosProvider.propTypes = {
	children: PropTypes.any,
};

export { CentrosProvider };
export default CentrosContext;
