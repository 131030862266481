/** @format */

import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";

const Error = ({ status, message }) => {
	return <>{status && <Alert variant={"danger"}>{message}</Alert>}</>;
};

Error.propTypes = {
	status: PropTypes.bool,
	message: PropTypes.string,
};

export default Error;
