/** @format */

import { useMemo } from "react";

export default function useColumnsRawmaterials() {
	const columns = useMemo(
		() => [
			{
				accessor: "id",
				Header: "#",
			},
			{
				accessor: "code",
				Header: "Código",
			},
			{
				accessor: "name",
				Header: "Nombre",
			},
			{
				accessor: "stage",
				Header: "Etapa",
			},
			{
				accessor: "acciones",
				Header: "Acciones",
			},
		],
		[]
	);

	return columns;
}
