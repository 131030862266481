/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { useNavigate, useParams } from "react-router-dom";
import { useEstamperia } from "hooks/useEstamperia";
import { Row, Col, Card, Tab, Tabs, Badge } from "react-bootstrap";
import Spinner from "components/spinner/Spinner";
// import NineDotMenu from "components/menuButtons/NineDotMenu";
import EstamperiaFormEdit from "./EstamperiaEdit";
import EstamperiaProductPagination from "./EstamperiaProductPagination";

const EstamperiaProductView = () => {
	const [loading, setLoading] = useState(true);
	const [editionMode, setEditionMode] = useState(false);
	const [atributos, setAtributos] = useState([]);

	const { relacionProdEstById, getRelacionProdEstById } = useEstamperia();

	// const { deleteRelacionProdEst, relacionProdEstById, getRelacionProdEstById } =
	// useEstamperia();

	const { id } = useParams();
	// const navigate = useNavigate();

	useEffect(() => {
		getRelacionProdEstById(id);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);

		setAtributos(relacionProdEstById.atributos);
	}, [relacionProdEstById]);

	// const handleDelete = () => {
	// 	const deleteRelProdEst = confirm("Está a punto de eliminar la estampa");
	// 	if (deleteRelProdEst) {
	// 		deleteRelacionProdEst(id);
	// 		navigate("/estamperia/list/relacion-producto-estampa");
	// 	}
	// };

	const handleEditionMode = () => {
		setEditionMode(!editionMode);
	};

	return (
		<>
			{loading ? (
				<Spinner />
			) : relacionProdEstById?.id ? (
				<>
					<Card className='mb-3'>
						<Card.Header className='p-2'>
							<Row className='align-items-baseline g-2'>
								<Col className='d-flex justify-content-center mt-4'>
									<h6 className='mb-0 mt-0 hover-actions-trigger'>
										Relación Producto-Estampa:{" "}
										<Badge bg='secondary'># {id}</Badge>{" "}
										{/* <Badge bg="secondary">{relacionProdEstById.producto.description}</Badge> */}
									</h6>
								</Col>
								{/* <Col xs={3} sm={3} md={1} lg={1}>
									<NineDotMenu
										handleEditionMode={handleEditionMode}
										handleDelete={handleDelete}
									/>
								</Col> */}
							</Row>
						</Card.Header>

						{/* FORMULARIO EDIT*/}
						<EstamperiaFormEdit
							editionMode={editionMode}
							handleEditionMode={handleEditionMode}
						/>

						<Card.Body>
							<Tab.Content>
								<Row>
									<Col xs={12} sm={12} lg={12} md={12}>
										<Tabs defaultActiveKey='general' id='tabs-estamperiaView'>
											<Tab eventKey='general' title='1 - Datos'>
												<Row>
													<Col
														className='mt-3 mb-2'
														xs={12}
														sm={12}
														lg={12}
														md={12}
													>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<h6 className='mb-3 fs-0'>
																			Información general
																		</h6>
																		{atributos?.length > 0 ? (
																			<EstamperiaProductPagination
																				data={atributos}
																			/>
																		) : (
																			"No cuenta con productos relacionados"
																		)}
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Col>
												</Row>
											</Tab>
										</Tabs>
									</Col>
								</Row>
							</Tab.Content>
						</Card.Body>
					</Card>
				</>
			) : (
				<h6 className='text-center'>Este ID no pertenece a ninguna estampa!</h6>
			)}
		</>
	);
};

export default EstamperiaProductView;
