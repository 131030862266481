/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Button, Modal } from "react-bootstrap";
import dayjs from "dayjs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ListadoTable from "components/salesmasterplan/MasterplanListadoTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const Listado = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [masterplans, setMasterplans] = useState([]);
	//Show alert eliminar plan maestro
	const [showAlert, setShowAlert] = useState(false);
	const [masterplanId, setMasterplanId] = useState(null);
	const toastId = React.useRef(null);

	useEffect(() => {
		getAllMasterPlans();
	}, []);

	const getAllMasterPlans = async () => {
		const { data } = await axiosWithToken.get("/masterplans");

		setMasterplans(
			data.data.map((masterplan) => {
				return {
					id: masterplan.id,
					name: masterplan.name,
					description: masterplan.description,
					planning_year: masterplan.planning_year,
					stage: masterplan.stage,
					created_at: dayjs(masterplan.created_at).format(
						"DD/MM/YYYY HH:mm:ss"
					),
				};
			})
		);
	};

	const closeModalDelete = () => {
		setMasterplanId(null);
		setShowAlert(false);
	};

	const deletePlan = async (id) => {
		setMasterplanId(id);
		setShowAlert(true);
	};

	const handleConfirmDelete = async () => {
		try {
			await axiosWithToken.delete(`/masterplan/${masterplanId}`);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
			getAllMasterPlans();
			setShowAlert(false);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* Componente Modal de alerta */}

			<Modal
				size='md'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={showAlert} /*onHide={handleCloseEstructure}*/
			>
				<Modal.Header>
					<Modal.Title>
						<h6 className='mb-0 hover-actions-trigger'>
							¿Estás seguro de que deseas eliminar?
						</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className='mt-1'>
						<span className='text-danger fs--2'>
							<strong>
								¡Se eliminara toda la planificación relacionada con el plan
								maestro!
							</strong>
						</span>
					</Row>
					<Row className='mt-3'>
						<Col className='d-flex justify-content-end'>
							<Button variant='danger' size='sm' onClick={closeModalDelete}>
								Cancelar
							</Button>
							<Button
								className='ms-1'
								variant='success'
								size='sm'
								onClick={handleConfirmDelete}
							>
								Confirmar
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>

			{/* CARD TABLA LISTADO */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='p-2'>
					<Row>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0'>Listado de Planes Maestros</h6>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/salesmasterplan/masterplanform'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body>
					<Tab.Content>
						<ListadoTable data={masterplans} deletePlan={deletePlan} />
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Listado;
