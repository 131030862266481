/** @format */

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import SearchAutocompleteCodes from "components/searchs/SearchAutocompleteCodes";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";
import { useRawmaterialContext } from "hooks/useRawmaterialContext";

const FormularioCode = ({ setDisabledRawmaterial, setKey }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//Navigate para navegar a otra pantalla
	const navigate = useNavigate();

	//Disabled enabled inputs
	const [disabled, setDisabled] = useState(false);

	//Estado para habilitar deshabilitar boton guardar
	const [disabledStore, setDisabledStore] = useState(false);

	//Objeto código de producto desde el buscador
	const [codeinsert, setCodeinsert] = useState({});

	//Códigos
	const [code, setCode] = useState("");
	const [materialCode, setMaterialCode] = useState({});
	const [submaterialCode, setSubmaterialCode] = useState({});
	const [colorCode, setColorCode] = useState({});

	const toastId = useRef(null);

	const {
		codeContext,
		handleCodeByRawmaterialId,
		handleRawmaterialById,
		handleRawmaterialByCode,
	} = useRawmaterialContext();

	useEffect(() => {
		if (codeContext && Object.entries(codeContext).length > 0) {
			const { code, materialcode, submaterialcode, materialcolorcode } =
				codeContext;

			setCode(code);
			setMaterialCode(materialcode);
			setSubmaterialCode(submaterialcode);
			setColorCode(materialcolorcode);

			//Habilitamos la pestaña de alta de materia prima
			setDisabledRawmaterial(false);
			//Deshabilitamos el botón guardar
			setDisabledStore(true);
			//Disabled inputs
			setDisabled(true);
		} else {
			//Limpiamos los estados
			setCode("");
			setMaterialCode({});
			setSubmaterialCode({});
			setColorCode({});
			//Habilitamos el botón guardar
			setDisabledStore(false);
			//Enabled inputs
			setDisabled(false);
		}
	}, [codeContext]);

	useEffect(() => {
		//Este useEffect tiene que estar porque viene de otro componente y tiene el objeto código de materia prima
		if (Object.entries(codeinsert).length > 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(
					"¡Buscando datos relacionados a la materia prima!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			setCode(codeinsert.code);
			setMaterialCode(codeinsert.materialcode);
			setSubmaterialCode(codeinsert.submaterialcode);
			setColorCode(codeinsert.materialcolorcode);
			//Disabled inputs
			setDisabled(true);

			//Guardarmos la materia prima por el código en el context
			handleRawmaterialByCode(codeinsert.code);

			setTimeout(() => {
				setDisabledStore(true);
				setDisabledRawmaterial(false);
				setKey("rawmaterial");
			}, 1000);
		}
	}, [codeinsert]);

	const handleCancel = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/rawmaterials/list`);
	};

	const buildPostData = () => {
		const postData = {
			usability: 0,
			material_id: materialCode.id,
			submaterial_id: submaterialCode.id,
			color_id: colorCode.id,
			code: `${materialCode.code}${submaterialCode.code}${colorCode.code}`,
			description: `${materialCode.description} ${submaterialCode.description} ${colorCode.description}`,
		};

		return postData;
	};

	const storeUpdate = async () => {
		if (Object.entries(materialCode).length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El código de material es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (Object.entries(submaterialCode).length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El código de submaterial es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (Object.entries(colorCode).length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El código de color es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		try {
			const postData = buildPostData();

			const { data } = await axiosWithToken.post(
				"/code/storeSimpleCode",
				postData
			);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			//Seteamos el código
			setCode(data.data.code);

			//Guardamos el código por rawmaterial id en el context
			handleCodeByRawmaterialId(data.data.id);

			//Guardamos la materia prima por su id en el context
			handleRawmaterialById(data.data.id);

			//Deshabilitamos los inputs
			setDisabled(true);

			setTimeout(() => {
				setDisabledStore(true);
				setDisabledRawmaterial(false);
				setKey("rawmaterial");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
	};

	return (
		<>
			<Row>
				<Col xs={12} sm={12} lg={12} md={12}>
					<Card className='mb-2 p-4 h-md-100'>
						<h6 className='mb-4 text-center fs--2'>
							¡Agregar código de materia prima!
						</h6>

						<Form>
							<Row className='mb-3 g-3'>
								<Col xs={12} sm={12} lg={12} md={12}>
									<Row className='mb-3'>
										<Form.Group
											as={Col}
											xs={12}
											sm={12}
											md={8}
											lg={8}
											controlId='formGridUnitsMp'
										>
											<Form.Label className='fs--2'>
												Buscar un código de materia prima
											</Form.Label>
											<SearchAutocomplete
												setSelect={setCodeinsert}
												modelSearch={"rawMaterialCodes"}
												disabled={disabled}
											/>
										</Form.Group>

										<Form.Group
											as={Col}
											xs={12}
											sm={12}
											md={4}
											lg={4}
											controlId='formGridCod'
										>
											<Form.Label className='fs--2'>
												Código
												<span className='text-danger'>*</span>
											</Form.Label>
											<Form.Control
												size='sm'
												value={code}
												type='text'
												placeholder=''
												disabled
												readOnly
											/>
										</Form.Group>
									</Row>

									<Row className='mb-3 g-3'>
										<Form.Group
											as={Col}
											xs={12}
											sm={12}
											md={4}
											lg={4}
											controlId='formGridCodMat'
										>
											<Form.Label>
												Material
												<span className='text-danger'>*</span>
											</Form.Label>
											<SearchAutocompleteCodes
												setSelect={setMaterialCode}
												modelSearch={"materialCodes"}
												disabled={disabled}
											/>

											{materialCode &&
												Object.entries(materialCode).length > 0 && (
													<>
														<Badge
															style={{ fontSize: "10px" }}
															className='mb-2'
															bg='secondary'
														>
															{materialCode.code} :
														</Badge>{" "}
														<span style={{ fontSize: "12px" }}>
															{materialCode.description}
														</span>
													</>
												)}

											{materialCode &&
											Object.entries(materialCode).length > 0 &&
											disabled == false ? (
												<FontAwesomeIcon
													style={{ fontSize: "8px" }}
													icon='times'
													size='sm'
													className='cursor-pointer text-danger ms-2'
													onClick={() => setMaterialCode({})}
												/>
											) : (
												""
											)}
										</Form.Group>

										<Form.Group
											as={Col}
											xs={12}
											sm={12}
											md={4}
											lg={4}
											controlId='formGridSubmat'
										>
											<Form.Label>
												Submaterial
												<span className='text-danger'>*</span>
											</Form.Label>

											<SearchAutocompleteCodes
												setSelect={setSubmaterialCode}
												materialcodeid={materialCode.id}
												modelSearch={"uniqueSubmaterialCodes"}
												disabled={disabled}
											/>

											{submaterialCode &&
												Object.entries(submaterialCode).length > 0 && (
													<>
														<Badge
															style={{ fontSize: "10px" }}
															className='mb-2'
															bg='secondary'
														>
															{submaterialCode.code} :
														</Badge>{" "}
														<span style={{ fontSize: "12px" }}>
															{submaterialCode.description}
														</span>
													</>
												)}

											{submaterialCode &&
											Object.entries(submaterialCode).length > 0 &&
											disabled == false ? (
												<FontAwesomeIcon
													style={{ fontSize: "8px" }}
													icon='times'
													size='sm'
													className='cursor-pointer text-danger ms-2'
													onClick={() => setSubmaterialCode({})}
												/>
											) : (
												""
											)}
										</Form.Group>

										<Form.Group
											as={Col}
											xs={12}
											sm={12}
											md={4}
											lg={4}
											controlId='formGridMPColor'
										>
											<Form.Label>
												Color
												<span className='text-danger'>*</span>
											</Form.Label>

											<SearchAutocompleteCodes
												setSelect={setColorCode}
												modelSearch={"uniqueMaterialColorCodes"}
												disabled={disabled}
											/>

											{colorCode && Object.entries(colorCode).length > 0 && (
												<>
													<Badge
														style={{ fontSize: "10px" }}
														className='mb-2'
														bg='secondary'
													>
														{colorCode.code} :
													</Badge>{" "}
													<span style={{ fontSize: "12px" }}>
														{colorCode.description}
													</span>
												</>
											)}

											{colorCode &&
											Object.entries(colorCode).length > 0 &&
											disabled == false ? (
												<FontAwesomeIcon
													style={{ fontSize: "8px" }}
													icon='times'
													size='sm'
													className='cursor-pointer text-danger ms-2'
													onClick={() => setColorCode({})}
												/>
											) : (
												""
											)}
										</Form.Group>
									</Row>
								</Col>
							</Row>

							<Row>
								<Col className='d-flex justify-content-end'>
									<Button
										size='sm'
										variant='danger'
										onClick={() => handleCancel()}
									>
										Cancelar
									</Button>

									<Button
										size='sm'
										variant='primary'
										className='ms-1'
										disabled={disabledStore}
										onClick={() => storeUpdate()}
									>
										Guardar
									</Button>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		</>
	);
};

FormularioCode.propTypes = {
	setDisabledRawmaterial: PropTypes.func,
	setKey: PropTypes.func,
};

export default FormularioCode;
