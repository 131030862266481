/** @format */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
	Form,
	Row,
	Button,
	Card,
	Col,
	Table,
	Badge,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { generarId } from "../../../helpers/utils";
import ModalStructureData from "components/products/products/ModalStructureData";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";
import { useProductContext } from "hooks/useProductContext";

const FormularioStructure = ({ setDisabledHdr, setKey }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Disabled status
	const [disabledSearchMp, setDisabledSearchMp] = useState(false);
	const [disabledCant, setDisabledCant] = useState(false);

	//Status structure
	const [idkeyEdit, setIdkeyEdit] = useState("");

	//Tipo de hilo, nueva funcionalidad
	const [showThreadupType, setShowThreadupType] = useState(false);
	const [selectMachtype, setSelectMachtype] = useState("");

	//Edit para editar datos dentro de la estructura
	const [edit, setEdit] = useState(0);

	const [structure, setStructure] = useState([]);
	const [codeMP, setCodeMP] = useState("");
	const [cantidad, setCantidad] = useState(0);
	const [orden, setOrder] = useState(0);
	const [unitsMP, setUnitsMP] = useState([]);
	const [disabledUM, setDisabledUM] = useState(true);
	const [selectUnitmp, setSelectUnitmp] = useState(0);
	const [materiaPrima, setMateriaPrima] = useState({});

	//Estados modal
	const [showEstructure, setShowEstructure] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [nameStructure, setNameStructure] = useState("");
	const [descriptionStructure, setDescriptionStructure] = useState("");

	const {
		settings,
		getSettings,
		product,
		handleProductById,
		selectMold,
		structureContext,
		handleStructureByProductId,
	} = useProductContext();

	useEffect(() => {
		getSettings();
	}, []);

	useEffect(() => {
		if (Object.entries(structureContext).length > 0) {
			setStructure(structureContext);
			setShowButton(true);
			setNameStructure(structureContext.name);
			setDescriptionStructure(structureContext.description);
			setDisabledHdr(false);
		}
	}, [structureContext]);

	useEffect(() => {
		//Este useEffect tiene que estar porque viene de otro componente

		if (Object.entries(materiaPrima).length > 0) {
			setCodeMP(materiaPrima.code);

			let units = Object.values(materiaPrima.units);
			setUnitsMP(
				units.map((unit) => {
					return {
						value: unit.id,
						label: unit.description,
					};
				})
			);

			setDisabledUM(false);

			let codeMAterial = materiaPrima.code.slice(0, 3);

			if (codeMAterial == "002") {
				setShowThreadupType(true);
			} else {
				setShowThreadupType(false);
				calcularMP();
			}

			if (structure.length == 0) {
				setOrder(1);
			} else {
				setOrder(structure.length + 1);
			}
		}
	}, [materiaPrima]);

	useEffect(() => {
		//Este useEffect tiene que estar porque ejecuta la funcion calcular materia prima si es un hilo, después de seleccionar el tipo de máquina
		calcularMP();
	}, [selectMachtype]);

	const calcularMP = async () => {
		if (Object.entries(materiaPrima).length > 0) {
			if (Object.entries(selectMold).length > 0) {
				//FALTA AGREGAR ACA EL CALCULO SI EL MOLDE TIENE PERÍMETRO DE CINTURA, ENTIENDO QUE SE LE RESTA AL PERIMETRO TOTAL LA CINTURA POR LA REUNION
				//PERO PREGUNTAR SI ES UN CÁLCULO DE CONVERSIÓN COMO EN EL CASO DE PERIMETRO FLAT Y EL PERIMETRO NORMAL PARA OBTENER LA CANTIDAD

				//Códigos individuales
				let codeMAterial = materiaPrima.code.slice(0, 3);
				//let codeSubmaterial = materiaPrima.code.slice(3,6)

				if (codeMAterial == "001" || codeMAterial == "002") {
					setDisabledCant(true);
				} else {
					setCantidad(0);
					setDisabledCant(false);
				}

				if (codeMAterial == "001") {
					setCantidad(
						(parseFloat(materiaPrima.rinde) * selectMold.squaremeters).toFixed(
							2
						)
					);
				}

				if (codeMAterial == "002") {
					if (selectMachtype != "") {
						if (selectMachtype == 1) {
							if (materiaPrima.thread == 1) {
								setCantidad(
									(
										settings.garment_convert_topthread *
											(selectMold.perimeter - selectMold.perimeterwaist) +
										settings.waist_convert_topthread * selectMold.perimeterwaist
									).toFixed(3)
								);
							}

							if (materiaPrima.thread == 0) {
								setCantidad(
									(
										settings.garment_convert_lowerthread *
											(selectMold.perimeter - selectMold.perimeterwaist) +
										settings.waist_convert_lowerthread *
											selectMold.perimeterwaist
									).toFixed(3)
								);
							}
						}

						if (selectMachtype == 2) {
							if (materiaPrima.thread == 1) {
								setCantidad(
									(
										settings.garment_convert_topthread *
											(selectMold.perimeter - selectMold.perimeterwaist) +
										settings.waist_convert_topthread *
											selectMold.perimeterwaist +
										settings.flatseamer_convert_topthread *
											selectMold.perimeterflat
									).toFixed(3)
								);
							}

							if (materiaPrima.thread == 0) {
								setCantidad(
									(
										settings.garment_convert_lowerthread *
											(selectMold.perimeter - selectMold.perimeterwaist) +
										settings.waist_convert_lowerthread *
											selectMold.perimeterwaist +
										settings.flatseamer_convert_lowerthread *
											selectMold.perimeterflat
									).toFixed(3)
								);
							}
						}
					}
				}
			} else {
				setCantidad(0);
				setDisabledCant(false);
			}
		}
	};

	const handleShowEstructure = () => {
		setShowEstructure(true);
	};

	const handleCancel = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/products/list`);
	};

	const handleDelete = (idkey) => {
		const newStructure = structure.filter(
			(structure) => structure.idkey !== idkey
		);
		setStructure(newStructure);
	};

	const handleAdd = async () => {
		if (codeMP == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo código es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		//Códigos individuales
		let codeMAterial = codeMP.slice(0, 3);

		if (codeMAterial == "002") {
			if (selectMachtype == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El seleccionar un tipo de máquina es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		if (cantidad == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo cantidad es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (orden == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo orden es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (selectUnitmp == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar una unidad de medida es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.get(
				`/rawmaterial/bycode/${codeMP}`
			);

			const newStructure = {
				idkey: edit ? idkeyEdit : generarId(),
				id: data.data.id,
				description: data.data.description,
				codeMP: data.data.code,
				cantidad: cantidad,
				oncantidad: codeMAterial == "001" || codeMAterial == "002" ? 0 : 1,
				rinde: data.data.rinde,
				orden: orden,
				unidadmp: selectUnitmp,
				unidades: unitsMP,
				tipohilo: data.data.thread,
				tipomaquina: selectMachtype != "" ? selectMachtype : null,
			};

			if (edit == 1) {
				const structureUpdate = structure.map((structureState) =>
					structureState.idkey === newStructure.idkey
						? newStructure
						: structureState
				);
				setStructure(structureUpdate);
				setEdit(0);
			} else {
				//Que tengo que hacer paso por paso

				//1ero tomar el código para saber que material estoy queriendo meter a la estructura, esto porque para hilo tiene validaciones distintas

				//Código de material en estado, el que se está por agregar a la estructura
				let codeMaterial1 = codeMP.slice(0, 3);

				//2do si es distinto de hilo hacer una validación simple que se fije si lo que estoy queriendo agregar ya está en la estructura
				if (codeMaterial1 != "002") {
					//Buscamos que la materia prima no esté guardada ya para poder agregarla
					const mprimaBuscada = structure.find((str) => str.codeMP === codeMP);

					if (mprimaBuscada) {
						//La materia prima ya está en el arreglo
						if (!toast.isActive(toastId.current)) {
							toastId.current = toast.error(
								"La materia prima ya está cargada",
								{
									role: "alert",
									theme: "dark",
								}
							);
						}
					} else {
						//La materia prima no está cargada, por lo que la agregamos
						setStructure([...structure, newStructure]);
					}
				} else {
					//3ro si es hilo, tengo que recorrer la estructura ya en estado para ver si ya se ingresó hilo de arriba y de abajo para el tipo de máquina
					//que estoy queriendo agregar a la estructura

					let hiloarriba = false;
					let hiloabajo = false;

					structure.forEach((str) => {
						if (newStructure.tipohilo == 1) {
							if (
								newStructure.tipohilo == str.tipohilo &&
								newStructure.tipomaquina == str.tipomaquina
							) {
								hiloarriba = true;
							}
						} else if (newStructure.tipohilo == 0) {
							if (
								newStructure.tipohilo == str.tipohilo &&
								newStructure.tipomaquina == str.tipomaquina
							) {
								hiloarriba = true;
							}
						}
					});

					//4to mostrar mensajes dependiendo de las validaciones

					if (hiloarriba) {
						if (!toast.isActive(toastId.current)) {
							toastId.current = toast.error(
								"Ya se cargó un hilo de arriba para el tipo de máquina que intenta cargar",
								{
									role: "alert",
									theme: "dark",
								}
							);
						}
					} else if (hiloabajo) {
						if (!toast.isActive(toastId.current)) {
							toastId.current = toast.error(
								"Ya se cargó un hilo de abajo para el tipo de máquina que intenta cargar",
								{
									role: "alert",
									theme: "dark",
								}
							);
						}
					} else {
						setStructure([...structure, newStructure]);
					}
				}
			}

			setCodeMP("");
			setMateriaPrima({});
			setCantidad(0);
			setOrder(0);
			setSelectUnitmp("");
			setDisabledUM(true);
			setDisabledCant(false);
			setShowThreadupType(false);
			setSelectMachtype("");
			setDisabledSearchMp(false);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
	};

	const deletematerial = async () => {
		setMateriaPrima({});
		setCodeMP("");
		setCantidad(0);
		setOrder(0);
		setSelectUnitmp("");
		setUnitsMP([]);
		setDisabledUM(true);
	};

	const storeUpdate = async () => {
		if (structure.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El producto tiene que tener una estructura.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		let validaCanitdad = false;

		structure.forEach((str) => {
			if (str.cantidad == 0) {
				validaCanitdad = true;
			}
		});

		if (validaCanitdad) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`La estructura no puede tener cantidades en 0.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		let hiloarribacomun = false;
		let hiloabajocomun = false;

		let tieneflat = false;
		let hiloarribaflat = false;
		let hiloabajoflat = false;

		structure.forEach((str) => {
			//hilo arriba comun
			if (str.tipohilo == 1 && str.tipomaquina == 1) {
				hiloarribacomun = true;
			}

			//hilo abajo comun
			if (str.tipohilo == 0 && str.tipomaquina == 1) {
				hiloabajocomun = true;
			}

			if (str.tipomaquina == 2) {
				tieneflat = true;
			}

			if (tieneflat) {
				//hilo arriba falt
				if (str.tipohilo == 1 && str.tipomaquina == 2) {
					hiloarribaflat = true;
				}

				//hilo abajo comun
				if (str.tipohilo == 0 && str.tipomaquina == 2) {
					hiloabajoflat = true;
				}
			}
		});

		if (!hiloarribacomun) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Tiene que cargar hilo de arriba para máquina comun`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (!hiloabajocomun) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Tiene que cargar hilo de abajo para máquina común`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (tieneflat) {
			if (!hiloarribaflat) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`Tiene que cargar hilo de arriba para máquina flatseamer`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			if (!hiloabajoflat) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`Tiene que cargar hilo de abajo para máquina flatseamer`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		try {
			const { data } = await axiosWithToken.post(
				"/structureproduct/assignStructure",
				{
					product_id: product.id,
					structure_id:
						Object.entries(structureContext).length > 0
							? structureContext.id
							: null,
					structure: structure,
					nameStructure: nameStructure,
					descriptionStructure: descriptionStructure,
				}
			);

			handleProductById(data.data.product_id);
			handleStructureByProductId(data.data.id);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				setDisabledHdr(false);
				setKey("hdr");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	return (
		<>
			{showEstructure && (
				<ModalStructureData
					show={showEstructure}
					setShow={setShowEstructure}
					nameStructure={nameStructure}
					setNameStructure={setNameStructure}
					descriptionStructure={descriptionStructure}
					setDescriptionStructure={setDescriptionStructure}
				/>
			)}

			<Row>
				<Card.Header className='border-bottom border-200 p-2 mb-3'>
					<Row>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0 hover-actions-trigger align-middle'>
								Cargar la estructura del producto
							</h6>
							{showButton && (
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip>Editar nombre de la estructura</Tooltip>}
								>
									<Button
										style={{
											fontSize: ".675rem",
											padding: "0.1675rem 0.25rem",
										}}
										onClick={() => handleShowEstructure()}
										variant='transparent'
										size='sm'
										className='text-primary mb-2'
									>
										<FontAwesomeIcon
											className='align-top'
											icon='edit'
											size='sm'
										/>
									</Button>
								</OverlayTrigger>
							)}
						</Col>
					</Row>
				</Card.Header>

				<Col className='mb-2' xs={12} sm={12} lg={4} md={4}>
					<Card className='mb-2 p-2 h-md-100'>
						<Card.Body>
							<h6 className='mb-4 text-center fs--2'>
								Agregar una materia prima a la estructura
							</h6>
							<Row className='mb-1'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									lg={12}
									md={12}
									controlId='formGridCode'
								>
									<Form.Label className='fs--2'>Buscar un código</Form.Label>
									<SearchAutocomplete
										setSelect={setMateriaPrima}
										modelSearch={"rawmaterial"}
										disabled={disabledSearchMp}
									/>
									<span style={{ fontSize: "9px" }}>
										<strong>{materiaPrima.name}</strong>{" "}
										{materiaPrima.thread != null ? (
											materiaPrima.thread == 1 ? (
												<Badge bg='secondary' style={{ fontSize: "5px" }}>
													H. arriba
												</Badge>
											) : (
												<Badge bg='secondary' style={{ fontSize: "8px" }}>
													H. abajo
												</Badge>
											)
										) : (
											""
										)}{" "}
									</span>
									{Object.entries(materiaPrima).length > 0 ? (
										<FontAwesomeIcon
											icon='times'
											style={{ fontSize: "10px" }}
											className='cursor-pointer text-danger ms-1'
											onClick={() => deletematerial()}
										/>
									) : (
										""
									)}
								</Form.Group>
							</Row>

							<Row className='mb-1'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridCod'
								>
									<Form.Label className='fs--2'>
										Código<span className='text-danger'>*</span>
									</Form.Label>
									<Form.Control
										size='sm'
										value={codeMP}
										type='text'
										placeholder=''
										disabled
										readOnly
									/>
								</Form.Group>
							</Row>

							<Row className='mb-1'>
								{showThreadupType && (
									<Form.Group
										as={Col}
										md={12}
										lg={12}
										controlId='formGridMachtype'
									>
										<Form.Label className='fs--2'>
											Seleccionar para tipo de máquina
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Select
											size='sm'
											aria-label='select-code'
											value={selectMachtype}
											onChange={(e) => setSelectMachtype(e.target.value)}
										>
											<option value='' disabled>
												Seleccionar...
											</option>
											<option value={1}>Común</option>
											<option value={2}>Flatseamer</option>
										</Form.Select>
									</Form.Group>
								)}
							</Row>

							<Row className='mb-1'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									lg={12}
									md={12}
									controlId='formGridCantidad'
								>
									<Form.Label className='fs--2'>
										Cantidad
										<span className='text-danger'>*</span>
									</Form.Label>
									<Form.Control
										size='sm'
										value={cantidad}
										onChange={(e) => setCantidad(e.target.value)}
										type='text'
										placeholder=''
										disabled={disabledCant}
									/>
								</Form.Group>
							</Row>

							<Row className='mb-1'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									lg={12}
									md={12}
									controlId='formGridOrder'
								>
									<Form.Label className='fs--2'>
										orden<span className='text-danger'>*</span>
									</Form.Label>
									<Form.Control
										size='sm'
										value={orden}
										onChange={(e) => setOrder(e.target.value)}
										type='text'
										placeholder=''
									/>
								</Form.Group>
							</Row>
							<Row className='mb-1'>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridUnitsMp'
								>
									<Form.Label className='fs--2'>
										Unidad de medida
										<span className='text-danger'>*</span>
									</Form.Label>
									<Form.Select
										size='sm'
										value={selectUnitmp || ""}
										aria-label='uniadmp'
										disabled={disabledUM}
										onChange={(e) => setSelectUnitmp(e.target.value)}
									>
										<option value='0'>Seleccionar...</option>
										{unitsMP.map((elem, index) => {
											return (
												<option key={`option-${index}`} value={elem.value}>
													{elem.label}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							</Row>

							<Row className='mt-4'>
								<Col className='d-flex justify-content-end fs--2'>
									<Button
										variant='success'
										className='ms-1 fs--2'
										size='sm'
										onClick={handleAdd}
									>
										{!edit ? "Agregar" : "Editar"}
									</Button>

									{edit ? (
										<Button
											size='sm'
											className='ms-1 fs--2'
											variant='danger'
											onClick={() => {
												setCodeMP("");
												setMateriaPrima({});
												setCantidad(0);
												setOrder(0);
												setEdit(0);
												setSelectUnitmp("");
												setDisabledUM(false);
												setDisabledSearchMp(false);
												setSelectMachtype("");
												setShowThreadupType(false);
											}}
										>
											Cancelar
										</Button>
									) : (
										""
									)}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>

				<Col className='mb-2' xs={12} sm={12} lg={8} md={8}>
					<Card className='mb-2 p-2 h-md-100'>
						<Card.Body>
							<h6 className='mb-4 text-center fs--2'>
								Detalle de la Estructura del producto
							</h6>

							<Row>
								<div className='table-responsive fs--2'>
									<Table
										className='p-0 border-bottom fs--2'
										style={{ padding: "0.45" }}
									>
										<thead className='bg-200 text-900'>
											<tr>
												<th className='border-0 text-start'>Código</th>
												<th
													style={{ width: "40%" }}
													className='border-0 text-start'
												>
													Descripción
												</th>
												<th className='border-0 text-center'>Cantidad</th>
												<th className='border-0 text-center'>Orden</th>
												<th
													style={{ width: "20%" }}
													className='border-0 text-start'
												>
													U. Medida
												</th>
												<th
													style={{ width: "30%" }}
													className='border-0 text-center'
												>
													Acciones
												</th>
											</tr>
										</thead>
										<tbody>
											{structure && structure.length ? (
												structure.map((item) => (
													<tr key={`tr-${item.idkey}`} className='border-200'>
														<td className='text-start'>{item.codeMP}</td>

														<td className='text-start'>
															{item.description}{" "}
															{item.tipohilo != null ? (
																item.tipohilo == 1 ? (
																	<Badge
																		bg='secondary'
																		style={{ fontSize: "8px" }}
																	>
																		H. arriba
																	</Badge>
																) : (
																	<Badge
																		bg='secondary'
																		style={{ fontSize: "8px" }}
																	>
																		H. abajo
																	</Badge>
																)
															) : (
																""
															)}{" "}
															{item.tipomaquina != null ? (
																item.tipomaquina == 2 ? (
																	<Badge
																		bg='danger'
																		style={{ fontSize: "8px" }}
																	>
																		Flat
																	</Badge>
																) : (
																	""
																)
															) : (
																""
															)}
														</td>

														<td className='text-center'>{item.cantidad}</td>

														<td className='text-center'>
															<Badge
																style={{ fontSize: "8px" }}
																className='rounded-pill'
																bg='primary'
															>
																{item.orden}
															</Badge>
														</td>

														<td className='text-start'>{item.unidadmp}</td>

														<td className='text-end'>
															<OverlayTrigger
																placement='top'
																overlay={<Tooltip>Editar</Tooltip>}
															>
																<Button
																	onClick={() => {
																		setMateriaPrima({});
																		setIdkeyEdit(item.idkey);
																		setCodeMP(item.codeMP);
																		setCantidad(item.cantidad);
																		setOrder(item.orden);
																		setDisabledUM(false);
																		setUnitsMP(item.unidades);
																		setSelectUnitmp(item.unidadmp);
																		item.oncantidad
																			? setDisabledCant(false)
																			: setDisabledCant(true);
																		setEdit(1);
																		setDisabledSearchMp(true);
																		item.tipohilo != null
																			? setShowThreadupType(true)
																			: setShowThreadupType(false);
																		item.tipohilo != null
																			? setSelectMachtype(item.tipomaquina)
																			: setSelectMachtype("");
																	}}
																	variant='transparent'
																	size='sm'
																	style={{ fontSize: "11px" }}
																	className='text-success'
																>
																	<FontAwesomeIcon icon='edit' />
																</Button>
															</OverlayTrigger>
															<OverlayTrigger
																placement='top'
																overlay={<Tooltip>Eliminar</Tooltip>}
															>
																<Button
																	onClick={() => handleDelete(item.idkey)}
																	variant='transparent'
																	size='sm'
																	style={{ fontSize: "11px" }}
																	className='text-danger me-2'
																>
																	<FontAwesomeIcon icon='trash' />
																</Button>
															</OverlayTrigger>
														</td>
													</tr>
												))
											) : (
												<tr className='border-200'>
													<td colSpan='6' className='align-middle text-center'>
														<h6 className='font-black text-3xl text-center fs--2'>
															¡No hay datos cargados!
														</h6>
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col
					xs={12}
					sm={12}
					lg={12}
					md={12}
					className='mb-2 mt-3 d-flex justify-content-end'
				>
					<Button size='sm' variant='danger' onClick={() => handleCancel()}>
						Cancelar
					</Button>

					<Button
						className='ms-1'
						size='sm'
						variant='primary'
						onClick={() => storeUpdate()}
					>
						Guardar
					</Button>
				</Col>
			</Row>
		</>
	);
};

FormularioStructure.propTypes = {
	setDisabledHdr: PropTypes.func,
	setKey: PropTypes.func,
};

export default FormularioStructure;
