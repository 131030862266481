/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListadoTable from "components/products/products/families/FamiliesListTable";
import { useAuthContext } from "hooks/useAuthContext";

const ListadoTabla = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const toastId = React.useRef(null);

	const [families, setFamilies] = useState([]);

	useEffect(() => {
		getAllFamilies();
	}, []);

	const deleteFamily = async (id) => {
		try {
			await axiosWithToken.delete(`/productfamily/${id}`);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
			getAllFamilies();
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const getAllFamilies = async () => {
		const { data } = await axiosWithToken.get("/productfamilies");
		setFamilies(
			data.data.map((family) => {
				return {
					id: family.id,
					name: family.parent_id
						? `${family.parent.name} > ${family.name}`
						: family.name,
					description: family.description,
					created_at: dayjs(family.created_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);
	};

	return (
		<>
			{/* CARD TABLA LISTADO */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>Listado de familias de productos</h5>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/products/products/families/familiesform'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body>
					<Tab.Content>
						{/* TOAST MENSAJE */}

						<ToastContainer
							position='top-right'
							autoClose={1000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>

						<ListadoTable data={families} deleteFamily={deleteFamily} />
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default ListadoTabla;
