/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isIterableArray } from "helpers/utils";
import SoftBadge from "components/common/SoftBadge";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";

const SearchBox = ({
	insertDat,
	setSelect = null,
	modelSearch,
	disabled,
	materialcodeid = null,
}) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [searchInputValue, setSearchInputValue] = useState("");
	const [resultItem, setResultItem] = useState([]);

	const toggle = () => setDropdownOpen(!dropdownOpen);

	const agregarDato = (item, modelSearch) => {
		insertDat(item, modelSearch);
	};

	const selectValue = (item) => {
		if (
			modelSearch == "fabricsCodes" ||
			modelSearch == "colorsCodes" ||
			modelSearch == "submaterialCodes" ||
			modelSearch == "materialColorCodes"
		) {
			agregarDato(item, modelSearch);
		} else {
			setSelect(item);
		}

		setDropdownOpen(false);
		setSearchInputValue("");
	};

	useEffect(() => {
		const searchFunction = async (search) => {
			if (search.length >= 1) {
				switch (modelSearch) {
					//Materias primas cases
					case "materialCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/materialcodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "submaterialCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/submaterialcodes/search/${searchInputValue}/materialid/${materialcodeid}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "uniqueSubmaterialCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/submaterialcodes/search/${searchInputValue}/materialid/${materialcodeid}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "materialColorCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/materialcolorcodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "uniqueMaterialColorCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/materialcolorcodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					//Productos cases
					case "productsCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/productcodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "modelsCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/modelcodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "fabricsCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/fabriccodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "uniqueFabricsCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/fabriccodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "sizesCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/sizecode/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "colorsCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/productcolorcodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "uniqueColorsCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/productcolorcodes/search/${searchInputValue}`
							);
							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
				}
			} else {
				setResultItem([]);
			}
		};

		searchFunction(searchInputValue);
	}, [searchInputValue]);

	return (
		<Dropdown onToggle={toggle} className='search-box'>
			<Dropdown.Toggle
				as='div'
				data-toggle='dropdown'
				aria-expanded={dropdownOpen}
				bsPrefix='toggle'
			>
				<div className='position-relative'>
					<Form.Control
						type='search'
						placeholder='Buscar...'
						aria-label='Search'
						className='search-input'
						size='sm'
						autoComplete='off'
						disabled={disabled}
						value={searchInputValue}
						onChange={({ target }) => setSearchInputValue(target.value)}
						onClick={() => setDropdownOpen(true)}
					/>
					<FontAwesomeIcon
						icon='search'
						className='position-absolute text-400 search-box-icon fs--2'
					/>
				</div>
			</Dropdown.Toggle>

			{!disabled && (
				<Dropdown.Menu style={{ width: "auto" }}>
					<div className='scrollbar py-1' style={{ maxHeight: "20rem" }}>
						{isIterableArray(resultItem) && (
							<>
								{resultItem.map((item) => (
									<Dropdown.Item
										className='fs--2 py-1 hover-primary '
										key={item.id}
									>
										<div onClick={() => selectValue(item)}>
											<SoftBadge
												style={{ fontSize: "10px" }}
												bg='primary'
												className='fw-medium text-decoration-none me-2'
											>
												{item.code}
											</SoftBadge>
											<span style={{ fontSize: "10px" }} className='flex-1'>
												{" "}
												{item.description}
											</span>
										</div>
									</Dropdown.Item>
								))}
							</>
						)}

						{resultItem.length === 0 && (
							<p className='fs--2 fw-bold text-center mb-0'>Sin resultado.</p>
						)}
					</div>
				</Dropdown.Menu>
			)}
		</Dropdown>
	);
};

SearchBox.propTypes = {
	insertDat: PropTypes.func,
	setSelect: PropTypes.func,
	modelSearch: PropTypes.string,
	disabled: PropTypes.bool,
	materialcodeid: PropTypes.number,
};

export default SearchBox;
