/** @format */
import PropTypes from "prop-types";
import { tipoEventosDataObj } from "components/calendario/data/tipoEventos";

const errores = {
	start: "Por favor ingrese una fecha de inicio",
	end: "Por favor ingrese una fecha de fin",
	title: "Por favor ingresar un título",
	year: "Por favor seleccione un año",
	temporada: "Por favor seleccione una temporada",
	tipoEvento: "Por favor seleccione un tipo de evento",
	description: "",
	sameDay: "El evento no puede iniciar y finalizar el mismo día",
};

export const formValidations = async (
	formData,
	handleOverlap,
	editingEvent = false
) => {
	let error = { status: false, message: "" };

	const formDataObjEntries = Object.entries(formData);
	for (let i = 0; i < formDataObjEntries.length; i++) {
		if (!formData[formDataObjEntries[i][0]]) {
			error = {
				status: true,
				message: errores[formDataObjEntries[i][0]],
			};
			break;
		}
	}

	if (error.status) return error;

	if (
		formData.start.toString() === formData.end.toString() &&
		formData.tipoEvento != tipoEventosDataObj.feriado.name
	) {
		return (error = {
			status: true,
			message: errores.sameDay,
		});
	}

	if (!editingEvent) {
		const isOverlapping = handleOverlap(formData);

		if (
			isOverlapping &&
			formData.tipoEvento != tipoEventosDataObj.finde.name &&
			formData.tipoEvento != tipoEventosDataObj.feriado.name
		) {
			return (error = {
				status: true,
				message: "Hay eventos que se superponen!",
			});
		}
	}
	return error;
};

formValidations.propTypes = {
	formData: PropTypes.array,
	handleOverlap: PropTypes.func,
};
