/** @format */

import { useMemo } from "react";

export default function useColumnsProveedores() {
	const columns = useMemo(
		() => [
			{
				accessor: "id",
				Header: "#",
			},
			{
				accessor: "description",
				Header: "Descripción",
			},
			{
				accessor: "created_at",
				Header: "Fecha de alta",
			},
			{
				accessor: "acciones",
				Header: "Acciones",
			},
		],
		[]
	);

	return columns;
}
