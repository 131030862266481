/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card, Row, Col, Form, Button, Tab, Nav, Badge } from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import { formatNumber } from "helpers/numberFormatter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnimatedProgressBar from "components/common/AnimatedProgressBar";

//import stages
import MasterplanFormStageOne from "components/salesmasterplan/MasterplanFormStageOne";
import MasterplanFormStageTwo from "components/salesmasterplan/MasterplanFormStageTwo";
import MasterplanFormStageThree from "components/salesmasterplan/MasterplanFormStageThree";
import MasterplanFormStageFour from "components/salesmasterplan/MasterplanFormStageFour";
import { useAuthContext } from "hooks/useAuthContext";
import { useMasterplanContext } from "hooks/useMasterplanContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//ESTADOS PARA GUARDAR PLAN MAESTRO COMPLETO, Y DETALLES (VER ESTO SI ESTÁ BIEN)
	const [masterPlan, setMasterPlan] = useState({});

	//PLAN MAESTRO
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [selectYear, setSelectYear] = useState("");
	const [selectType, setSelectType] = useState("");
	const [startdate, setStartdate] = useState("");
	const [enddate, setEnddate] = useState("");
	//TITULO Y BOTONES
	const [title, setTitle] = useState("");
	const [action, setAction] = useState("");

	//Estado para muestrar el cargar detalles is el plan ya está creado
	const [editShow, setEditShow] = useState(false);

	//Estados habilita/deshabilita stages, con sus detalles
	const [disabledStage2, setdisabledStage2] = useState(true);
	const [disabledStage3, setdisabledStage3] = useState(true);
	const [disabledStage4, setdisabledStage4] = useState(true);
	//minutos totales
	const [totalMinutes, setTotalMinutes] = useState(0);
	//Minutos disponibles
	const [minutesAvailable, setMinutesAvailable] = useState(0);
	//Porcentaje Horas disponibles vs planificadas
	const [percentage, setPercentage] = useState(0);
	//Estado para activar tabs
	const [key, setKey] = useState("stage1");

	const {
		masterplanContext,
		handleSetMasterplan,
		stageOneDetails,
		handleStageOneDetails,
		stageTwoDetails,
		handleStageTwoDetails,
		stageThreeDetails,
		handleStageThreeDetails,
		handleStageFourDetails,
	} = useMasterplanContext();

	const { id } = useParams();

	const toastId = React.useRef(null);

	useEffect(() => {
		if (id) {
			setTitle("Modificar");
			setAction("Editar plan");
			getSalesPlanById(id);
			setTimeout(() => {
				setEditShow(true);
			}, 1000);
		} else {
			setTitle("Agregar");
			setAction("Generar plan");
		}
		//TODO traer los minutos totales desde api
		//Seteo los minutos totales
		setTotalMinutes(Number("2050650"));
	}, []);

	useEffect(() => {
		stageOneDetails.length > 0
			? setdisabledStage2(false)
			: setdisabledStage2(true);

		stageTwoDetails.length > 0
			? setdisabledStage3(false)
			: setdisabledStage3(true);

		stageThreeDetails.length > 0
			? setdisabledStage4(false)
			: setdisabledStage4(true);
	}, [masterplanContext, stageOneDetails, stageTwoDetails, stageThreeDetails]);

	//Objeto para manejar los colores del Badge de minutos disponibles
	const badgeStatuses = {
		full: "primary",
		medium: "warning",
		low: "danger",
	};

	const getSalesPlanById = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/masterplan/${id}`);

			//Guardo el plan maestro completo ver esto si está bien
			// setMasterPlan(data.data);
			handleSetMasterplan(data.data);

			setName(data.data.name);
			setDescription(data.data.description);
			setSelectYear(data.data.planning_year);
			setSelectType(data.data.type);
			setStartdate(data.data.start_date);
			setEnddate(data.data.end_date);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	//Recibo los minutos totales disponibles y los minutos diferenciados por familia de la etapa 1
	const handleSetMinutesAvailable = (totalAvailableMinutes) => {
		if (stageOneDetails.length <= 0) {
			setMinutesAvailable(totalMinutes);
			totalAvailableMinutes = totalMinutes;
		} else {
			setMinutesAvailable(totalAvailableMinutes);
		}

		const progressBarPercentage = (
			(1 - totalAvailableMinutes / totalMinutes) *
			100
		).toFixed(0);

		setPercentage(Number(progressBarPercentage));
	};

	//Funcion para cambiar el color de la cantidad disponible dependiendo del porcetaje que representa
	const handleBadgeStatus = (planned, total) => {
		const percentageMinutesAvailable = (
			(1 - Number(planned) / Number(total)) *
			100
		).toFixed(2);

		if (percentageMinutesAvailable <= 33.33) return badgeStatuses.low;
		if (
			percentageMinutesAvailable > 33.33 &&
			percentageMinutesAvailable <= 66.66
		)
			return badgeStatuses.medium;

		return badgeStatuses.full;
	};

	const store = async (e) => {
		e.preventDefault();

		if (name == "") {
			toast.error(`El campo nombre es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (description == "") {
			toast.error(`El campo descripción es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (selectYear == "") {
			toast.error(`El seleccionar un año es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (selectType == "") {
			toast.error(`El seleccionar un tipo de plan es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (startdate == "") {
			toast.error(`El seleccionar una fecha de inicio es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (enddate == "") {
			toast.error(`El seleccionar una fecha de finalización es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.post("/masterplan", {
				name: name,
				description: description,
				selectType: selectType,
				selectYear: selectYear,
				startdate: startdate,
				enddate: enddate,
			});

			//Guardo el plan maestro completo ver esto si está bien
			handleStageOneDetails([]);
			handleStageTwoDetails([]);
			handleStageThreeDetails([]);
			handleStageFourDetails([]);
			handleSetMasterplan(data.data);

			toast.success(
				"¡Plan maestro generado correctamente, puede empezar la planificación!",
				{
					role: "alert",
					theme: "dark",
				}
			);

			setTitle("Modificar");
			setAction("Editar plan");

			setTimeout(() => {
				setEditShow(true);
			}, 1000);
		} catch (error) {
			console.log(error);
			toast.warning(
				`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
				{
					role: "alert",
					theme: "dark",
				}
			);

			// error
			return;
		}
	};

	const update = async (e) => {
		e.preventDefault();

		if (name == "") {
			toast.error(`El campo nombre es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (description == "") {
			toast.error(`El campo descripción es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (selectYear == "") {
			toast.error(`El seleccionar un año es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (selectType == "") {
			toast.error(`El seleccionar un tipo de plan es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (startdate == "") {
			toast.error(`El seleccionar una fecha de inicio es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		if (enddate == "") {
			toast.error(`El seleccionar una fecha de finalización es obligatorio.`, {
				role: "alert",
				theme: "dark",
			});

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.put(`/masterplan/${id}`, {
				name: name,
				description: description,
				selectYear: selectYear,
				selectType: selectType,
				startdate: startdate,
				enddate: enddate,
			});

			//Guardo el plan maestro completo ver esto si está bien
			// setMasterPlan(data.data);
			handleSetMasterplan(data.data);

			toast.success("¡Plan maestro modificado corectamente!", {
				role: "alert",
				theme: "dark",
			});
		} catch (error) {
			toast.warning(
				`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
				{
					role: "alert",
					theme: "dark",
				}
			);

			// error
			return;
		}
	};

	const handleTabSelect = (tab) => {
		setKey(tab);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario plan maestro de ventas
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<h6 className='mb-4 text-center'>
									{title} campos{" "}
									{masterplanContext.stage
										? `: ${masterplanContext.name} ETAPA: ${masterplanContext.stage}`
										: ""}
								</h6>

								<Form onSubmit={editShow ? update : store}>
									<Card className='mb-2 p-4 h-md-100'>
										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={4}
												lg={4}
												controlId='formGridName'
											>
												<Form.Label>
													Nombre<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													value={name}
													onChange={(e) => setName(e.target.value)}
													type='text'
													placeholder='Ingrese nombre'
												/>
											</Form.Group>

											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={4}
												lg={4}
												controlId='formGridDescription'
											>
												<Form.Label>
													Descripción<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													type='text'
													placeholder='Ingrese descripción'
												/>
											</Form.Group>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={4}
												lg={4}
												controlId='formGridYear'
											>
												<Form.Label>
													Tipo de plan maestro
													<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Select
													size='sm'
													aria-label='select-code'
													value={selectType}
													onChange={(e) => setSelectType(e.target.value)}
												>
													<option value='' disabled>
														Seleccionar...
													</option>
													<option value={1}>Definitivo</option>
													<option value={0}>Borrador</option>
												</Form.Select>
											</Form.Group>
										</Row>
										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={4}
												lg={4}
												controlId='formGridYear'
											>
												<Form.Label>
													Seleccionar año a planificar
													<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Select
													size='sm'
													aria-label='select-code'
													value={selectYear}
													onChange={(e) => setSelectYear(e.target.value)}
												>
													<option value='' disabled>
														Seleccionar...
													</option>
													<option value={2023}>2023</option>
													<option value={2024}>2024</option>
													<option value={2025}>2025</option>
													<option value={2026}>2026</option>
													<option value={2027}>2027</option>
												</Form.Select>
											</Form.Group>
											<Form.Group
												controlId='formGridStartdate'
												as={Col}
												xs={12}
												sm={12}
												md={4}
												lg={4}
											>
												<Form.Label>Fecha de inicio</Form.Label>
												<Form.Control
													size='sm'
													type='date'
													value={startdate}
													onChange={(e) => setStartdate(e.target.value)}
												/>
											</Form.Group>
											<Form.Group
												controlId='formGridStartdate'
												as={Col}
												xs={12}
												sm={12}
												md={4}
												lg={4}
											>
												<Form.Label>Fecha de fin</Form.Label>
												<Form.Control
													size='sm'
													type='date'
													value={enddate}
													onChange={(e) => setEnddate(e.target.value)}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Col className='d-flex justify-content-end'>
												<Button size='sm' variant='primary' type='submit'>
													{action}
												</Button>
											</Col>
										</Row>
									</Card>

									{editShow && (
										<Card className='mb-2 p-4 h-md-100'>
											<Row className='mb-3 g-3'>
												<Tab.Container
													activeKey={key}
													defaultActiveKey={key}
													onSelect={handleTabSelect}
												>
													<Nav variant='tabs' className='mb-3'>
														<Nav.Item>
															<Nav.Link eventKey='stage1'>
																Etapa 1: Familias
															</Nav.Link>
														</Nav.Item>
														<Nav.Item>
															<Nav.Link
																eventKey='stage2'
																disabled={disabledStage2}
															>
																Etapa 2: Rubros y familias
															</Nav.Link>
														</Nav.Item>
														<Nav.Item>
															<Nav.Link
																eventKey='stage3'
																disabled={disabledStage3}
															>
																Etapa 3: Temporadas
															</Nav.Link>
														</Nav.Item>
														<Nav.Item>
															<Nav.Link
																eventKey='stage4'
																disabled={disabledStage4}
															>
																Etapa 4: Distribución por quincenas
															</Nav.Link>
														</Nav.Item>
													</Nav>

													<Col>
														<AnimatedProgressBar percentage={percentage} />
													</Col>
													<Col xs={12} sm={12} md={12} lg={12}>
														<h6 className='mb-3 fs--2 d-flex align-items-center gap-1 flex-wrap'>
															Min. totales:{" "}
															<Badge className='p-1 fs--2' bg='primary'>
																{formatNumber(totalMinutes)}
															</Badge>{" "}
															Min.Disp:{" "}
															<Badge
																className='p-1 fs--2'
																bg={handleBadgeStatus(
																	totalMinutes - minutesAvailable,
																	totalMinutes
																)}
															>
																{formatNumber(minutesAvailable)}
															</Badge>
														</h6>
													</Col>
													<Tab.Content>
														<Tab.Pane eventKey='stage1'>
															<MasterplanFormStageOne
																setdisabledStage2={setdisabledStage2}
																setKey={setKey}
																totalMinutes={totalMinutes}
																minutesAvailable={minutesAvailable}
																handleSetMinutesAvailable={
																	handleSetMinutesAvailable
																}
																badgeStatuses={badgeStatuses}
																handleBadgeStatus={handleBadgeStatus}
															/>
														</Tab.Pane>
														<Tab.Pane eventKey='stage2'>
															<MasterplanFormStageTwo
																masterPlan={masterPlan}
																setMasterPlan={setMasterPlan}
																setdisabledStage3={setdisabledStage3}
																setKey={setKey}
																totalMinutes={totalMinutes}
																minutesAvailable={minutesAvailable}
																handleSetMinutesAvailable={
																	handleSetMinutesAvailable
																}
																badgeStatuses={badgeStatuses}
																handleBadgeStatus={handleBadgeStatus}
															/>
														</Tab.Pane>
														<Tab.Pane eventKey='stage3'>
															<MasterplanFormStageThree
																masterPlan={masterPlan}
																setMasterPlan={setMasterPlan}
																setdisabledStage4={setdisabledStage4}
																setKey={setKey}
															/>
														</Tab.Pane>
														<Tab.Pane eventKey='stage4'>
															<MasterplanFormStageFour
																masterPlan={masterPlan}
																setMasterPlan={setMasterPlan}
																setKey={setKey}
															/>
														</Tab.Pane>
													</Tab.Content>
												</Tab.Container>
											</Row>
										</Card>
									)}
								</Form>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
