/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import dayjs from "dayjs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ListadoTable from "components/products/products/ListadoTable";
import { useAuthContext } from "hooks/useAuthContext";

const Listado = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [products, setProducts] = useState([]);

	useEffect(() => {
		getAllProducts();
	}, []);

	const getAllProducts = async () => {
		const { data } = await axiosWithToken.get("/products");

		setProducts(
			data.data.map((product) => {
				const typeMapping = {
					1: "Producto final",
					2: "Básico",
					3: "Semielaborado",
					4: "Genérico",
				};

				const stageMapping = {
					0: "Código generado",
					1: "Producto generado",
					2: "Molde asignado",
					3: "Estructura generada",
					4: "Hoja de ruta asignada",
					5: "Semielaborado asignado",
				};

				const type = typeMapping[product.situation];
				const stage = stageMapping[product.stage];

				return {
					id: product.id,
					code: product.code,
					name: product.name,
					description: product.description,
					type: type,
					stage: stage,
					created_at: dayjs(product.created_at).format("DD/MM/YYYY HH:mm:ss"),
					updated_at: dayjs(product.updated_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);
	};

	return (
		<>
			{/* CARD TABLA LISTADO */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='p-2'>
					<Row>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0'>Listado de Productos</h6>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/products/products/form'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body>
					<Tab.Content>
						<ListadoTable data={products} />
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Listado;
