/** @format */

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HojaRutaTable from "./HojaRutaTable";
import { getHojasRuta } from "data/hojaRuta/hojaRuta";
import Error from "components/error/Error";

const HojaRutaList = () => {
	const [hojasRuta, setHojasRuta] = useState([]);
	const [error, setError] = useState({ status: false, message: "" });

	useEffect(async () => {
		try {
			const hojasRutaApi = await getHojasRuta();
			setHojasRuta(hojasRutaApi);
		} catch (error) {
			setError({ status: true, message: "No existen Hoja de Ruta Cargadas" });
		}
	}, []);

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>Listado de Hojas de Ruta</h5>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/hoja-ruta/form'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>
				{error.status ? (
					<Error status={error.status} message={error.message} />
				) : (
					<Card.Body className='bg-light'>
						<Tab.Content>
							<HojaRutaTable hojasRuta={hojasRuta} />
						</Tab.Content>
					</Card.Body>
				)}
			</Card>
		</>
	);
};

export default HojaRutaList;
