/** @format */

import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Card, Tab, Button, Badge } from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/customCss.css";
import { useCentros } from "hooks/useCentros";
import FormularioModal from "components/common/FormularioModal";
import FormCentroEdit from "./FormCentroEdit";
import CenterPagination from "./CenterPagination";
import MachinePagination from "./MachinePagination";
import Spinner from "components/spinner/Spinner";

const CenterView = () => {
	const [childs, setChilds] = useState([]);
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(true);
	const [days, setDays] = useState([]);
	// const [editionMode, setEditionMode] = useState(false);
	const editionMode = false;

	const { id } = useParams();

	const { center, getCenter, deleteInCenter } = useCentros();

	const location = useLocation();

	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		getCenter(Number(id));
	}, [location]);

	useEffect(() => {
		let timeoutId = null;

		if (center.children != undefined && center.children.length > 0) {
			setChilds(center.children);
		} else {
			setChilds([]);
		}

		if (Object.values(center).length > 0) {
			if (center.center_day === null) {
				center.center_day = [];
			} else {
				if (center.center_day.day != undefined) {
					const dias = JSON.parse(center.center_day.day, true);
					setDays(dias);
				} else {
					setDays([]);
				}
			}
		}

		timeoutId = setTimeout(() => {
			setLoading(false);
		}, 500);

		// Cleanup function
		return () => {
			clearTimeout(timeoutId);
		};
	}, [center]);

	const deleteCenter = () => {
		if (Number(childs.length) > 0) {
			const confirmar = confirm(
				"Se borraran todos los datos, incluyendo los centros hijos!"
			);

			if (confirmar) {
				deleteInCenter(
					"/centros-de-trabajo/listado-centros/eliminar-centro",
					id
				);

				setTimeout(() => {
					navigate(-1);
				}, 200);
			}
		} else {
			const confirmar = confirm("Se borraran todos los datos!");

			if (confirmar) {
				deleteInCenter(
					"/centros-de-trabajo/listado-centros/eliminar-centro",
					id
				);

				setTimeout(() => {
					navigate(-1);
				}, 200);
			}
		}
	};

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<>
					<Card className='mb-3 border-bottom border-200'>
						<Card.Header className='bg-light border-bottom border-200'>
							<Row>
								<Col>
									<h5
										className='mb-0 hover-actions-trigger'
										id={camelize(`View`)}
									>
										Centro de trabajo : <Badge bg='primary'>#{center.id}</Badge>{" "}
										<Badge bg='primary'>{center.description}</Badge>{" "}
									</h5>
								</Col>
								<Col>
									<Button
										/* as={Link}
                      to={`/centros-de-trabajo/listado-centros/formCentroEdit/${center.id}`} */
										onClick={() => deleteCenter()}
										variant='danger'
										size='sm'
										className='border-0 float-end'
									>
										<FontAwesomeIcon icon='trash' /> ELIMINAR
									</Button>
									<Button
										onClick={() => setShow(!show)}
										variant='warning'
										size='sm'
										className='border-0 float-end'
										style={{ marginRight: "5px" }}
									>
										<FontAwesomeIcon icon='edit' /> EDITAR
									</Button>
								</Col>
							</Row>
						</Card.Header>

						<Card.Body className='bg-light'>
							<Row>
								<Col
									lg={editionMode ? 8 : 4}
									md={editionMode ? 8 : 4}
									sm={12}
									xs={12}
								>
									<Tab.Content>
										<Card className='mb-3 border-bottom border-100'>
											<Card.Header className='border-bottom border-200'>
												{editionMode ? (
													<>
														<h6 className='text-center'>Editar Datos</h6>
													</>
												) : (
													<>
														<h6 className='text-center'>Datos</h6>
														<h6 className='mt-3'>
															Estado:
															<span
																className='fs-0 card-text'
																style={{ fontWeight: "200" }}
															>
																{" "}
																{center.status === 1 ? "Activo" : "Inactivo"}
															</span>
														</h6>
														{/* <Card.Text className='fs--1'>{center.status === 1 ? "Activo" : "Inactivo"}</Card.Text> */}
														<h6 className='mt-3'>
															Total Centros internos:{" "}
															<span
																className='fs-0 card-text'
																style={{ fontWeight: "200" }}
															>
																{center.countChilds}
															</span>
														</h6>
														{/* <Card.Text className='fs--1'>{center.countChilds}</Card.Text> */}
														{/* <h6 className='mt-3'>Total Máquinas: <span className='fs-0 card-text' style={{fontWeight:'200'}}>{center.countMachines}</span></h6> */}
														{/* <Card.Text className='fs--1'>{center.countMachines}</Card.Text> */}
														<h6 className='mt-3'>
															Tipo:{" "}
															<span
																className='fs-0 card-text'
																style={{ fontWeight: "200" }}
															>
																{center.tipo}
															</span>
														</h6>
														<h6 className='mt-3'>
															Costo:{" "}
															<span
																className='fs-0 card-text'
																style={{ fontWeight: "200" }}
															>
																$.{center.costo}
															</span>
														</h6>
														{days.length > 0 ? (
															<>
																<h6 className='mt-3'>
																	Días laborales:{" "}
																	<span
																		className='fs-0 card-text'
																		style={{ fontWeight: "200" }}
																	>
																		{days.map((day) => {
																			return (
																				<React.Fragment key={day.value}>
																					<span>{day.label}, </span>
																				</React.Fragment>
																			);
																		})}
																	</span>
																</h6>
															</>
														) : (
															""
														)}
													</>
												)}
											</Card.Header>
										</Card>
									</Tab.Content>
								</Col>
								<Col
									lg={editionMode ? 4 : 8}
									md={editionMode ? 4 : 8}
									sm={12}
									xs={12}
								>
									<Tab.Content>
										<Card className='mb-3 border-bottom border-100'>
											<Card.Header className='border-bottom border-200'>
												{childs != undefined && childs.length > 0 ? (
													<CenterPagination childs={childs} />
												) : center.machines != undefined &&
												  center.machines.length > 0 ? (
													<MachinePagination
														machines={center.machines}
														centerId={center.id}
													/>
												) : (
													<h6 className='text-center'>
														No cuenta con maquinaria asociada
													</h6>
												)}
											</Card.Header>
										</Card>
									</Tab.Content>
								</Col>
							</Row>
						</Card.Body>
					</Card>

					{/* MODAL PARA AGREGAR CENTRO */}
					<FormularioModal
						show={show}
						size='lg'
						setShow={setShow}
						title='Editar Centro de trabajo'
					>
						<FormCentroEdit id={id} setShow={setShow} />
					</FormularioModal>
				</>
			)}
		</>
	);
};

export default CenterView;
