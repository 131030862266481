import { useMemo } from "react";

export default function useColumnsAtributos() {
  const columns = useMemo(
    () => [
        {
        accessor: 'id',
        Header: '#'
        },
        {
        accessor: 'description',
        Header: 'Descripción'
        }
        ,
        {
        accessor: 'technical_order',
        Header: 'Orden'
        }
        ,
        {
        accessor: 'created_at',
        Header: 'Fecha de alta'
        },
        {
        accessor: 'acciones',
        Header: 'Acciones'
        }
    ],
    []
  );

  return columns;
}
