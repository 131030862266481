/** @format */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Row, Button, Card, Col, Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import Flex from "components/common/Flex";
const img = `${process.env.REACT_APP_ENPOINT_BASE}/img-rawmaterials/`;
import cloudUpload from "assets/img/img-dashboard/icons/cloud-upload.svg";
import { useAuthContext } from "hooks/useAuthContext";
import { useRawmaterialContext } from "hooks/useRawmaterialContext";

const Formulario = ({ setDisabledStructure, setKey }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Estados validaciones
	const [showFabrics, setShowFabrics] = useState(false);
	const [disabledMaterial, setDisabledMaterial] = useState(false);
	const [disabledSupplier, setDisabledSupplier] = useState(true);
	const [showThreadtype, setShowThreadtype] = useState(false);

	//Estados campos de productos (materias primas)
	const [files, setFiles] = useState([]);
	const [selectUsability, setSelectUsability] = useState("");
	const [code, setCode] = useState("");
	const [name, setName] = useState("");
	const [codePresea, setCodePresea] = useState("");
	const [description, setDescription] = useState("");

	const [price, setPrice] = useState(null);
	const [anchoreal, setAnchoreal] = useState(null);
	const [rindereal, setRindereal] = useState(null);
	const [anchoficha, setAnchoficha] = useState(null);
	const [rindeficha, setRindeficha] = useState(null);
	const [weightconv, setWeightconv] = useState(null);
	const [quantityconv, setQuantityconv] = useState(null);
	const [tubeweight, setTubeweight] = useState(null);

	const [observacion, setObservacion] = useState("");
	const [selectSupplier, setSelectSupplier] = useState("");
	const [selectTubular, setSelectTubular] = useState(false);
	const [selectTermoestampado, setSelectTermoestampado] = useState(false);
	const [selectUnitPrimary, setSelectUnitPrimary] = useState("");
	const [selectUnitSecundary, setSelectUnitSecundary] = useState("");

	const [selectThreadtype, setSelectThreadtype] = useState("");
	const [selectedFamily, setSelectedFamily] = useState("");
	const [selectedFabrictype, setSelectedFabrictype] = useState("");
	const [bath, setBath] = useState("");
	const [composition, setComposition] = useState("");
	const [tolerance, setTolerance] = useState(null);
	const [resettingTime, setResettingTime] = useState(null);
	const [dollarCost, setdollarCost] = useState(null);

	const {
		fabrictypes,
		getFabrictypes,
		rawmaterialFamilies,
		getFamilies,
		rawmaterial,
		handleRawmaterialById,
		suppliers,
		getAllSuppliers,
		units,
		getAllUnits,
		materialCodes,
		getAllMaterialCodes,
	} = useRawmaterialContext();

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	//UseEffect general, se ejecuta una sola vez, cuándo se termina el renderizado
	useEffect(() => {
		getFamilies();
		getFabrictypes();
		getAllSuppliers();
		getAllUnits();
		getAllMaterialCodes();
	}, []);

	useEffect(() => {
		if (rawmaterial && Object.entries(rawmaterial).length > 0) {
			console.log(rawmaterial);
			if (rawmaterial.stage == 0) {
				setDisabledMaterial(true);
				const codefirst = rawmaterial.code.slice(0, 3);
				if (codefirst === "001") {
					//Mostrar campos específicos de tela
					setShowFabrics(true);
					setShowThreadtype(false);
					setSelectThreadtype("");
				} else if (codefirst === "002") {
					//Mostrar hilo de arriba o hilo de abajo en un select
					setShowThreadtype(true);
					setShowFabrics(false);
				} else {
					setShowFabrics(false);
					setShowThreadtype(false);
					setSelectThreadtype("");
				}

				setSelectUsability(codefirst);
				setName(rawmaterial.codeobject.description);
				setDescription(rawmaterial.codeobject.description);
				setCode(rawmaterial.code);
				setDisabledSupplier(false);
				setDisabledStructure(true);
				setCodePresea("");
				setPrice(null);
				setAnchoreal(null);
				setRindereal(null);
				setAnchoficha(null);
				setRindeficha(null);
				setObservacion("");
				setSelectSupplier("");
				setSelectTubular(false);
				setSelectTermoestampado(false);
				setWeightconv(null);
				setQuantityconv(null);
				setSelectUnitPrimary("");
				setSelectUnitSecundary("");
				setTubeweight(null);
				setBath("");
				setSelectThreadtype("");
				setSelectedFamily("");
				setComposition("");
				setTolerance(null);
				setResettingTime(null);
				setdollarCost(null);
				setFiles([]);
			}

			if (rawmaterial.stage > 0) {
				setDisabledMaterial(true);
				setDisabledSupplier(true);
				setDisabledStructure(false);

				const codefirst = rawmaterial.code.slice(0, 3);
				if (codefirst === "001") {
					//Mostrar campos específicos de tela
					setShowFabrics(true);
					setShowThreadtype(false);
					setSelectThreadtype("");
				} else if (codefirst === "002") {
					//Mostrar hilo de arriba o hilo de abajo en un select
					setShowThreadtype(true);
					setShowFabrics(false);
				} else {
					setShowFabrics(false);
					setShowThreadtype(false);
					setSelectThreadtype("");
				}

				if (rawmaterial.image) {
					setFiles([
						{
							name: rawmaterial.image, // Nombre de la imagen
							preview: img + rawmaterial.image, // URL de la imagen desde el backend
						},
					]);
				}

				setSelectUsability(codefirst);
				setDescription(rawmaterial.description);
				setCode(rawmaterial.code);
				setName(rawmaterial.name);
				setCodePresea(rawmaterial.code_presea);
				setObservacion(rawmaterial.comment ? rawmaterial.comment : "");
				setWeightconv(rawmaterial.weight_conversion);
				setQuantityconv(rawmaterial.quantity_conversion);
				setTubeweight(rawmaterial.tube_weight);
				setBath(rawmaterial.bath);
				setComposition(rawmaterial.composition);
				setTolerance(rawmaterial.tolerance);
				setResettingTime(rawmaterial.resetting_time);
				setdollarCost(rawmaterial.dollar_cost);

				const properties = [
					{ property: "rawmaterial_family_id", setter: setSelectedFamily },
					{ property: "fabrictype_id", setter: setSelectedFabrictype },
				];

				properties.forEach(({ property, setter }) => {
					if (rawmaterial[property]) {
						setter(rawmaterial[property]);
					} else {
						setter("");
					}
				});

				rawmaterial.units.forEach((unit) => {
					if (unit.pivot.main) {
						setSelectUnitPrimary(unit.id);
					} else {
						setSelectUnitSecundary(unit.id);
					}
				});

				rawmaterial.suppliers_rawmaterials.forEach((supplier, indice) => {
					if (indice == 0) {
						setSelectSupplier(supplier.id);
						setPrice(supplier.pivot.price_supplier);

						//Propios de la tela
						if (codefirst == "001") {
							setRindeficha(supplier.pivot.rinde_supplier);
							setAnchoficha(supplier.pivot.ancho_supplier);
						}
					}
				});

				//Propios de la tela
				if (codefirst == "001") {
					setAnchoreal(rawmaterial.width);
					setRindereal(rawmaterial.rinde);

					setSelectTubular(rawmaterial.tubular ? true : false);
					setSelectTermoestampado(rawmaterial.hot_stamping ? true : false);
				}

				//Propios del hilo
				if (codefirst == "002") {
					if (rawmaterial.thread == 0) {
						setSelectThreadtype(2);
					} else if (rawmaterial.thread == 1) {
						setSelectThreadtype(1);
					}
				}
			}
		} else {
			//Limpiamos los estados
			setDisabledSupplier(false);
			setShowFabrics(false);
			setDisabledMaterial(false);
			setDisabledSupplier(true);
			setShowThreadtype(false);

			setSelectUsability("");
			setCode("");
			setName("");
			setCodePresea("");
			setDescription("");
			setPrice(null);
			setAnchoreal(null);
			setRindereal(null);
			setAnchoficha(null);
			setRindeficha(null);
			setObservacion("");
			setSelectSupplier("");
			setSelectTubular(false);
			setSelectTermoestampado(false);
			setWeightconv(null);
			setQuantityconv(null);
			setSelectUnitPrimary("");
			setSelectUnitSecundary("");
			setTubeweight(null);
			setBath("");
			setSelectThreadtype("");
			setSelectedFamily("");
			setComposition("");
			setTolerance(null);
			setResettingTime(null);
			setdollarCost(null);
			setFiles([]);
		}
	}, [rawmaterial]);

	const handleCancel = () => {
		navigate("/products/rawmaterials/list");
	};

	const handleRemoveImg = (path) => {
		setFiles(files.filter((file) => file.path !== path));
	};

	const onChangeTubular = () => {
		setSelectTubular(!selectTubular);
	};

	const onChangeTermoestampado = () => {
		setSelectTermoestampado(!selectTermoestampado);
	};

	const storeUpdate = async (e) => {
		e.preventDefault();
		if (selectUsability == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar un tipo de materia prima es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (code == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo código es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (name == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo nombre es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (codePresea == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo código presea es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (selectUnitPrimary == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar una unidad de medida principal es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (selectUsability === "001") {
			if (!anchoreal) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El campo ancho real es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}

				//Error
				return;
			}

			if (!rindereal) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El campo rinde real es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}

				//Error
				return;
			}
		}

		if (selectSupplier == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar un proveedor es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (!price) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo precio es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (selectUsability === "001") {
			if (!tubeweight) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo peso del tubo es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			if (!anchoficha) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo ancho ficha es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			if (!rindeficha) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo rinde ficha es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		if (selectUsability === "002") {
			if (selectThreadtype == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El seleccionar un tipo de hilo es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		const dataForm = new FormData();
		dataForm.append("code", code);
		dataForm.append("name", name);
		dataForm.append("description", description);
		dataForm.append("codePresea", codePresea);
		dataForm.append("price", price);
		dataForm.append("ancho_supplier", anchoficha);
		dataForm.append("rinde_supplier", rindeficha);
		dataForm.append("ancho_real", anchoreal);
		dataForm.append("rinde_real", rindereal);
		dataForm.append("comment", observacion);
		dataForm.append("supplier_id", selectSupplier);
		dataForm.append("tubular", +selectTubular);
		dataForm.append("termoestampado", +selectTermoestampado);
		dataForm.append("weightconv", weightconv);
		dataForm.append("quantityconv", quantityconv);
		dataForm.append("selectUnitPrimary", selectUnitPrimary);
		dataForm.append("selectUnitSecundary", selectUnitSecundary);
		dataForm.append("tubeweight", tubeweight);
		dataForm.append("bath", bath);
		dataForm.append("selectThreadtype", selectThreadtype);
		dataForm.append("selectedFamily", selectedFamily);
		dataForm.append("selectedFabrictype", selectedFabrictype);
		dataForm.append("composition", composition);
		dataForm.append("tolerance", tolerance);
		dataForm.append("resetting_time", resettingTime);
		dataForm.append("dollar_cost", dollarCost);
		dataForm.append("stage", 1);

		// Agregar la imagen al FormData
		if (files.length > 0) {
			const image = files[0]; // Tomamos solo la primera imagen (si solo se permite una imagen)
			dataForm.append("image", image);
		} else {
			dataForm.append("image", null);
		}

		try {
			const { data } = await axiosWithToken.post(
				`/rawmaterial/${rawmaterial.id}`,
				dataForm
			);
			handleRawmaterialById(data.data.id);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				setDisabledStructure(false);
				setKey("structure");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			if (rawmaterial) {
				if (Object.entries(rawmaterial).length > 0 && rawmaterial.stage >= 1) {
					setCodePresea(rawmaterial.code_presea);
				}
			}
			// error
			return;
		}
	};

	return (
		<>
			{/* FORM */}

			<Form onSubmit={storeUpdate} encType='multipart/form-data'>
				<Row>
					<Col className='mb-2' xs={12} sm={12} lg={8} md={8}>
						<Card className='mb-2 p-2 h-md-100'>
							<h6 className='mb-4 text-center fs--2'>
								Cargar campos de la materia prima:{" "}
								{code && (
									<span>
										<Badge bg='secondary'>{code}</Badge>
									</span>
								)}
							</h6>
							<Card.Body>
								<Row className='mb-3 g-3'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										id='formGridCode'
									>
										<Form.Label>
											Seleccionar tipo de materia prima
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Select
											size='sm'
											aria-label='select-code'
											onChange={(e) => setSelectUsability(e.target.value)}
											value={selectUsability}
											disabled={disabledMaterial}
										>
											<option value='' disabled>
												Seleccionar...
											</option>
											{materialCodes.map((elem) => {
												return (
													<option key={elem.value} value={elem.value}>
														{elem.label}
													</option>
												);
											})}
										</Form.Select>
									</Form.Group>
								</Row>
								<Row className='mb-2'>
									<Form.Group as={Col} md={6} lg={6} controlId='formGridName'>
										<Form.Label>
											Nombre<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											value={name}
											onChange={(e) => setName(e.target.value)}
											type='text'
											placeholder='Nombre'
										/>
									</Form.Group>

									<Form.Group as={Col} md={6} lg={6} controlId='formGridName'>
										<Form.Label>
											Código Presea<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											value={codePresea}
											onChange={(e) => setCodePresea(e.target.value)}
											type='text'
											placeholder='Código presea'
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group
										as={Col}
										md={6}
										lg={6}
										controlId='formGridDescription'
									>
										<Form.Label>Descripción</Form.Label>
										<Form.Control
											size='sm'
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											type='text'
											placeholder='Descripción'
										/>
									</Form.Group>

									{showThreadtype && (
										<Form.Group
											as={Col}
											md={6}
											lg={6}
											controlId='formGridThread'
										>
											<Form.Label>
												Seleccionar tipo de hilo
												<span className='text-danger'>*</span>
											</Form.Label>
											<Form.Select
												size='sm'
												aria-label='select-code'
												value={selectThreadtype}
												onChange={(e) => setSelectThreadtype(e.target.value)}
											>
												<option value='' disabled>
													Seleccionar...
												</option>
												<option value={1}>Hilo de arriba</option>
												<option value={2}>Hilo de abajo</option>
											</Form.Select>
										</Form.Group>
									)}
								</Row>
								<Row className='mb-3 mt-2 g-2'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										id='formGridFamilies'
									>
										<Form.Label className='fs--2'>
											Seleccionar una familia
										</Form.Label>
										<Form.Select
											size='sm'
											aria-label='select-family'
											onChange={(e) => setSelectedFamily(e.target.value)}
											value={selectedFamily}
										>
											<option value=''>Sin familia</option>
											{rawmaterialFamilies.map((elem) => {
												return (
													<option
														key={`opt-famility-${elem.id}`}
														value={elem.id}
													>
														{elem.name}
													</option>
												);
											})}
										</Form.Select>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group
										as={Col}
										md={6}
										lg={6}
										sm={12}
										xs={12}
										controlId='formGridDescription'
									>
										<Form.Label>Días anticipacion m. prima</Form.Label>
										<Form.Control
											size='sm'
											type='number'
											value={resettingTime === null ? "" : resettingTime}
											onChange={(e) => setResettingTime(e.target.value)}
											placeholder='Ingresar días'
										/>
									</Form.Group>

									<Form.Group
										as={Col}
										md={6}
										lg={6}
										sm={12}
										xs={12}
										controlId='formGridDollarprice'
									>
										<Form.Label>Precio en dolares</Form.Label>
										<Form.Control
											size='sm'
											type='number'
											value={dollarCost === null ? "" : dollarCost}
											onChange={(e) => setdollarCost(e.target.value)}
											placeholder='Precio en dolares'
										/>
									</Form.Group>
								</Row>

								<Row className='mb-3 mt-2 g-2'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										id='formGridObservacion'
									>
										<Form.Label>Observaciones</Form.Label>
										<Form.Control
											size='sm'
											as='textarea'
											placeholder='Observaciones'
											value={observacion}
											onChange={(e) => setObservacion(e.target.value)}
											style={{ height: "100px" }}
										/>
									</Form.Group>
								</Row>
							</Card.Body>
						</Card>
					</Col>

					<Col xs={12} sm={12} lg={4} md={4}>
						<Card className='mb-2 p-2'>
							<h6 className='mb-2 text-center fs--2'>Imagen</h6>
							<Card.Body>
								<Row className='mb-3'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										id='formGridImage'
									>
										<div
											style={{ height: "50%" }}
											{...getRootProps({
												className: "dropzone-area pt-4 pb-5",
											})}
										>
											<input {...getInputProps()} />
											<Flex justifyContent='center'>
												<img
													src={cloudUpload}
													alt=''
													width={20}
													className='me-2'
												/>
												<p className='fs--1 mb-0 text-700'>
													Arrastre la imagen aquí
												</p>
											</Flex>
										</div>
										<div className='mt-1'>
											{files.map((file) => (
												<Flex
													alignItems='center'
													className='py-3 border-bottom btn-reveal-trigger'
													key={file.path}
												>
													<img
														width={50}
														height={40}
														src={file.preview}
														alt={file.path}
													/>

													<Button
														style={{ fontSize: "12px" }}
														onClick={() => handleRemoveImg(file.path)}
														variant='transparent'
														size='sm'
														className='text-danger'
													>
														<FontAwesomeIcon icon='trash' /> Eliminar
													</Button>
												</Flex>
											))}
										</div>
									</Form.Group>
								</Row>
							</Card.Body>
						</Card>
						<Card className='mb-2 p-2 h-md-30'>
							<h6 className='mb-4 text-center fs--2'>Unidades de conversión</h6>
							<Card.Body>
								<Row className='mb-2'>
									<Form.Group
										as={Col}
										md={12}
										lg={12}
										controlId='formGridWeightConv'
									>
										<Form.Label>Peso conversión (Gramos)</Form.Label>
										<Form.Control
											size='sm'
											type='number'
											value={weightconv === null ? "" : weightconv}
											onChange={(e) => setWeightconv(e.target.value)}
											placeholder='Peso conversión'
										/>
									</Form.Group>

									<Form.Group
										as={Col}
										md={12}
										lg={12}
										controlId='formGridQuantityConv'
									>
										<Form.Label>Cantidad de conversión</Form.Label>
										<Form.Control
											size='sm'
											type='number'
											value={quantityconv === null ? "" : quantityconv}
											onChange={(e) => setQuantityconv(e.target.value)}
											placeholder='Cantidad conversión'
										/>
									</Form.Group>
								</Row>
							</Card.Body>
						</Card>

						<Card className='mb-2 p-2 h-md-30'>
							<h6 className='mb-4 text-center fs--2'>Unidades de medidas</h6>
							<Card.Body>
								<Row className='mb-2'>
									<Form.Group
										as={Col}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridSelectSupplier'
									>
										<Form.Label>
											Unidad de medida principal
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Select
											size='sm'
											value={selectUnitPrimary || ""}
											aria-label='uniadprincipal'
											onChange={(e) => setSelectUnitPrimary(e.target.value)}
										>
											<option value=''>Sin unidad de medida</option>
											{units.map((elem) => {
												return (
													<option key={elem.value} value={elem.value}>
														{elem.label}
													</option>
												);
											})}
										</Form.Select>
									</Form.Group>

									<Form.Group
										as={Col}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridSelectSupplier'
									>
										<Form.Label>Unidad de medida secundaria</Form.Label>
										<Form.Select
											size='sm'
											value={selectUnitSecundary || ""}
											aria-label='uniadprincipal'
											onChange={(e) => setSelectUnitSecundary(e.target.value)}
										>
											<option value=''>Sin unidad de medida</option>
											{units.map((elem) => {
												return (
													<option key={elem.value} value={elem.value}>
														{elem.label}
													</option>
												);
											})}
										</Form.Select>
									</Form.Group>
								</Row>
							</Card.Body>
						</Card>
					</Col>

					{showFabrics && (
						<Col xs={12} sm={12} lg={6} md={6}>
							<Card className='mb-2 p-2 h-md-100'>
								<h6 className='mb-4 text-center fs--2'>
									¡Datos propios de la tela!
								</h6>
								<Card.Body>
									<Row className='mb-1 mt-1 g-3'>
										<Form.Group
											as={Col}
											xs={12}
											sm={12}
											md={12}
											lg={12}
											id='formGridFabricTypes'
										>
											<Form.Label className='fs--2'>
												Seleccionar un tipo de tela
											</Form.Label>
											<Form.Select
												size='sm'
												aria-label='select-fabrictype'
												onChange={(e) => setSelectedFabrictype(e.target.value)}
												value={selectedFabrictype}
											>
												<option value=''>Sin tipo de tela</option>
												{fabrictypes.map((elem) => {
													return (
														<option
															key={`opt-fabrictype-${elem.id}`}
															value={elem.id}
														>
															{elem.code}: {elem.description}
														</option>
													);
												})}
											</Form.Select>
										</Form.Group>

										<Form.Group
											as={Col}
											md={6}
											lg={6}
											controlId='formGridTubeWeight'
										>
											<Form.Label>
												Peso del tubo
												<span className='text-danger'>*</span>
											</Form.Label>
											<Form.Control
												size='sm'
												type='number'
												value={tubeweight === null ? "" : tubeweight}
												onChange={(e) => setTubeweight(e.target.value)}
												placeholder='Ingrese el peso del tubo'
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={6} controlId='formGridBath'>
											<Form.Label>Baño</Form.Label>
											<Form.Control
												size='sm'
												type='number'
												value={bath}
												onChange={(e) => setBath(e.target.value)}
												placeholder='Ingrese el baño de la tela'
											/>
										</Form.Group>

										<Form.Group
											as={Col}
											md={6}
											lg={6}
											controlId='formGridAnchoReal'
										>
											<Form.Label>
												Ancho real<span className='text-danger'>*</span>
											</Form.Label>
											<Form.Control
												size='sm'
												type='number'
												value={anchoreal === null ? "" : anchoreal}
												onChange={(e) => setAnchoreal(e.target.value)}
												placeholder='Ingrese ancho real'
											/>
										</Form.Group>

										<Form.Group
											as={Col}
											md={6}
											lg={6}
											controlId='formGridRindeReal'
										>
											<Form.Label>
												Rinde real<span className='text-danger'>*</span>
											</Form.Label>
											<Form.Control
												size='sm'
												type='number'
												value={rindereal === null ? "" : rindereal}
												onChange={(e) => setRindereal(e.target.value)}
												placeholder='Ingrese rinde real'
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={6} controlId='formGridName'>
											<Form.Label>Composición</Form.Label>
											<Form.Control
												size='sm'
												value={composition}
												onChange={(e) => setComposition(e.target.value)}
												type='text'
												placeholder='Composición'
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={6} controlId='formGridBath'>
											<Form.Label>Tolerancia</Form.Label>
											<Form.Control
												size='sm'
												type='number'
												value={tolerance === null ? "" : tolerance}
												onChange={(e) => setTolerance(e.target.value)}
												placeholder='Ingrese la tolerancia'
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={6} id='formGridTubular'>
											<Form.Check
												type='switch'
												label='Tubular'
												checked={selectTubular}
												onChange={onChangeTubular}
											/>
										</Form.Group>

										<Form.Group
											as={Col}
											md={6}
											lg={6}
											id='formGridTermoestampado'
										>
											<Form.Check
												type='switch'
												label='Termoestampado'
												checked={selectTermoestampado}
												onChange={onChangeTermoestampado}
											/>
										</Form.Group>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					)}

					<Col
						xs={12}
						sm={12}
						lg={showFabrics ? 6 : 8}
						md={showFabrics ? 6 : 8}
					>
						<Card className='mb-2 p-2 h-md-100'>
							<h6 className='mb-4 text-center fs--2'>¡Datos del proveedor!</h6>
							<Card.Body>
								<Row className='mb-2'>
									<Form.Group
										as={Col}
										sm={12}
										md={6}
										lg={6}
										controlId='formGridSelectSupplier'
									>
										<Form.Label>
											Proveedor<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Select
											size='sm'
											value={selectSupplier || ""}
											aria-label='Tipo'
											disabled={disabledSupplier}
											onChange={(e) => setSelectSupplier(e.target.value)}
										>
											<option value='0'>Seleccionar...</option>
											{suppliers.map((elem) => {
												return (
													<option key={elem.value} value={elem.value}>
														{elem.label}
													</option>
												);
											})}
										</Form.Select>
									</Form.Group>

									<Form.Group as={Col} md={6} lg={6} controlId='formGridPrice'>
										<Form.Label>
											Precio<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											type='number'
											value={price === null ? "" : price}
											onChange={(e) => setPrice(e.target.value)}
											placeholder='Precio'
										/>
									</Form.Group>

									{showFabrics && (
										<>
											<Form.Group
												as={Col}
												md={6}
												lg={6}
												controlId='formGridAnchoFicha'
											>
												<Form.Label>
													Ancho ficha
													<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													type='number'
													value={anchoficha === null ? "" : anchoficha}
													onChange={(e) => setAnchoficha(e.target.value)}
													placeholder='Ingrese ancho ficha'
												/>
											</Form.Group>

											<Form.Group
												as={Col}
												md={6}
												lg={6}
												controlId='formGridRindeFicha'
											>
												<Form.Label>
													Rinde ficha
													<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													type='number'
													value={rindeficha === null ? "" : rindeficha}
													onChange={(e) => setRindeficha(e.target.value)}
													placeholder='Ingrese rinde ficha'
												/>
											</Form.Group>
										</>
									)}
								</Row>
							</Card.Body>
						</Card>
					</Col>

					<Col
						as={Col}
						xs={12}
						sm={12}
						md={12}
						lg={12}
						className='d-flex justify-content-end mt-2'
					>
						<Button size='sm' variant='danger' onClick={() => handleCancel()}>
							Cancelar
						</Button>

						<Button className='ms-1' size='sm' variant='primary' type='submit'>
							Guardar
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
};

Formulario.propTypes = {
	setDisabledStructure: PropTypes.func,
	setKey: PropTypes.func,
};

export default Formulario;
