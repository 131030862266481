/** @format */

import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Card,
	Tab,
	Button,
	Form,
	InputGroup,
	FormControl,
	OverlayTrigger,
	Tooltip,
	Badge,
} from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	useTable,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
	usePagination,
} from "react-table";
import classNames from "classnames";
import useColumnsMolds from "../../hooks/useColumnsMolds";
import { Link } from "react-router-dom";
import Flex from "../common/Flex";
import axios from "axios";
import SimpleBarReact from "simplebar-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";

const ListadoTabla = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const toastId = React.useRef(null);

	const [data, setMolds] = useState([]);

	useEffect(() => {
		getAllMolds();
	}, []);

	const deleteMold = async (id) => {
		const respuesta = await axiosWithToken.delete(`/mold/${id}`);

		if (respuesta.status == 200) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${respuesta.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}

		getAllMolds();
	};

	const getAllMolds = async () => {
		const response = await axiosWithToken.get("/molds");

		setMolds(
			response.data.data.map((mold) => {
				return {
					id: mold.id,
					code: mold.code,
					description: mold.description,
					created_at: dayjs(mold.created_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);
	};

	const columns = useColumnsMolds();

	function MoldsFilter({ globalFilter, setGlobalFilter }) {
		const [value, setValue] = useState(globalFilter);

		const onFilterChange = useAsyncDebounce(
			(value) => setGlobalFilter(value || undefined),
			200
		);

		const handleInputChange = (e) => {
			setValue(e.target.value);
			onFilterChange(e.target.value);
		};

		return (
			<>
				<InputGroup className='position-relative'>
					<FormControl
						value={value || ""}
						onChange={handleInputChange}
						size='sm'
						id='search'
						placeholder='Buscar...'
						type='search'
						className='shadow-none'
					/>
					<InputGroup.Text className='bg-transparent'>
						<FontAwesomeIcon icon='search' className='text-600' />
					</InputGroup.Text>
				</InputGroup>
			</>
		);
	}

	const table = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 15,
				pageIndex: 0,
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		rowsPerPageOptions = [5, 10, 15],
		setGlobalFilter,
	} = table;

	return (
		<>
			{/* CARD TABLA LISTADO */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>Listado de Moldes</h5>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/molds/MoldsForm'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						{/* TOAST MENSAJE */}

						<ToastContainer
							position='top-right'
							autoClose={1000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>

						{/* CONTENIDO */}

						<SimpleBarReact>
							{/* BUSCADOR */}

							<MoldsFilter
								globalFilter={globalFilter}
								setGlobalFilter={setGlobalFilter}
							/>

							{/* TABLA */}

							<table
								className='fs--1 mb-0 overflow-hidden table table-striped table-bordered mt-2'
								{...getTableProps()}
							>
								<thead className='bg-200 text-900 text-nowrap align-middle'>
									{
										// Loop over the header rows
										headerGroups.map((headerGroup, index) => (
											// Apply the header row props
											<tr
												key={`tr-${index}`}
												{...headerGroup.getHeaderGroupProps()}
											>
												{
													// Loop over the headers in each row
													headerGroup.headers.map((column, index) => (
														// Apply the header cell props
														<th
															key={`th-${index}`}
															{...column.getHeaderProps(
																column.id != "acciones"
																	? column.getSortByToggleProps(
																			column.headerProps
																	  )
																	: ""
															)}
														>
															{
																// Render the header
																column.render("Header")
															}

															{column.id != "acciones" ? (
																column.canSort ? (
																	column.isSorted ? (
																		column.isSortedDesc ? (
																			<span className='sort desc' />
																		) : (
																			<span className='sort asc' />
																		)
																	) : (
																		<span className='sort' />
																	)
																) : (
																	""
																)
															) : (
																""
															)}
														</th>
													))
												}
											</tr>
										))
									}
								</thead>
								{/* Apply the table body props */}
								<tbody {...getTableBodyProps()}>
									{
										// Loop over the table rows
										page.map((row, index) => {
											// Prepare the row for display
											prepareRow(row);
											return (
												// Apply the row props
												<tr key={`tr2-${index}`} {...row.getRowProps()}>
													{
														// Loop over the rows cells
														row.cells.map((cell, index) => {
															return (
																<td
																	key={`td2-${index}`}
																	{...cell.getCellProps()}
																>
																	{
																		// Render the cell contents
																		cell.column.id == "tipo" ? (
																			<Badge bg='primary'>
																				{cell.render("Cell")}
																			</Badge>
																		) : cell.column.id == "acciones" ? (
																			<>
																				<OverlayTrigger
																					placement='top'
																					overlay={<Tooltip>Editar</Tooltip>}
																				>
																					<Button
																						as={Link}
																						to={`/molds/moldsform/${cell.row.values.id}`}
																						variant='transparent'
																						size='sm'
																						className='text-success me-2'
																					>
																						<FontAwesomeIcon icon='edit' />
																					</Button>
																				</OverlayTrigger>
																				<OverlayTrigger
																					placement='top'
																					overlay={<Tooltip>Eliminar</Tooltip>}
																				>
																					<Button
																						onClick={() =>
																							deleteMold(cell.row.values.id)
																						}
																						variant='transparent'
																						size='sm'
																						className=' text-danger me-2'
																					>
																						<FontAwesomeIcon icon='trash' />
																					</Button>
																				</OverlayTrigger>
																			</>
																		) : (
																			cell.render("Cell")
																		)
																	}
																</td>
															);
														})
													}
												</tr>
											);
										})
									}
								</tbody>
							</table>

							{/* PAGINACIÓN */}

							<Flex className='mt-3 align-items-center justify-content-between'>
								<Flex alignItems='center' className='fs--1'>
									<p className='mb-0'>
										<span className='d-none d-sm-inline-block me-2'>
											Del {pageSize * pageIndex + 1} al{" "}
											{pageSize * pageIndex + page.length} de {pageCount}
										</span>
									</p>

									<p className='mb-0 mx-2'>Filas por página:</p>
									<Form.Select
										size='sm'
										className='w-auto'
										onChange={(e) => setPageSize(e.target.value)}
										defaultValue={pageSize}
									>
										{rowsPerPageOptions.map((value) => (
											<option value={value} key={value}>
												{value}
											</option>
										))}
									</Form.Select>
								</Flex>

								<Flex>
									<Button
										size='sm'
										variant={canPreviousPage ? "primary" : "light"}
										onClick={() => previousPage()}
										className={classNames({ disabled: !canPreviousPage })}
									>
										Anterior
									</Button>
									<Button
										size='sm'
										variant={canNextPage ? "primary" : "light"}
										className={classNames("px-4 ms-2", {
											disabled: !canNextPage,
										})}
										onClick={() => nextPage()}
									>
										Siguiente
									</Button>
								</Flex>
							</Flex>
						</SimpleBarReact>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

ListadoTabla.propTypes = {
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func,
};

export default ListadoTabla;
