/** @format */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Flex from "components/common/Flex";
import CardDropdown from "components/common/CardDropdown";
import { Image } from "react-bootstrap";
import { getSize } from "helpers/utils";
import { Dropdown } from "react-bootstrap";

const DragAndDrop = ({ imagen, handleImage }) => {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (files.length < 1) {
			handleImage(null);
		} else {
			handleImage(files[0]);
		}
	}, [files]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const handleRemove = (path) => {
		setFiles(files.filter((file) => file.path !== path));
	};

	return (
		<>
			<div {...getRootProps({ className: "dropzone-area py-6" })}>
				<input {...getInputProps()} />
				<Flex justifyContent='center'>
					{/* <img src={faCloudUploadAlt} alt="" width={25} className="me-2" /> */}
					<p className='fs-0 mb-0 text-700'>Seleccionar imagen</p>
				</Flex>
			</div>

			<div className='mt-3'>
				{files.length < 1 ? (
					<Flex
						alignItems='center'
						className='py-3 border-bottom btn-reveal-trigger'
					>
						<Image
							rounded
							width={40}
							height={40}
							src={`${process.env.REACT_APP_ENDPOINT_BASE}/img-estamperia/${imagen}`}
							alt={imagen}
						/>
						<Flex
							justifyContent='between'
							alignItems='center'
							className='ms-3 flex-1'
						>
							<div>
								<h6>{imagen}</h6>
							</div>
						</Flex>
					</Flex>
				) : (
					files.map((file) => (
						<div key={file.path}>
							<Flex
								alignItems='center'
								className='py-3 border-bottom btn-reveal-trigger'
							>
								<Image
									rounded
									width={40}
									height={40}
									src={file.preview}
									alt={file.path}
								/>
								<Flex
									justifyContent='between'
									alignItems='center'
									className='ms-3 flex-1'
								>
									<div>
										<h6>{file.path}</h6>
										<Flex className='position-relative' alignItems='center'>
											<p className='mb-0 fs--1 text-400 line-height-1'>
												<strong>{getSize(file.size)}</strong>
											</p>
										</Flex>
									</div>
								</Flex>
								<CardDropdown>
									<div className='py-2'>
										<Dropdown.Item
											className='text-danger'
											onClick={() => handleRemove(file.path)}
										>
											Remover
										</Dropdown.Item>
									</div>
								</CardDropdown>
							</Flex>
						</div>
					))
				)}
			</div>
		</>
	);
};

DragAndDrop.propTypes = {
	imagen: PropTypes.any,
	handleImage: PropTypes.func,
};

export default DragAndDrop;
