/** @format */

import { createContext, useState } from "react";
import PropTypes from "prop-types";

const MasterplanContext = createContext();

const MasterplanProvider = ({ children }) => {
	const [masterplanContext, setMasterplanContext] = useState({});
	const [stageOneDetails, setStageOneDetails] = useState([]);
	const [stageTwoDetails, setStageTwoDetails] = useState([]);
	const [stageThreeDetails, setStageThreeDetails] = useState([]);
	const [stageFourDetails, setStageFourDetails] = useState([]);

	//Guardamos los datos del plan maestro
	const handleSetMasterplan = (data) => {
		if (data) return setMasterplanContext(data);
	};

	//Funcion para guardar datos de la etapa 1
	const handleStageOneDetails = (data) => {
		if (data) return setStageOneDetails(data);
	};

	//Funcion para guardar datos de la etapa 2
	const handleStageTwoDetails = (data) => {
		if (data) return setStageTwoDetails(data);
	};

	//Funcion para guardar datos de la etapa 3
	const handleStageThreeDetails = (data) => {
		if (data) return setStageThreeDetails(data);
	};

	//Funcion para guardar datos de la etapa 4
	const handleStageFourDetails = (data) => {
		if (data) return setStageFourDetails(data);
	};

	return (
		<MasterplanContext.Provider
			value={{
				masterplanContext,
				handleSetMasterplan,
				stageOneDetails,
				handleStageOneDetails,
				stageTwoDetails,
				handleStageTwoDetails,
				stageThreeDetails,
				handleStageThreeDetails,
				stageFourDetails,
				handleStageFourDetails,
			}}
		>
			{children}
		</MasterplanContext.Provider>
	);
};

MasterplanProvider.propTypes = {
	children: PropTypes.node,
};

export { MasterplanProvider };

export default MasterplanContext;
