/** @format */

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OperationTable from "./OperationTable";
import { getOperations } from "data/operations/operations";
import { useOperations } from "hooks/useOperations";

const OperationsList = () => {
	const [operations, setOperations] = useState([]);

	useEffect(() => {
		getOperationsApi();
	}, []);

	const { deleteOperation } = useOperations();

	const toastId = React.useRef(null);

	const getOperationsApi = async () => {
		const consultaOperaciones = await getOperations();
		setOperations(consultaOperaciones);
	};

	const handleDeleteOperation = async (id) => {
		const deleteOk = await deleteOperation(id);

		if (deleteOk) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
			getOperationsApi();
		}
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>Listado de Operaciones</h5>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/operations/opForm'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<OperationTable
							operations={operations}
							handleDeleteOperation={handleDeleteOperation}
						/>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default OperationsList;
