/** @format */

import { createContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const RawmaterialContext = createContext();

const RawmaterialProvider = ({ children }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//MATERIA PRIMA FAMILIES
	const [rawmaterialFamilies, setRawmaterialFamilies] = useState([]);

	//TIPOS DE TELAS
	const [fabrictypes, setFabrictypes] = useState([]);

	//CODE
	const [codeContext, setCodeContext] = useState({});

	//MATERIA PRIMA
	const [rawmaterial, setRawmaterial] = useState({});

	//STRUCTURE
	const [structure, setStructure] = useState([]);

	//HDR
	const [hdr, setHdr] = useState({});

	//UNITS
	const [units, setUnits] = useState([]);

	//SUPPLIERS
	const [suppliers, setSuppliers] = useState([]);

	//MATERIAL CODES
	const [materialCodes, setMaterialCodes] = useState([]);

	const getFamilies = async () => {
		try {
			const { data } = await axiosWithToken.get("/rawmaterialfamilies");
			setRawmaterialFamilies(data.data);
		} catch (error) {
			setRawmaterialFamilies([]);
		}
	};

	const getFabrictypes = async () => {
		try {
			const { data } = await axiosWithToken.get("/fabrictypes");
			setFabrictypes(data.data);
		} catch (error) {
			setFabrictypes([]);
		}
	};

	const cleanContext = () => {
		setCodeContext({});
		setRawmaterial({});
		setStructure([]);
		setHdr({});
	};

	const handleCodeByRawmaterialId = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/rawmaterial/${id}`);
			const code = await axiosWithToken.get(`/code/${data.data.code}`);
			setCodeContext(code.data.data);
		} catch (error) {
			setCodeContext({});
		}
	};

	const handleRawmaterialById = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/rawmaterial/${id}`);
			setRawmaterial(data.data);
		} catch (error) {
			setRawmaterial({});
		}
	};

	const handleRawmaterialByCode = async (code) => {
		try {
			const { data } = await axiosWithToken.get(`/rawmaterial/bycode/${code}`);
			setRawmaterial(data.data);
			handleCodeByRawmaterialId(data.data.id);
		} catch (error) {
			setRawmaterial({});
		}
	};

	const handleStructureByRawmaterialId = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/rawmaterial/structure/${id}`);
			setStructure(data.data);
		} catch (error) {
			setStructure([]);
		}
	};

	const handleHdrByRawmaterialId = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/hoja-ruta/hdr-rawmaterial/${id}`
			);
			setHdr(data.data);
		} catch (error) {
			setHdr({});
		}
	};

	const getAllSuppliers = async () => {
		try {
			const { data } = await axiosWithToken.get("/suppliers");
			setSuppliers(
				data.data.map((supplier) => {
					return {
						value: supplier.id,
						label: supplier.description,
					};
				})
			);
		} catch (error) {
			setUnits([]);
		}
	};

	const getAllUnits = async () => {
		try {
			const { data } = await axiosWithToken.get("/units");
			setUnits(
				data.data.map((unit) => {
					return {
						value: unit.id,
						label: unit.description,
					};
				})
			);
		} catch (error) {
			setUnits([]);
		}
	};

	const getAllMaterialCodes = async () => {
		try {
			const { data } = await axiosWithToken.get("/codesnew/individualcodes/1");
			setMaterialCodes(
				data.data.map((rp) => {
					return {
						value: rp.code,
						label: rp.description,
					};
				})
			);
		} catch (error) {
			setMaterialCodes([]);
		}
	};

	return (
		<RawmaterialContext.Provider
			value={{
				fabrictypes,
				getFabrictypes,
				rawmaterialFamilies,
				getFamilies,
				cleanContext,
				codeContext,
				handleCodeByRawmaterialId,
				rawmaterial,
				handleRawmaterialById,
				handleRawmaterialByCode,
				structure,
				handleStructureByRawmaterialId,
				hdr,
				handleHdrByRawmaterialId,
				suppliers,
				getAllSuppliers,
				units,
				getAllUnits,
				materialCodes,
				getAllMaterialCodes,
			}}
		>
			{children}
		</RawmaterialContext.Provider>
	);
};

RawmaterialProvider.propTypes = {
	children: PropTypes.any,
};

export { RawmaterialProvider };
export default RawmaterialContext;
