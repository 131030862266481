/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col, Form, Button, Tab } from "react-bootstrap";
import { camelize } from "../../../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	const toastId = React.useRef(null);
	const [families, setFamilies] = useState([]);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [selectFamily, setSelectFamily] = useState("");
	const [titulo, setTitulo] = useState("");
	const [accion, setAccion] = useState("");

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			setTitulo("Modificar");
			setAccion("Editar");
			getFamilyById();
		} else {
			setTitulo("Agregar");
			setAccion("Guardar");
		}

		getAllFamilies();
	}, []);

	const getFamilyById = async () => {
		try {
			const { data } = await axiosWithToken.get(`/rawmaterialfamily/${id}`);
			if (!data || !data.data) {
				// error
				return;
			}

			setName(data.data.name);
			setDescription(data.data.description);
			setSelectFamily(data.data.parent_id);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const getAllFamilies = async () => {
		try {
			const { data } = await axiosWithToken.get("/rawmaterialfamilies");
			setFamilies(
				data.data.map((family) => {
					return {
						id: family.id,
						name: family.name,
						description: family.description,
						created_at: dayjs(family.created_at).format("DD/MM/YYYY HH:mm:ss"),
					};
				})
			);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const store = async (e) => {
		e.preventDefault();

		if (name == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo nombre es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (selectFamily === "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar familia padre es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.post("/rawmaterialfamily", {
				name: name,
				description: description,
				parent_id: selectFamily,
			});

			if (!data || !data.data) {
				// error
				return;
			}

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("Familia agregada correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/products/rawmaterials/families/familieslist");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const update = async (e) => {
		e.preventDefault();

		if (name == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo nombre es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (selectFamily === "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar familia padre es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.put(`/rawmaterialfamily/${id}`, {
				name: name,
				description: description,
				parent_id: selectFamily,
			});

			if (!data || !data.data) {
				// error
				return;
			}

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("Familia modificada correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/products/rawmaterials/families/familieslist");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario de familia de materia prima
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={id ? update : store}>
							<Row>
								<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
									<Card className='mb-2 p-4 h-md-100'>
										<h6 className='mb-4 text-center'>
											{titulo} datos de la familia
										</h6>

										<Row className='mb-3 g-3'>
											<Col xs={12} sm={12} lg={12} md={12}>
												<Row className='mb-2'>
													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={6}
														lg={6}
														controlId='formGridName'
													>
														<Form.Label>
															Nombre<span className='text-danger'>*</span>
														</Form.Label>
														<Form.Control
															size='sm'
															value={name}
															onChange={(e) => setName(e.target.value)}
															type='text'
															placeholder='Ingrese nombre'
														/>
													</Form.Group>

													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={6}
														lg={6}
														controlId='formGridDescription'
													>
														<Form.Label>
															Descripción<span className='text-danger'>*</span>
														</Form.Label>
														<Form.Control
															size='sm'
															value={description}
															onChange={(e) => setDescription(e.target.value)}
															type='text'
															placeholder='Ingrese descripción'
														/>
													</Form.Group>
												</Row>
												<Row className='mb-2'>
													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={6}
														lg={6}
														id='formGridCode'
													>
														<Form.Label>
															Seleccionar padre
															<span className='text-danger'>*</span>
														</Form.Label>
														<Form.Select
															value={selectFamily}
															size='sm'
															onChange={(e) => setSelectFamily(e.target.value)}
															aria-label='select-family'
														>
															<option value='' disabled>
																Seleccionar...
															</option>
															<option value={0}>Familia padre</option>
															{families.map((elem) => {
																return (
																	<option key={elem.id} value={elem.id}>
																		{elem.name}
																	</option>
																);
															})}
														</Form.Select>
													</Form.Group>
												</Row>
											</Col>
										</Row>
									</Card>
								</Col>

								<Col
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									className='d-flex justify-content-end mt-2'
								>
									<Button size='sm' variant='primary' type='submit'>
										{accion}
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
