/** @format */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
	Button,
	Form,
	InputGroup,
	FormControl,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	useTable,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
	usePagination,
} from "react-table";

import useColumnsCalendario from "../../hooks/useColumnsCalendario";
import classNames from "classnames";
import Flex from "../common/Flex";
import SimpleBarReact from "simplebar-react";

import CalendarFormEdit from "./CalendarFormEdit";
import FormularioModal from "components/common/FormularioModal";
import { getEvent, updateEventsList } from "data/calendar/eventos";

const CalendarioTable = ({ eventos, handleEventDelete }) => {
	const [data, setData] = useState([]);
	const [editingEvent, setEditingEvent] = useState(false);
	const [eventContent, setEventContent] = useState({});

	useEffect(() => {
		setData(eventos);
	}, [eventos]);

	const columns = useColumnsCalendario();

	function CalendarioFilter({ globalFilter, setGlobalFilter }) {
		const [value, setValue] = useState(globalFilter);

		const onFilterChange = useAsyncDebounce(
			(value) => setGlobalFilter(value || undefined),
			200
		);

		const handleInputChange = (e) => {
			setValue(e.target.value);
			onFilterChange(e.target.value);
		};

		return (
			<InputGroup className='position-relative'>
				<FormControl
					value={value || ""}
					onChange={handleInputChange}
					size='sm'
					id='search'
					placeholder='Buscar...'
					type='search'
					className='shadow-none'
				/>
				<InputGroup.Text className='bg-transparent'>
					<FontAwesomeIcon icon='search' className='text-600' />
				</InputGroup.Text>
			</InputGroup>
		);
	}

	CalendarioFilter.propTypes = {
		globalFilter: PropTypes.func,
		setGlobalFilter: PropTypes.func,
	};

	const table = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 5,
				pageIndex: 0,
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const openEditEventModal = async (id) => {
		const getEventApi = await getEvent(id);
		setEventContent(getEventApi);
		setEditingEvent(!editingEvent);
	};

	const handleEventEdited = async () => {
		const eventsBackground = await updateEventsList();
		setData(eventsBackground);
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		rowsPerPageOptions = [5, 15, 20],
		preGlobalFilteredRows,
		setGlobalFilter,
	} = table;

	return (
		<>
			{eventos.length ? (
				<>
					<SimpleBarReact>
						{/* BUSCADOR */}

						<CalendarioFilter
							preGlobalFilteredRows={preGlobalFilteredRows}
							globalFilter={globalFilter}
							setGlobalFilter={setGlobalFilter}
						/>

						{/* TABLA */}

						<table
							className='fs--1 mb-0 overflow-hidden table table-striped table-bordered mt-2'
							{...getTableProps()}
						>
							<thead className='bg-200 text-900 text-nowrap align-middle'>
								{
									// Loop over the header rows
									headerGroups.map((headerGroup, index) => (
										// Apply the header row props

										<tr
											key={`header-${index}`}
											{...headerGroup.getHeaderGroupProps()}
										>
											{
												// Loop over the headers in each row

												headerGroup.headers.map((column, index) => (
													// Apply the header cell props

													<th
														key={`column-${index}`}
														{...column.getHeaderProps(
															column.id != "acciones"
																? column.getSortByToggleProps(
																		column.getHeaderProps
																  )
																: ""
														)}
													>
														{
															// Render the header
															column.render("Header")
														}

														{column.id != "acciones" ? (
															column.canSort ? (
																column.isSorted ? (
																	column.isSortedDesc ? (
																		<span className='sort desc' />
																	) : (
																		<span className='sort asc' />
																	)
																) : (
																	<span className='sort' />
																)
															) : (
																""
															)
														) : (
															""
														)}
													</th>
												))
											}
										</tr>
									))
								}
							</thead>
							{/* Apply the table body props */}
							<tbody {...getTableBodyProps()}>
								{
									// Loop over the table rows
									page.map((row, index) => {
										// Prepare the row for display
										prepareRow(row);
										return (
											// Apply the row props

											<tr key={`row-${index}`} {...row.getRowProps()}>
												{
													// Loop over the rows cells
													row.cells.map((cell, index) => {
														return (
															<td
																key={`cell-${index}`}
																{...cell.getCellProps()}
															>
																{
																	// Render the cell contents
																	cell.column.id != "acciones" ? (
																		cell.render("Cell")
																	) : (
																		<>
																			<OverlayTrigger
																				placement='top'
																				overlay={<Tooltip>Editar</Tooltip>}
																			>
																				<Button
																					// as={Link}
																					// to={`/calendario/listado/${cell.row.original.id}`}
																					onClick={() =>
																						openEditEventModal(
																							cell.row.original.id
																						)
																					}
																					variant='transparent'
																					size='sm'
																					className='text-primary me-2'
																				>
																					<FontAwesomeIcon icon='edit' />
																				</Button>
																			</OverlayTrigger>

																			<OverlayTrigger
																				placement='top'
																				overlay={<Tooltip>Ver</Tooltip>}
																			>
																				<Button
																					as={Link}
																					to={`/calendario/listado/${cell.row.original.id}`}
																					variant='transparent'
																					size='sm'
																					className='text-primary me-2'
																				>
																					<FontAwesomeIcon icon='eye' />
																				</Button>
																			</OverlayTrigger>
																			<OverlayTrigger
																				placement='top'
																				overlay={<Tooltip>Eliminar</Tooltip>}
																			>
																				<Button
																					onClick={() =>
																						handleEventDelete(
																							cell.row.original.id
																						)
																					}
																					variant='transparent'
																					size='sm'
																					className=' text-danger me-2'
																				>
																					<FontAwesomeIcon icon='trash' />
																				</Button>
																			</OverlayTrigger>
																		</>
																	)
																}
															</td>
														);
													})
												}
											</tr>
										);
									})
								}
							</tbody>
						</table>

						{/* PAGINACIÓN */}

						<Flex className='mt-3 align-items-center justify-content-between'>
							<Flex alignItems='center' className='fs--1'>
								<p className='mb-0'>
									<span className='d-none d-sm-inline-block me-2'>
										Del {pageSize * pageIndex + 1} al{" "}
										{pageSize * pageIndex + page.length} de {pageCount}
									</span>
								</p>

								<p className='mb-0 mx-2'>Filas por página:</p>
								<Form.Select
									size='sm'
									className='w-auto'
									onChange={(e) => setPageSize(e.target.value)}
									defaultValue={pageSize}
								>
									{rowsPerPageOptions.map((value) => (
										<option value={value} key={value}>
											{value}
										</option>
									))}
								</Form.Select>
							</Flex>

							<Flex>
								<Button
									size='sm'
									variant={canPreviousPage ? "primary" : "light"}
									onClick={() => previousPage()}
									className={classNames({ disabled: !canPreviousPage })}
								>
									Anterior
								</Button>
								<Button
									size='sm'
									variant={canNextPage ? "primary" : "light"}
									className={classNames("px-4 ms-2", {
										disabled: !canNextPage,
									})}
									onClick={() => nextPage()}
								>
									Siguiente
								</Button>
							</Flex>
						</Flex>
					</SimpleBarReact>

					{/* Formulario de edición */}
					<FormularioModal
						title={`Editando ${eventContent.title}`}
						show={editingEvent}
						size={"md"}
						setShow={setEditingEvent}
					>
						<CalendarFormEdit
							eventContent={eventContent}
							editFromEventListComponent={true}
							eventEdit={editingEvent}
							setEventEdit={setEditingEvent}
							handleEventEdited={handleEventEdited}
						/>
					</FormularioModal>
				</>
			) : (
				<h6 className='text-center'>No hay eventos cargados aún!</h6>
			)}
		</>
	);
};

CalendarioTable.propTypes = {
	eventos: PropTypes.array,
	handleEventDelete: PropTypes.func,
};

export default CalendarioTable;
