/** @format */

import React from "react";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import Flex from "../common/Flex";
import PropTypes from "prop-types";

const Pagination = ({
	pageSize,
	pageIndex,
	page,
	pageCount,
	setPageSize,
	rowsPerPageOptions,
	canPreviousPage,
	canNextPage,
	previousPage,
	nextPage,
}) => {
	return (
		<>
			{/* PAGINACIÓN */}

			<Flex className='mt-3 align-items-center justify-content-between'>
				<Flex alignItems='center' className='fs--1'>
					<p className='mb-0'>
						<span className='d-none d-sm-inline-block me-2'>
							Del {pageSize * pageIndex + 1} al{" "}
							{pageSize * pageIndex + page.length} de {pageCount}
						</span>
					</p>

					<p className='mb-0 mx-2'>Filas por página:</p>
					<Form.Select
						size='sm'
						className='w-auto'
						onChange={(e) => setPageSize(e.target.value)}
						defaultValue={pageSize}
					>
						{rowsPerPageOptions.map((value) => (
							<option value={value} key={value}>
								{value}
							</option>
						))}
					</Form.Select>
				</Flex>

				<Flex>
					<Button
						size='sm'
						variant={canPreviousPage ? "primary" : "light"}
						onClick={() => previousPage()}
						className={classNames({ disabled: !canPreviousPage })}
					>
						Anterior
					</Button>
					<Button
						size='sm'
						variant={canNextPage ? "primary" : "light"}
						className={classNames("px-4 ms-2", {
							disabled: !canNextPage,
						})}
						onClick={() => nextPage()}
					>
						Siguiente
					</Button>
				</Flex>
			</Flex>
		</>
	);
};

Pagination.propTypes = {
	pageSize: PropTypes.number,
	pageIndex: PropTypes.number,
	page: PropTypes.array,
	pageCount: PropTypes.number,
	setPageSize: PropTypes.func,
	rowsPerPageOptions: PropTypes.array,
	canPreviousPage: PropTypes.bool,
	canNextPage: PropTypes.bool,
	previousPage: PropTypes.func,
	nextPage: PropTypes.func,
};

export default Pagination;
