/** @format */

import { useMemo } from "react";

export default function useColumnsMasterplans() {
	const columns = useMemo(
		() => [
			{
				accessor: "id",
				Header: "#",
			},
			{
				accessor: "name",
				Header: "Nombre",
			},
			{
				accessor: "description",
				Header: "Descripción",
			},
			{
				accessor: "planning_year",
				Header: "Año planificado",
			},
			{
				accessor: "stage",
				Header: "Etapa",
			},
			{
				accessor: "acciones",
				Header: "Acciones",
			},
		],
		[]
	);

	return columns;
}
