/** @format */
import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import PropTypes from "prop-types";

function AnimatedProgressBar({ percentage }) {
	const [state, setState] = useState(0);

	const variantes = {
		success: "success",
		warning: "warning",
		danger: "danger",
	};

	useEffect(() => {
		if (percentage >= 0 && percentage <= 34) return setState(variantes.success);

		if (percentage > 34 && percentage <= 67) return setState(variantes.warning);

		return setState(variantes.danger);
	}, [percentage]);

	return (
		<ProgressBar
			animated
			now={percentage >= 100 ? 100 : percentage}
			label={`${percentage}%`}
			variant={state}
		/>
	);
}

AnimatedProgressBar.propTypes = {
	percentage: PropTypes.number,
};

export default AnimatedProgressBar;
