/** @format */

import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";

const EstamperiaContext = createContext();

const EstamperiaProvider = ({ children }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [estampas, setEstampas] = useState([]);
	const [estampa, setEstampa] = useState({});
	const toastId = React.useRef(null);
	const [ubicaciones, setUbicaciones] = useState([]);
	const [ubicacionesByRubro, setUbicacionesByRubro] = useState([]);
	const [relacionProdEst, setRelacionProdEst] = useState([]);
	const [relacionProdEstById, setRelacionProdEstById] = useState({});

	//Obtener todas las estampas
	const getEstampas = async () => {
		try {
			const { data } = await axiosWithToken.get("/estamperia");
			setEstampas(data.data);
		} catch (error) {
			console.log(error.response.data.message);
		}
	};

	//Obtener estampa por ID
	const getEstampa = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/estamperia/${id}`);
			setEstampa(data.data);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("Hubo un error!", {
					role: "alert",
					theme: "dark",
				});
			}
			console.log(error.response.data.message);
		}
	};

	//Eliminar estampa
	const deleteEstampa = async (id) => {
		try {
			await axiosWithToken.delete(`/estamperia/delete/${id}`);
			getEstampas();
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.info("Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	//Traigo ubicaciones
	const getUbicaciones = async () => {
		try {
			const { data } = await axiosWithToken.get("/estamperia/ubicaciones");
			setUbicaciones(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	//Traigo ubicaciones dependiendo del rubro
	const getUbicacionesByRubro = async (rubro) => {
		try {
			const { data } = await axiosWithToken.get(
				`/estamperia/ubicaciones/rubro/${rubro}`
			);
			setUbicacionesByRubro(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	//Traigo las relaciones de estampas con productos
	const getRelacionProdEst = async () => {
		try {
			const { data } = await axiosWithToken.get(
				"/estamperia/relacionProductoEstamp"
			);
			setRelacionProdEst(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	//Traigo la relacion de producto-estampa by id
	const getRelacionProdEstById = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/estamperia/relacionProductoEstampa/${id}`
			);
			setRelacionProdEstById(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	//Eliminar relacion producto-estampa
	const deleteRelacionProdEst = async (id) => {
		try {
			await axiosWithToken.delete(
				`/estamperia/relacionProductoEstampa/delete/${id}`
			);
			getRelacionProdEst();
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	return (
		<EstamperiaContext.Provider
			value={{
				estampas,
				getEstampas,
				deleteEstampa,
				estampa,
				getEstampa,
				ubicaciones,
				getUbicaciones,
				ubicacionesByRubro,
				getUbicacionesByRubro,
				relacionProdEst,
				getRelacionProdEst,
				relacionProdEstById,
				getRelacionProdEstById,
				deleteRelacionProdEst,
			}}
		>
			{children}
		</EstamperiaContext.Provider>
	);
};

EstamperiaProvider.propTypes = {
	children: PropTypes.any,
};

export { EstamperiaProvider };
export default EstamperiaContext;
