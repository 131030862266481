/** @format */
import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Button,
	Card,
	Table,
	OverlayTrigger,
	Tooltip,
	Badge,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MasterplanModalStageTwo from "./MasterplanModalStageTwo";
import { generarId } from "../../helpers/utils";
import { formatNumber } from "helpers/numberFormatter";
import { formatTimeToMinutes } from "helpers/formatTimeToMinutes";
import Alert from "react-bootstrap/Alert";
import { useMasterplanContext } from "hooks/useMasterplanContext";

const MasterplanFormStageTwo = ({
	totalMinutes,
	handleSetMinutesAvailable,
	setKey,
}) => {
	//Estados modal
	const [showModal, setShowModal] = useState(false);

	//Detalle stage 1 para pasar al modal
	const [detailsFamilyStgOne, setDetailsFamilyStgOne] = useState({});

	//Minutos planificados de la etapa 2
	const [plannedMinutesStage2, setPlannedMinutesStage2] = useState(0);

	const [exceededTime, setExceededTime] = useState([]);

	const {
		masterplanContext,
		stageOneDetails,
		handleStageTwoDetails,
		stageTwoDetails,
	} = useMasterplanContext();

	useEffect(() => {
		if (masterplanContext && Object.entries(masterplanContext).length > 0) {
			getStageTwo();
		}
	}, [stageOneDetails, masterplanContext]);

	useEffect(() => {
		if (stageOneDetails && stageOneDetails.length > 0) {
			//Minutos totales acumulados de la Etapa 1
			const stage1TotalMinutes = stageOneDetails.reduce((acc, value) => {
				const minutes = formatTimeToMinutes(value.averagetime) * value.quantity;
				return (acc += minutes);
			}, 0);

			//Minutos totales vs minutos planificados en Etapa 1
			const totalAvailableMinutes = totalMinutes - stage1TotalMinutes;

			//Si me excedo en tiempo de planificacion vs Etapa 1 resto diferencia a minutos disponibles
			if (exceededTime.length > 0) {
				const totalExceededTime = exceededTime.reduce((acc, value) => {
					if (value.time) {
						return (acc += value.diff);
					} else {
						return (acc += 0);
					}
				}, 0);

				const newTotalAvailableMinutes =
					totalAvailableMinutes - totalExceededTime;

				//Seteo nuevo minutos disponibles
				handleSetMinutesAvailable(newTotalAvailableMinutes);
			} else {
				//Si no hay excedente seteo minutos disponibles originales
				handleSetMinutesAvailable(totalAvailableMinutes);
			}
		}
	}, [exceededTime]);

	const getStageTwo = async () => {
		const stagesTwoDetails = [];

		if (masterplanContext?.stage_one?.length > 0) {
			masterplanContext.stage_one.forEach((stageOne) => {
				if (stageOne.stage_two.length > 0) {
					stageOne.stage_two.forEach((stageTwo) => {
						let qo = (stageTwo.planned_quantity / stageTwo.opening).toFixed(2);
						const newDetail = {
							id: stageTwo.id,
							idkey: generarId(),
							stageOneId: stageTwo.sale_masterplan_stageone_id,
							familyId: stageOne.family.id,
							familyName: stageOne.family.description,
							publicId: stageTwo.publico.id,
							name: stageTwo.publico.description,
							quantity: stageTwo.planned_quantity,
							opening: stageTwo.opening,
							quantity_opening: qo,
							averagetime: stageTwo.average_time,
							stage_three: stageTwo.stage_three,
						};
						stagesTwoDetails.push(newDetail);
					});
				}
			});
		}
		const stagesTwoDetailsNonUndefined = stagesTwoDetails.filter(
			(detail) => detail != undefined
		);

		setExceededTime([]);
		handleStageTwoDetails(stagesTwoDetailsNonUndefined);
		handleMinutesAvailable(stagesTwoDetailsNonUndefined);
	};

	const handleMinutesAvailable = (detailsPublicFamilyTable) => {
		//Creo array para acumular los tiempos de las familias
		const accTimePerFamily = [];
		if (
			detailsPublicFamilyTable.length > 0 &&
			stageOneDetails &&
			stageOneDetails.length > 0
		) {
			//Comparamos minutos planificados de cada familia en etapa 1 con los minutos totales de la division de familia por publico
			stageOneDetails.map((family) => {
				//Filtro por familia
				const families = detailsPublicFamilyTable.filter(
					(fm) => fm.familyId === family.family.id
				);

				//Tiempo de familia en Etapa 1
				const stgOneFamilyTime =
					family.quantity * formatTimeToMinutes(family.averagetime);
				//Acumulo tiempo de familia en Etapa 2
				const accumStgTwoFamilyTime = families.reduce((acc, currentValue) => {
					const minutes =
						formatTimeToMinutes(currentValue.averagetime) *
						currentValue.quantity;

					return (acc += minutes);
				}, 0);

				//Acumulo cantidad de familia en Etapa 2
				const accumStgTwoFamilyQuantity = families.reduce(
					(acc, currentValue) => {
						return (acc += currentValue.quantity);
					},
					0
				);

				accTimePerFamily.push(accumStgTwoFamilyTime);

				//Existe diferencia entre el tiempo planificado de la familia en la etapa 1 con la etapa 2
				if (accumStgTwoFamilyTime > stgOneFamilyTime) {
					const plannedTimeDiff = accumStgTwoFamilyTime - stgOneFamilyTime;
					const familyError = {
						time: true,
						label: family.family.name,
						error: `Sobrepasó los minutos planificados de la familia ${family.family.name} por ${plannedTimeDiff} minutos`,
						diff: plannedTimeDiff,
					};
					setExceededTime((prevValue) => [...prevValue, familyError]);
				}

				//Existe diferencia entre la cantidad planificada de la familia en la etapa 1 con la etapa 2
				if (accumStgTwoFamilyQuantity > Number(family.quantity)) {
					const plannedQuantityDiff =
						accumStgTwoFamilyQuantity - Number(family.quantity);
					const familyError = {
						time: false,
						label: family.family.name,
						error: `Sobrepasó las cantidades planificadas de la familia ${family.family.name} por ${plannedQuantityDiff} unidades.`,
						diff: plannedQuantityDiff,
					};
					setExceededTime((prevValue) => [...prevValue, familyError]);
				}
			});

			//Acumulamos el tiempo que lleva hacer todas las prendas en minutos - Etapa 2.
			const totalFamiliesAccTime = accTimePerFamily.reduce((acc, value) => {
				return (acc += value);
			}, 0);

			//Seteo los minutos acumulados de las familias - Etapa 2
			setPlannedMinutesStage2(totalFamiliesAccTime);
		} else {
			setExceededTime([]);
			setPlannedMinutesStage2(0);
		}
	};

	const renderTooltip = (data) => {
		return (
			<Tooltip id='button-tooltip'>
				<span className='text-left fs--2'>
					<strong>Familia: </strong> {data.family.name}
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Cantidad planificada: </strong> {formatNumber(data.quantity)}
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Apertura: </strong> {data.opening}
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Tiempo promedio: </strong> {data.averagetime}
				</span>
				<br />
			</Tooltip>
		);
	};

	const handleShowModal = (item) => {
		setDetailsFamilyStgOne(item);
		setShowModal(true);
	};

	return (
		<Row>
			{showModal && (
				<MasterplanModalStageTwo
					show={showModal}
					setShow={setShowModal}
					detailsFamilyStgOne={detailsFamilyStgOne}
				/>
			)}
			<Col className='mb-2' xs={12} sm={12} lg={4} md={4}>
				<Card className='mb-2 p-2 h-md-100'>
					<Card.Body>
						<h6 className='mb-4 text-center fs--2'>
							Cantidades por familia planificadas
						</h6>

						<Row>
							<div className='table-responsive fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th
												style={{ width: "30%" }}
												className='border-0 text-start'
											>
												Familia
											</th>
											<th
												style={{ width: "30%" }}
												className='border-0 text-center'
											>
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{stageOneDetails && stageOneDetails.length > 0 ? (
											stageOneDetails.map((item, index) => (
												<tr
													key={`key-${index}-${item.id}`}
													className='border-200'
												>
													<td className='text-start'>
														<OverlayTrigger
															placement='top'
															delay={{ show: 100, hide: 200 }}
															overlay={renderTooltip(item)}
														>
															<span className='fs--2'>{item.family.name}</span>
														</OverlayTrigger>
													</td>
													<td className='text-center'>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Abrir en públicos</Tooltip>}
														>
															<Button
																onClick={() => handleShowModal(item)}
																variant='transparent'
																size='sm'
																style={{
																	fontSize: "11px",
																}}
																className='text-primary'
															>
																<FontAwesomeIcon icon='plus' />
															</Button>
														</OverlayTrigger>
													</td>
												</tr>
											))
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>
			<Col className='mb-2' xs={12} sm={12} lg={8} md={8}>
				<Card className='mb-2 p-2 h-md-100'>
					<Card.Body>
						<h6 className='mb-4 text-center fs--2'>
							Info detalle por públicos y familias
						</h6>

						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<h6 className='mb-4 fs--2'>
									Min. planificados:{" "}
									<Badge className='p-1 fs--2' bg={"primary"}>
										{plannedMinutesStage2}
									</Badge>
								</h6>
							</Col>
							{exceededTime.length > 0 &&
								exceededTime.map((alert, index) => {
									return (
										<Col key={index} lg={12} md={12} sm={12} xs={12}>
											<OverlayTrigger
												placement='auto'
												delay={{ show: 100, hide: 200 }}
												overlay={
													<Tooltip id={`tooltip-auto`}>
														{alert.time
															? "Esta diferencia se resta de los minutos disponibles"
															: ""}
													</Tooltip>
												}
											>
												<Alert
													variant={"danger"}
													className='fs--2 p-0 w-auto p-1'
												>
													{alert.error}
												</Alert>
											</OverlayTrigger>
										</Col>
									);
								})}

							<div className='table-responsive fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th
												style={{ width: "20%" }}
												className='border-0 text-start'
											>
												Familia
											</th>
											<th
												style={{ width: "20%" }}
												className='border-0 text-start'
											>
												Público
											</th>

											<th
												style={{ width: "15%" }}
												className='border-0 text-start'
											>
												Cantidad
											</th>
											<th className='border-0 text-center'>Apertura</th>
											<th
												style={{ width: "20%" }}
												className='border-0 text-center'
											>
												Cant. x aper.
											</th>
											<th className='border-0 text-center'>Tiempo</th>
										</tr>
									</thead>
									<tbody>
										{stageTwoDetails && stageTwoDetails.length > 0 ? (
											stageTwoDetails.map((item, index) => (
												<tr
													key={`key-${index}-${item.idkey}`}
													className='border-200'
												>
													<td className='text-start'>{item.familyName}</td>
													<td className='text-start'>{item.name}</td>
													<td className='text-start'>{item.quantity}</td>
													<td className='text-center'>{item.opening}</td>
													<td className='text-center'>
														{item.quantity_opening}
													</td>
													<td className='text-center'>{item.averagetime}</td>
												</tr>
											))
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>
			<Col
				xs={12}
				sm={12}
				lg={12}
				md={12}
				className='mb-2 mt-3 d-flex justify-content-end'
			>
				{stageTwoDetails && stageTwoDetails.length ? (
					<Button size='sm' variant='primary' onClick={() => setKey("stage3")}>
						Seguir
					</Button>
				) : (
					""
				)}
			</Col>
		</Row>
	);
};

MasterplanFormStageTwo.propTypes = {
	setKey: PropTypes.func,
	totalMinutes: PropTypes.number,
	minutesAvailable: PropTypes.number,
	handleSetMinutesAvailable: PropTypes.func,
};

export default MasterplanFormStageTwo;
