/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Row,
	Col,
	Card,
	Tabs,
	Tab,
	Badge,
	Table,
	Image,
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import Divider from "components/common/Divider";
import { generarId } from "../../../helpers/utils";
import PieChart from "components/charts/echarts/pie-charts/PieChartGraf";
import axios from "axios";
import imgdefault from "assets/img/img-dashboard/images/product.jpg";
const img = `${process.env.REACT_APP_ENPOINT_BASE}/img-products`;
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { id } = useParams();
	const [description, setDescription] = useState("");
	const [name, setName] = useState("");
	const [codPresea, setCodPresea] = useState("");
	const [image, setImage] = useState("");
	const [fechaalta, setFechaalta] = useState("");
	const [situation, setSituation] = useState("");
	const [estructuraMain, setEstructuraMain] = useState({});
	const [estructuraSemi, setEstructuraSemi] = useState({});
	const [moldProduct, setMoldProduct] = useState({});
	const [hdr, setHdr] = useState({});
	const [hdrSemi, setHdrSemi] = useState({});
	const [year, setYear] = useState("");
	const [productType, setProductType] = useState("");
	const [stage, setStage] = useState("");
	const [family, setFamily] = useState("");
	const [publico, setPublico] = useState("");
	const [line, setLine] = useState("");
	const [subline, setSubline] = useState("");
	const [height, setHeight] = useState("");
	const [length, setLength] = useState("");
	const [width, setWidth] = useState("");
	const [weight, setWeight] = useState("");
	const [minimumsale, setMinimumsale] = useState("");
	const [modeldescript, setModeldescript] = useState("");
	const [detmodp, setDetmodp] = useState("");
	const [quality, setQuality] = useState("");
	const [iramsize, setIramsize] = useState("");
	const [comment, setComment] = useState("");

	//Costos
	const [costGraf, setCostGraf] = useState([]);
	const [individualCost, setIndividualCost] = useState([]);
	const [individualCostGraf, setIndividualCostGraf] = useState([]);
	const [heightIndCost, setHeightIndCost] = useState(0);

	//Costos Totales
	const [subTotalStructura, setSubTotalStructura] = useState(0);
	const [subTotalHdr, setSubTotalHdr] = useState(0);
	const [subTotalCostInd, setSubTotalCostInd] = useState(0);
	const [total, setTotal] = useState(0);

	//códigos individuales
	const [compoundcode, setCompoundcode] = useState("");
	const [productocode, setProductocode] = useState("");
	const [modelocode, setModelocode] = useState("");
	const [telacode, setTelacode] = useState("");
	const [tallecode, setTallecode] = useState("");
	const [colorcode, setColorcode] = useState("");

	useEffect(() => {
		getProductById();
	}, []);

	const getProductById = async () => {
		const { data } = await axiosWithToken.get(`/product/${id}`);

		setDescription(data.data.description);
		setName(data.data.name);
		setCodPresea(data.data.code_presea);
		setFechaalta(dayjs(data.data.created_at).format("DD/MM/YYYY HH:mm:ss"));
		setSituation(data.data.situation);
		if (data.data.image) {
			setImage(data.data.image);
		}

		setYear(data.data.year);
		setHeight(data.data.height);
		setLength(data.data.length);
		setWidth(data.data.width);
		setWeight(data.data.weight);
		setMinimumsale(data.data.minimum_sale);
		setComment(data.data.comment);

		if (data.data.situation !== 3) {
			//Código compuesto
			setCompoundcode(() => {
				return {
					code: data.data.codeobject.code,
					description: data.data.codeobject.description,
				};
			});

			//códigos individuales
			setProductocode(data.data.codeobject.productcode);
			if (data.data.situation == 1 || data.data.situation == 2) {
				setModelocode(data.data.codeobject.modelcode);
				setTelacode(data.data.codeobject.fabriccode);
				setTallecode(data.data.codeobject.sizecode);
				setColorcode(data.data.codeobject.productcolorcode);
			}
		}

		if (data.data.structures.length > 0) {
			data.data.structures.forEach((estructura) => {
				if (estructura.main == 1) {
					const newStructureMain = {
						name: estructura.name,
						description: estructura.description,
						structure_rawmaterials: estructura.structure_rawmaterials.map(
							(str) => {
								return {
									idkey: generarId(),
									machinetype: str.machinetype,
									rawmaterial: str.rawmaterial,
									price: str.rawmaterial.suppliers_rawmaterials.map(
										(supplier, indice) => {
											if (indice == 0) {
												return supplier.pivot.price_supplier;
											}
										}
									),
									quantity: str.quantity,
									technical_order: str.technical_order,
									created_at: str.created_at,
								};
							}
						),
					};

					setEstructuraMain(newStructureMain);
				}
			});
		}

		if (data.data.semifinished) {
			if (data.data.semifinished.structures.length > 0) {
				data.data.semifinished.structures.forEach((estructura) => {
					if (estructura.main == 1) {
						const newStructureMain = {
							name: estructura.name,
							description: estructura.description,
							structure_rawmaterials: estructura.structure_rawmaterials.map(
								(str) => {
									return {
										idkey: generarId(),
										machinetype: str.machinetype,
										rawmaterial: str.rawmaterial,
										price: str.rawmaterial.suppliers_rawmaterials.map(
											(supplier, indice) => {
												if (indice == 0) {
													return supplier.pivot.price_supplier;
												}
											}
										),
										quantity: str.quantity,
										technical_order: str.technical_order,
										created_at: str.created_at,
									};
								}
							),
						};

						setEstructuraSemi(newStructureMain);
					}
				});
			}

			if (data.data.semifinished.hdr.length > 0) {
				data.data.semifinished.hdr.forEach((hdr, indice) => {
					if (indice == 0) {
						const hdrMain = {
							codigo: hdr.codigo,
							denominacion: hdr.denominacion,
							operations: hdr.operations.map((op) => {
								return {
									idkey: generarId(),
									denomination: op.denomination,
									description: op.description,
									time: op.time,
									costo: op.centers.map((center) => {
										return center.costo;
									}),
								};
							}),
						};
						setHdrSemi(hdrMain);
					}
				});
			}
		}

		if (data.data.molds.length > 0) {
			data.data.molds.forEach((mold, indice) => {
				if (indice == 0) {
					setMoldProduct(mold);
				}
			});
		}

		if (data.data.hdr.length > 0) {
			data.data.hdr.forEach((hdr, indice) => {
				if (indice == 0) {
					const hdrMain = {
						codigo: hdr.codigo,
						denominacion: hdr.denominacion,
						operations: hdr.operations.map((op) => {
							return {
								idkey: generarId(),
								denomination: op.denomination,
								description: op.description,
								time: op.time,
								costo: op.centers.map((center) => {
									return center.costo;
								}),
							};
						}),
					};
					setHdr(hdrMain);
				}
			});
		}

		if (data.data.individualCost) {
			setIndividualCost(Object.values(data.data.individualCost));

			setIndividualCostGraf(
				Object.values(data.data.individualCost).map((cost) => {
					if (cost.source != "totalcost") {
						let concatenatedname = `${cost.name} - ${cost.percentage}% - $${cost.cost}`;

						return {
							value: cost.cost,
							name: concatenatedname,
						};
					}
				})
			);

			setHeightIndCost(Object.values(data.data.individualCost).length * 4);
		}

		if (data.data.costs) {
			setCostGraf([
				{
					value: data.data.costs.cost_estructura.toFixed(2),
					name: "Costo por estructura",
				},
				{
					value: data.data.costs.cost_hdr.toFixed(2),
					name: "Costo por HDR",
				},
				{
					value: data.data.costs.indirect_cost.toFixed(2),
					name: "Costo indirecto",
				},
			]);

			//Mostramos los costos
			setSubTotalStructura(data.data.costs.cost_estructura.toFixed(2));
			setSubTotalHdr(data.data.costs.cost_hdr.toFixed(2));
			setSubTotalCostInd(data.data.costs.indirect_cost.toFixed(2));
			setTotal(data.data.costs.total_cost.toFixed(2));
		}

		setFamily(data?.data?.family?.name ? data.data.family.name : "");
		setPublico(
			data?.data?.public?.description ? data.data.public.description : ""
		);
		setLine(data?.data?.line?.description ? data.data.line.description : "");
		setSubline(
			data?.data?.subline?.description ? data.data.subline.description : ""
		);
		setModeldescript(
			data?.data?.modeldescript?.description
				? data.data.modeldescript.description
				: ""
		);
		setDetmodp(
			data?.data?.detmodp?.description ? data.data.detmodp.description : ""
		);
		setQuality(
			data?.data?.quality?.description ? data.data.quality.description : ""
		);
		setIramsize(
			data?.data?.iramsize?.description ? data.data.iramsize.description : ""
		);

		if (data.data.product_type_id) {
			const productsTypeData = {
				1: "Prenda producida totalmente en los talleres de Sonder",
				2: "Prenda mixta, una parte se produce en talleres externos y parte en talleres propios",
				3: "Prenda producida totalmente en talleres externos",
				4: "Prenda comprada (solo lleva la etiqueta)",
				5: "Prenda comprada + proceso en taller",
			};

			setProductType(productsTypeData[data.data.product_type_id]);
		}

		const productsStageData = {
			0: "Código",
			1: "Código y datos",
			2: "Molde asignado",
			3: "Estructura generada",
			4: "Hoja de ruta asignada",
			5: "Semielaborado asignado",
		};

		setStage(`Etapa ${data.data.stage}: ${productsStageData[data.data.stage]}`);
	};

	return (
		<>
			{/* VIEW */}
			<Card className='mb-3'>
				<Card.Header className='p-2'>
					<Row className='align-items-end g-2'>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0 hover-actions-trigger'>
								<Badge bg='secondary'># {id}</Badge>{" "}
								{situation === 1 ? (
									<Badge bg='primary'>Producto final</Badge>
								) : situation === 2 ? (
									<Badge bg='primary'>Producto básico</Badge>
								) : situation === 3 ? (
									<Badge bg='primary'>Producto semielaborado</Badge>
								) : (
									<Badge bg='primary'>Producto genérico</Badge>
								)}{" "}
								<Badge bg='secondary'>{stage}</Badge>{" "}
							</h6>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<Tabs defaultActiveKey='general' id='tabs-viewmp'>
									<Tab eventKey='general' title='Datos'>
										<Row>
											<Col className='mt-3 mb-2' xs={12} sm={12} lg={8} md={8}>
												<Card className='h-md-100 p-1'>
													<Card.Body className='position-relative'>
														<Row>
															<h6 className='mb-3 fs-0'>Información general</h6>

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Tipo de producto:
																	</strong>{" "}
																	{productType ? productType : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Nombre:
																	</strong>{" "}
																	{name}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Descripción:
																	</strong>{" "}
																	{description ? description : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Código de presea:
																	</strong>{" "}
																	{codPresea ? codPresea : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Año:
																	</strong>{" "}
																	{year}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Mínimo de venta:
																	</strong>{" "}
																	{minimumsale ? minimumsale : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Observaciones:
																	</strong>{" "}
																	{comment ? comment : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Fecha de alta:
																	</strong>{" "}
																	{fechaalta}
																</p>
															</Col>

															<Divider />

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<Row>
																	<h6 className='mb-3 fs-0'>Clasificadores</h6>

																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={6}
																		md={6}
																	>
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Familia:
																			</strong>{" "}
																			{family ? family : "--"}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Público:
																			</strong>{" "}
																			{publico ? publico : "--"}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Linea:
																			</strong>{" "}
																			{line ? line : "--"}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Sublinea:
																			</strong>{" "}
																			{subline ? subline : "--"}
																		</p>
																	</Col>

																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={6}
																		md={6}
																	>
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Descripción modelo:
																			</strong>{" "}
																			{modeldescript ? modeldescript : "--"}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Detalle modelo:
																			</strong>{" "}
																			{detmodp ? detmodp : "--"}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Calidad:
																			</strong>{" "}
																			{quality ? quality : "--"}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Talle iram:
																			</strong>{" "}
																			{iramsize ? iramsize : "--"}
																		</p>
																	</Col>
																</Row>
															</Col>

															<Divider />

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<Row>
																	<h6 className='mb-3 fs-0'>
																		Información del molde
																	</h6>

																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={6}
																		md={6}
																	>
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Código:
																			</strong>{" "}
																			{moldProduct.code}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Descripción:
																			</strong>{" "}
																			{moldProduct.description}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Metros lineales:
																			</strong>{" "}
																			{moldProduct.linealmeters}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Metros cuadrados:
																			</strong>{" "}
																			{moldProduct.squaremeters}
																		</p>
																	</Col>

																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={6}
																		md={6}
																	>
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Perímetro:
																			</strong>{" "}
																			{moldProduct.perimeter}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Perímetro flat:
																			</strong>{" "}
																			{moldProduct.perimeterflat}
																		</p>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Perímetro cinturera:
																			</strong>{" "}
																			{moldProduct.perimeterwaist}
																		</p>
																	</Col>
																</Row>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</Col>

											<Col className='mt-3 mb-2' xs={12} sm={12} lg={4} md={4}>
												<Card className='mb-4 h-md-100 p-1'>
													<Card.Body className='position-relative'>
														<Row>
															<Col
																className='d-flex justify-content-center'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																{image != "" ? (
																	<Image
																		width='270'
																		height='270'
																		src={`${img}/${image}`}
																		rounded
																	/>
																) : (
																	// Imagen por defecto si no existe 'image'
																	<Image
																		width='270'
																		height='270'
																		src={imgdefault}
																		rounded
																	/>
																)}
															</Col>

															<Col
																className='mt-4 d-flex justify-content-center'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<strong>
																	<span className='mb-3 fs--2 text-800'>
																		{name}
																	</span>
																</strong>
															</Col>

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<Row>
																	<h6 className='mb-2 mt-2 fs-0'>
																		Dimensiones
																	</h6>

																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<Row>
																			<Col
																				className='mt-3'
																				xs={12}
																				sm={12}
																				lg={6}
																				md={6}
																			>
																				<p className='mb-2 fs--1'>
																					<strong className='fs--0 text-900'>
																						Alto:
																					</strong>{" "}
																					{height}
																				</p>
																				<p className='mb-2 fs--1'>
																					<strong className='fs--0 text-900'>
																						Ancho:
																					</strong>{" "}
																					{width}
																				</p>
																			</Col>

																			<Col
																				className='mt-3'
																				xs={12}
																				sm={12}
																				lg={6}
																				md={6}
																			>
																				<p className='mb-2 fs--1'>
																					<strong className='fs--0 text-900'>
																						Largo:
																					</strong>{" "}
																					{length}
																				</p>
																				<p className='mb-2 fs--1'>
																					<strong className='fs--0 text-900'>
																						Peso:
																					</strong>{" "}
																					{weight}
																				</p>
																			</Col>
																		</Row>
																	</Col>
																</Row>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Tab>

									{Object.entries(estructuraMain).length > 0 && (
										<Tab eventKey='history' title='Estructura'>
											<Row>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={5}
													md={5}
												>
													<Card className='mb-3'>
														<Card.Body>
															<Row>
																<Col
																	className='mt-3'
																	xs={12}
																	sm={12}
																	lg={12}
																	md={12}
																>
																	<h6 className='mb-3 fs-0'>
																		Estructura del producto
																	</h6>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Nombre:
																		</strong>{" "}
																		{estructuraMain.name}
																	</p>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Descripción:
																		</strong>{" "}
																		{estructuraMain.description}
																	</p>
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Col>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={7}
													md={7}
												>
													<Card className='mb-3'>
														<Card.Body>
															<h6 className='mb-3 fs-0'>
																Detalle de la estructura
															</h6>
															<div className='table-responsive fs--1'>
																<Table striped className='border-bottom'>
																	<thead className='bg-200 text-900'>
																		<tr>
																			<th className='border-0 text-start'>
																				Código
																			</th>
																			<th className='border-0 text-start'>
																				Materia Prima
																			</th>
																			<th className='border-0 text-start'>
																				Cantidad
																			</th>
																			<th className='border-0 text-start'>
																				Orden
																			</th>
																			<th className='border-0 text-start'>
																				Fecha de alta
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{!!estructuraMain.structure_rawmaterials &&
																			estructuraMain.structure_rawmaterials.map(
																				(rawmaterial) => (
																					<tr
																						key={rawmaterial.idkey}
																						className='border-200'
																					>
																						<td className='align-middle text-start'>
																							<strong>
																								{rawmaterial.rawmaterial.code}
																							</strong>
																						</td>

																						<td className='align-middle text-start'>
																							{rawmaterial.rawmaterial.name}{" "}
																							{rawmaterial.rawmaterial.thread !=
																							null ? (
																								rawmaterial.rawmaterial
																									.thread == 1 ? (
																									<Badge bg='secondary'>
																										H. arriba
																									</Badge>
																								) : (
																									<Badge bg='secondary'>
																										H. abajo
																									</Badge>
																								)
																							) : (
																								""
																							)}{" "}
																							{rawmaterial.machinetype !=
																							null ? (
																								rawmaterial.machinetype == 2 ? (
																									<Badge bg='danger'>
																										Flatseamer
																									</Badge>
																								) : (
																									""
																								)
																							) : (
																								""
																							)}
																						</td>

																						<td className='align-middle text-start'>
																							{rawmaterial.quantity}
																						</td>

																						<td className='align-middle text-start'>
																							<Badge
																								className='rounded-pill'
																								bg='primary'
																							>
																								{rawmaterial.technical_order}
																							</Badge>
																						</td>

																						<td className='align-middle text-start'>
																							{dayjs(
																								rawmaterial.created_at
																							).format("DD/MM/YYYY HH:mm:ss")}
																						</td>
																					</tr>
																				)
																			)}
																	</tbody>
																</Table>
															</div>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Tab>
									)}

									{situation && situation !== 3 && (
										<Tab eventKey='info' title='Info'>
											<Row>
												<Col className='mt-3' xs={12} sm={12} lg={12} md={12}>
													<Card className='mb-4 h-md-100 p-1'>
														<Card.Body className='position-relative'>
															<Row>
																<h6 className='mt-3 mb-3 fs-0'>
																	Información del código
																</h6>

																<Col
																	className='mt-3'
																	xs={12}
																	sm={12}
																	lg={6}
																	md={6}
																>
																	<h6 className='mb-3 fs-0'>
																		Códigos individuales
																	</h6>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Producto:
																		</strong>{" "}
																		#{productocode.code} {""}{" "}
																		{productocode.description}
																	</p>

																	{modelocode && (
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Modelo:
																			</strong>{" "}
																			#{modelocode.code} {""}{" "}
																			{modelocode.description}
																		</p>
																	)}

																	{telacode && (
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Tela:
																			</strong>{" "}
																			#{telacode.code} {""}{" "}
																			{telacode.description}
																		</p>
																	)}

																	{tallecode && (
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Talle:
																			</strong>{" "}
																			#{tallecode.code} {""}{" "}
																			{tallecode.description}
																		</p>
																	)}

																	{colorcode && (
																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Color:
																			</strong>{" "}
																			#{colorcode.code} {""}{" "}
																			{colorcode.description}
																		</p>
																	)}
																</Col>

																<Col
																	className='mt-3'
																	xs={12}
																	sm={12}
																	lg={6}
																	md={6}
																>
																	<h6 className='mb-3 fs-0'>
																		Código compuesto
																	</h6>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Código:
																		</strong>{" "}
																		#{compoundcode.code}
																	</p>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Descripción:
																		</strong>{" "}
																		{compoundcode.description}
																	</p>
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Tab>
									)}

									{Object.entries(hdr).length > 0 && (
										<Tab eventKey='hdr' title='Hoja de ruta'>
											<Row>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={5}
													md={5}
												>
													<Card className='mb-3'>
														<Card.Body>
															<Row>
																<Col
																	className='mt-3'
																	xs={12}
																	sm={12}
																	lg={12}
																	md={12}
																>
																	<h6 className='mb-3 fs-0'>Hoja de ruta</h6>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Código:
																		</strong>{" "}
																		{hdr.codigo}
																	</p>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Denominación:
																		</strong>{" "}
																		{hdr.denominacion}
																	</p>
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Col>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={7}
													md={7}
												>
													<Card className='mb-3'>
														<Card.Body>
															<h6 className='mb-3 fs-0'>
																Detalle de operaciones
															</h6>
															<div className='table-responsive fs--1'>
																<Table striped className='border-bottom'>
																	<thead className='bg-200 text-900'>
																		<tr>
																			<th className='border-0 text-start'>
																				Denominación
																			</th>
																			<th className='border-0 text-start'>
																				Descripción
																			</th>
																			<th className='border-0 text-start'>
																				Tiempo
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{!!hdr.operations &&
																			hdr.operations.map((operation) => (
																				<tr
																					key={operation.idkey}
																					className='border-200'
																				>
																					<td className='align-middle text-start'>
																						<strong>
																							{operation.denomination}
																						</strong>
																					</td>

																					<td className='align-middle text-start'>
																						{operation.description}
																					</td>

																					<td className='align-middle text-start'>
																						{operation.time}
																					</td>
																				</tr>
																			))}
																	</tbody>
																</Table>
															</div>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Tab>
									)}

									{costGraf.length > 0 && (
										<Tab eventKey='costos' title='Costos'>
											<Row>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={7}
													md={7}
												>
													<Card className='h-md-100 p-1'>
														<Card.Body className='position-relative'>
															<Row>
																<Col
																	className='mt-1 mb-2'
																	xs={12}
																	sm={12}
																	lg={12}
																	md={12}
																>
																	<span className='mb-3 fs-0'>Estructura</span>

																	<div className='table-responsive fs--2 mt-2'>
																		<Table striped className='border-bottom'>
																			<thead className='bg-200 text-900'>
																				<tr>
																					<th className='border-0 text-start'>
																						Materia Prima
																					</th>
																					<th className='border-0 text-start'>
																						Precio
																					</th>
																					<th className='border-0 text-start'>
																						Cantidad
																					</th>
																				</tr>
																			</thead>
																			<tbody>
																				{!!estructuraMain.structure_rawmaterials &&
																					estructuraMain.structure_rawmaterials.map(
																						(rawmaterial) => (
																							<tr
																								key={rawmaterial.idkey}
																								className='border-200'
																							>
																								<td className='align-middle text-start'>
																									{rawmaterial.rawmaterial.name}{" "}
																									{rawmaterial.rawmaterial
																										.thread != null ? (
																										rawmaterial.rawmaterial
																											.thread == 1 ? (
																											<Badge bg='secondary'>
																												H. arriba
																											</Badge>
																										) : (
																											<Badge bg='secondary'>
																												H. abajo
																											</Badge>
																										)
																									) : (
																										""
																									)}{" "}
																									{rawmaterial.machinetype !=
																									null ? (
																										rawmaterial.machinetype ==
																										2 ? (
																											<Badge bg='danger'>
																												Flatseamer
																											</Badge>
																										) : (
																											""
																										)
																									) : (
																										""
																									)}
																								</td>

																								<td className='align-middle text-start'>
																									${rawmaterial.price}
																								</td>

																								<td className='align-middle text-start'>
																									{rawmaterial.quantity}
																								</td>
																							</tr>
																						)
																					)}
																			</tbody>
																		</Table>
																	</div>
																</Col>

																{Object.entries(estructuraSemi).length > 0 && (
																	<Col
																		className='mt-1 mb-2'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<Divider />
																		<span className='mb-3 fs-0'>
																			Estructura por producto semielaborado
																		</span>

																		<div className='table-responsive fs--2 mt-2'>
																			<Table striped className='border-bottom'>
																				<thead className='bg-200 text-900'>
																					<tr>
																						<th className='border-0 text-start'>
																							Materia Prima
																						</th>
																						<th className='border-0 text-start'>
																							Precio
																						</th>
																						<th className='border-0 text-start'>
																							Cantidad
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					{!!estructuraSemi.structure_rawmaterials &&
																						estructuraSemi.structure_rawmaterials.map(
																							(rawmaterial) => (
																								<tr
																									key={rawmaterial.idkey}
																									className='border-200'
																								>
																									<td className='align-middle text-start'>
																										{
																											rawmaterial.rawmaterial
																												.name
																										}{" "}
																										{rawmaterial.rawmaterial
																											.thread != null ? (
																											rawmaterial.rawmaterial
																												.thread == 1 ? (
																												<Badge bg='secondary'>
																													H. arriba
																												</Badge>
																											) : (
																												<Badge bg='secondary'>
																													H. abajo
																												</Badge>
																											)
																										) : (
																											""
																										)}{" "}
																										{rawmaterial.machinetype !=
																										null ? (
																											rawmaterial.machinetype ==
																											2 ? (
																												<Badge bg='danger'>
																													Flatseamer
																												</Badge>
																											) : (
																												""
																											)
																										) : (
																											""
																										)}
																									</td>

																									<td className='align-middle text-start'>
																										${rawmaterial.price}
																									</td>

																									<td className='align-middle text-start'>
																										{rawmaterial.quantity}
																									</td>
																								</tr>
																							)
																						)}
																				</tbody>
																			</Table>
																		</div>
																	</Col>
																)}

																{Object.entries(hdr).length > 0 && (
																	<Col
																		className='mt-3 mb-2'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<Divider />
																		<span className='mb-3 fs-0'>
																			Hoja de ruta
																		</span>

																		<div className='table-responsive fs--2 mt-2'>
																			<Table striped className='border-bottom'>
																				<thead className='bg-200 text-900'>
																					<tr>
																						<th className='border-0 text-start'>
																							Denominación
																						</th>
																						<th className='border-0 text-start'>
																							Tiempo
																						</th>
																						<th className='border-0 text-start'>
																							Costo
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					{!!hdr.operations &&
																						hdr.operations.map((operation) => (
																							<tr
																								key={operation.idkey}
																								className='border-200'
																							>
																								<td className='align-middle text-start'>
																									<strong>
																										{operation.denomination}
																									</strong>
																								</td>

																								<td className='align-middle text-start'>
																									{operation.time}
																								</td>

																								<td className='align-middle text-start'>
																									${operation.costo[0]}
																								</td>
																							</tr>
																						))}
																				</tbody>
																			</Table>
																		</div>
																	</Col>
																)}

																{Object.entries(hdrSemi).length > 0 && (
																	<Col
																		className='mt-3 mb-2'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<Divider />
																		<span className='mb-3 fs-0'>
																			Hoja de ruta por producto semielaborado
																		</span>

																		<div className='table-responsive fs--2 mt-2'>
																			<Table striped className='border-bottom'>
																				<thead className='bg-200 text-900'>
																					<tr>
																						<th className='border-0 text-start'>
																							Denominación
																						</th>
																						<th className='border-0 text-start'>
																							Tiempo
																						</th>
																						<th className='border-0 text-start'>
																							Costo
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					{!!hdrSemi.operations &&
																						hdrSemi.operations.map(
																							(operation) => (
																								<tr
																									key={operation.idkey}
																									className='border-200'
																								>
																									<td className='align-middle text-start'>
																										<strong>
																											{operation.denomination}
																										</strong>
																									</td>

																									<td className='align-middle text-start'>
																										{operation.time}
																									</td>

																									<td className='align-middle text-start'>
																										${operation.costo[0]}
																									</td>
																								</tr>
																							)
																						)}
																				</tbody>
																			</Table>
																		</div>
																	</Col>
																)}
															</Row>
														</Card.Body>
													</Card>
												</Col>

												<Col
													className='mt-3 h-md-100 mb-2'
													xs={12}
													sm={12}
													lg={5}
													md={5}
												>
													<Row>
														<Col xs={12} sm={12} lg={12} md={12}>
															<Card className='mb-4 p-1'>
																<Card.Body className='position-relative'>
																	<Row>
																		<Col
																			className='d-flex justify-content-center'
																			xs={12}
																			sm={12}
																			lg={12}
																			md={12}
																		>
																			<strong>
																				<span className='mb-3 fs--2 text-800'>
																					COSTOS
																				</span>
																			</strong>
																		</Col>
																		<Col xs={12} sm={12} lg={7} md={12}>
																			<span className='fs--1 text-800'>
																				Costo por estructura:
																			</span>
																		</Col>
																		<Col xs={12} sm={12} lg={5} md={12}>
																			<strong>
																				<span className='fs--1 text-800'>
																					${subTotalStructura}
																				</span>
																			</strong>
																		</Col>
																		<Col xs={12} sm={12} lg={7} md={12}>
																			<span className='fs--1 text-800'>
																				Costo por HDR:
																			</span>
																		</Col>
																		<Col xs={12} sm={12} lg={5} md={12}>
																			<strong>
																				<span className='fs--1 text-800'>
																					${subTotalHdr}
																				</span>
																			</strong>
																		</Col>
																		<Col xs={12} sm={12} lg={7} md={12}>
																			<span className='fs--1 text-800'>
																				Costo indirecto:
																			</span>
																		</Col>
																		<Col xs={12} sm={12} lg={5} md={12}>
																			<strong>
																				<span className='fs--1 text-800'>
																					${subTotalCostInd}
																				</span>
																			</strong>
																		</Col>

																		<Divider />

																		<Col xs={12} sm={12} lg={7} md={12}>
																			<strong>
																				<span className='fs--1 text-800'>
																					TOTAL
																				</span>
																			</strong>
																		</Col>
																		<Col xs={12} sm={12} lg={5} md={12}>
																			<strong>
																				<span className='fs--1 text-800'>
																					${total}
																				</span>
																			</strong>
																		</Col>
																	</Row>
																</Card.Body>
															</Card>
														</Col>
														<Col xs={12} sm={12} lg={12} md={12}>
															<Card className='mb-4 p-1'>
																<Card.Body className='position-relative'>
																	<Row>
																		<PieChart
																			data={costGraf}
																			height={"20rem"}
																		/>
																	</Row>
																</Card.Body>
															</Card>
														</Col>
													</Row>
												</Col>
											</Row>
										</Tab>
									)}

									{individualCost.length > 0 && (
										<Tab eventKey='ind-costos' title='Costos individuales'>
											<Row>
												<Col
													className='mt-3 mb-2'
													xs={12}
													sm={12}
													lg={7}
													md={7}
												>
													<Card className='h-md-100 p-1'>
														<Card.Body className='position-relative'>
															<Row>
																<Col
																	className='mt-1 mb-2'
																	xs={12}
																	sm={12}
																	lg={12}
																	md={12}
																>
																	<span className='mb-3 fs-0'>
																		Costos individuales
																	</span>

																	<div className='table-responsive fs--2 mt-2'>
																		<Table striped className='border-bottom'>
																			<tbody>
																				{!!individualCost &&
																					individualCost.map((costo, index) => (
																						<tr
																							key={`tr-${index}`}
																							className='border-200'
																						>
																							<td className='align-middle text-start'>
																								{costo.name}
																							</td>

																							{costo.source != "totalcost" && (
																								<td className='align-middle text-start'>
																									{costo.percentage}%
																								</td>
																							)}

																							{costo.source == "totalcost" && (
																								<td className='align-middle text-start'>
																									{""}
																								</td>
																							)}

																							<td className='align-middle text-start'>
																								${costo.cost}
																							</td>
																						</tr>
																					))}
																			</tbody>
																		</Table>
																	</div>
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Col>

												<Col
													className='mt-3 h-auto mb-2'
													xs={12}
													sm={12}
													lg={5}
													md={5}
												>
													<Row>
														<Col xs={12} sm={12} lg={12} md={12}>
															<Card className='mb-4 h-md-100 p-1'>
																<Card.Body>
																	<Row>
																		<PieChart
																			data={individualCostGraf}
																			height={`${heightIndCost}rem`}
																		/>
																	</Row>
																</Card.Body>
															</Card>
														</Col>
													</Row>
												</Col>
											</Row>
										</Tab>
									)}
								</Tabs>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
