/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListadoTable from "components/codes/singleCodes/SingleCodesListTable";
import { useAuthContext } from "hooks/useAuthContext";

const ListadoTabla = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const toastId = React.useRef(null);
	const [codes, setCodes] = useState([]);
	const [codesBackup, setCodesBackup] = useState([]);

	useEffect(() => {
		getAllCodes();
	}, []);

	const deleteCode = async (id, typeid) => {
		try {
			await axiosWithToken.delete(
				`/codesnew/individualcodes/${id}/type/${typeid}`
			);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
		getAllCodes();
		// setFiltro("");
		// setFiltroValueDat("");
	};

	const getAllCodes = async () => {
		const { data } = await axiosWithToken.get("/codesnew/individualcodes");

		setCodes(
			data.data.map((code) => {
				return {
					id: code.id,
					code: code.code,
					description: code.description,
					material: code.material_code ? code.material_code.description : "",
					tipo: code.tag.description,
					tipoid: code.tag.id,
					created_at: dayjs(code.created_at).format("DD/MM/YYYY HH:mm:ss"),
					updated_at: dayjs(code.updated_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);

		setCodesBackup(
			data.data.map((code) => {
				return {
					id: code.id,
					code: code.code,
					description: code.description,
					material: code.material_code ? code.material_code.description : "",
					tipo: code.tag.description,
					tipoid: code.tag.id,
					created_at: dayjs(code.created_at).format("DD/MM/YYYY HH:mm:ss"),
					updated_at: dayjs(code.updated_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);
	};

	return (
		<>
			{/* CARD TABLA LISTADO */}
			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>Listado de Códigos Individuales</h5>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/codes/SingleCodesForm'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						{/* TOAST MENSAJE */}
						<ToastContainer
							position='top-right'
							autoClose={1000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>

						<ListadoTable
							data={codes}
							setData={setCodes}
							dataBackup={codesBackup}
							deleteCode={deleteCode}
						/>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default ListadoTabla;
