/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { breakpoints, capitalize } from "helpers/utils";
import { topNavbarBreakpoint } from "config";

const NavbarDropdown = ({ title, children }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	return (
		<Dropdown
			show={dropdownOpen}
			onToggle={() => setDropdownOpen(!dropdownOpen)}
			onMouseOver={() => {
				let windowWidth = window.innerWidth;
				if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
					setDropdownOpen(true);
				}
			}}
			onMouseLeave={() => {
				let windowWidth = window.innerWidth;
				if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
					setDropdownOpen(false);
				}
			}}
		>
			<Dropdown.Toggle as={Link} className='nav-link fw-semi-bold me-1'>
				<span style={{ fontSize: "10px" }}>{capitalize(title)}</span>
			</Dropdown.Toggle>

			<Dropdown.Menu
				className='dropdown-menu-card mt-0 dropdown-caret'
				style={title == "Ingenieria de productos" ? { minWidth: "28rem" } : {}}
			>
				<Card className='shadow-none dark__bg-1000'>
					<Card.Body
						style={{ fontSize: "10px" }}
						className='scrollbar max-h-dropdown'
					>
						{children}
					</Card.Body>
				</Card>
			</Dropdown.Menu>
		</Dropdown>
	);
};

NavbarDropdown.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
};

export default NavbarDropdown;
