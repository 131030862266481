import React, {useContext} from 'react';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import sonder from 'assets/img/img-dashboard/icons/sonder.png';
import sonderB from 'assets/img/img-dashboard/icons/sonderB.png';
import GreetingsDate from './GreetingsDate';
import AppContext from 'context/Context';

const Greetings = () => {

  const {
    config: { isDark }
  } = useContext(AppContext)

  return (
    <Card className="mb-3">
      <Card.Body className="py-0">
        <Row className="g-0 justify-content-between">
          
          <Col md="auto" className="mb-3 mb-md-0">
            <Row className="g-3 gy-md-0 h-100 align-items-center">
              <Col xs="auto">
                <h6 className="text-700 mb-0 text-nowrap">Mostrar datos para:</h6>
              </Col>
              <Col md="auto">
                <GreetingsDate />
              </Col>
            </Row>
          </Col>

          <Col sm="auto">
            <Flex alignItems="center">
              <img src={ isDark ? sonderB : sonder} alt="sonder" width={40} />
            </Flex>
          </Col>
          
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Greetings;
