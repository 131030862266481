/** @format */

import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import HojaRutaForm from "./HojaRutaForm";

const ModalEditRuta = ({ editionMode, handleModal, ruta_id }) => {
	const handleClose = () => handleModal();

	return (
		<>
			<Modal
				show={editionMode}
				onHide={handleClose}
				backdrop='static'
				keyboard={false}
				size='lg'
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<HojaRutaForm ruta_id={ruta_id} handleClose={handleClose} />
				</Modal.Body>
			</Modal>
		</>
	);
};

ModalEditRuta.propTypes = {
	editionMode: PropTypes.bool,
	handleModal: PropTypes.func,
	ruta_id: PropTypes.number,
};

export default ModalEditRuta;
