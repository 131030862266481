/** @format */

import { useMemo } from "react";

export default function useColumnsManPowerTable() {
	const columns = useMemo(
		() => [
			{
				accessor: "id",
				Header: "#",
			},
			{
				accessor: "description",
				Header: "Nombre",
			},
			{
				accessor: "cod_recurso",
				Header: "Cod. Recurso",
			},
			/* {
				accessor: "eficiencia",
				Header: "Eficiencia",
			}, */
			{
				accessor: "status",
				Header: "Estado",
			},
			{
				accessor: "acciones",
				Header: "Acciones",
			},
		],
		[]
	);

	return columns;
}
