/** @format */

//React
import React, { useEffect, useState } from "react";

//Props
import PropTypes from "prop-types";

//Styles
import {
	Form,
	Row,
	Col,
	Card,
	Badge,
	Stack,
	OverlayTrigger,
	Tooltip,
	ListGroup,
	Popover,
	Button,
} from "react-bootstrap";

//components
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const dataBase = [
	{
		id: "droppable1",
		contents: [],
	},
	{
		id: "droppable2",
		contents: [],
	},
];

const DraggableComponent = ({ datos, handleOpSelected }) => {
	const [draggableData, setDraggableData] = useState(datos);

	const handleAddComment = (idOp) => {
		const comment = prompt("Ingresar comentario");

		const maxLength = 255;
		const comentarioLimitado = comment.substring(0, maxLength);

		const oper = draggableData[1].contents.find(({ id }) => id === idOp);

		oper.comment = comentarioLimitado;

		// document.querySelector(`#comment-${idOp}`).innerHTML = ` ${comentarioLimitado}`;
		handleOpSelected(draggableData[1].contents);
	};

	const getColumn = (id) => {
		return draggableData.find((item) => item.id === id);
	};

	const reorder = (array, fromIndex, toIndex) => {
		const newArr = [...array];

		const chosenItem = newArr.splice(fromIndex, 1)[0];
		newArr.splice(toIndex, 0, chosenItem);

		return newArr;
	};

	const move = (source, destination) => {
		const sourceItemsClone = [...getColumn(source.droppableId).contents];
		const destItemsClone = [...getColumn(destination.droppableId).contents];

		const [removedItem] = sourceItemsClone.splice(source.index, 1);
		destItemsClone.splice(destination.index, 0, removedItem);

		return {
			updatedDestItems: destItemsClone,
			updatedSourceItems: sourceItemsClone,
		};
	};

	const onDragEnd = (result) => {
		const { source, destination } = result;

		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const items = getColumn(source.droppableId).contents;
			const reorderedItems = reorder(items, source.index, destination.index);
			const result = draggableData.map((item) =>
				item.id === source.droppableId
					? { ...item, contents: reorderedItems }
					: item
			);

			setDraggableData(result);
			handleOpSelected(result[1].contents);
		} else {
			const sourceColumn = getColumn(source.droppableId);
			const destColumn = getColumn(destination.droppableId);
			const movedItems = move(source, destination);
			const result = draggableData.map((item) => {
				return {
					...item,
					contents:
						(item.id === sourceColumn.id && movedItems.updatedSourceItems) ||
						(item.id === destColumn.id && movedItems.updatedDestItems),
				};
			});

			handleOpSelected(result[1].contents);

			setDraggableData(result);
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Row>
				<Col xs={12} sm={12} md={6} lg={6}>
					<Form.Label>Operaciones</Form.Label>
					<Droppable droppableId='droppable1' type='DRAG'>
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className='kanban-items-container border bg-white dark__bg-1000 rounded-2'
							>
								{draggableData[0]?.contents.map((item, index) => (
									<Draggable
										key={item.id}
										draggableId={`drag${item.id}`}
										index={index}
									>
										{(provided) => (
											<div>
												<Card
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className='mb-3 kanban-item shadow-sm dark__bg-1100'
												>
													<Card.Body>
														<OverlayTrigger
															placement='top'
															overlay={
																<Tooltip>
																	<ListGroup>
																		<ListGroup.Item>
																			<b>Descripción:</b>{" "}
																			{item.oper.description}
																		</ListGroup.Item>
																		<ListGroup.Item>
																			<b>Tiempo:</b> {item.oper.time}
																		</ListGroup.Item>
																		<ListGroup.Item>
																			<b>Tipo fabricación:</b>{" "}
																			{item.oper.op_type}
																		</ListGroup.Item>
																		<ListGroup.Item>
																			<b>Monitoreo:</b>{" "}
																			{item.oper.monitoring_control === 0
																				? "NO"
																				: "SI"}
																		</ListGroup.Item>
																		<ListGroup.Item>
																			<b>Centro:</b>{" "}
																			{item.oper.centers.map((center) => {
																				return (
																					<React.Fragment key={center.id}>
																						center.description
																					</React.Fragment>
																				);
																			})}
																		</ListGroup.Item>
																	</ListGroup>
																</Tooltip>
															}
														>
															<p className='fs--1 fw-medium font-sans-serif mb-0'>
																{item.text}
															</p>
														</OverlayTrigger>
													</Card.Body>
												</Card>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</Col>

				<Col xs={12} sm={12} md={6} lg={6}>
					<Form.Label>Seleccionadas</Form.Label>
					<Droppable droppableId='droppable2' type='DRAG'>
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`kanban-items-container border bg-white dark__bg-1000 rounded-2 py-4`}
							>
								{draggableData[1]?.contents.map((item, index) => {
									return (
										<Draggable
											key={item.id}
											draggableId={`drag${item.id}`}
											index={index}
										>
											{(provided) => (
												<div>
													<Card
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className='mb-1 kanban-item shadow-sm dark__bg-1100'
													>
														<Card.Body className='px-1 py-3'>
															<OverlayTrigger
																trigger='click'
																placement='top'
																overlay={
																	<Popover>
																		<Popover.Body>
																			<ListGroup>
																				<ListGroup.Item>
																					<b>Descrip:</b>{" "}
																					{item.oper.description}
																				</ListGroup.Item>
																				<ListGroup.Item>
																					<b>Monitoreo:</b>{" "}
																					{item.oper.monitoring_control === 0
																						? "NO"
																						: "SI"}
																				</ListGroup.Item>
																				<ListGroup.Item>
																					<b>Tipo fabricación:</b>{" "}
																					{item.oper.op_type}
																				</ListGroup.Item>
																				<ListGroup.Item>
																					<b>Tiempo:</b> {item.oper.time}
																				</ListGroup.Item>

																				<ListGroup.Item>
																					<b>Comentario:</b>
																					<span id={`comment-${item.id}`}>
																						{item.comment != ""
																							? item.comment
																							: ""}
																					</span>
																				</ListGroup.Item>
																			</ListGroup>
																			<Button
																				onClick={() =>
																					handleAddComment(item.id)
																				}
																				className='fs--1 float-end my-3'
																				variant='success'
																			>
																				+ Agregar comentario
																			</Button>
																		</Popover.Body>
																	</Popover>
																}
															>
																<Stack
																	direction='horizontal'
																	gap={2}
																	className='d-flex'
																>
																	<Badge pill bg='warning' className='fs--2'>
																		Orden {index + 1}
																	</Badge>
																	<div className='fs--1'>{item.text}</div>
																</Stack>
															</OverlayTrigger>
														</Card.Body>
													</Card>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</Col>
			</Row>
		</DragDropContext>
	);
};

const DraggableHR = ({ oper, handleSelectChangeOperations, opsFromBack }) => {
	const [data, setData] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);
	const [operaciones, setOperaciones] = useState([]);
	const [show, setShow] = useState(false);
	const [operationsSelectedApi, setOperationsSelectedApi] = useState([]);

	useEffect(() => {
		dataBase[0].contents = [];
		dataBase[1].contents = [];
	}, []);

	useEffect(() => {
		if (oper.length > 0) setOperaciones(oper);
		setShow(false);

		setTimeout(() => {
			setShow(true);
		}, 200);
	}, [oper]);

	useEffect(() => {
		if (operaciones.length > 0) {
			const opFiltradas = operaciones.filter(
				(oper) => !dataSelected.some(({ id }) => oper.value === id)
			);
			const array = opFiltradas.map((op) => {
				const obj = {
					id: op.id,
					text: op.denomination,
					oper: op,
					comment: op.comment ? op.comment : "",
				};
				return obj;
			});

			dataBase[0].contents = array;
			setData(dataBase);
		} else {
			dataBase[0].contents = [];
		}

		//Consulto operaciones guardadas en la db y la seteo en el componente
		if (opsFromBack.length > 0) {
			setOperationsSelectedApi(opsFromBack);
		}
	}, [operaciones]);

	useEffect(async () => {
		if (opsFromBack.length > 0 && operaciones.length > 0) {
			const opsBack = await opsFromBack.map((op) => {
				const obj = {
					id: op.id,
					text: op.denomination,
					oper: op,
					comment: op.comment ? op.comment : "",
				};
				return obj;
			});

			handleOpSelected(opsBack);
		}
	}, [operationsSelectedApi]);

	useEffect(() => {
		// if (dataSelected.length > 0) {
		// 	dataBase[0].contents = [];
		// 	dataBase[1].contents = dataSelected;
		// 	setData(dataBase);
		// } else {
		// 	dataBase[1].contents = dataSelected;
		// }
		dataBase[1].contents = dataSelected;
		handleSelectChangeOperations(dataSelected);
	}, [dataSelected]);

	const handleOpSelected = (content) => {
		setDataSelected(content);
	};

	return (
		<>
			<Row>
				{show ? (
					<DraggableComponent
						datos={data}
						handleOpSelected={handleOpSelected}
					/>
				) : (
					""
				)}
			</Row>
		</>
	);
};

DraggableComponent.propTypes = {
	datos: PropTypes.array,
	handleOpSelected: PropTypes.func,
};

DraggableHR.propTypes = {
	oper: PropTypes.array,
	handleSelectChangeOperations: PropTypes.func,
	opsFromBack: PropTypes.array,
};

DraggableHR.defaultProps = {
	oper: [],
};

export default DraggableHR;
