/** @format */

import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

//Get all operations
export const getOperations = async () => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get("/operations");
	const operations = data.data;

	return operations;
};

//Get all operations by family
export const getOperationsByFamily = async (
	familiesId,
	selectedOperations = []
) => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get("/operations");
	const response = data.data;

	const filteredOperations = response.filter((op) =>
		op.relation_op_attr.some((attr) =>
			familiesId.some((family) => family.value === attr.id)
		)
	);

	//Filtramos las operaciones que NO se encuentran en selectedOperations
	if (selectedOperations.length > 0) {
		const noSelectedOperations = filteredOperations.filter(
			(ops) =>
				!selectedOperations.some((selectedOp) => selectedOp.id === ops.id)
		);
		return noSelectedOperations;
	}

	return filteredOperations;
};

//Get operations by family passing operation's array
export const getOperationsByFamilyInOperationsArray = (
	operacionesApi,
	familiesId,
	selectedOperations = []
) => {
	const filteredOperations = operacionesApi.filter((op) =>
		op.relation_op_attr.some((attr) =>
			familiesId.some((family) => family.value === attr.id)
		)
	);

	//Filtramos las operaciones que NO se encuentran en selectedOperations
	if (selectedOperations.length > 0) {
		const noSelectedOperations = filteredOperations.filter(
			(op) => !selectedOperations.some((selectedOp) => selectedOp.id === op.id)
		);
		return noSelectedOperations;
	}

	return filteredOperations;
};

//Get operations by product code
export const getOperationsByProductCode = (
	operaciones,
	selectedOperations = [],
	productCode
) => {
	const filteredOperations = operaciones.filter((op) =>
		op.relation_op_product.some((prod) => prod.id === productCode.value)
	);

	//Filtramos las operaciones que NO se encuentran en selectedOperations
	if (selectedOperations.length > 0) {
		const noSelectedOperations = filteredOperations.filter(
			(op) => !selectedOperations.some((selectedOp) => selectedOp.id === op.id)
		);
		return noSelectedOperations;
	}

	return filteredOperations;
};

//Get Operation by id
export const getOperation = async (id) => {
	try {
		const { token } = useAuthContext();
		axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const { data } = await axiosWithToken.get(`/operations/${id}`);
		return data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
