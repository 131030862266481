/** @format */

import React from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import usePagination from "hooks/usePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";

const ViewStageOne = ({ data }) => {
	const perPage = 15;
	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(data, perPage);

	console.log("familia detalle desde stage one", data);
	return (
		<>
			<Row className='mt-3'>
				<Col xs={12} sm={12} lg={12} md={12}>
					<Tab.Content>
						{paginatedDetails.map((dt) => (
							<Card
								key={dt.id}
								className='mb-2 square border-start border-start-2 border-primary'
							>
								<Row>
									<Col xs={12} sm={12} lg={12} md={12}>
										<p className='m-1 p-1 fs--1'>
											<span className='me-4'>
												<strong>Familia: </strong>
												{dt.family.name}
											</span>
											<span className='me-4'>
												<strong>Cant. planificadas: </strong>
												{dt.planned_quantity}
											</span>
											<span className='me-4'>
												<strong>Apertura: </strong> {dt.opening}
											</span>
											<span className='me-4'>
												<strong>Tiempo promedio: </strong>
												{dt.average_time}
											</span>
										</p>
									</Col>
								</Row>
							</Card>
						))}
					</Tab.Content>

					<div className='mt-4 d-flex justify-content-center'>
						<div>
							<Button
								variant='falcon-default'
								size='sm'
								disabled={!canPreviousPage}
								onClick={prevPage}
								className='me-2'
								trigger='focus'
							>
								<FontAwesomeIcon icon='chevron-left' />
							</Button>
						</div>

						<ul className='pagination mb-0'>
							{paginationArray.map((page) => (
								<li
									key={page}
									className={classNames({
										active: currentPage === page,
									})}
								>
									<Button
										size='sm'
										variant='falcon-default'
										className='page me-2'
										onClick={() => goToPage(page)}
									>
										{page}
									</Button>
								</li>
							))}
						</ul>
						<div>
							<Button
								variant='falcon-default'
								size='sm'
								disabled={!canNextPage}
								onClick={nextPage}
								trigger='focus'
							>
								<FontAwesomeIcon icon='chevron-right' />
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};

ViewStageOne.propTypes = {
	data: PropTypes.array,
};

export default ViewStageOne;
