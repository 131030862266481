/** @format */

import { useMemo } from "react";

export default function useColumnsCalendario() {
	const columns = useMemo(
		() => [
			{
				accessor: "id",
				Header: "#",
			},
			{
				accessor: "title",
				Header: "Título",
			},
			{
				accessor: "year",
				Header: "Año",
			},
			{
				accessor: "start",
				Header: "Inicio",
			},
			{
				accessor: "end",
				Header: "Fin",
			},
			{
				accessor: "temporadas.name",
				Header: "Temporada",
			},
			{
				accessor: "acciones",
				Header: "Acciones",
			},
		],
		[]
	);

	return columns;
}
