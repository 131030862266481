/** @format */
import axios from "axios";
import { tipoEventosDataObj } from "components/calendario/data/tipoEventos";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

//Traemos todos los eventos
export const getAllEventos = async () => {
	try {
		const { token } = useAuthContext();
		axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const { data } = await axiosWithToken.get("/calendario");
		const eventos = data.data;
		return eventos;
	} catch (error) {
		console.log(error);
	}
};
//Borramos evento sefun ID
export const deleteEvent = async (id) => {
	try {
		const { token } = useAuthContext();
		axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const { data } = await axiosWithToken.delete(`/calendario/delete/${id}`);
		return data;
	} catch (error) {
		const serverError = error.response.data.message;
		console.log(serverError);
		const errorObj = {
			status: "error",
			message: "Hubo un error al intentar borrar el evento",
		};
		return errorObj;
	}
};

//Update evento by id

export const updateEvento = async (eventData) => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.put(
		`/calendario/update/${eventData.id}`,
		eventData
	);
	return data;
};

//Get an Event by id
export const getEvent = async (id) => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get(`/calendario/${id}`);
	return data.data;
};

//Actualizar lista de eventos
export const updateEventsList = async () => {
	const events = await getAllEventos();
	const eventsBackground = events.map((event) =>
		event.tipo_evento === tipoEventosDataObj.feriado.name
			? { ...event, backgroundColor: "gold" }
			: { ...event, backgroundColor: null }
	);
	return eventsBackground;
};
