/** @format */

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Form, Button, Tab, Badge } from "react-bootstrap";
import { camelize } from "../../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchAutocompleteCodes from "components/searchs/SearchAutocompleteCodes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//Navigate para navegar a otra pantalla
	const navigate = useNavigate();

	//Buscadores deshbilitados y habilitados (submaterial y color)
	const [isDisabled, setIsDisabled] = useState(true);

	//Estado para material
	const [materialCode, setMaterialCode] = useState({});
	//Código material seleccionado
	const [materialCodeString, setMaterialCodeString] = useState("");

	//Array de objetos de submateriales
	const [submaterialArray, setSubmaterialArray] = useState([]);

	//Array de objetos de colores
	const [colormpArray, setColormpArray] = useState([]);

	const toastId = useRef(null);

	//MP
	useEffect(() => {
		if (Object.entries(materialCode).length > 0) {
			setMaterialCodeString(materialCode.code);
			setIsDisabled(false);
		}
	}, [materialCode]);

	const insertDataarray = (dato, modelSearch) => {
		switch (modelSearch) {
			case "submaterialCodes":
				if (
					!submaterialArray.some((submaterial) => submaterial.id === dato.id)
				) {
					setSubmaterialArray([...submaterialArray, dato]);
				}
				break;
			case "materialColorCodes":
				if (!colormpArray.some((color) => color.id === dato.id)) {
					setColormpArray([...colormpArray, dato]);
				}
				break;
		}
	};

	const deleteSubmaterial = async (id) => {
		const newData = submaterialArray.filter((item) => item.id !== id);
		setSubmaterialArray(newData);
	};

	const deleteMaterialCode = async (id) => {
		const newData = colormpArray.filter((item) => item.id !== id);
		setColormpArray(newData);
	};

	const handleCancel = () => {
		//Cancelar, simplemente lleva al listado de códigos nuevamente
		navigate(`/codes/compositeRawmaterialscodeslist`);
	};

	const storeCode = async (e) => {
		e.preventDefault();

		//MP
		if (materialCodeString == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo material es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (submaterialArray?.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Debe seleccionar por lo menos 1 submaterial.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (colormpArray?.length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Debe seleccionar por lo menos 1 color.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		const codesArray = submaterialArray.flatMap((submaterial) => {
			return colormpArray.map((color) => {
				return {
					code: materialCode.code + submaterial.code + color.code,
					material_id: materialCode.id,
					material_descrip: materialCode.description,
					submaterial_id: submaterial.id,
					submaterial_descrip: submaterial.description,
					color_id: color.id,
					color_descrip: color.description,
				};
			});
		});

		try {
			await axiosWithToken.post("/codes/massStorage", {
				codes: codesArray,
				selectUsability: 0,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/codes/compositeRawmaterialscodeslist");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
			// error
			return;
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario alta de códigos compuestos
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<Card className='mb-2 p-4 h-md-100'>
									<Row>
										<Tab.Content>
											<h6 className='mb-4 text-center'>
												Ingresar campos para códigos de materias primas
											</h6>

											<Form onSubmit={storeCode}>
												<Row className='mb-2 g-3'>
													<Col xs={12} sm={12} lg={12} md={12}>
														<Row className='mb-3 g-3'>
															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																controlId='formGridCodMat'
															>
																<Form.Label>
																	Material
																	<span className='text-danger'>*</span>
																</Form.Label>
																<SearchAutocompleteCodes
																	setSelect={setMaterialCode}
																	modelSearch={"materialCodes"}
																	disabled={false}
																/>
																<Form.Control
																	className='mt-2'
																	size='sm'
																	value={materialCodeString}
																	onChange={(e) =>
																		setMaterialCodeString(e.target.value)
																	}
																	type='text'
																	placeholder=''
																	disabled
																/>
															</Form.Group>
														</Row>

														<Row className='mb-2 g-3'>
															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																controlId='formGridSubmat'
															>
																<Form.Label>
																	Submaterial
																	<span className='text-danger'>*</span>
																</Form.Label>

																<SearchAutocompleteCodes
																	insertDat={insertDataarray}
																	materialcodeid={materialCode.id}
																	modelSearch={"submaterialCodes"}
																	disabled={isDisabled}
																/>
																{submaterialArray?.length > 0
																	? submaterialArray.map((elem) => {
																			return (
																				<div key={elem.id}>
																					<Badge
																						style={{ fontSize: "10px" }}
																						className='mb-2'
																						bg='secondary'
																					>
																						{elem.code} :
																					</Badge>{" "}
																					<span style={{ fontSize: "10px" }}>
																						{elem.description}
																					</span>{" "}
																					<FontAwesomeIcon
																						style={{ fontSize: "8px" }}
																						icon='times'
																						size='sm'
																						className='cursor-pointer text-danger ms-2'
																						onClick={() =>
																							deleteSubmaterial(elem.id)
																						}
																					/>
																				</div>
																			);
																	  })
																	: ""}
															</Form.Group>

															<Form.Group
																as={Col}
																xs={12}
																sm={12}
																md={6}
																lg={6}
																controlId='formGridMPColor'
															>
																<Form.Label>
																	Color
																	<span className='text-danger'>*</span>
																</Form.Label>

																<SearchAutocompleteCodes
																	insertDat={insertDataarray}
																	modelSearch={"materialColorCodes"}
																	disabled={isDisabled}
																/>
																{colormpArray?.length > 0
																	? colormpArray.map((elem) => {
																			return (
																				<div key={elem.id}>
																					<Badge
																						style={{ fontSize: "10px" }}
																						className='mb-2'
																						bg='secondary'
																					>
																						{elem.code} :
																					</Badge>{" "}
																					<span style={{ fontSize: "10px" }}>
																						{elem.description}
																					</span>{" "}
																					<FontAwesomeIcon
																						style={{ fontSize: "8px" }}
																						icon='times'
																						size='sm'
																						className='cursor-pointer text-danger ms-2'
																						onClick={() =>
																							deleteMaterialCode(elem.id)
																						}
																					/>
																				</div>
																			);
																	  })
																	: ""}
															</Form.Group>
														</Row>
													</Col>
												</Row>

												<Row>
													<Col className='d-flex justify-content-end'>
														<Button
															id='val-pr'
															size='sm'
															className='mt-3 me-2'
															variant='danger'
															onClick={() => handleCancel()}
														>
															Cancelar
														</Button>

														<Button
															size='sm'
															className='mt-3'
															variant='primary'
															type='submit'
														>
															Guardar
														</Button>
													</Col>
												</Row>
											</Form>
										</Tab.Content>
									</Row>
								</Card>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
