/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Tab, Button, Badge } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListadoTable from "components/codes/compositeCodes/CompositeProductsCodesListTable";
import { useAuthContext } from "hooks/useAuthContext";

const ListadoTabla = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const toastId = useRef(null);
	const [codes, setCodes] = useState([]);

	useEffect(() => {
		getAllCodes();
	}, []);

	const getAllCodes = async () => {
		const { data } = await axiosWithToken.get("/codes/bytype/1");
		setCodes(
			data.data.map((code) => {
				return {
					id: code.id,
					code: code.code,
					description: code.description,
					usability: code.usability ? "Producto" : "Materia prima",
					status: code.status ? (
						<Badge bg='danger'>
							Utilizado <FontAwesomeIcon icon='ban' className='ms-1 fs--2' />
						</Badge>
					) : (
						<Badge bg='success'>
							Habilitado <FontAwesomeIcon icon='check' className='ms-1 fs--2' />
						</Badge>
					),
					created_at: dayjs(code.created_at).format("DD/MM/YYYY HH:mm:ss"),
					updated_at: dayjs(code.updated_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);
	};

	const deleteCode = async (id) => {
		try {
			await axiosWithToken.delete(`/code/${id}`);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
		getAllCodes();
	};

	return (
		<>
			{/* CARD TABLA LISTADO */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>
								Listado de Códigos Compuestos de Productos
							</h5>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/codes/CompositeProductsCodesForm'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<ToastContainer
							position='top-right'
							autoClose={1000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>

						<ListadoTable data={codes} deleteCode={deleteCode} />
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default ListadoTabla;
