/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
	Card,
	Row,
	Col,
	Form,
	Button,
	Table,
	OverlayTrigger,
	Tooltip,
	Modal,
	Badge,
} from "react-bootstrap";
import { toast } from "react-toastify";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { generarId } from "../../helpers/utils";
import { formatNumber } from "helpers/numberFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PieChart from "components/charts/echarts/pie-charts/PieChartGraf";
import moment from "moment";
import { formatTimeToMinutes } from "helpers/formatTimeToMinutes";
import { useMasterplanContext } from "hooks/useMasterplanContext";
import { useAuthContext } from "hooks/useAuthContext";

const MasterplanFormStageOne = ({
	totalMinutes,
	handleSetMinutesAvailable,
	setKey,
}) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const toastId = React.useRef(null);

	//Edit registro del detalle
	const [edit, setEdit] = useState(false);

	//Show alert eliminar familia
	const [showAlert, setShowAlert] = useState(false);
	const [selectedFamily, setSelectedFamily] = useState({});

	const [family, setFamily] = useState({});
	const [quantity, setQuantity] = useState("");
	const [opening, setOpening] = useState("");
	const [averagetime, setAveragetime] = useState("");
	const [idkeyEdit, setIdkeyEdit] = useState("");
	const [idEditing, setIdEditing] = useState("");

	//Minutos planificados de la etapa 1
	const [plannedMinutesStage1, setPlannedMinutesStage1] = useState(0);

	const [stageOneGraph, setStageOneGraph] = useState([]);

	const {
		masterplanContext,
		handleSetMasterplan,
		handleStageOneDetails,
		stageOneDetails,
	} = useMasterplanContext();

	useEffect(async () => {
		//Traer etapa 1 si el plan maestro tiene datos
		if (masterplanContext && Object.entries(masterplanContext).length > 0) {
			getStageOne();
		}
	}, [masterplanContext]);

	useEffect(() => {
		if (stageOneDetails && stageOneDetails.length > 0) {
			//Creo el array de objetos que va a tener el total de minutos planificados por familia
			const minutesPerFamily = stageOneDetails.map((family) => {
				const obj = {
					value: formatTimeToMinutes(family.averagetime) * family.quantity,
					name: family.family.name,
				};
				return obj;
			});

			setStageOneGraph(minutesPerFamily);
		}
	}, [stageOneDetails]);

	const getStageOne = async () => {
		const data = masterplanContext.stage_one ? masterplanContext.stage_one : 0;
		if (data.length > 0) {
			const detailUpdate = data.map((detail) => {
				const newDetail = {
					id: detail.id,
					idkey: generarId(),
					family: detail.family,
					quantity: detail.planned_quantity,
					opening: detail.opening,
					averagetime: detail.average_time,
					stage_two: detail.stage_two,
				};

				return newDetail;
			});

			handleStageOneDetails(detailUpdate);
			//calculo y seteo las horas disponibles
			handleMinutesAvailable(detailUpdate);
		} else {
			handleStageOneDetails([]);
		}
	};

	const handleMinutesAvailable = (detailsFamilyTable) => {
		if (detailsFamilyTable.length > 0) {
			//Transformo el srting de minutos a minutos (string -> time)
			const initialTime = moment.duration(Number(totalMinutes), "minutes");

			//Acumulamos el tiempo que lleva hacer todas las prendas en minutos
			const totalAverageTime = detailsFamilyTable.reduce(
				(accumulator, currentValue) => {
					const unitAverageTime = moment.duration(
						currentValue.averagetime,
						"minutes"
					);
					const averageTime = unitAverageTime * currentValue.quantity;
					return accumulator.add(averageTime);
				},
				moment.duration(0)
			);

			setPlannedMinutesStage1(totalAverageTime.asMinutes());

			//Diferencia entre minutos totales contra minutos disponibles
			const newAvailableMinutes = initialTime
				.subtract(totalAverageTime)
				.asMinutes();

			// Seteo los minutos disponibles a mostrar
			handleSetMinutesAvailable(newAvailableMinutes);
		} else {
			handleSetMinutesAvailable(0);
			setPlannedMinutesStage1(0);
		}
	};

	const openModalDelete = (item) => {
		setSelectedFamily(item);
		setShowAlert(true);
	};

	const closeModalDelete = () => {
		setSelectedFamily({});
		setShowAlert(false);
	};

	const handleConfirmDelete = async () => {
		try {
			const { data } = await axiosWithToken.delete(
				`/masterplanStageone/masterplan/${masterplanContext.id}/deleteRow/${selectedFamily.id}`
			);
			handleSetMasterplan(data.data);
			setShowAlert(false);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
	};

	const deleteFamily = async () => {
		setFamily({});
		setQuantity(0);
		setOpening(0);
		setAveragetime("");
	};

	const isValidated = () => {
		if (!edit) {
			const existingFamily = stageOneDetails.find(
				(familyObj) => familyObj.family.id === family.id
			);

			if (existingFamily) {
				setTimeout(() => {
					toast.error(`Ya se ingresó la familia.`, {
						role: "alert",
						theme: "dark",
					});
				}, 0);

				//Error
				return false;
			}
		}

		if (quantity === "" || quantity === 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo cantidad planificada es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return false;
		}
		if (opening === "" || opening === 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo apertura es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return false;
		}

		if (averagetime === "" || averagetime === "00:00:00") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo tiempo promedio es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return false;
		}

		return true;
	};

	const handleAdd = async () => {
		if (Object.values(family).length === 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo familia es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		//Agregando un row
		if (!edit) {
			const validation = isValidated();
			if (!validation) return;

			const newDetail = {
				idkey: edit ? idkeyEdit : generarId(),
				family: family,
				quantity: quantity,
				opening: opening,
				averagetime: averagetime,
			};

			saveRowStageOne(newDetail);
		}

		//Editando un row
		if (edit) {
			const validation = isValidated();
			if (!validation) return;

			const editedDetail = {
				id: idEditing,
				idkey: edit ? idkeyEdit : generarId(),
				family: family,
				quantity: quantity,
				opening: opening,
				averagetime: averagetime,
			};
			saveRowStageOne(editedDetail);
		}
	};

	//funcion para formatear y setear el campo de tiempode ejecucion
	const handleTime = (event) => {
		const newValue = event.target.value.replace(/\D/g, ""); // solo se permiten números
		const maxLength = 8;
		const match = newValue.match(/^(\d{1,2})(\d{0,2})(\d{0,4})$/); // separa los numeros en grupos
		if (match) {
			const formattedValue =
				match[1] +
				(match[2] ? ":" + match[2] : "") +
				(match[3] ? ":" + match[3] : ""); // agrega dos puntos
			event.target.value = formattedValue.slice(0, maxLength); // limita la longitud a 8 caracteres
		}
		setAveragetime(event.target.value);
	};

	const saveRowStageOne = async (detalle) => {
		try {
			const { data } = await axiosWithToken.post(
				"/masterplanStageone/stageOne",
				{
					masterplan_id: masterplanContext.id,
					detalle,
				}
			);
			//Paso los datos de la etapa 1 del plan maestro al context
			handleStageOneDetails(data.stageOne);
			handleSetMasterplan(data.data);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(
					"¡Detalle por familia guardado, puede continuar!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			setFamily({});
			setQuantity("");
			setOpening("");
			setAveragetime("");
			setIdkeyEdit("");
			setIdEditing("");
			setEdit(false);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
	};

	return (
		<Row>
			<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
				<Card className='mb-2 p-0 h-md-100'>
					<Card.Body className='pb-0'>
						<h6 className='mb-2 text-center fs--2'>
							Agregar una familia de producto para planificar
						</h6>

						{/* Componente Modal de alerta */}

						<Modal
							size='md'
							aria-labelledby='contained-modal-title-vcenter'
							centered
							show={showAlert}
						>
							<Modal.Header>
								<Modal.Title>
									<h6 className='mb-0 hover-actions-trigger'>
										¿Estás seguro de que deseas eliminar?
									</h6>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Row className='mt-1'>
									<span className='text-danger fs--2'>
										<strong>
											¡Se eliminaran detalles relacionados en las etapas
											siguientes si la familia lo tiene!
										</strong>
									</span>
								</Row>
								<Row className='mt-3'>
									<Col className='d-flex justify-content-end'>
										<Button
											variant='danger'
											size='sm'
											onClick={closeModalDelete}
										>
											Cancelar
										</Button>
										<Button
											className='ms-1'
											variant='success'
											size='sm'
											onClick={handleConfirmDelete}
										>
											Confirmar
										</Button>
									</Col>
								</Row>
							</Modal.Body>
						</Modal>

						<Row className='mb-1'>
							<Form.Group as={Col} xs={12} sm={12} md={6} lg={3}>
								<Form.Label className='fs--2'>Buscar una familia</Form.Label>
								<SearchAutocomplete
									setSelect={setFamily}
									modelSearch={"families"}
									disabled={edit}
								/>
								<span style={{ fontSize: "12px" }}>
									<strong>{family.name}</strong>
								</span>
								{Object.entries(family).length > 0 ? (
									<FontAwesomeIcon
										icon='times'
										style={{ fontSize: "10px" }}
										className='cursor-pointer text-danger ms-1'
										onClick={edit ? null : () => deleteFamily()}
									/>
								) : (
									""
								)}
							</Form.Group>

							<Form.Group as={Col} xs={12} sm={12} md={6} lg={3}>
								<Form.Label className='fs--2'>
									Cantidad planificada
									<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Control
									size='sm'
									value={quantity}
									onChange={(e) => setQuantity(e.target.value)}
									type='text'
									placeholder=''
								/>
							</Form.Group>

							<Form.Group as={Col} xs={12} sm={12} md={6} lg={3}>
								<Form.Label className='fs--2'>
									Apertura
									<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Control
									size='sm'
									value={opening}
									onChange={(e) => setOpening(Number(e.target.value))}
									type='text'
									placeholder=''
								/>
							</Form.Group>

							{/* TIEMPO */}
							<Form.Group as={Col} xs={12} sm={12} md={6} lg={3}>
								<Form.Label className='fs--1'>
									Tiempo promedio
									<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Control
									size='sm'
									type={"text"}
									value={averagetime}
									onChange={handleTime}
									placeholder={"hh : mm : ss"}
								/>
							</Form.Group>

							<Form.Group as={Col} xs={12} sm={12} md={12} lg={12}>
								<Button
									variant='success'
									className='ms-1 fs--2 float-end mt-2'
									size='sm'
									onClick={handleAdd}
								>
									{!edit ? "Agregar" : "Guardar"}
								</Button>

								{edit ? (
									<Button
										size='sm'
										className='ms-1 fs--2 float-end mt-2'
										variant='danger'
										onClick={() => {
											setFamily({});
											setIdkeyEdit("");
											setIdEditing("");
											setQuantity("");
											setOpening("");
											setAveragetime("");
											setEdit(false);
										}}
									>
										Cancelar
									</Button>
								) : (
									""
								)}
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col className='mb-2' xs={12} sm={12} lg={8} md={8}>
				<Card className='mb-2 p-2 h-md-100'>
					<Card.Body>
						<h6 className='mb-4 text-center fs--2'>Detalle por familia</h6>

						<Row>
							<div className='table-responsive fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th
												style={{ width: "30%" }}
												className='border-0 text-start'
											>
												Familia
											</th>
											<th
												style={{ width: "20%" }}
												className='border-0 text-start'
											>
												Cantidad planificada
											</th>
											<th className='border-0 text-center'>Apertura</th>
											<th
												style={{ width: "20%" }}
												className='border-0 text-center'
											>
												Tiempo promedio
											</th>
											<th
												style={{ width: "30%" }}
												className='border-0 text-center'
											>
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{stageOneDetails && stageOneDetails.length ? (
											stageOneDetails.map((item, index) => (
												<tr
													key={`key-${index}-${item.id}`}
													className='border-200'
												>
													<td className='text-start'>{item.family.name}</td>

													<td className='text-start'>
														{formatNumber(item.quantity)}
													</td>
													<td className='text-center'>{item.opening}</td>
													<td className='text-center'>{item.averagetime}</td>
													<td className='text-end'>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Editar</Tooltip>}
														>
															<Button
																onClick={() => {
																	setEdit(true);
																	setIdEditing(item.id);
																	setIdkeyEdit(item.idkey);
																	setFamily(item.family);
																	setQuantity(item.quantity);
																	setOpening(item.opening);
																	setAveragetime(item.averagetime);
																}}
																variant='transparent'
																size='sm'
																style={{
																	fontSize: "11px",
																}}
																className='text-success'
															>
																<FontAwesomeIcon icon='edit' />
															</Button>
														</OverlayTrigger>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Eliminar</Tooltip>}
														>
															<Button
																onClick={() => openModalDelete(item)}
																variant='transparent'
																size='sm'
																style={{
																	fontSize: "11px",
																}}
																className='text-danger me-2'
															>
																<FontAwesomeIcon icon='trash' />
															</Button>
														</OverlayTrigger>
													</td>
												</tr>
											))
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col xs={12} sm={12} lg={4} md={4}>
				<Card className='mb-4 p-1'>
					<Card.Header className='border-0 p-0 text-center fs--2'>
						Minutos p/familia
					</Card.Header>
					<Card.Body className='position-relative'>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<PieChart data={stageOneGraph} height={"20rem"} />
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<h6 className='fs--2 text-center'>
									Min. planificados:{" "}
									<Badge className='p-1 fs--2' bg='primary'>
										{formatNumber(plannedMinutesStage1)}
									</Badge>
								</h6>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col
				xs={12}
				sm={12}
				lg={12}
				md={12}
				className='mb-2 mt-3 d-flex justify-content-end'
			>
				{stageOneDetails && stageOneDetails.length ? (
					<Button size='sm' variant='primary' onClick={() => setKey("stage2")}>
						Seguir
					</Button>
				) : (
					""
				)}
			</Col>
		</Row>
	);
};

MasterplanFormStageOne.propTypes = {
	setdisabledStage2: PropTypes.func,
	setKey: PropTypes.func,
	totalMinutes: PropTypes.number,
	minutesAvailable: PropTypes.number,
	handleSetMinutesAvailable: PropTypes.func,
	badgeStatuses: PropTypes.object,
	handleBadgeStatus: PropTypes.func,
};

export default MasterplanFormStageOne;
