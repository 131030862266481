/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Badge } from "react-bootstrap";
import dayjs from "dayjs";
import axios from "axios";
import ViewStageOne from "components/salesmasterplan/ViewStageOne";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { id } = useParams();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [creationdate, setCreationdate] = useState("");
	const [startdate, setStartdate] = useState("");
	const [enddate, setEnddate] = useState("");
	const [planningyear, setPlanningyear] = useState("");
	const [stage, setStage] = useState("");
	const [type, setType] = useState("");
	const [status, setStatus] = useState("");

	//ETAPAS
	const [showStages, setShowStages] = useState(false);

	//ETAPA 1
	const [stageOne, setStageOne] = useState([]);

	useEffect(() => {
		getMasterPlanById();
	}, []);

	const getMasterPlanById = async () => {
		const { data } = await axiosWithToken.get(`/masterplan/${id}`);

		setName(data.data.name);
		setDescription(data.data.description);
		setCreationdate(dayjs(data.data.created_at).format("DD/MM/YYYY HH:mm:ss"));
		setStartdate(dayjs(data.data.start_date).format("DD/MM/YYYY"));
		setEnddate(dayjs(data.data.end_date).format("DD/MM/YYYY"));
		setPlanningyear(data.data.planning_year);
		setStage(data.data.stage);
		setType(data.data.type);
		setStatus(data.data.status);

		if (data.data.stage) {
			setShowStages(true);
		}

		if (data.data.stage_one.length > 0) {
			setStageOne(data.data.stage_one);
		}
	};

	return (
		<>
			{/* VIEW */}
			<Card className='mb-3'>
				<Card.Header className='p-2'>
					<Row className='align-items-end g-2'>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0 hover-actions-trigger'>
								Plan maestro: <span>{name}</span>{" "}
								{status ? (
									<Badge bg='success'>Activo</Badge>
								) : (
									<Badge bg='danger'>Inactivo</Badge>
								)}
								<Badge className='ms-1' bg='primary'>
									Etapa: {stage}
								</Badge>
							</h6>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<h6 className='mb-3 fs-0'>
									Información general del plan maestro
								</h6>
							</Col>
							<Col xs={12} sm={12} lg={6} md={6}>
								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>Nombre: </strong> {name}{" "}
								</p>

								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>Descripción: </strong>{" "}
									{description}
								</p>

								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>Fecha de alta: </strong>{" "}
									{creationdate}
								</p>

								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>
										Año de planificación:{" "}
									</strong>{" "}
									{planningyear}
								</p>
							</Col>

							<Col xs={12} sm={12} lg={6} md={6}>
								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>Fecha de inicio:</strong>{" "}
									{startdate}
								</p>

								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>Fecha de fin:</strong>{" "}
									{enddate}
								</p>

								<p className='mb-4 fs--1'>
									<strong className='fs--0 text-600'>Tipo de plan:</strong>{" "}
									{type ? "Definitivo" : "Borrador"}
								</p>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
			{showStages && (
				<Card className='mb-3'>
					<Card.Body>
						<Tab.Content>
							<Row>
								<Col xs={12} sm={12} lg={12} md={12}>
									{stageOne && (
										<Tabs defaultActiveKey='stage1' id='tabs-viewmp'>
											<Tab
												eventKey='stage1'
												title='Etapa 1: Detalle por familias'
											>
												<ViewStageOne data={stageOne} />
											</Tab>
										</Tabs>
									)}
								</Col>
							</Row>
						</Tab.Content>
					</Card.Body>
				</Card>
			)}
		</>
	);
};

export default Formulario;
