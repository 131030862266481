/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEstamperia } from "hooks/useEstamperia";
import { Row, Col, Card, Tab, Tabs, Badge, Image } from "react-bootstrap";
import Spinner from "components/spinner/Spinner";
import Divider from "components/common/Divider";
import NineDotMenu from "components/menuButtons/NineDotMenu";
import { dateFormatter } from "helpers/dateFormatter";
import EstamperiaFormEdit from "./EstamperiaEdit";
import EstamperiaPagination from "./EstamperiaPagination";

const EstamperiaView = () => {
	const [loading, setLoading] = useState(true);
	const [editionMode, setEditionMode] = useState(false);
	const [productosAgrupados, setProductosAgrupados] = useState([]);

	const { estampa, getEstampa, deleteEstampa } = useEstamperia();

	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		getEstampa(id);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);

		let objeto = [];
		if (estampa.atributos != undefined && estampa.atributos.length > 0) {
			const agruparProducts = estampa.atributos.reduce((acc, attr) => {
				if (!objeto[`${attr.attr_id}`]) {
					objeto[`${attr.attr_id}`] = [];
				}

				objeto[`${attr.attr_id}`].push(attr);
				return objeto;
			}, {});

			const filterAgruparProducts = agruparProducts.filter(
				(elem) => elem != undefined && elem != null
			);
			4;
			setProductosAgrupados(filterAgruparProducts);
		}

		let mp = estampa.products_mp;

		if (mp != undefined && mp.length > 0) {
			// console.log('MP', mp)
			const totalCant = mp.reduce((acc, obj) => {
				return (acc += obj.pivot.cantidad);
			}, 0);

			estampa.total = totalCant;
		}
	}, [estampa]);

	const handleDelete = () => {
		const deleteEst = confirm("Está a punto de eliminar la estampa");
		if (deleteEst) {
			deleteEstampa(estampa.id);
			navigate("/estamperia/list");
		}
	};

	const handleEditionMode = () => {
		setEditionMode(!editionMode);
	};

	return (
		<>
			{loading ? (
				<Spinner />
			) : estampa?.id ? (
				<>
					<Card className='mb-3'>
						<Card.Header className='p-2'>
							<Row className='align-items-baseline g-2'>
								<Col className='d-flex justify-content-center mt-0'>
									<h6 className='mb-0 hover-actions-trigger'>
										Estampa: <Badge bg='secondary'># {estampa.id}</Badge>{" "}
										<Badge bg='secondary'>{estampa.nombre}</Badge>
									</h6>
								</Col>
								<Col xs={3} sm={3} md={1} lg={1}>
									<NineDotMenu
										handleEditionMode={handleEditionMode}
										handleDelete={handleDelete}
									/>
								</Col>
							</Row>
						</Card.Header>

						{/* FORMULARIO EDIT*/}
						<EstamperiaFormEdit
							editionMode={editionMode}
							handleEditionMode={handleEditionMode}
						/>

						<Card.Body>
							<Tab.Content>
								<Row>
									<Col xs={12} sm={12} lg={12} md={12}>
										<Tabs defaultActiveKey='general' id='tabs-estamperiaView'>
											<Tab eventKey='general' title='1 - Datos'>
												<Row>
													<Col
														className='mt-3 mb-2'
														xs={12}
														sm={12}
														lg={8}
														md={8}
													>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<h6 className='mb-3 fs-0'>
																			Información general
																		</h6>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Nombre:</b>{" "}
																			{estampa.nombre}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Descripción:
																			</b>{" "}
																			{estampa.obs}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Fecha de alta:
																			</b>{" "}
																			{dateFormatter(estampa.created_at)}
																		</p>

																		<p className='mb-4 fs--1'>
																			<b className='fs--0 text-600'>
																				Fecha de modificación:
																			</b>{" "}
																			{dateFormatter(estampa.updated_at)}
																		</p>
																	</Col>

																	<Divider />

																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={4}
																		md={4}
																	>
																		<h6 className='mb-3 fs-0'>Detalles</h6>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Carpeta:</b>{" "}
																			{estampa.carpeta}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Folio:</b>{" "}
																			{estampa.folio}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Película:
																			</b>{" "}
																			{estampa.pelicula}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Cant.MP:</b>{" "}
																			{estampa.total}
																		</p>
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Col>

													<Col
														className='mt-3 mb-2'
														xs={12}
														sm={12}
														lg={4}
														md={4}
													>
														<Card className='mb-4 h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='d-flex justify-content-center'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<Image
																			width={"100%"}
																			src={`${process.env.REACT_APP_ENDPOINT_BASE}/img-estamperia/${estampa.imagen}`}
																			rounded
																			alt={`imagen - ${estampa.imagen}`}
																			loading='lazy'
																		/>
																	</Col>

																	<Col
																		className='mt-4 d-flex justify-content-center'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<b>
																			<span className='mb-3 fs--2 text-800'>
																				{estampa.nombre}
																			</span>
																		</b>
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Col>
												</Row>
											</Tab>

											<Tab eventKey='Relacion' title='2 - Productos Relaciones'>
												<Row>
													<Col
														className='mt-3 mb-2'
														xs={12}
														sm={12}
														lg={12}
														md={12}
													>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<h6 className='mb-3 fs-0'>
																			Productos relacionados
																		</h6>
																		{estampa.atributos?.length > 0 ? (
																			<EstamperiaPagination
																				data={productosAgrupados}
																			/>
																		) : (
																			"No cuenta con productos relacionados"
																		)}
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Col>
												</Row>
											</Tab>

											<Tab eventKey='Ubicaciones' title='3 - MP Relacionadas'>
												<Row>
													<Col
														className='mt-3 mb-2'
														xs={12}
														sm={12}
														lg={12}
														md={12}
													>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<h6 className='mb-3 fs-0'>
																			Materias Primas relacionados (default)
																		</h6>
																		{estampa.products_mp.map((prod) => {
																			return (
																				<React.Fragment key={prod.id}>
																					<Row>
																						<Col
																							xs={12}
																							sm={12}
																							md={12}
																							lg={12}
																						>
																							<b className='fs--1 text-600'>
																								Nombre:
																							</b>{" "}
																							<Badge
																								bg='primary'
																								className='fs--2'
																							>
																								{prod.code}
																							</Badge>{" "}
																							<span className='mb-2 fs--1'>
																								{prod.description}
																							</span>
																						</Col>
																						<Col
																							xs={12}
																							sm={12}
																							md={12}
																							lg={12}
																						>
																							<b className='fs--1 text-600'>
																								Cantidad:
																							</b>{" "}
																							<span className='mb-2 fs--1'>
																								{prod.pivot["cantidad"]}
																							</span>
																						</Col>
																					</Row>
																					<Divider />
																				</React.Fragment>
																			);
																		})}
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Col>
												</Row>
											</Tab>
										</Tabs>
									</Col>
								</Row>
							</Tab.Content>
						</Card.Body>
					</Card>
				</>
			) : (
				<h6 className='text-center'>Este ID no pertenece a ninguna estampa!</h6>
			)}
		</>
	);
};

export default EstamperiaView;
