/** @format */

import { useMemo } from "react";

export default function useColumnsManPower() {
	const columns = useMemo(
		() => [
			{
				accessor: "id",
				Header: "#",
			},
			{
				accessor: "description",
				Header: "Nombre",
			},
			{
				accessor: "cod_recurso",
				Header: "Cod. Recurso",
			},
			{
				accessor: "manpower_type[description]",
				Header: "Tipo Mano de Obra",
			},
			{
				accessor: "phone",
				Header: "Tel.",
			},
			{
				accessor: "status",
				Header: "Estado",
			},
			{
				accessor: "acciones",
				Header: "Acciones",
			},
		],
		[]
	);

	return columns;
}
