/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Row, Col, Button, Card, Tab } from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import { useCentros } from "hooks/useCentros";
import { days } from "data/center/days";
import { useAuthContext } from "hooks/useAuthContext";

const FormCentroEdit = ({ editId = null, setShow = null }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [description, setDescription] = useState("");
	const [padre, setPadre] = useState(0);
	const [estado, setEstado] = useState(1);
	const [centerType, setCenterType] = useState("");
	const [costo, setCosto] = useState(0);
	const [padres, setPadres] = useState([]);
	const [selectedDays, setSelectedDays] = useState([]);

	const { id } = useParams();

	const toastId = React.useRef(null);

	const { center, getCenter, centros, getAllCentros } = useCentros();

	useEffect(() => {
		getCenter(editId != null ? editId : id);
		getAllCentros();
	}, []);

	useEffect(() => {
		setDescription(center.description);
		setEstado(center.status);
		setPadre(center.padre);
		setCenterType(center.tipo);
		setCosto(center.costo);
		if (Object.values(center).length > 0) {
			if (center.center_day != null) {
				if (Object.values(center.center_day).length > 0) {
					if (center.center_day.day != undefined) {
						const dias = JSON.parse(center.center_day.day, true);
						setSelectedDays(dias);
					}
				} else {
					setSelectedDays([]);
				}
			}
		}
	}, [center]);

	useEffect(() => {
		const centrosFiltrado = centros.filter(
			(centro) => centro.id != (editId != null ? editId : id)
		);
		setPadres(centrosFiltrado);
	}, [centros]);

	const updateCentro = async (e) => {
		e.preventDefault();
		try {
			const respuesta = await axiosWithToken.put(
				`/centros-de-trabajo/listado-centros/actualizar-centro/${
					editId != null ? editId : id
				}`,
				{
					description: description.toUpperCase(),
					padre,
					estado,
					centerType,
					costo,
					workDays: selectedDays,
				}
			);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("Editado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				editId != null ? getAllCentros() : getCenter(id);

				setTimeout(() => {
					setShow(false);
					// navigate(`/centros-de-trabajo/listado-centros/view/${editId != null ? editId : id}`)
				}, 1500);
			} else {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${respuesta.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		} catch (error) {
			console.log(error.response.data.message);
		}
	};

	const handleSelectChange = (selectedOption) => {
		setSelectedDays(selectedOption);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Editar Centro
							</h5>
							<p className='mt-2 mb-0'>Ingresá los datos correspondientes.</p>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={updateCentro}>
							<Row className='mb-3 g-3'>
								{/* DESCRIPCION */}
								<Form.Group
									as={Col}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridDescription'
								>
									<Form.Label>Descripción</Form.Label>
									<Form.Control
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										type='text'
										placeholder='Ingrese descripción'
									/>
								</Form.Group>

								{/* PADRE */}
								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridSelectPadre'
									//className="overflow-scroll"
									//style={{ height: '300px' }}
								>
									<Form.Label>Padre</Form.Label>
									<Form.Select
										value={padre}
										aria-label='Padre'
										onChange={(e) => setPadre(e.target.value)}
									>
										<option value={0}> Utilizar como padre </option>
										{padres.map((elem) => {
											return (
												<option key={elem.id} value={elem.id}>
													#{elem.id} {elem.description} {elem.padre_description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								{/* ESTADO */}
								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridSelectTipo'
								>
									<Form.Label>Estado</Form.Label>
									<Form.Select
										value={estado}
										aria-label='Estado'
										onChange={(e) => setEstado(Number(e.target.value))}
									>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Form.Select>
								</Form.Group>

								{/* TIPO */}
								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridCenterType'
								>
									<Form.Label>Tipo</Form.Label>
									<Form.Select
										value={centerType}
										aria-label='Tipo'
										onChange={(e) => setCenterType(e.target.value)}
									>
										<option disabled value={0}>
											-- Seleccione tipo de centro --
										</option>
										<option value={"interno"}>Interno</option>
										<option value={"externo"}>Externo</option>
									</Form.Select>
								</Form.Group>

								{/* COSTO */}
								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridCost'
								>
									<Form.Label>Costo</Form.Label>
									<Form.Control
										disabled={center?.padre === 0 ? false : true}
										value={costo}
										onChange={(e) => setCosto(e.target.value)}
										type='number'
										placeholder='Ingrese Costo'
									/>
								</Form.Group>

								{/* DIAS DE TRABAJO */}
								<Col xs={12} sm={12} md={12} lg={12}>
									<Form.Label className='fs--1'>Días de trabajo</Form.Label>
									<Select
										options={days}
										value={selectedDays}
										isMulti
										onChange={handleSelectChange}
										placeholder={"Seleccione los días de trabajo"}
									/>
								</Col>
							</Row>
							<Row>
								{/* {!!!id && 
                        <Col>
                          <Button variant="warning" onClick={() => volver()}>
                            Volver
                          </Button>
                        </Col>
                      } */}

								<Col>
									<Button className='float-end' variant='primary' type='submit'>
										Editar
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

FormCentroEdit.propTypes = {
	editId: PropTypes.number,
	setShow: PropTypes.func,
};

export default FormCentroEdit;
