/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Form,
	Row,
	Col,
	Button,
	Card,
	Tab,
	FloatingLabel,
	Stack,
} from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import SoftBadge from "components/common/SoftBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import InputsCargaAPrendas from "./InputsCargaAPrendas";
import Divider from "components/common/Divider";
import { useAuthContext } from "hooks/useAuthContext";

const EstamperiaProductForm = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [productosSeleccionados, setProductosSeleccionados] = useState([]);
	const [numInputs, setNumInputs] = useState(1);
	const [obs, setObs] = useState("");
	const [rubro, setRubro] = useState(0);
	const [productos, setProductos] = useState([]);

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	useEffect(() => {
		setRubro(0);
	}, []);

	useEffect(() => {
		if (productos.length > 0) {
			handleSearchProds(productos);
		}
	}, [productos]);

	//Funcion para agregar un row más de ubicaciones
	const agregarRowUbicaciones = () => {
		setNumInputs(numInputs + 1);
	};

	//Funcion para borrar un row específico de ubicaciones
	const eliminarRowUbicaciones = (keyId) => {
		input.splice(Number(keyId), 1);
		document.querySelector(`#row-${keyId}`).remove();
	};

	//Inputs de ubicaciones
	const input = [];

	for (let i = 0; i < numInputs; i++) {
		input.push(
			<InputsCargaAPrendas
				key={i}
				keyId={i}
				eliminarRowUbicaciones={eliminarRowUbicaciones}
				rubro={rubro}
			/>
		);
	}

	//Funcion para eliminar productos agregados
	const handleProd = (prod_id) => {
		const newPr = productosSeleccionados.filter((pr) => pr.id != prod_id);
		setProductosSeleccionados(newPr);
	};

	//Función para guardar los productos seleccionadas
	const handleSearchProds = (searchObject) => {
		productosSeleccionados.push(searchObject);
	};

	//Funcion de error si hay datos vacios en los renglones
	const error = (nombre, renglon) => {
		if (!toast.isActive(toastId.current)) {
			toastId.current = toast.error(
				`Por favor seleccione: ${nombre} en el renglón ${renglon + 1}`,
				{
					role: "alert",
					theme: "dark",
					autoClose: 4000,
				}
			);
		}
	};

	const storeRelacionEstamperiasProductos = async (e) => {
		e.preventDefault();

		if (productosSeleccionados.length < 1) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					"Debe seleccionar un código de producto",
					{
						role: "alert",
						theme: "dark",
						autoClose: 4000,
					}
				);
			}
			return;
		}

		const misDatos = [];
		let mpStacks = [];
		let datos = {};

		for (let i = 0; i < input.length; i++) {
			if (document.querySelector(`#row-${i}`) != null) {
				mpStacks = [];
				datos = {};
				let ubicacion = Number(document.querySelector(`#ubicacion-${i}`).value);
				let cantColor = Number(
					document.querySelector(`#cantColor-${i.toString()}`).value
				);

				const totalMpStackNodes = document.querySelectorAll(
					`input[name='mpStack-${i}']`
				);

				const totalMpStacks = Object.values(totalMpStackNodes);

				totalMpStacks.forEach((mpStack) => {
					const obj = {
						value: mpStack.value,
						cant: Number(mpStack.nextElementSibling.value),
					};
					mpStacks.push(obj);
				});

				let estampa = Number(
					document.querySelector(`#estampaStack-${i}`)?.value
				);

				datos = {
					ubicacion,
					estampa,
					cantColor,
					mpStacks,
				};

				if (isNaN(datos.estampa)) return error("estampa", i);

				for (let i = 0; i < datos.mpStacks.length; i++) {
					if (isNaN(datos.mpStacks[i].cant)) return error("materia prima", i);
				}

				misDatos.push(datos);
			}
		}

		try {
			const { data } = await axiosWithToken.post(
				"/estamperia/relacionProductoEstampa",
				{
					array: JSON.stringify(misDatos),
					prod: productosSeleccionados,
				}
			);

			if (data.status == "ok") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setTimeout(() => {
					navigate(`/estamperia/relacion-producto-estampa/${data.relacionId}`);
				}, 2000);
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
					autoClose: 4000,
				});
			}
		}
	};

	const volver = () => {
		navigate(-1);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Relación Productos - Estamperias
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={storeRelacionEstamperiasProductos}>
							<Row className='mb-3 g-3'>
								{/* BUSCADOR PRODUCTOS */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={8}
									lg={8}
									controlId='formGridProductosCode'
								>
									<Form.Label className='fs--2'>
										Buscar código de producto
									</Form.Label>
									<SearchAutocomplete
										setSelect={setProductos}
										handleSearchProds={handleSearchProds}
										modelSearch={"product"}
										productosSeleccionados={productosSeleccionados}
									/>
									<Stack
										direction='horizontal'
										gap={3}
										className='d-flex'
										style={{ flexWrap: "wrap" }}
									>
										{productosSeleccionados?.length > 0
											? productosSeleccionados.map((pr) => {
													return (
														<div key={pr.id}>
															<SoftBadge className='fs--2' variant='secondary'>
																{pr.code} - {pr.description}
															</SoftBadge>{" "}
															<span
																style={{ cursor: "pointer" }}
																onClick={() => handleProd(pr.id)}
															>
																x
															</span>
														</div>
													);
											  })
											: ""}
									</Stack>
								</Form.Group>

								{/* SELECCIONAR RUBRO */}
								{/* <Form.Group
									as={Col}
									sm={12}
									md={4}
									lg={4}
									controlId='formGridSelectRubro'
								>
									<Form.Label className='fs--2'>Rubro(ubicación):</Form.Label>
									<Form.Select
										defaultValue={rubro}
										size='sm'
										onChange={(e) => setRubro(Number(e.target.value))}
									>
										<option value={0}>-- Todos --</option>
										{rubroData.map((item) => {
											return (
												<option key={item.id} value={item.id}>
													{item.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group> */}
							</Row>

							<Divider />

							<Row className='mb-3 g-3'>
								{input}

								<Col xs={12} sm={12} md={12} lg={12}>
									<Button
										onClick={agregarRowUbicaciones}
										className='float-end fs--1'
									>
										<FontAwesomeIcon icon='plus' /> Agregar ubicación
									</Button>
								</Col>
							</Row>

							<Divider />

							<Row>
								{/* OBSERVACIONES */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridObservaciones'
								>
									<FloatingLabel
										controlId='floatingTextarea'
										label='Breve descripción'
										className='mb-3'
									>
										<Form.Control
											as='textarea'
											placeholder='Ingrese una breve descripción'
											value={obs}
											onChange={(e) => setObs(e.target.value)}
										/>
									</FloatingLabel>
								</Form.Group>
							</Row>

							<Row>
								<Col>
									<Button
										className='float-start'
										variant='warning'
										onClick={volver}
									>
										Volver
									</Button>
								</Col>

								<Col>
									<Button className='float-end' variant='primary' type='submit'>
										Guardar
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

export default EstamperiaProductForm;
