import { useMemo } from "react";

export default function useColumnsProductos() {
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: '#'
      },
      {
        accessor: 'code',
        Header: 'Código'
      }
      ,
      {
        accessor: 'description',
        Header: 'Descripción'
      },
      {
      accessor: 'usability',
      Header: 'Código para'
      },
      {
      accessor: 'status',
      Header: 'Estado'
      },
      {
      accessor: 'acciones',
      Header: 'Acciones'
      }
    ],
    []
  );

  return columns;
}
