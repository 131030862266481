/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Tab, Badge, Accordion } from "react-bootstrap";
import { camelize } from "../../../helpers/utils";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	// Attribute
	const [description, setDescription] = useState("");
	const [permanent, setPermanent] = useState("");
	const [orden, setOrden] = useState(0);
	const [torelate, setTorelate] = useState(0);
	const [idatributo, setIdatributo] = useState(null);
	const [fechaalta, setFechaalta] = useState("");
	const [fechamodificacion, setFechamodificacion] = useState("");
	const { id } = useParams();

	// Fields
	const [fields, setFields] = useState([]);

	useEffect(() => {
		getAttribute();
	}, []);

	const getAttribute = async () => {
		const response = await axiosWithToken.get(
			`/attribute/withFieldsAndValues/${id}`
		);

		setIdatributo(response.data.data.attibute.id);
		setDescription(response.data.data.attibute.description);
		setPermanent(response.data.data.attibute.permanent);
		setOrden(response.data.data.attibute.technical_order);
		setOrden(response.data.data.attibute.technical_order);
		setTorelate(response.data.data.attibute.torelate);
		setFechaalta(
			dayjs(response.data.data.attibute.created_at).format(
				"DD/MM/YYYY HH:mm:ss"
			)
		);
		setFechamodificacion(
			dayjs(response.data.data.attibute.update_at).format("DD/MM/YYYY HH:mm:ss")
		);
		if (response.data.data.fields) {
			setFields(Object.values(response.data.data.fields));
		} else {
			setFields([]);
		}
	};
	return (
		<>
			{/* VIEW */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Vista de atributos: <Badge bg='secondary'># {idatributo}</Badge>{" "}
								<Badge bg='secondary'>{description}</Badge>{" "}
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={4} md={4}>
								<Card className='mb-2'>
									<Card.Body className='position-relative'>
										<Row>
											<h6 className='mb-4 text-center'>Datos del atributo</h6>

											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Descripción: </h6>
												<p className='fs--1'>
													{description} <Badge bg='secondary'>Atributo</Badge>
												</p>
											</Col>

											<Col className='mb-3' xs={12} sm={12} lg={6} md={6}>
												<h6>Atributo fijo: </h6>
												<p className='fs--1'>
													{permanent ? (
														<Badge className='rounded-pill' bg='success'>
															<FontAwesomeIcon icon='check' className='fs--2' />
														</Badge>
													) : (
														<Badge className='rounded-pill' bg='danger'>
															<FontAwesomeIcon icon='ban' className='fs--2' />
														</Badge>
													)}
												</p>
											</Col>

											<Col className='mb-3' xs={12} sm={12} lg={6} md={6}>
												<h6>Orden: </h6>
												<p className='fs--1'>
													<Badge className='rounded-pill' bg='primary'>
														{orden}
													</Badge>
												</p>
											</Col>

											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Permite a sus valores relacionarse: </h6>
												<p className='fs--1'>
													{torelate ? (
														<Badge className='rounded-pill' bg='success'>
															<FontAwesomeIcon icon='check' className='fs--2' />
														</Badge>
													) : (
														<Badge className='rounded-pill' bg='danger'>
															<FontAwesomeIcon icon='ban' className='fs--2' />
														</Badge>
													)}
												</p>
											</Col>

											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Fecha de alta: </h6>
												<p className='fs--1'>{fechaalta}</p>
											</Col>

											<Col className='mb-3' xs={12} sm={12} lg={12} md={12}>
												<h6>Fecha de modificación: </h6>
												<p className='fs--1'>{fechamodificacion}</p>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
							<Col xs={12} sm={12} lg={8} md={8}>
								<Card className='mb-3 p-4 border-bottom border-200'>
									<h6 className='mb-4 text-center'>Campos del atributo</h6>

									{fields.map((field) => (
										<>
											<Accordion>
												<Accordion.Item eventKey={field.id}>
													<Accordion.Header
														style={{
															borderLeft: "4px solid #96bdf2",
															borderTopLeftRadius: "3px",
															borderBottomLeftRadius: "3px",
														}}
													>
														<h6 className='mb-1'>
															{field.description}{" "}
															<Badge bg='primary'>Campos</Badge>{" "}
														</h6>
													</Accordion.Header>
													<Accordion.Body>
														{field.values.length ? (
															<h6 className='mb-3 text-center'>
																Valores del campo atributo
															</h6>
														) : (
															""
														)}

														<Tab.Content>
															{field.values.length ? (
																field.values.map((value) => (
																	<Card
																		key={value.id}
																		className='mb-2 square border-start border-start-2 border-primary'
																	>
																		<span>
																			<Row>
																				<Col xs={12} sm={12} lg={8} md={8}>
																					<p className='m-1 p-1 fs--1'>
																						<strong>Código: </strong>{" "}
																						{value.code} &nbsp;&nbsp;{" "}
																						<strong>Valor: </strong>{" "}
																						{value.value}
																					</p>
																					<p className='m-1 p-1 fs--1'>
																						<strong>Descripción: </strong>{" "}
																						{value.description}
																					</p>
																				</Col>
																			</Row>
																		</span>
																	</Card>
																))
															) : (
																<h6 className='text-center'>
																	¡No tiene valores cargados!
																</h6>
															)}
														</Tab.Content>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</>
									))}
								</Card>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
