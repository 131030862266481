import { useMemo } from "react";

export default function useColumnsCombinations() {
  const columns = useMemo(
    () => [
        {
        accessor: 'id',
        Header: '#'
        },
        {
        accessor: 'description',
        Header: 'Descripción'
        },
        {
        accessor: 'created_at',
        Header: 'Fecha de alta'
        },
        {
          accessor: 'status',
          Header: 'Estado'
        },
        {
        accessor: 'acciones',
        Header: 'Acciones'
        }
    ],
    []
  );

  return columns;
}
