/** @format */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	Row,
	Col,
	Card,
	Tab,
	Button,
	Badge,
	Stack,
	Tabs,
} from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import OperationForm from "./OperationForm";
import Spinner from "components/spinner/Spinner";
import { getOperation } from "data/operations/operations";

const OperationView = () => {
	const [loading, setLoading] = useState(true);
	// const [editionMode, setEditionMode] = useState(false);
	const [errorExist, setErrorExist] = useState({});
	const [operation, setOperation] = useState({});

	const { id } = useParams();

	const navigate = useNavigate();

	useEffect(async () => {
		try {
			const operation = await getOperation(id);
			setOperation(operation);
		} catch (error) {
			setErrorExist({
				error: true,
				message: `No existe operación con el id ${id}`,
			});
		}

		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	const volver = () => {
		navigate("/operations");
	};

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<>
					{errorExist.error ? (
						<>
							<h6 className='text-center'>{errorExist.message}</h6>
							<div className='text-center'>
								<Button variant='warning' onClick={() => volver()}>
									Volver
								</Button>
							</div>
						</>
					) : (
						<>
							<Card className='mb-3'>
								<Card.Header className='p-2'>
									<Row className='align-items-baseline g-2'>
										<Col className='d-flex justify-content-center mt-0'>
											<h6 className='mb-0 mt-3 hover-actions-trigger'>
												Operación:{" "}
												<Badge bg='secondary'># {operation.id}</Badge>{" "}
												<Badge bg='secondary'>{operation.denomination}</Badge>
											</h6>
										</Col>
									</Row>
								</Card.Header>

								<Card.Body className='bg-light'>
									<Tab.Content>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Tabs
													defaultActiveKey='general'
													id='tabs-operationView'
												>
													<Tab eventKey='general' title='1 - Datos'>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<h6 className='mb-3 fs-0'>
																			Información general
																		</h6>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Nombre:</b>{" "}
																			{operation.denomination}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Tipo:</b>{" "}
																			{operation.op_type}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Control de seguimineto:
																			</b>{" "}
																			{operation.monitoring_control === 0
																				? "NO"
																				: "SI"}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Tiempo de ejecución:
																			</b>{" "}
																			{operation.time}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Breve descripción:
																			</b>{" "}
																			{operation.description}
																		</p>
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Tab>

													<Tab eventKey='relations' title='2 - Relaciones'>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		md={12}
																		lg={12}
																	>
																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Familia de productos:
																			</b>{" "}
																			{operation.relation_op_attr !=
																				undefined &&
																			operation.relation_op_attr.length > 0
																				? operation.relation_op_attr.map(
																						(family) => {
																							return (
																								<span key={family.id}>
																									{family.name},
																								</span>
																							);
																						}
																				  )
																				: "-"}
																		</p>
																	</Col>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		md={12}
																		lg={12}
																	>
																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Centros:</b>{" "}
																			{operation.centers != undefined &&
																			operation.centers.length > 0
																				? operation.centers.map((center) => {
																						return (
																							<span
																								key={center.id}
																								className='fs--0 text-600'
																							>
																								{center.description},
																							</span>
																						);
																				  })
																				: ""}
																		</p>
																	</Col>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		md={12}
																		lg={12}
																	>
																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Productos:
																			</b>{" "}
																			{operation.relation_op_product !=
																				undefined &&
																			operation.relation_op_product.length > 0
																				? operation.relation_op_product.map(
																						(prod) => {
																							return (
																								<span
																									key={prod.id}
																									className='fs--0 text-600'
																								>
																									{prod.code} -{" "}
																									{prod.description},
																								</span>
																							);
																						}
																				  )
																				: "-"}
																		</p>
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Tab>
												</Tabs>
											</Col>

											{/*
											//TODO armar formulario de edicion de operaciones
 
											<Col lg={8} md={8} sm={12} xs={12}>
												{editionMode && <OperationForm operation={operation} />}
											</Col> */}

											<Col className='mt-2'>
												<Stack
													className='d-flex justify-content-end'
													direction='horizontal'
													gap={2}
												>
													{/*//TODO armar formulario de edicion de operaciones
														<Button
															onClick={() => setEditionMode(!editionMode)}
															variant='warning'
															size='sm'
															className='border-0 float-end'
														>
															<FontAwesomeIcon icon='edit' />{" "}
															{!editionMode ? "EDITAR" : "X"}
														</Button> */}
													<Button
														className='border'
														variant='transparent'
														onClick={() => volver()}
													>
														Volver
													</Button>
												</Stack>
											</Col>
										</Row>
									</Tab.Content>
								</Card.Body>
							</Card>
						</>
					)}
				</>
			)}
		</>
	);
};

export default OperationView;
