/** @format */

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import AppContext from "context/Context";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import Error from "components/error/Error";
import { year } from "data/calendar/year";
import { getTemporadas } from "data/calendar/temporadas";
import { tipoEventosDataObj } from "./data/tipoEventos";
import { formValidations } from "data/calendar/formValidations";

registerLocale("es", es);
setDefaultLocale("es");

const AddScheduleModal = ({
	setIsOpenScheduleModal,
	isOpenScheduleModal,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	handleOverlap,
	handleSaveCalendar,
}) => {
	const {
		config: { isDark },
	} = useContext(AppContext);

	const [formData, setFormData] = useState({});
	const [error, setError] = useState({ status: false, message: "" });
	const [temporadas, setTemporadas] = useState([]);
	const [tipoEventos, setTipoEventos] = useState([]);
	// const [name, setName] = useState("");
	// const [eventYear, setEventYear] = useState(0);
	// const [temporada, setTemporada] = useState(0);
	// const [tipoEvento, setTipoEvento] = useState(0);
	// const [description, setDescription] = useState("");

	useEffect(async () => {
		const temp = await getTemporadas();
		setTemporadas(temp);
		//seteamos los tipos de eventos
		const tipoEventosArrayTransform = Object.values(tipoEventosDataObj);
		setTipoEventos(tipoEventosArrayTransform);
	}, []);

	useEffect(() => {
		if (isOpenScheduleModal) {
			setFormData({
				...formData,
				start: startDate,
				end: endDate,
			});
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	}, [isOpenScheduleModal, startDate, endDate]);

	const handleClose = () => {
		setError({ status: false, message: "" });
		setIsOpenScheduleModal(!isOpenScheduleModal);
	};

	const handleChange = ({ target }) => {
		let name = target.name;
		let value = name === "allDay" ? target.checked : target.value;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setFormData({
			...formData,
			title: formData.title,
			start: startDate,
			end: endDate,
			year: formData.year,
			temporada: formData.temporada,
			tipoEvento: formData.tipoEvento,
			description: formData.description,
			allDay: true,
		});

		const enventData = {
			title: formData.title,
			start: startDate,
			end: endDate,
			year: formData.year,
			temporada: formData.temporada,
			tipoEvento: formData.tipoEvento,
			description: formData.description,
			allDay: true,
		};

		//validamos si hay errores
		const errores = await formValidations(enventData, handleOverlap);

		if (errores.status) {
			setError(errores);
			return;
		}

		setError({ status: false, message: "" });
		// setInitialEvents([...initialEvents, { id: uuid(), ...formData }]);
		handleSaveCalendar([{ id: uuid(), ...formData }]);
		setIsOpenScheduleModal(false);
		handleClose();
	};

	return (
		<Modal
			show={isOpenScheduleModal}
			onHide={handleClose}
			contentClassName='border'
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? "white" : undefined}
					className='bg-light px-card border-bottom-0'
				>
					<Modal.Title as='h5'> Crear Evento</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-card'>
					<Error status={error.status} message={error.message} />
					<Row>
						<Form.Group
							className='mb-3'
							as={Col}
							xs={12}
							sm={12}
							md={12}
							lg={12}
							controlId='titleInput'
						>
							<Form.Label className='fs-0'>Titulo</Form.Label>
							<Form.Control
								size='md'
								type='text'
								name='title'
								required
								onChange={handleChange}
							/>
						</Form.Group>
						<Form.Group
							className='mb-3'
							as={Col}
							xs={12}
							sm={12}
							md={6}
							lg={6}
							controlId='startDate'
						>
							<Form.Label className='fs-0'>Fecha inicio</Form.Label>
							<DatePicker
								selected={startDate}
								onChange={(date) => {
									setStartDate(date);
									setFormData({ ...formData, start: date });
								}}
								className='form-control'
								placeholderText='DD-MM-YYYY'
								dateFormat='dd-MM-yyyy'
								locale='es'
								// showTimeSelect
							/>
						</Form.Group>
						<Form.Group
							className='mb-3'
							as={Col}
							xs={12}
							sm={12}
							md={6}
							lg={6}
							controlId='endDate'
						>
							<Form.Label className='fs-0'>Fecha fin</Form.Label>
							<DatePicker
								selected={endDate}
								onChange={(date) => {
									setEndDate(date);
									setFormData({ ...formData, end: date });
								}}
								className='form-control'
								placeholderText='DD-MM-YYYY'
								dateFormat='dd-MM-yyyy'
								// showTimeSelect
							/>
						</Form.Group>
						{/* <Form.Group controlId='allDay'>
							<Form.Check
								type='checkbox'
								id='allDay'
								label='All Day'
								name='allDay'
								onChange={handleChange}
							/>
						</Form.Group> */}
						{/* <Form.Group className='mb-3'>
							<Form.Label className='fs-0'>Schedule Meeting</Form.Label>
							<div>
								<Button
									as={Link}
									variant='link'
									to='#!'
									// type="button"
									className='badge-soft-success fw-medium'
									size='sm'
								>
									<FontAwesomeIcon icon='video' className='me-2' />
									<span>Add video conference link</span>
								</Button>
							</div>
						</Form.Group> */}

						<Form.Group
							className='mb-3'
							controlId='year'
							as={Col}
							xs={12}
							sm={12}
							md={6}
							lg={6}
						>
							<Form.Label className='fs-0'>Año</Form.Label>
							<Form.Select
								name='year'
								onChange={handleChange}
								defaultValue={0}
								required
							>
								<option value={"0"} disabled>
									-- Seleccione año --
								</option>
								<option value={year()}>{year()}</option>
								<option value={year() + 1}>{year() + 1}</option>
							</Form.Select>
						</Form.Group>

						<Form.Group
							className='mb-3'
							controlId='temporada'
							as={Col}
							xs={12}
							sm={12}
							md={6}
							lg={6}
						>
							<Form.Label className='fs-0'>Temporada</Form.Label>
							<Form.Select
								name='temporada'
								onChange={handleChange}
								defaultValue={0}
								required
							>
								<option value={0} disabled>
									-- Seleccione temporada --
								</option>
								{temporadas.map((temporada) => {
									return (
										<option key={temporada.id} value={temporada.id}>
											{temporada.name}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>

						<Form.Group
							className='mb-3'
							controlId='tipoEvento'
							as={Col}
							xs={12}
							sm={12}
							md={12}
							lg={12}
						>
							<Form.Label className='fs-0'>Tipo de evento</Form.Label>
							<Form.Select
								name='tipoEvento'
								onChange={handleChange}
								defaultValue={0}
								required
							>
								<option value={"0"} disabled>
									-- Seleccione tipo de evento --
								</option>
								{tipoEventos.map((tipoEvento) => {
									return (
										<option key={tipoEvento.id} value={tipoEvento.name}>
											{tipoEvento.name}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>

						<Form.Group
							className='mb-3'
							controlId='description'
							as={Col}
							xs={12}
							sm={12}
							md={12}
							lg={12}
						>
							<Form.Label className='fs-0'>Description</Form.Label>
							<Form.Control
								size='sm'
								as='textarea'
								rows={3}
								name='description'
								defaultValue={""}
								onChange={handleChange}
							/>
						</Form.Group>
					</Row>
				</Modal.Body>
				<Modal.Footer className='bg-light px-card border-top-0'>
					<Button
						variant='danger'
						type='button'
						onClick={handleClose}
						className='px-4 mx-1'
					>
						Cerrar
					</Button>
					<Button variant='primary' type='submit' className='px-4 mx-0'>
						Guardar
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

AddScheduleModal.propTypes = {
	setIsOpenScheduleModal: PropTypes.func.isRequired,
	isOpenScheduleModal: PropTypes.bool.isRequired,
	setInitialEvents: PropTypes.func,
	initialEvents: PropTypes.array,
	startDate: PropTypes.instanceOf(Date),
	endDate: PropTypes.instanceOf(Date),
	setStartDate: PropTypes.func.isRequired,
	setEndDate: PropTypes.func.isRequired,
	handleOverlap: PropTypes.func,
	handleSaveCalendar: PropTypes.func,
};

export default AddScheduleModal;
