/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isIterableArray } from "helpers/utils";
import SoftBadge from "components/common/SoftBadge";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";

const SearchBox = ({
	setSelect,
	modelSearch,
	disabled,
	keyId = null,
	handleSearchMP = null,
	mpSeleccionada = null,
	productosSeleccionados = null,
	handleSearchProds = null,
	estampasSeleccionadas = null,
	handleSearchEstamp = null,
}) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [searchInputValue, setSearchInputValue] = useState("");
	const [resultItem, setResultItem] = useState([]);

	const toggle = () => setDropdownOpen(!dropdownOpen);

	const selectValue = (item) => {
		setSelect(item);
		setDropdownOpen(false);
		setSearchInputValue("");
		//Si estamos en ABM de "Estampa", llamamos a esta funcion para acumular las opciones seleccionadas
		if (handleSearchMP != null) {
			handleSearchMP(keyId, item);
		}
		//Si estamos en alta de Relacion entre producto y estampa -> Relacion Productos
		if (handleSearchProds != null) {
			handleSearchProds(item);
		}
		//Si estamos en alta de Relacion entre producto y estampa -> Relacion Estampas
		if (handleSearchEstamp != null) {
			handleSearchEstamp(keyId, item);
		}
	};

	const modelSearchType = {
		rawmaterial: "name",
		productsCodes: "description",
		rawMaterialCodes: "description",
		molds: "description",
		roadmap: "denominacion",
		estampas: "nombre",
		product: "name",
		productPs: "name",
		families: [],
	};

	useEffect(() => {
		const searchFunction = async (search) => {
			if (search.length >= 3) {
				switch (modelSearch) {
					case "rawmaterial":
						try {
							const { data } = await axiosWithToken.get(
								`/rawmaterial/search/${searchInputValue}`
							);

							const modifiedResult = data.data.map((element) => {
								const codefirst = element.code.slice(0, 3);
								let treadString = null;

								if (codefirst === "002") {
									if (element.thread !== null && element.thread == 1) {
										treadString = "H. arriba";
									} else if (element.thread !== null && element.thread == 0) {
										treadString = "H. abajo";
									}
								}

								return {
									...element,
									treadString: treadString,
								};
							});

							//si mpSeleccionada es distinto de nulo significa que estoy en el ABM de Estamperia
							if (mpSeleccionada != null) {
								//Filtramos las mp seleccionadas desde estamperia vs las que vienen de la db.
								//Guardamos las opciones que no se encuentren seleccionadas en nuestro array(mpSeleccionada)
								const newOptions = data.data.filter(
									(res) => !mpSeleccionada.some(({ id }) => res.id === id)
								);
								setResultItem(newOptions);
							} else {
								setResultItem(modifiedResult);
							}

							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "productsCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/codes/search/${searchInputValue}/type/${1}`
							);
							const resultado = data.data;

							//si productosSeleccionados es distinto de nulo significa que estoy en el form de relacion de producto con estampa
							if (productosSeleccionados != null) {
								//Filtramos los productos seleccionados desde productos-estampas vs las que vienen de la db.
								//Guardamos las opciones que no se encuentren seleccionadas en nuestro array(productosSeleccionados)
								const newOptions = resultado.filter(
									(res) =>
										!productosSeleccionados.some(({ id }) => res.id === id)
								);
								setResultItem(newOptions);
							} else {
								setResultItem(resultado);
							}

							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "rawMaterialCodes":
						try {
							const { data } = await axiosWithToken.get(
								`/codes/search/${searchInputValue}/type/${0}`
							);

							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "molds":
						try {
							const { data } = await axiosWithToken.get(
								`/molds/search/${searchInputValue}`
							);

							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "roadmap":
						try {
							const { data } = await axiosWithToken.get(
								`/hoja-ruta/search/${searchInputValue}`
							);

							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "estampas":
						try {
							const { data } = await axiosWithToken.get(
								`/estamperia/id-name/${searchInputValue}`
							);

							const resultado = data.data;

							//si estampasSeleccionadas es distinto de nulo significa que estoy en el form de relacion de producto con estampa
							if (estampasSeleccionadas != null) {
								//Filtramos los productos seleccionados desde productos-estampas vs las que vienen de la db.
								//Guardamos las opciones que no se encuentren seleccionadas en nuestro array(estampasSeleccionadas)
								const newOptions = resultado.filter(
									(res) =>
										!estampasSeleccionadas.some(({ id }) => res.id === id)
								);
								setResultItem(newOptions);
							} else {
								setResultItem(resultado);
							}

							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "product":
						try {
							const { data } = await axiosWithToken.get(
								`/product/search/${searchInputValue}`
							);

							const resultado = data.data;

							//si mpSeleccionada es distinto de nulo significa que estoy en el ABM de Estamperia
							if (mpSeleccionada != null) {
								//Filtramos las mp seleccionadas desde estamperia vs las que vienen de la db.
								//Guardamos las opciones que no se encuentren seleccionadas en nuestro array(mpSeleccionada)
								const newOptions = resultado.filter(
									(res) => !mpSeleccionada.some(({ id }) => res.id === id)
								);
								setResultItem(newOptions);
							} else {
								setResultItem(resultado);
							}

							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "productPs":
						try {
							const { data } = await axiosWithToken.get(
								`/products/semis/search/${searchInputValue}`
							);

							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
					case "families":
						try {
							const { data } = await axiosWithToken.get(
								`/productfamilies/search/${searchInputValue}`
							);

							setResultItem(data.data);
							setDropdownOpen(true);
						} catch (error) {
							setResultItem([]);
						}
						break;
				}
			} else if (
				search.length > 0 &&
				search.length < 3 &&
				handleSearchEstamp != null
			) {
				try {
					const { data } = await axiosWithToken.get(
						`/estamperia/id-name/${searchInputValue}`
					);
					const resultado = data.data;

					//si estampasSeleccionadas es distinto de nulo significa que estoy en el form de relacion de producto con estampa
					if (estampasSeleccionadas != null) {
						//Filtramos los productos seleccionados desde productos-estampas vs las que vienen de la db.
						//Guardamos las opciones que no se encuentren seleccionadas en nuestro array(estampasSeleccionadas)
						const newOptions = resultado.filter(
							(res) => !estampasSeleccionadas.some(({ id }) => res.id === id)
						);
						setResultItem(newOptions);
					} else {
						setResultItem(resultado);
					}

					setDropdownOpen(true);
				} catch (error) {
					setResultItem([]);
				}
			} else {
				setResultItem([]);
			}
		};

		searchFunction(searchInputValue);
	}, [searchInputValue]);

	return (
		<Dropdown onToggle={toggle} className='search-box'>
			<Dropdown.Toggle
				as='div'
				data-toggle='dropdown'
				aria-expanded={dropdownOpen}
				bsPrefix='toggle'
			>
				<div className='position-relative'>
					<Form.Control
						type='search'
						placeholder='Buscar...'
						aria-label='Search'
						className='search-input'
						size='sm'
						autoComplete='off'
						disabled={disabled}
						value={searchInputValue}
						onChange={({ target }) => setSearchInputValue(target.value)}
						onClick={() => setDropdownOpen(true)}
					/>
					<FontAwesomeIcon
						icon='search'
						className='position-absolute text-400 search-box-icon fs--2'
					/>
				</div>
			</Dropdown.Toggle>

			{!disabled && (
				<Dropdown.Menu style={{ width: "auto" }}>
					<div className='scrollbar py-1' style={{ maxHeight: "20rem" }}>
						{isIterableArray(resultItem) && (
							<>
								{resultItem.map((item) => (
									<Dropdown.Item
										className='fs--2 py-1 hover-primary '
										key={item.id}
									>
										<div onClick={() => selectValue(item)}>
											<SoftBadge
												bg='primary'
												className='fw-medium text-decoration-none me-2 fs--2'
											>
												{modelSearch == "roadmap"
													? item.codigo
													: modelSearch == "estampas" ||
													  modelSearch == "families"
													? item.id
													: modelSearch != "productPs"
													? item.code
													: "Semi"}{" "}
												{""}
											</SoftBadge>
											<span className='flex-1 fs--2'>
												{" "}
												{modelSearch === "families"
													? item.parent_id != 0
														? `${item.parent.name} > ${item.name}`
														: `${item.name}`
													: item[modelSearchType[modelSearch]]}
											</span>

											{item.treadString !== null &&
												item.treadString !== false && (
													<>
														{" "}
														<SoftBadge
															bg='secondary'
															className='fw-medium text-decoration-none me-2 fs--3'
														>
															{item.treadString}
														</SoftBadge>
													</>
												)}
										</div>
									</Dropdown.Item>
								))}
							</>
						)}

						{resultItem.length === 0 && (
							<p className='fs--2 fw-bold text-center mb-0'>Sin resultado.</p>
						)}
					</div>
				</Dropdown.Menu>
			)}
		</Dropdown>
	);
};

SearchBox.propTypes = {
	setSelect: PropTypes.func,
	modelSearch: PropTypes.string,
	disabled: PropTypes.bool,
	keyId: PropTypes.number,
	handleSearchMP: PropTypes.func,
	mpSeleccionada: PropTypes.array,
	productosSeleccionados: PropTypes.array,
	handleSearchProds: PropTypes.func,
	estampasSeleccionadas: PropTypes.array,
	handleSearchEstamp: PropTypes.func,
};

export default SearchBox;
