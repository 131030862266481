/** @format */

import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const OperationsContext = createContext();

const OperationsProvider = ({ children }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [operations, setOperations] = useState([]);
	const [centers, setCenters] = useState([]);
	const [error, setError] = useState({});
	const [operationsByProdCod, setOperationsByProdCod] = useState([]);

	useEffect(() => {
		setOperations([]);
		setCenters([]);
	}, []);

	//Delete Operation by id
	const deleteOperation = async (id) => {
		try {
			await axiosWithToken.delete(`/operations/delete/${id}`);
			setError({});
			return true;
		} catch (error) {
			setError(error.response.data);
			return false;
		}
	};

	//Get all centers
	const getCenters = async () => {
		const { data } = await axiosWithToken.get(
			"/centros-de-trabajo/listado-centros"
		);
		setCenters(data.data);
	};

	//Get operations to filter Product Code's operations
	const getOperationsByProdCod = async () => {
		try {
			const { data } = await axiosWithToken.get("/operations");
			setOperationsByProdCod(data.data);
		} catch (error) {
			console.log(error.request.data.message);
		}
	};

	return (
		<OperationsContext.Provider
			value={{
				operations,
				deleteOperation,
				centers,
				getCenters,
				error,
				operationsByProdCod,
				getOperationsByProdCod,
			}}
		>
			{children}
		</OperationsContext.Provider>
	);
};

OperationsProvider.propTypes = {
	children: PropTypes.any,
};

export { OperationsProvider };
export default OperationsContext;
