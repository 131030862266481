/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Tab } from "react-bootstrap";
import Divider from "components/common/Divider";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { id } = useParams();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [subfamilies, setSubfamilies] = useState([]);

	useEffect(() => {
		const getFamilyById = async () => {
			const { data } = await axiosWithToken.get(`/rawmaterialfamily/${id}`);
			setName(data.data.name);
			setDescription(data.data.description);
			setSubfamilies(data.data.children);
		};

		getFamilyById();
	}, []);

	return (
		<>
			{/* VIEW */}
			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5 className='mb-0 hover-actions-trigger'>
								Vista de familia de productos
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<h5 className='mb-0 hover-actions-trigger'>
									Familia: <span>{name}</span>
								</h5>

								<p className='mt-3 mb-2 fs--1'>
									<strong className='fs--0 text-600'>Nombre: </strong> {name}{" "}
								</p>

								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>Descripción: </strong>{" "}
									{description}
								</p>
							</Col>

							<Col xs={12} sm={12} lg={12} md={12}>
								{subfamilies.length && (
									<>
										<Divider />
										<Card className='mb-3'>
											<Card.Body>
												<Tab.Content>
													<Row>
														<h5 className='mb-0 hover-actions-trigger'>
															Sub familias
														</h5>
														<Col xs={12} sm={12} lg={12} md={12}>
															{subfamilies && (
																<Row className='mt-3'>
																	<Col xs={12} sm={12} lg={12} md={12}>
																		<Tab.Content>
																			{subfamilies.map((fm) => (
																				<Card
																					key={fm.id}
																					className='mb-2 square border-start border-start-2 border-primary'
																				>
																					<Row>
																						<Col
																							xs={12}
																							sm={12}
																							lg={12}
																							md={12}
																						>
																							<p className='m-1 p-1 fs--1'>
																								<span className='me-4'>
																									<strong>Nombre: </strong>
																									{fm.name}
																								</span>
																								<span className='me-4'>
																									<strong>Descripción: </strong>
																									{fm.description}
																								</span>
																							</p>
																						</Col>
																					</Row>
																				</Card>
																			))}
																		</Tab.Content>
																	</Col>
																</Row>
															)}
														</Col>
													</Row>
												</Tab.Content>
											</Card.Body>
										</Card>
									</>
								)}
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
