/** @format */

import React from "react";
import { Row, Col } from "react-bootstrap";
import {
	totalOrder,
	totalSales,
	weeklySalesData,
	runningProjects,
	weeklyReport,
} from "data/dashboard/default";

import TotalOrder from "./TotalOrder";
import TotalSales from "./TotalSales";
import RunningProjects from "./RunningProjects";
import WeeklySales from "./WeeklySales";
import Greetings from "./Greetings";
import ReportForThisWeek from "./ReportForThisWeek";

const Dashboard = () => {
	return (
		<>
			<Greetings />
			<Row className='g-3 mb-3'>
				<Col md={6} xxl={3}>
					<WeeklySales data={weeklySalesData} />
				</Col>
				<Col md={6} xxl={3}>
					<TotalOrder data={totalOrder} />
				</Col>
			</Row>

			<Row className='g-3 mb-3'>
				<Col xxl={6} lg={12}>
					<Row>
						<Col lg={12}>
							<ReportForThisWeek data={weeklyReport} />
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className='g-3 mb-3'>
				<Col lg={6}>
					<RunningProjects data={runningProjects} />
				</Col>
				<Col lg={6}>
					<TotalSales data={totalSales} />
				</Col>
			</Row>
		</>
	);
};

export default Dashboard;
