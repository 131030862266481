/** @format */

import React, { useEffect, useState } from "react";

//Props
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import Select from "react-select";

//Hooks + helpers
import { getHojaRuta } from "data/hojaRuta/hojaRuta";
import { camelize } from "../../helpers/utils";
import {
	getOperations,
	getOperationsByFamilyInOperationsArray,
	getOperationsByProductCode,
} from "data/operations/operations";
import { getFamilyParents } from "data/operations/familia";
import { getProductCodesByFamily } from "data/operations/productCodes";

//Components
import DraggableHR from "./DraggableHR";
import Divider from "components/common/Divider";
import { ToastContainer, toast } from "react-toastify";

//Styles
import { Form, Row, Col, Button, Card, FloatingLabel } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const customInputStyle = {
	height: "25px",
	fontSize: "0.85rem",
	lineHeight: "0",
};

const HojaRutaForm = ({ ruta_id = false, handleClose }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [codigo, setCodigo] = useState("");
	const [denominacion, setDenominacion] = useState("");
	const [descripcion, setDescripcion] = useState("");
	const [tipo, setTipo] = useState("");
	const [desde, setDesde] = useState("");
	const [opEstandar, setOpEstandar] = useState(0);
	const [operaciones, setOperaciones] = useState([]);
	const [operacionesApi, setOperacionesApi] = useState([]);
	const [selectedOperations, setSelectedOperations] = useState([]);
	const [hojaRutaToEdit, setHojaRutaToEdit] = useState({});
	const [productRelationChecked, setProductRelationChecked] = useState(0); // Producto SI/NO
	const [productRelations, setProductRelations] = useState({}); // Selected product
	const [familyRelations, setFamilyRelations] = useState([]); // Selected product's family
	const [familiesData, setFamiliesData] = useState([]); // All Product's Families
	const [productData, setProductData] = useState([]); // All products
	const [opsFromBack, setOpsFromBack] = useState([]); //Si estoy editando, mando las ops que vienen del back

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	useEffect(async () => {
		setCodigo("");
		setDenominacion("");
		setDescripcion("");
		setTipo("");
		setDesde("");
		setOpEstandar(0);
		//Tremos todos las familias de productos
		const families = await getFamilyParents();
		const familiesFormated = await families.map((family) => {
			const familyObj = {
				label: family.name,
				value: family.id,
				children: family.children,
			};

			return familyObj;
		});
		setFamiliesData(familiesFormated);

		//Traemos todas las operaciones
		const apiOperations = await getOperations();
		setOperacionesApi(apiOperations);

		//Editando ruta
		if (ruta_id) {
			const hojaRutaApi = await getHojaRuta(ruta_id);
			if (Object.values(hojaRutaApi).length > 0) {
				setHojaRutaToEdit(hojaRutaApi);
			}
		}
	}, []);

	useEffect(async () => {
		if (hojaRutaToEdit?.id) {
			await showHojaRutaData();
			setOpsFromBack(hojaRutaToEdit.operations);
		}
	}, [hojaRutaToEdit]);

	//Se ejecuta si selecciono relacionar con Productos
	useEffect(async () => {
		if (Number(productRelationChecked) === 1) {
			const productsByFamily = await getProductCodesByFamily(familyRelations);
			setProductData(productsByFamily);
		} else {
			setProductRelations(0);
		}
	}, [productRelationChecked]);

	useEffect(async () => {
		if (selectedOperations.length > 0) {
			const filteredOperations = await getOperationsByFamilyInOperationsArray(
				operacionesApi,
				familyRelations,
				selectedOperations
			);
			setOperaciones(filteredOperations);
		}
	}, [selectedOperations]);

	//Traigo y muestro los datos de la HDR a modificar
	const showHojaRutaData = async () => {
		setCodigo(hojaRutaToEdit.codigo);
		setDenominacion(hojaRutaToEdit.denominacion);
		setOpEstandar(hojaRutaToEdit.op_estandar);
		setTipo(hojaRutaToEdit.tipo);
		setDesde(hojaRutaToEdit.desde);
		setDescripcion(
			hojaRutaToEdit.descripcion != null ? hojaRutaToEdit.descripcion : ""
		);

		// ----- INICIO SETEO DE FAMILIAS DE PRODUCTOS SELECCIONADAS
		//Creo array para acumular familias seleccionadas
		const operationsFamiliesSelected = [];
		//Recorro operaciones para ver a que familia de producto pertenecen
		hojaRutaToEdit.operations.map((operation) => {
			//Aprovechamos que recorremos operaciones y nos fijamos si hay relacion con productos.
			//Si hay relacion con productos, seteamos el productRelationChecked en 1
			if (
				operation.relation_op_product.length > 0 &&
				Number(productRelationChecked) === 0
			) {
				setProductRelationChecked(1);
			}
			operation.relation_op_attr.forEach((relation) => {
				const relationExist = operationsFamiliesSelected.find(
					(family) => family.value === relation.id
				);

				if (!relationExist) {
					const findInFamiliesData = familiesData.find(
						(family) => family.value === relation.id
					);
					const obj = {
						label: relation.name,
						value: relation.id,
						children: findInFamiliesData.children,
					};
					operationsFamiliesSelected.push(obj);
				}
			});
		});

		handleSelectFamiliesDataChange(operationsFamiliesSelected);
		// ----- FIN SETEO DE FAMILIAS DE PRODUCTOS SELECCIONADAS
	};

	//Funcion para guardar la familia de producto que seleccionamos a relacionar
	const handleSelectFamiliesDataChange = async (value) => {
		setFamilyRelations(value);

		if (value.length < 1) {
			setProductRelationChecked(0);
			setSelectedOperations([]);
		}

		if (Number(productRelationChecked) === 1) {
			const productsByFamily = await getProductCodesByFamily(value);
			setProductData(productsByFamily);
		}

		const operations = await getOperationsByFamilyInOperationsArray(
			operacionesApi,
			value
		);

		//Quitamos las operaciones seleccionadas del array de operaciones
		if (selectedOperations.length > 0) {
			const filteredOperations = await getOperationsByFamilyInOperationsArray(
				operacionesApi,
				familyRelations,
				selectedOperations
			);
			return setOperaciones(filteredOperations);
		}

		setOperaciones(operations);
	};

	//Se ejecuta cuando selecciono un producto luego de elegir SI en la relación de HDR con Producto
	const handleSelectProductDataChange = async (value) => {
		setProductRelations(value);

		const operations = await getOperationsByFamilyInOperationsArray(
			operacionesApi,
			familyRelations
		);

		const productCodeOperations = await getOperationsByProductCode(
			operations,
			[],
			value
		);

		//Quitamos las operaciones seleccionadas del array de operaciones
		if (selectedOperations.length > 0) {
			const filteredOperations = await getOperationsByProductCode(
				operations,
				selectedOperations,
				value
			);
			return setOperaciones(filteredOperations);
		}

		setOperaciones(productCodeOperations);
	};

	const handleSelectChangeOperations = async (selectedOp) => {
		setSelectedOperations(selectedOp);
	};

	//AGREGAR HOJA DE RUTA
	const handleSubmit = async (event) => {
		event.preventDefault();
		if ([codigo, denominacion, descripcion].includes("")) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("¡Por favor complete todos los datos!", {
					theme: "colored",
					autoClose: 3000,
				});
			}
			return;
		}

		try {
			const respuesta = await axiosWithToken.post("/hoja-ruta/new", {
				codigo,
				denominacion,
				tipo,
				desde,
				opEstandar,
				descripcion,
				operations: selectedOperations,
			});

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}
			}

			setTimeout(() => {
				navigate(`/hoja-ruta/${respuesta.data.id}`);
			}, 1300);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(error.response.data.message, {
					theme: "colored",
					autoClose: 3000,
				});
			}
		}
	};

	//ACTUALIZAR HOJA DE RUTA
	const handleUpdate = async (event) => {
		event.preventDefault();
		if ([codigo, denominacion, descripcion].includes("")) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("¡Por favor complete todos los datos!", {
					theme: "colored",
					autoClose: 3000,
				});
			}
			return;
		}

		try {
			const respuesta = await axiosWithToken.put(
				`/hoja-ruta/update/${hojaRutaToEdit.id}`,
				{
					codigo,
					denominacion,
					tipo,
					desde,
					opEstandar,
					descripcion,
					operations: selectedOperations,
				}
			);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("Editado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}
			}

			setTimeout(() => {
				getHojaRuta(`${respuesta.data.id}`);
				navigate(`/hoja-ruta/${respuesta.data.id}`);
				handleClose();
			}, 1300);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(error.response.data.message, {
					theme: "colored",
					autoClose: 3000,
				});
			}
		}
	};

	const volver = () => {
		navigate(-1);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								{ruta_id ? "Editar " : "Agregar "}Hoja de Ruta
							</h5>
							<p className='mt-2 mb-0'>Ingresá los datos correspondientes.</p>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Form onSubmit={ruta_id ? handleUpdate : handleSubmit}>
						<Row>
							{/* CODIGO */}
							<Form.Group
								controlId='formCodigo'
								as={Col}
								xs={12}
								sm={12}
								md={6}
								lg={4}
							>
								<Form.Label>Código</Form.Label>
								<Form.Control
									type='text'
									placeholder='Ingrese el código'
									style={customInputStyle}
									value={codigo}
									onChange={(e) => setCodigo(e.target.value)}
								/>
							</Form.Group>

							{/* DENOMINACION */}
							<Form.Group
								controlId='formDenominacion'
								as={Col}
								xs={12}
								sm={12}
								md={6}
								lg={4}
							>
								<Form.Label>Denominación</Form.Label>
								<Form.Control
									type='text'
									placeholder='Ingrese nombre'
									style={customInputStyle}
									value={denominacion}
									onChange={(e) => setDenominacion(e.target.value)}
								/>
							</Form.Group>

							{/* OP.ESTANDAR */}
							<Form.Group
								controlId='formOpEstandar'
								as={Col}
								xs={12}
								sm={12}
								md={6}
								lg={4}
							>
								<Form.Label>Operación Estandar</Form.Label>
								<Form.Select
									size='sm'
									value={opEstandar}
									onChange={(e) => setOpEstandar(e.target.value)}
								>
									<option value={0}>No</option>
									<option value={1}>Si</option>
								</Form.Select>
							</Form.Group>

							{/* DESDE */}
							<Form.Group
								controlId='formDesde'
								as={Col}
								xs={12}
								sm={12}
								md={6}
								lg={6}
							>
								<Form.Label>Desde</Form.Label>
								<Form.Control
									size='sm'
									type='date'
									value={desde}
									onChange={(e) => setDesde(e.target.value)}
								/>
							</Form.Group>

							{/* TIPO */}
							<Form.Group
								controlId='formTipo'
								as={Col}
								xs={12}
								sm={12}
								md={6}
								lg={6}
							>
								<Form.Label>Tipo</Form.Label>
								<Form.Select
									size='sm'
									value={tipo}
									onChange={(e) => setTipo(e.target.value)}
								>
									<option value={"0"}>-- Seleccione un tipo --</option>
									<option value={"estandar"}>Estandar</option>
									<option value={"generica"}>Genérica</option>
								</Form.Select>
							</Form.Group>

							<Divider />

							{/* RELACION STEP 1 - FAMILIA DE PRODUCTOS */}

							<Form.Group
								as={Col}
								sm={12}
								md={6}
								lg={6}
								controlId='formGridSelectRelacionProdStep2'
							>
								<Col xs={12} sm={12} md={12} lg={12}>
									<Form.Label className='fs--1'>
										PASO 1 (relación Familia de productos)
									</Form.Label>
									<Select
										options={familiesData}
										value={familyRelations}
										isMulti
										onChange={handleSelectFamiliesDataChange}
										placeholder={"Seleccione Familia"}
									/>
								</Col>
							</Form.Group>

							{/* RELACION STEP 1 PRODUCTO*/}
							<Form.Group
								as={Col}
								sm={12}
								md={6}
								lg={6}
								controlId='formGridSelectRelacionProductStep1'
							>
								<Form.Label className='fs--1'>
									Relacionar con Productos:
								</Form.Label>
								<Form.Select
									size='sm'
									value={productRelationChecked}
									aria-label='Relation Product'
									onChange={(e) => {
										setProductRelationChecked(Number(e.target.value));
									}}
									disabled={familyRelations.length === 0}
								>
									<option value={0}>NO</option>
									<option value={1}>SI</option>
								</Form.Select>
							</Form.Group>

							{/* RELACION STEP 2 - Product */}

							{productRelationChecked != 0 && (
								<Form.Group
									as={Col}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridSelectRelacionProdStep2'
								>
									<div style={{ width: "95%", float: "right" }}>
										<Form.Label className='fs--2'>
											Seleccionar Productos
										</Form.Label>
										<Select
											options={productData}
											value={productRelations}
											onChange={handleSelectProductDataChange}
											placeholder={"Seleccione los productos"}
										/>
									</div>
								</Form.Group>
							)}

							<Divider />

							{/* DRAGGABLE */}
							{familyRelations.length ? (
								<DraggableHR
									oper={operaciones}
									handleSelectChangeOperations={handleSelectChangeOperations}
									opsFromBack={opsFromBack}
								/>
							) : (
								""
							)}

							{/* DESCRIPCION */}
							<Form.Group
								controlId='formDescripcion'
								as={Col}
								xs={12}
								sm={12}
								md={12}
								lg={12}
							>
								<FloatingLabel
									controlId='floatingTextarea'
									label='Descripción'
									className='mb-3'
								>
									<Form.Control
										as='textarea'
										placeholder='Ingrese una breve descripción'
										value={descripcion}
										onChange={(e) => setDescripcion(e.target.value)}
									/>
								</FloatingLabel>
							</Form.Group>
						</Row>

						<Divider />

						<Row>
							<Col>
								<Button
									variant='warning'
									onClick={() => (ruta_id ? handleClose() : volver())}
								>
									{ruta_id ? "Cerrar" : "Volver"}
								</Button>
							</Col>

							<Col>
								<Button className='float-end' variant='primary' type='submit'>
									{"Guardar"}
								</Button>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
		</>
	);
};

HojaRutaForm.propTypes = {
	ruta_id: PropTypes.number,
	handleClose: PropTypes.func,
};

export default HojaRutaForm;
