/** @format */

import { createContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const ProductContext = createContext();

const ProductProvider = ({ children }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//PRODUCT FAMILIES
	const [productFamilies, setProductFamilies] = useState([]);

	//PUBLICS
	const [publics, setPublics] = useState([]);

	//LINES
	const [lines, setLines] = useState([]);

	//SUBLINES
	const [sublines, setSublines] = useState([]);

	//MODEL DESCRIPTs
	const [modelDescripts, setModelDescripts] = useState([]);

	//DETMODPs
	const [detmodps, setDetmodps] = useState([]);

	//QUALITIES
	const [qualities, setQualities] = useState([]);

	//IRAM SIZES
	const [iramsizes, setIramsizes] = useState([]);

	//SETTINGS
	const [settings, setSettings] = useState({});

	//CODE
	const [codeContext, setCodeContext] = useState({});

	//PRODUCT
	const [product, setProduct] = useState({});

	//MOLD
	const [selectMold, setSelectMold] = useState({});

	//STRUCTURE
	const [structureContext, setStructureContext] = useState([]);

	//HDR
	const [selectRoadmap, setSelectRoadmap] = useState({});

	//SEMI
	const [selectSemi, setSelectSemi] = useState({});

	const cleanContext = () => {
		setCodeContext({});
		setProduct({});
		setSelectMold({});
		setStructureContext([]);
		setSelectRoadmap({});
		setSelectSemi({});
	};

	const getFamilies = async () => {
		try {
			const { data } = await axiosWithToken.get("/productfamilies");

			setProductFamilies(data.data);
		} catch (error) {
			setProductFamilies([]);
		}
	};

	const getPublic = async () => {
		try {
			const { data } = await axiosWithToken.get("/publics");

			setPublics(data.data);
		} catch (error) {
			setPublics([]);
		}
	};

	const getLines = async () => {
		try {
			const { data } = await axiosWithToken.get("/lines");

			setLines(data.data);
		} catch (error) {
			setLines([]);
		}
	};

	const getSublines = async () => {
		try {
			const { data } = await axiosWithToken.get("/sublines");

			setSublines(data.data);
		} catch (error) {
			setSublines([]);
		}
	};

	const getModeldescript = async () => {
		try {
			const { data } = await axiosWithToken.get("/modeldescripts");

			setModelDescripts(data.data);
		} catch (error) {
			setModelDescripts([]);
		}
	};

	const getDetmodp = async () => {
		try {
			const { data } = await axiosWithToken.get("/detmodps");

			setDetmodps(data.data);
		} catch (error) {
			setDetmodps([]);
		}
	};

	const getQualities = async () => {
		try {
			const { data } = await axiosWithToken.get("/qualities");

			setQualities(data.data);
		} catch (error) {
			setQualities([]);
		}
	};

	const getIramsize = async () => {
		try {
			const { data } = await axiosWithToken.get("/iramsizes");

			setIramsizes(data.data);
		} catch (error) {
			setIramsizes([]);
		}
	};

	const getSettings = async () => {
		try {
			const { data } = await axiosWithToken.get(
				"/setting/bycode/conversion_unit"
			);

			const newSettings = {};

			data.data.forEach((element) => {
				newSettings[element.key] = parseFloat(element.value);
			});

			setSettings(newSettings);
		} catch (error) {
			setSettings({});
		}
	};

	const handleCodeByProductId = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/product/getProductById/${id}`
			);

			if (data.data.code !== null) {
				const code = await axiosWithToken.get(`/code/${data.data.code}`);

				setCodeContext(code.data.data);
			} else {
				setCodeContext({});
			}
		} catch (error) {
			setCodeContext({});
		}
	};

	const handleProductById = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/product/getProductById/${id}`
			);

			setProduct(data.data);
		} catch (error) {
			setProduct({});
		}
	};

	const handleProductByCode = async (code) => {
		try {
			const { data } = await axiosWithToken.get(`/product/bycode/${code}`);

			setProduct(data.data);
			handleCodeByProductId(data.data.id);
		} catch (error) {
			setProduct({});
		}
	};

	const handleMoldByProductId = async (id) => {
		const { data } = await axiosWithToken.get(
			`/mold/getMoldsByProductId/${id}`
		);

		setSelectMold(data.data);
	};

	const handleStructureByProductId = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/product/structure/${id}`);

			const structureUpdate = data.data.structure_rawmaterials.map((str) => {
				const newStructure = {
					idkey: str.id,
					id: str.rawmaterial.id,
					description: str.rawmaterial.name,
					codeMP: str.rawmaterial.code,
					cantidad: str.quantity,
					oncantidad:
						str.rawmaterial.codeobject.materialcode.code == "001" ||
						str.rawmaterial.codeobject.materialcode.code == "002"
							? 0
							: 1,
					rinde: str.rawmaterial.rinde,
					orden: str.technical_order,
					unidadmp: str.unit_id,
					unidades: str.rawmaterial.units.map((unit) => {
						return {
							value: unit.id,
							label: unit.description,
						};
					}),
					tipohilo: str.rawmaterial.thread,
					tipomaquina: str.machinetype,
				};

				return newStructure;
			});

			setStructureContext(structureUpdate);
		} catch (error) {
			setStructureContext([]);
		}
	};

	const handleRoadmapByProductId = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/hoja-ruta/hdr-product/${id}`);

			setSelectRoadmap(data.data);
		} catch (error) {
			setSelectRoadmap({});
		}
	};

	const handleSemiByProductId = async (id) => {
		try {
			const { data } = await axiosWithToken.get(
				`/product/getSemifinished/${id}`
			);

			setSelectSemi(data.data);
		} catch (error) {
			setSelectSemi({});
		}
	};

	return (
		<ProductContext.Provider
			value={{
				productFamilies,
				getFamilies,
				publics,
				getPublic,
				lines,
				getLines,
				sublines,
				getSublines,
				modelDescripts,
				getModeldescript,
				detmodps,
				getDetmodp,
				qualities,
				getQualities,
				iramsizes,
				getIramsize,
				cleanContext,
				settings,
				getSettings,
				codeContext,
				handleCodeByProductId,
				product,
				handleProductById,
				handleProductByCode,
				selectMold,
				handleMoldByProductId,
				structureContext,
				handleStructureByProductId,
				selectRoadmap,
				handleRoadmapByProductId,
				selectSemi,
				handleSemiByProductId,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};

ProductProvider.propTypes = {
	children: PropTypes.any,
};

export { ProductProvider };
export default ProductContext;
