/** @format */

export const dateFormatter = (date) => {
	const fecha = new Date(date);
	const fechaFormateada = fecha.toLocaleDateString("es-ES", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		// hour:'numeric',
		// minute:'numeric'
	});

	return fechaFormateada;
};
