//React
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//Props
import PropTypes from 'prop-types'

//Components
import {
  Row,
  Col,
  Card,
  Tab,
  Button,
  Form,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination
} from 'react-table';
import classNames from 'classnames';
import Flex from '../../common/Flex';
import SimpleBarReact from 'simplebar-react';
import { ToastContainer } from 'react-toastify';

//Styles
import 'react-toastify/dist/ReactToastify.css';

//Hooks
import { useCentros } from 'hooks/useCentros';
import useColumnsManPowerTable from 'hooks/useColumnsManPowerTable';

const ManPowerTable = ({ manpowerList }) => {
  const [data, setData] = useState([]);

  const { deleteInCenter } = useCentros();

  useEffect(() => {
    const mpl = manpowerList;
    mpl.map(elem =>
      elem.status === 1 ? (elem.status = 'Activo') : (elem.status = 'Inactivo')
    );

    setData(mpl);
  }, [manpowerList]);

  const columns = useColumnsManPowerTable();

  function MachinesFilter({
    globalFilter,
    setGlobalFilter
  }) {

    MachinesFilter.propTypes = {
      globalFilter : PropTypes.array,
      setGlobalFilter : PropTypes.func,
    }

    const [value, setValue] = useState(globalFilter);

    const onFilterChange = useAsyncDebounce(
      value => setGlobalFilter(value || undefined),
      200
    );

    const handleInputChange = e => {
      setValue(e.target.value);
      onFilterChange(e.target.value);
    };

    return (
      <InputGroup className="position-relative">
        <FormControl
          value={value || ''}
          onChange={handleInputChange}
          size="sm"
          id="search"
          placeholder="Buscar..."
          type="search"
          className="shadow-none"
        />
        <InputGroup.Text className="bg-transparent">
          <FontAwesomeIcon icon="search" className="text-600" />
        </InputGroup.Text>
      </InputGroup>
    );
  }

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 5,
        pageIndex: 0
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    rowsPerPageOptions = [5, 10, 15],
    preGlobalFilteredRows,
    setGlobalFilter
  } = table;

  return (
    <>
      <SimpleBarReact>
        {/* BUSCADOR */}

        <MachinesFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        {/* TABLA */}

        <table
          className="fs--1 mb-0 overflow-hidden table table-striped table-bordered mt-2"
          {...getTableProps()}
        >
          <thead className="bg-200 text-900 text-nowrap align-middle">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props

                <tr key={`header-${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row

                    headerGroup.headers.map((column, index) => (
                      // Apply the header cell props

                      <th
                        key={`column-${index}`}
                        {...column.getHeaderProps(
                          column.id != 'acciones'
                            ? column.getSortByToggleProps(column.headerProps)
                            : ''
                        )}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }

                        {column.id != 'acciones' ? (
                          column.canSort ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="sort desc" />
                              ) : (
                                <span className="sort asc" />
                              )
                            ) : (
                              <span className="sort" />
                            )
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props

                  <tr key={`row-${index}`} {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, index) => {
                        return (
                          <td key={`cell-${index}`} {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.column.id != 'acciones' ? (
                                cell.render('Cell')
                              ) : (
                                <>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Ver</Tooltip>}
                                  >
                                    <Button
                                      as={Link}
                                      to={`/work-center/manpower-list/view/${cell.row.values.id}`}
                                      variant="transparent"
                                      size="sm"
                                      className="text-primary me-2"
                                    >
                                      <FontAwesomeIcon icon="eye" />
                                    </Button>
                                  </OverlayTrigger>
                                  {/* <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Editar</Tooltip>}
                                  >
                                    <Button
                                      as={Link}
                                      to={`/work-center/manpower-list/edit-manpower/${cell.row.values.id}`}
                                      variant="transparent"
                                      size="sm"
                                      className="text-success me-2"
                                    >
                                      <FontAwesomeIcon icon="edit" />
                                    </Button>
                                  </OverlayTrigger> */}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Eliminar</Tooltip>}
                                  >
                                    <Button
                                      onClick={() =>
                                        deleteInCenter(
                                          '/work-center/remove-manpower',
                                          cell.row.values.id
                                        )
                                      }
                                      variant="transparent"
                                      size="sm"
                                      className=" text-danger me-2"
                                    >
                                      <FontAwesomeIcon icon="trash" />
                                    </Button>
                                  </OverlayTrigger>
                                </>
                              )
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>

        {/* PAGINACIÓN */}

        <Flex className="mt-3 align-items-center justify-content-between">
          <Flex alignItems="center" className="fs--1">
            <p className="mb-0">
              <span className="d-none d-sm-inline-block me-2">
                Del {pageSize * pageIndex + 1} al{' '}
                {pageSize * pageIndex + page.length} de {pageCount}
              </span>
            </p>

            <p className="mb-0 mx-2">Filas por página:</p>
            <Form.Select
              size="sm"
              className="w-auto"
              onChange={e => setPageSize(e.target.value)}
              defaultValue={pageSize}
            >
              {rowsPerPageOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Flex>

          <Flex>
            <Button
              size="sm"
              variant={canPreviousPage ? 'primary' : 'light'}
              onClick={() => previousPage()}
              className={classNames({ disabled: !canPreviousPage })}
            >
              Anterior
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? 'primary' : 'light'}
              className={classNames('px-4 ms-2', {
                disabled: !canNextPage
              })}
              onClick={() => nextPage()}
            >
              Siguiente
            </Button>
          </Flex>
        </Flex>
      </SimpleBarReact>
    </>
  );
};

const ManPowerTableList = ({ manpower = [] }) => {
  const [manpowerList, setManPowerList] = useState([]);
  // const [show, setShow] = useState(false);

  const { manPowerByMachine } = useCentros();

  useEffect(() => {
    setManPowerList(manpower);
  }, [manpower]);

  useEffect(() => {
    if (manpower.length > 0) {
      setManPowerList(manpower);
    }
  }, [manPowerByMachine]);

  // const openModal = () => {
  //   setShow(false);
  //   setShow(true);
  // };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {/* CARD TABLA LISTADO */}

      <Card className="mb-3 border-bottom border-200">
        <Card.Header className="bg-light border-bottom border-200">
          <Row>
            <Col>
              <h5 className="mb-0">Listado de Mano de Obra</h5>
            </Col>
            {/*  <Col className="d-flex justify-content-end">
              <Button
                as={Link}
                onClick={ () => openModal()}
                variant="success"
                size="sm"
                className="border-300"
              >
                <FontAwesomeIcon icon="plus" /> Nuevo
              </Button>
            </Col> */}
          </Row>
        </Card.Header>

        <Card.Body className="bg-light">
          <Tab.Content>
            {manpowerList.length > 0 ? (
              <ManPowerTable manpowerList={manpowerList} />
            ) : (
              <p>No cuenta con maquinaria asociada..</p>
            )}
          </Tab.Content>

          {/* MODAL PARA AGREGAR MANO DE OBRA */}
          {/* <FormularioModal
            show={show}
            size='lg'
            setShow={setShow}
            title='Agregar Mano de Obra'
          >
            <FormManPower id={cod_maq === undefined ? id : Number(cod_maq)}/>

          </FormularioModal> */}
        </Card.Body>
      </Card>
    </>
  );
};

ManPowerTable.propTypes = {
  manpowerList : PropTypes.array,
}
ManPowerTableList.propTypes = {
  manpower :  PropTypes.array,
}

export default ManPowerTableList;
