/** @format */

export const Productos = {
	label: "Productos",
	labelDisable: true,
	children: [
		{
			name: "Ingenieria de productos",
			icon: "cubes",
			active: true,
			children: [
				{
					name: "Códigos",
					active: true,
					icon: "barcode",
					children: [
						{
							name: "Códigos individuales",
							to: "/codes/singlecodeslist",
							active: true,
						},
						{
							name: "Códigos productos",
							to: "/codes/compositeProductscodeslist",
							active: true,
						},
						{
							name: "Códigos M. Primas",
							to: "/codes/compositeRawmaterialscodeslist",
							active: true,
						},
					],
				},
				{
					name: "Productos",
					active: true,
					icon: "tshirt",
					children: [
						{
							name: "Familias de productos",
							to: "/products/products/families/familieslist",
							active: true,
						},
						{
							name: "Listado productos",
							to: "/products/products/list",
							active: true,
						},
						{
							name: "Alta producto",
							to: "/products/products/form",
							active: true,
						},
					],
				},
				{
					name: "Materias primas",
					active: true,
					icon: "palette",
					children: [
						{
							name: "Familias de m. primas",
							to: "/products/rawmaterials/families/familieslist",
							active: true,
						},
						{
							name: "Listado m. primas",
							to: "/products/rawmaterials/list",
							active: true,
						},
						{
							name: "Alta m. prima",
							to: "/products/rawmaterials/form",
							active: true,
						},
					],
				},
				{
					name: "Moldes",
					active: true,
					icon: "ruler-combined",
					children: [
						{
							name: "Listado de moldes",
							to: "/molds/moldslist",
							active: true,
						},
					],
				},
			],
		},
	],
};

export const Proveedores = {
	label: "Proveedores",
	labelDisable: true,
	children: [
		{
			name: "Proveedores",
			active: true,
			icon: "briefcase",
			to: "/suppliers/listado",
		},
	],
};

export const Configurador = {
	label: "Configurador",
	labelDisable: true,
	children: [
		{
			name: "Configurador",
			active: true,
			icon: "cogs",
			children: [
				{
					name: "Atributos",
					to: "/configurador/atributos/listado",
					active: true,
				},
				{
					name: "Combinación de atributos",
					to: "/configurador/combinaciones/listado",
					active: true,
				} /* ,
        {
          name: 'Configurador de productos',
          to: '/configurador/configurador/form',
          active: true
        } */,
			],
		},
	],
};

export const CentroTrabajo = {
	label: "Centros de trabajos",
	labelDisable: true,
	children: [
		{
			name: "Centros de trabajos",
			active: true,
			icon: "store-alt",
			children: [
				{
					name: "Listado de centros",
					to: "/centros-de-trabajo/listado-centros",
					active: true,
				},
				{
					name: "Listado Maquinas",
					to: "/work-center/machine-list",
					active: true,
				},
				{
					name: "Listado Mano de Obra",
					to: "/work-center/manpower-list",
					active: true,
				},
			],
		},
	],
};

export const Operations = {
	label: "Operaciones",
	labelDisable: true,
	children: [
		{
			name: "Operaciones",
			active: true,
			icon: "sort-numeric-down",
			children: [
				{
					name: "Listado de operaciones",
					to: "/operations",
					active: true,
				},
			],
		},
	],
};

export const HojaRuta = {
	label: "HojasDeRuta",
	labelDisable: true,
	children: [
		{
			name: "Hojas de ruta",
			active: true,
			icon: "file-alt",
			children: [
				{
					name: "Listado Hojas de ruta",
					to: "/hoja-ruta/list",
					active: true,
				},
			],
		},
	],
};

export const Estamperia = {
	label: "Estamperia",
	labelDisable: true,
	children: [
		{
			name: "Estamperia",
			active: true,
			icon: "brush",
			children: [
				{
					name: "Listado Estampas",
					to: "/estamperia/list",
					active: true,
				},
				{
					name: "Relacion Prod/Est",
					to: "/estamperia/relacion-producto-estampa",
					active: true,
				},
				{
					name: "Listado relaciones",
					to: "/estamperia/list/relacion-producto-estampa",
					active: true,
				},
			],
		},
	],
};

export const Configuraciones = {
	label: "Configuraciones",
	labelDisable: true,
	children: [
		{
			name: "Configuraciones",
			active: true,
			icon: "wrench",
			to: "/settings/settingform",
		},
	],
};

export const Calendario = {
	label: "Calendario",
	labelDisable: true,
	children: [
		{
			name: "Calendario",
			active: true,
			icon: "ellipsis-h",
			children: [
				{
					name: "Listado",
					active: true,
					to: "/calendario/listado",
				},
				{
					name: "Calendario General",
					active: true,
					to: "/calendario",
				},
			],
		},
	],
};

export const SalesMasterPlan = {
	label: "Plan maestro",
	labelDisable: true,
	children: [
		{
			name: "Plan maestro",
			active: true,
			icon: "ellipsis-h",
			children: [
				{
					name: "Listado",
					to: "/salesmasterplan/masterplanlist",
					active: true,
				},
				{
					name: "Alta de plan maestro",
					to: "/salesmasterplan/masterplanform",
					active: true,
				},
			],
		},
	],
};

export default [
	Productos,
	Proveedores,
	Configurador,
	CentroTrabajo,
	Operations,
	HojaRuta,
	Estamperia,
	Configuraciones,
	Calendario,
	SalesMasterPlan,
];
