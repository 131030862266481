/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, Row, Col, Form, Button, Tab } from "react-bootstrap";
import { camelize } from "../../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	const toastId = React.useRef(null);
	const [selectCode, setSelectCode] = useState("");
	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [titulo, setTitulo] = useState("");
	const [accion, setAccion] = useState("");
	const [searchParams] = useSearchParams();
	const [isDisabled, setIsDisabled] = useState(false);
	const [isVisibleCode, setIsVisibleCode] = useState(false);
	const [materialcodes, setMaterialcodes] = useState([]);
	const [selectMaterialCode, setSelectMaterialCode] = useState("");
	const [families, setFamilies] = useState([]);
	const [selectFamily, setSelectFamily] = useState("");
	const [isVisibleFamily, setIsVisibleFamily] = useState(false);

	const [selectFilter, setSelectFilter] = useState("");
	const [isVisibleFilterSize, setIsVisibleFilterSize] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			setTitulo("Modificar");
			setAccion("Editar");
			setIsDisabled(true);
			const typeid = searchParams.get("typeid");
			if (typeid) {
				getSingleCodeById(typeid);
			}
		} else {
			setTitulo("Agregar");
			setAccion("Guardar");
			setIsDisabled(false);
		}

		getAllFamilies();
		getAllCodes();
	}, []);

	useEffect(() => {
		const action = selectActions[selectCode] || selectActions["default"];
		action();
	}, [selectCode]);

	const selectActions = {
		2: () => {
			setIsVisibleCode(true);
			setIsVisibleFamily(false);
			setIsVisibleFilterSize(false);
			setSelectFamily("");
			setSelectFilter("");
		},
		4: () => {
			setIsVisibleFamily(true);
			setIsVisibleCode(false);
			setIsVisibleFilterSize(false);
			setSelectMaterialCode("");
			setSelectFilter("");
		},
		7: () => {
			setIsVisibleFamily(false);
			setIsVisibleCode(false);
			setSelectMaterialCode("");
			setIsVisibleFilterSize(true);
			setSelectFilter("");
		},
		default: () => {
			setIsVisibleCode(false);
			setSelectMaterialCode("");
			setIsVisibleFamily(false);
			setSelectFamily("");
		},
	};

	const codeRules = {
		1: { length: 3, errorMessage: "El código tiene que ser de 3 caracteres." },
		2: { length: 3, errorMessage: "El código tiene que ser de 3 caracteres." },
		3: { length: 3, errorMessage: "El código tiene que ser de 3 caracteres." },
		4: { length: 3, errorMessage: "El código tiene que ser de 3 caracteres." },
		5: { length: 2, errorMessage: "El código puede ser hasta 2 caracteres." },
		6: { length: 2, errorMessage: "El código tiene que ser de 2 caracteres." },
		7: { length: 2, errorMessage: "El código tiene que ser de 2 caracteres." },
		8: { length: 2, errorMessage: "El código tiene que ser de 2 caracteres." },
	};

	const getAllCodes = async () => {
		try {
			const { data } = await axiosWithToken.get("/codesnew/individualcodes/1");

			setMaterialcodes(
				data.data.map((unit) => {
					return {
						value: unit.id,
						label: unit.description,
					};
				})
			);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const getAllFamilies = async () => {
		try {
			const { data } = await axiosWithToken.get("/productfamilies");

			setFamilies(
				data.data.map((family) => {
					return {
						id: family.id,
						name: family.name,
						description: family.description,
						created_at: dayjs(family.created_at).format("DD/MM/YYYY HH:mm:ss"),
					};
				})
			);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const getSingleCodeById = async (typeid) => {
		try {
			const { data } = await axiosWithToken.get(
				`/codesnew/individualcodes/${id}/type/${typeid}`
			);

			setSelectCode(data.data.codetag_id.toString());
			setCode(data.data.code);
			setDescription(data.data.description);

			if (data.data.product_family_id) {
				setSelectFamily(data.data.product_family_id);
			}

			if (data.data.material_code_id) {
				setSelectMaterialCode(data.data.material_code_id);
			}

			if (data.data.filter) {
				setSelectFilter(data.data.filter);
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const codeExists = async (endpointpart) => {
		try {
			const { data } = await axiosWithToken.get(
				`/${endpointpart}/code/${code}`
			);

			if (data.data) {
				return true;
			}
		} catch (error) {
			return false;
		}
	};

	function validateCode(code, selectCode) {
		const rule = codeRules[selectCode];
		const { length: expectedLength, errorMessage } = rule;

		if (selectCode == 5) {
			if (parseInt(code.length) > expectedLength) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(errorMessage, {
						role: "alert",
						theme: "dark",
					});
				}
				//Error
				return false;
			}
			return true;
		}

		if (parseInt(code.length) !== expectedLength) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(errorMessage, {
					role: "alert",
					theme: "dark",
				});
			}
			//Error
			return false;
		}
		return true;
	}

	const store = async (e) => {
		e.preventDefault();

		if (selectCode == "0") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Debe definir que tipo de código va a dar de alta.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		} else {
			if (selectCode == "2") {
				if (selectMaterialCode == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`El seleccionar un código de material es obligatorio.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}

					//Error
					return;
				}
			}

			if (selectCode == "4") {
				if (selectFamily == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`El seleccionar una familia de producto es obligatorio.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}

					//Error
					return;
				}
			}

			if (selectCode == "7") {
				if (selectFilter == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`El seleccionar un filtro de talle es obligatorio.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}

					//Error
					return;
				}
			}
		}

		if (code == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo código es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		} else {
			if (!validateCode(code, selectCode)) {
				// Error
				return;
			}
		}

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		const endpointTagMap = {
			1: { endpointpart: "materialcode", tagid: 1 },
			2: {
				endpointpart: `submaterialcode/materialid/${selectMaterialCode}`,
				tagid: 2,
			},
			3: { endpointpart: "materialcolorcode", tagid: 3 },
			4: { endpointpart: "productcode", tagid: 4 },
			5: { endpointpart: "modelcode", tagid: 5 },
			6: { endpointpart: "fabriccode", tagid: 6 },
			7: { endpointpart: "sizecode", tagid: 7 },
			8: { endpointpart: "productcolorcode", tagid: 8 },
		};

		const { endpointpart, tagid } = endpointTagMap[selectCode];

		let isCodeExist = codeExists(endpointpart);
		if (isCodeExist) {
			toastId.current = toast.error("El código ya existe", {
				role: "alert",
				theme: "dark",
			});
			// error
			return;
		}

		try {
			await axiosWithToken.post(`/${endpointpart}`, {
				code: code,
				description: description,
				tagid: tagid,
				family_id: selectFamily,
				filter: selectFilter,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Código agregado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/codes/singlecodeslist");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			setTimeout(() => {
				navigate("/codes/singlecodeslist");
			}, 1000);
		}
	};

	const update = async (e) => {
		e.preventDefault();

		if (selectCode == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Debe definir que tipo de código va a dar de alta.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		} else {
			if (selectCode == 2) {
				if (selectMaterialCode == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`El seleccionar un código de material es obligatorio.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}

					//Error
					return;
				}
			}

			if (selectCode == 4) {
				if (selectFamily == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`El seleccionar una familia de producto es obligatorio.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}

					//Error
					return;
				}
			}

			if (selectCode == "7") {
				if (selectFilter == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`El seleccionar un filtro de talle es obligatorio.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}

					//Error
					return;
				}
			}
		}

		if (code == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo código es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		let endpointpart = "";

		switch (selectCode) {
			case "1":
				endpointpart = "materialcode";
				break;
			case "2":
				endpointpart = `submaterialcode/materialid/${selectMaterialCode}`;
				break;
			case "3":
				endpointpart = "materialcolorcode";
				break;
			case "4":
				endpointpart = "productcode";
				break;
			case "5":
				endpointpart = "modelcode";
				break;
			case "6":
				endpointpart = "fabriccode";
				break;
			case "7":
				endpointpart = "sizecode";
				break;
			case "8":
				endpointpart = "productcolorcode";
				break;
		}

		try {
			await axiosWithToken.put(`/${endpointpart}/id/${id}`, {
				code: code,
				description: description,
				family_id: selectFamily,
				filter: selectFilter,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Código modificado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/codes/singlecodeslist");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			setTimeout(() => {
				navigate("/codes/singlecodeslist");
			}, 1000);
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario alta de códigos individuales
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<Card className='mb-2 p-4 h-md-100'>
									<h6 className='mb-4 text-center'>{titulo} campos</h6>
									<Form onSubmit={id ? update : store}>
										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={6}
												lg={6}
												id='formGridCode'
											>
												<Form.Label>
													Seleccionar tipo de código
													<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Select
													value={selectCode}
													size='sm'
													onChange={(e) => setSelectCode(e.target.value)}
													aria-label='select-code'
													disabled={isDisabled}
												>
													<option value='' disabled>
														Seleccionar...
													</option>
													<option value='1'>Código de material "MP"</option>
													<option value='2'>Código de submaterial "MP"</option>
													<option value='3'>Código de color "MP"</option>
													<option value='4'>Código de producto "P"</option>
													<option value='5'>Código de modelo "P"</option>
													<option value='6'>Código de tela "P"</option>
													<option value='7'>Código de talle "P"</option>
													<option value='8'>Código de color "P"</option>
												</Form.Select>
											</Form.Group>

											{isVisibleCode && (
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													id='formGridCode'
												>
													<Form.Label>
														Seleccionar código de material
														<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Select
														value={selectMaterialCode}
														size='sm'
														onChange={(e) =>
															setSelectMaterialCode(e.target.value)
														}
														aria-label='select-materialcode'
													>
														<option value='' disabled>
															Seleccionar...
														</option>
														{materialcodes.map((elem) => {
															return (
																<option key={elem.value} value={elem.value}>
																	{elem.label}
																</option>
															);
														})}
													</Form.Select>
												</Form.Group>
											)}
										</Row>

										{isVisibleFilterSize && (
											<Row className='mb-3 g-3'>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													id='formGridType'
												>
													<Form.Label>
														Seleccionar filtro de talle
														<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Select
														size='sm'
														aria-label='select-type'
														value={selectFilter}
														onChange={(e) => setSelectFilter(e.target.value)}
													>
														<option value='' disabled>
															Seleccionar...
														</option>
														<option value={1}>Prendas</option>
														<option value={2}>Prendas/Equipos</option>
														<option value={3}>Equipos</option>
														<option value={4}>Bebés</option>
													</Form.Select>
												</Form.Group>
											</Row>
										)}

										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={6}
												lg={6}
												controlId='formGridCod'
											>
												<Form.Label>
													Código<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													value={code}
													onChange={(e) => setCode(e.target.value)}
													type='text'
													placeholder='Ingrese código'
												/>
											</Form.Group>

											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={6}
												lg={6}
												controlId='formGridDescription'
											>
												<Form.Label>
													Descripción<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													size='sm'
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													type='text'
													placeholder='Ingrese descripción'
												/>
											</Form.Group>
										</Row>

										{isVisibleFamily && (
											<Row>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formGridFamily'
												>
													<Form.Label>
														Familia de producto
														<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Select
														size='sm'
														value={selectFamily}
														aria-label='family'
														onChange={(e) => setSelectFamily(e.target.value)}
													>
														<option value=''>Seleccionar...</option>
														{families.map((elem) => {
															return (
																<option key={elem.id} value={elem.id}>
																	{elem.name}
																</option>
															);
														})}
													</Form.Select>
												</Form.Group>
											</Row>
										)}

										<Row>
											<Col className='d-flex justify-content-end'>
												<Button size='sm' variant='primary' type='submit'>
													{accion}
												</Button>
											</Col>
										</Row>
									</Form>
								</Card>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
