/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useAuthContext } from "hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

const ProfileDropdown = () => {
	const navigate = useNavigate();

	const { user, logout } = useAuthContext();

	const handlelogout = () => {
		logout().then(() => {
			// La función de logout se ha completado aquí.
			navigate("/");
		});
	};

	return (
		<Dropdown navbar={true} as='li'>
			<Dropdown.Toggle
				bsPrefix='toggle'
				as={Link}
				to='#!'
				className='pe-0 ps-2 nav-link'
			>
				<div className='theme-control-toggle'>
					<div className='theme-control-toggle-label'>
						<FontAwesomeIcon icon='user' className='text-primary' />
					</div>
				</div>
			</Dropdown.Toggle>

			<Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
				<div className='bg-white rounded-2 py-2 dark__bg-1000'>
					<Dropdown.Item style={{ cursor: "inherit" }}>
						<strong className='text-900'>Bienvenido: </strong>
						{user.name}
					</Dropdown.Item>
					{/*  <Dropdown.Item as={Link} to="#">
            Mi cuenta
          </Dropdown.Item> */}
					<Dropdown.Item as='button' onClick={() => handlelogout()}>
						Cerrar Sesión
					</Dropdown.Item>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default ProfileDropdown;
