/** @format */

import moment from "moment";

//funcion para formatear tiempo a minutos (de HH:mm:ss -> a minutos)
export const formatTimeToMinutes = (time) => {
	const timeFormatted = moment.duration(time);
	const newTime = timeFormatted.asMinutes();
	return newTime;
};
