/** @format */

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tab } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
import CalendarioTable from "./CalendarioTable";
import { getAllEventos, deleteEvent } from "data/calendar/eventos";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dateFormatter } from "helpers/dateFormatter";

const CalendarioList = () => {
	const [eventos, setEventos] = useState([]);

	const toastId = React.useRef(null);

	useEffect(async () => {
		const events = await getAllEventos();
		//Formateo fecha
		events.map((event) => {
			event.start = dateFormatter(event.start);
			event.end = dateFormatter(event.end);
		});
		setEventos(events);
	}, []);

	//Funcion para borrar un evento por id
	const handleEventDelete = async (id) => {
		const deleteResponse = await deleteEvent(id);
		if (deleteResponse.status === "ok") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			const events = await getAllEventos();
			setEventos(events);
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${deleteResponse.message}`, {
					role: "alert",
					theme: "dark",
					autoClose: 4000,
				});
			}
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>Listado Eventos del calendario</h5>
						</Col>
						<Col className='d-flex justify-content-end'>
							{/* <Button
                                as={Link}
                                to='/estamperia/relacion-producto-estampa'
                                variant='success'
                                size='sm'
                                className='border-300'
                            >
                                <FontAwesomeIcon icon='plus' /> Nuevo
                            </Button> */}
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						{eventos.length > 0 && (
							<CalendarioTable
								eventos={eventos}
								handleEventDelete={handleEventDelete}
							/>
						)}
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default CalendarioList;
