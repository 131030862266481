export const OPTYPES = [
    {
        id:1,
        item:"Fabricación Interna"
    },
    {
        id:2,
        item:"Control de Calidad"
    },
    {
        id:3,
        item:"Fabricación de Terceros"
    }    
]