/* eslint-disable no-sparse-arrays */
import { useMemo } from 'react';

export default function useColumnsMachines() {
  const columns = useMemo(
    () => [
      {
        accessor: 'cod_maq',
        Header: '#'
      },
      {
        accessor: 'nro_maq',
        Header: 'N° Máquina'
      },
      {
        accessor: 'description',
        Header: 'Descripción'
      },
      {
        accessor: 'tipo_maq_name',
        Header: 'Tipo de máquina'
      },
      {
        accessor: 'machine_status',
        Header: 'Estado'
      },
      {
        accessor: 'obs',
        Header: 'Obs'
      },
      {
        accessor: 'acciones',
        Header: 'Acciones'
      }
    ],
    []
  );

  return columns;
}
