/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleBarReact from "simplebar-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListadoTable from "components/suppliers/ListadoTable";
import { useAuthContext } from "hooks/useAuthContext";

const ListadoTabla = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const toastId = React.useRef(null);
	const [proveedores, setProveedores] = useState([]);

	useEffect(() => {
		getAllProveedores();
	}, []);

	const getAllProveedores = async () => {
		const { data } = await axiosWithToken.get("/suppliers");
		setProveedores(
			data.data.map((supplier) => {
				return {
					id: supplier.id,
					description: supplier.description,
					created_at: dayjs(supplier.created_at).format("DD/MM/YYYY HH:mm:ss"),
					updated_at: dayjs(supplier.updated_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);
	};

	const deleteProveedor = async (id) => {
		await axiosWithToken.delete(`/supplier/${id}`);
		if (!toast.isActive(toastId.current)) {
			toastId.current = toast.success("¡Eliminado correctamente!", {
				role: "alert",
				theme: "dark",
			});
		}

		getAllProveedores();
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<SimpleBarReact>
				{/* CARD TABLA LISTADO */}
				<Card className='mb-3 border-bottom border-200'>
					<Card.Header className='bg-light border-bottom border-200'>
						<Row>
							<Col>
								<h5 className='mb-0'>Listado de Proveedores</h5>
							</Col>
							<Col className='d-flex justify-content-end'>
								<Button
									as={Link}
									to='/suppliers/form'
									variant='success'
									size='sm'
									className='border-300'
								>
									<FontAwesomeIcon icon='plus' /> Nuevo
								</Button>
							</Col>
						</Row>
					</Card.Header>

					<Card.Body className='bg-light'>
						<Tab.Content>
							<ListadoTable
								data={proveedores}
								deleteProveedor={deleteProveedor}
							/>
						</Tab.Content>
					</Card.Body>
				</Card>
				{/* PAGINACIÓN */}
			</SimpleBarReact>
		</>
	);
};

export default ListadoTabla;
