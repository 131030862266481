/** @format */

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	Form,
	Row,
	Col,
	Button,
	Card,
	Tab,
	OverlayTrigger,
	Tooltip,
	Badge,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { camelize } from "../../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import SoftBadge from "components/common/SoftBadge";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	const toastId = useRef(null);

	const [idAtributo, setIdAtributo] = useState(null);
	const [description, setDescription] = useState("");
	const [fechaAlta, setFechaAlta] = useState("");
	//const [fechaModificacion, setFechaModificacion] = useState('')
	const [newDescription, setNewDescription] = useState("");
	const [selectCode, setSelectCode] = useState("");
	const [newOrder, setNewOrder] = useState("");
	const [editId, setEditId] = useState(null);
	const [data, setCamposAtributos] = useState([]);
	const [searchParams] = useSearchParams();
	const [relate, setRelate] = useState(0);

	const { id } = useParams();

	const perPage = 4;
	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(data, perPage);

	useEffect(() => {
		getAttributeById();
		getAllCamposAtributos();

		const torelate = searchParams.get("torelate");

		if (torelate) {
			setRelate(torelate);
		}
	}, []);

	const getAllCamposAtributos = async () => {
		const response = await axiosWithToken.get(
			`/attributefields/byattribute/${id}`
		);

		setCamposAtributos(
			response.data.data.map((attributefield) => {
				return {
					id: attributefield.id,
					description: attributefield.description,
					flag_code: attributefield.flag_code,
					order: attributefield.order,
					created_at: dayjs(attributefield.created_at).format(
						"DD/MM/YYYY HH:mm:ss"
					),
					updated_at: dayjs(attributefield.updated_at).format(
						"DD/MM/YYYY HH:mm:ss"
					),
				};
			})
		);
	};

	const getAttributeById = async () => {
		const response = await axiosWithToken.get(`/attribute/${id}`);

		setIdAtributo(response.data.data.id);
		setDescription(response.data.data.description);
		setFechaAlta(
			dayjs(response.data.data.created_at).format("DD/MM/YYYY HH:mm:ss")
		);
		//setFechaModificacion(dayjs(response.data.data.updated_at).format('DD/MM/YYYY HH:mm:ss'))
	};

	const deleteCampoAtributo = async (id) => {
		await axiosWithToken.delete(`/attributefield/${id}`);

		if (!toast.isActive(toastId.current)) {
			toastId.current = toast.success("¡Eliminado correctamente!", {
				role: "alert",
				theme: "dark",
			});
		}
		getAllCamposAtributos();
	};

	const cargarValores = async (atributoid, campoid) => {
		navigate(
			`/configurador/atributos/AtributoCamposValores?atributoid=${atributoid}&campoid=${campoid}&torelate=${relate}`
		);
	};

	const insertUpdate = async (e) => {
		e.preventDefault();

		if (editId) {
			if (newDescription == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo descripción es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			console.log("imprimirmos valor del selectCode", selectCode);

			if (selectCode === "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`Debe definir si integra el código del producto o no.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			if (selectCode == 1) {
				if (newOrder == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`Cómo integra el código, tiene que definir un orden.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}

					//Error
					return;
				}
			}

			const respuesta = await axiosWithToken.put(`/attributefield/${editId}`, {
				attribute_id: id,
				description: newDescription,
				flag_code: selectCode,
				order: newOrder,
			});

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Actualizado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setNewDescription("");
				setSelectCode("");
				setNewOrder("");
				setEditId(null);
				getAllCamposAtributos();
			} else {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${respuesta.message}`, {
						role: "alert",
						theme: "dark",
					});
				}

				setNewDescription("");
				setSelectCode("");
				setNewOrder("");
			}
		} else {
			if (newDescription == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El campo descripción es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			console.log("imprimirmos valor del selectCode", selectCode);

			if (selectCode == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`Debe definir si integra el código del producto o no.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			if (selectCode == 1) {
				if (newOrder == "") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(
							`Cómo integra el código, tiene que definir un orden.`,
							{
								role: "alert",
								theme: "dark",
							}
						);
					}
					//Error
					return;
				}
			}

			const respuesta = await axiosWithToken.post("/attributefield", {
				attribute_id: id,
				description: newDescription,
				flag_code: selectCode,
				order: newOrder,
			});

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}
				setNewDescription("");
				setSelectCode("");
				setNewOrder("");
				setEditId(null);
				getAllCamposAtributos();
			} else {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${respuesta.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
				setNewDescription("");
				setSelectCode("");
				setNewOrder("");
			}
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{/* FORM */}
			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Datos del atributo: <Badge bg='secondary'># {idAtributo}</Badge>{" "}
								<Badge bg='secondary'>{description}</Badge>
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<Card className='mb-2'>
									<Card.Body className='position-relative'>
										<Row>
											<Col className='mb-3' xs={12} sm={12} lg={4} md={4}>
												<h6>Descripción: </h6>
												<span className='fs--1'>
													{description} <Badge bg='secondary'>Atributo</Badge>
												</span>
											</Col>
											<Col className='mb-3' xs={12} sm={12} lg={4} md={4}>
												<h6>Fecha de alta:</h6>
												<span className='fs--1'>{fechaAlta}</span>
											</Col>
											<Col className='mb-3' xs={12} sm={12} lg={4} md={4}>
												<h6>Fecha de última modificación:</h6>
												<span className='fs--1'>{fechaAlta}</span>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>

							<Col xs={12} sm={12} lg={4} md={4}>
								<Card className='mb-2 p-4 h-md-100'>
									<h6 className='mb-4 text-center'>Ingresar campos</h6>
									<Form className='mt-2' onSubmit={insertUpdate}>
										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={12}
												lg={12}
												controlId='formGridDescription'
											>
												<Form.Label>
													Descripción<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Control
													value={newDescription}
													size='sm'
													onChange={(e) => setNewDescription(e.target.value)}
													type='text'
													placeholder='Ingrese descripción'
												/>
											</Form.Group>
										</Row>

										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={12}
												lg={12}
												id='formGridCode'
											>
												<Form.Label>
													Integra código<span className='text-danger'>*</span>
												</Form.Label>
												<Form.Select
													value={selectCode}
													size='sm'
													onChange={(e) => setSelectCode(e.target.value)}
													aria-label='select-code'
												>
													<option value='' disabled>
														Seleccionar...
													</option>
													<option value={0}>No integra</option>
													<option value={1}>Integra</option>
												</Form.Select>
											</Form.Group>
										</Row>

										<Row className='mb-3 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={12}
												lg={12}
												controlId='formGridOrder'
											>
												<Form.Label>Orden</Form.Label>
												<Form.Control
													value={newOrder}
													size='sm'
													onChange={(e) => setNewOrder(e.target.value)}
													type='text'
													placeholder='Ingrese orden'
												/>
											</Form.Group>
										</Row>

										<Row>
											<Col className='d-flex justify-content-end'>
												<Button size='sm' variant='primary' type='submit'>
													{!editId ? "Agregar" : "Editar"}
												</Button>
												{editId ? (
													<Button
														size='sm'
														className='ms-1'
														variant='danger'
														onClick={() => {
															setNewDescription("");
															setSelectCode("");
															setNewOrder("");
															setEditId(null);
														}}
													>
														Cancelar
													</Button>
												) : (
													""
												)}
											</Col>
										</Row>
									</Form>
								</Card>
							</Col>

							<Col xs={12} sm={12} lg={8} md={8}>
								<Card className='p-4 h-md-100'>
									{data.length ? (
										<>
											<Row>
												<h6 className='mb-4 text-center'>
													Campos del atributo
												</h6>
												<Col xs={12} sm={12} lg={12} md={12}>
													<Tab.Content>
														{paginatedDetails.map((dt, index) => (
															<Card
																key={index}
																className='mb-2 square border-start border-start-2 border-primary'
															>
																<span>
																	<Row>
																		<Col xs={12} sm={12} lg={8} md={8}>
																			<span className='m-1 p-1 fs--1'>
																				<strong>Campo:</strong> {dt.description}{" "}
																				{dt.flag_code ? (
																					<Badge bg='success'>
																						Integra code
																					</Badge>
																				) : (
																					<Badge bg='danger'>
																						No integra code
																					</Badge>
																				)}{" "}
																				<SoftBadge pill bg='primary'>
																					{dt.order}
																				</SoftBadge>
																			</span>
																		</Col>
																		<Col xs={12} sm={12} lg={4} md={4}>
																			<span className='mt-1'>
																				<OverlayTrigger
																					placement='top'
																					overlay={
																						<Tooltip>Cargar valores</Tooltip>
																					}
																				>
																					<Button
																						onClick={() =>
																							cargarValores(idAtributo, dt.id)
																						}
																						variant='transparent'
																						size='sm'
																						className='text-primary'
																					>
																						<FontAwesomeIcon icon='plus' />
																					</Button>
																				</OverlayTrigger>

																				<OverlayTrigger
																					placement='top'
																					overlay={<Tooltip>Editar</Tooltip>}
																				>
																					<Button
																						onClick={() => {
																							setNewDescription(dt.description);
																							setSelectCode(dt.flag_code);
																							setNewOrder(dt.order);
																							setEditId(dt.id);
																						}}
																						variant='transparent'
																						size='sm'
																						className='text-success'
																					>
																						<FontAwesomeIcon icon='edit' />
																					</Button>
																				</OverlayTrigger>

																				<OverlayTrigger
																					placement='top'
																					overlay={<Tooltip>Eliminar</Tooltip>}
																				>
																					<Button
																						onClick={() =>
																							deleteCampoAtributo(dt.id)
																						}
																						variant='transparent'
																						size='sm'
																						className='text-danger'
																					>
																						<FontAwesomeIcon icon='trash' />
																					</Button>
																				</OverlayTrigger>
																			</span>
																		</Col>
																	</Row>
																</span>
															</Card>
														))}
													</Tab.Content>

													<div className='d-flex justify-content-center'>
														<div>
															{/* <OverlayTrigger placement="top" overlay={<Tooltip>Prev</Tooltip>}> */}
															<Button
																variant='falcon-default'
																size='sm'
																disabled={!canPreviousPage}
																onClick={prevPage}
																className='me-2'
																trigger='focus'
															>
																<FontAwesomeIcon icon='chevron-left' />
															</Button>
															{/* </OverlayTrigger> */}
														</div>

														<ul className='pagination mb-0'>
															{paginationArray.map((page) => (
																<li
																	key={page}
																	className={classNames({
																		active: currentPage === page,
																	})}
																>
																	<Button
																		size='sm'
																		variant='falcon-default'
																		className='page me-2'
																		onClick={() => goToPage(page)}
																	>
																		{page}
																	</Button>
																</li>
															))}
														</ul>
														<div>
															{/* <OverlayTrigger
                            trigger="click"
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">Next</Tooltip>}
                          > */}
															<Button
																variant='falcon-default'
																size='sm'
																disabled={!canNextPage}
																onClick={nextPage}
																trigger='focus'
															>
																<FontAwesomeIcon icon='chevron-right' />
															</Button>
															{/* </OverlayTrigger> */}
														</div>
													</div>
												</Col>
											</Row>
										</>
									) : (
										<h6 className='font-black text-3xl text-center'>
											¡No hay valores cargados!
										</h6>
									)}
								</Card>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
