/** @format */

import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

export const getTemporadas = async () => {
	let temp = [];
	try {
		const { token } = useAuthContext();
		axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const { data } = await axiosWithToken.get("/temporadas");
		temp = data.data;
		return temp;
	} catch (error) {
		console.log(error);
		return (temp = []);
	}
};
