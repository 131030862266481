/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import {
	Row,
	Col,
	Form,
	Button,
	Modal,
	Table,
	OverlayTrigger,
	Tooltip,
	Card,
} from "react-bootstrap";
import PropTypes from "prop-types";
import Divider from "components/common/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { generarId } from "../../helpers/utils";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import { useMasterplanContext } from "hooks/useMasterplanContext";
import { useAuthContext } from "hooks/useAuthContext";

const MasterplanModalStageTwo = ({ show, setShow, detailsFamilyStgOne }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//Cantidad de registros que se muestran en la paginación
	const perPage = 6;

	//Estado para saber si editar un registro
	const [edit, setEdit] = useState(false);

	//Estado para todos los publicos
	const [publics, setPublics] = useState([]);

	//Detalles para la etapa 2
	const [detalles, setDetalles] = useState([]);

	const [idEditing, setIdEditing] = useState("");
	const [idToDelete, setIdToDelete] = useState("");
	const [selectPublic, setSelectPublic] = useState("");
	const [selectPublicName, setSelectPublicName] = useState("");
	const [quantity, setQuantity] = useState(0);
	const [opening, setOpening] = useState(0);
	const [averagetime, setAveragetime] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	const {
		stageTwoDetails,
		masterplanContext,
		handleStageTwoDetails,
		handleSetMasterplan,
	} = useMasterplanContext();

	const toastId = React.useRef(null);

	useEffect(() => {
		getAllPublic();
		// console.log({ stageTwoDetails });
		// if (stageTwoDetails) {
		// 	if (detail.length > 0) {
		// 		const newDetailsArray = detail.filter(
		// 			(dt) => dt.familyId === detailsFamilyStgOne.family.id
		// 		);
		// 		const detailUpdate = newDetailsArray.map((detail) => {
		// 			const newDetail = {
		// 				idkey: detail.idkey,
		// 				stageOneId: detail.stageOneId,
		// 				familyId: detail.familyId,
		// 				familyName: detail.familyName,
		// 				publicId: detail.publicId,
		// 				name: detail.name,
		// 				quantity: detail.quantity,
		// 				opening: detail.opening,
		// 				quantity_opening: (detail.quantity / detail.opening).toFixed(2),
		// 				averagetime: detail.averagetime,
		// 			};

		// 			return newDetail;
		// 		});

		// 		setDetalles(detailUpdate);
		// 	}
		// }
	}, []);

	useEffect(() => {
		if (stageTwoDetails?.length > 0) {
			const details = stageTwoDetails.filter(
				(detail) => detail.stageOneId === detailsFamilyStgOne.id
			);
			setDetalles(details);
		} else {
			setDetalles([]);
		}
	}, [stageTwoDetails]);

	const {
		paginationState: {
			data: paginatedDetails,
			canNextPage,
			canPreviousPage,
			paginationArray,
			currentPage,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(detalles, perPage);

	const getAllPublic = async () => {
		const { data } = await axiosWithToken.get("/publics");
		setPublics(
			data.data.map((publico) => {
				return {
					id: publico.id,
					description: publico.description,
					created_at: dayjs(publico.created_at).format("DD/MM/YYYY HH:mm:ss"),
				};
			})
		);
	};

	const handleSelectPublic = (event) => {
		const selectedIndex = event.target.selectedIndex;
		const selectedOption = event.target.options[selectedIndex];
		const selectedValue = selectedOption.value;
		const selectedText = selectedOption.text;

		setSelectPublic(selectedValue);
		setSelectPublicName(selectedText);
	};

	//funcion para formatear y setear el campo de tiempode ejecucion
	const handleTime = (event) => {
		const newValue = event.target.value.replace(/\D/g, ""); // solo se permiten números
		const maxLength = 8;
		const match = newValue.match(/^(\d{1,2})(\d{0,2})(\d{0,4})$/); // separa los numeros en grupos
		if (match) {
			const formattedValue =
				match[1] +
				(match[2] ? ":" + match[2] : "") +
				(match[3] ? ":" + match[3] : ""); // agrega dos puntos
			event.target.value = formattedValue.slice(0, maxLength); // limita la longitud a 8 caracteres
		}
		setAveragetime(event.target.value);
	};

	//Paso 1 del guardado-edicion
	const handleAddPublic = async () => {
		//Agregando un row
		if (!edit) {
			// Verificar si el público ya existe para la familia
			const existingPublic = detalles.find(
				(publicObj) => publicObj.publicId === Number(selectPublic)
			);

			if (existingPublic) {
				setTimeout(() => {
					toast.error(`Ya se ingresó ese público para esta familia.`, {
						role: "alert",
						theme: "dark",
					});
				}, 0);

				//Error
				return;
			}

			const newDetail = {
				idkey: generarId(),
				stageOneId: detailsFamilyStgOne.id,
				familyId: detailsFamilyStgOne.family.id,
				familyName: detailsFamilyStgOne.family.name,
				publicId: selectPublic,
				name: selectPublicName,
				quantity: quantity,
				opening: opening,
				quantity_opening: (quantity / opening).toFixed(2),
				averagetime: averagetime,
			};

			saveStageTwo(newDetail);
		}

		if (edit) {
			const updtDetail = {
				id: idEditing,
				idkey: generarId(),
				stageOneId: detailsFamilyStgOne.id,
				familyId: detailsFamilyStgOne.family.id,
				familyName: detailsFamilyStgOne.family.name,
				publicId: selectPublic,
				name: selectPublicName,
				quantity: quantity,
				opening: opening,
				quantity_opening: (quantity / opening).toFixed(2),
				averagetime: averagetime,
			};

			saveStageTwo(updtDetail);
		}

		//TODO por el momento quitamos las validaciones de cantidades y aperturas
		// const totalQuantity = detalles.reduce((accumulator, publicObj) => {
		// 	const shouldUseOpening = idkeyEdit === publicObj.idkey;
		// 	const openingValue = shouldUseOpening
		// 		? Number(quantity)
		// 		: Number(publicObj.quantity);
		// 	return accumulator + openingValue;
		// }, 0);

		// const remainingQuantity =
		// 	detailsFamilyStgOne.planned_quantity - totalQuantity;

		// if (edit) {
		// 	if (remainingQuantity < 0) {
		// 		setTimeout(() => {
		// 			toast.error(
		// 				`La sumatoria de cantidades de publicos no puede superar la cantidad planificada de la familia.`,
		// 				{
		// 					role: "alert",
		// 					theme: "dark",
		// 				}
		// 			);
		// 		}, 0);

		// 		// Error
		// 		return;
		// 	}
		// } else {
		// 	if (Number(quantity) > remainingQuantity) {
		// 		setTimeout(() => {
		// 			toast.error(
		// 				`La sumatoria de cantidades de publicos no puede superar la cantidad planificada de la familia.`,
		// 				{
		// 					role: "alert",
		// 					theme: "dark",
		// 				}
		// 			);
		// 		}, 0);

		// 		// Error
		// 		return;
		// 	}
		// }

		// const totalOpening = detalles.reduce((accumulator, publicObj) => {
		// 	const shouldUseOpening = idkeyEdit === publicObj.idkey;
		// 	const openingValue = shouldUseOpening
		// 		? Number(opening)
		// 		: Number(publicObj.opening);
		// 	return accumulator + openingValue;
		// }, 0);

		// const remainingOpening = detailsFamilyStgOne.opening - totalOpening;
	};

	const handleDelete = (id) => {
		setIdToDelete(id);
		setShowAlert(true);
	};

	const closeModalDelete = () => {
		setShowAlert(false);
	};

	const handleConfirmDelete = async () => {
		try {
			const { data } = await axiosWithToken.delete(
				`/masterplanStagetwo/masterplan/${masterplanContext.id}/deleteRow/${idToDelete}`
			);

			handleSetMasterplan(data.data);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Detalle eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("¡Hubo un error!", {
					role: "alert",
					theme: "dark",
				});
			}
			console.log(error);
		}

		setEdit(false);
		setIdEditing("");
		setIdToDelete("");
		setSelectPublic("");
		setSelectPublicName("");
		setQuantity(0);
		setOpening(0);
		setAveragetime("");
		setShowAlert(false);
	};

	const isValidated = () => {
		if (selectPublic === "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`debe seleccionar un público!`, {
					role: "alert",
					theme: "dark",
				});
			}
			return false;
		}

		if (quantity === "" || quantity === 0 || isNaN(quantity)) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`La cantidad no puede ser nula!`, {
					role: "alert",
					theme: "dark",
				});
			}
			return false;
		}

		if (opening === "" || opening === 0 || isNaN(opening)) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`La apertura no puede ser nula!`, {
					role: "alert",
					theme: "dark",
				});
			}
			return false;
		}

		if (averagetime === "" || averagetime === "00:00:00") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El tiempo promedio no puede ser nulo!`, {
					role: "alert",
					theme: "dark",
				});
			}
			return false;
		}

		return true;
	};

	//Paso 2 del guardado-edicion
	const saveStageTwo = async (detalle) => {
		const validation = isValidated();
		if (!validation) return;

		try {
			const { data } = await axiosWithToken.post(
				"/masterplanStagetwo/stageTwo",
				{
					masterplan_id: masterplanContext.id,
					detalle,
				}
			);

			handleStageTwoDetails(data.stageTwo);
			handleSetMasterplan(data.data);

			setEdit(false);
			setIdEditing("");
			setIdToDelete("");
			setSelectPublic("");
			setSelectPublicName("");
			setQuantity(0);
			setOpening(0);
			setAveragetime("");

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(
					"¡Detalle por rubro y familia guardado, puede continuar!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		} catch (error) {
			console.log({ error });
			toast.warning(
				`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
				{
					role: "alert",
					theme: "dark",
				}
			);
			return;
		}
	};

	return (
		<Modal
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			show={show} /*onHide={handleCloseEstructure}*/
		>
			<Modal.Header>
				<Modal.Title>
					<h6 className='mb-0 hover-actions-trigger'>
						Familia: {detailsFamilyStgOne.family.name}{" "}
					</h6>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className='mb-3'>
					<Col xs={12} sm={12} lg={12} md={12}>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Cantidad planificada: </strong>
							{detailsFamilyStgOne.quantity}{" "}
						</p>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Apertura: </strong>
							{detailsFamilyStgOne.opening}{" "}
						</p>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Tiempo promedio: </strong>
							{detailsFamilyStgOne.averagetime}{" "}
						</p>
					</Col>

					<Divider />

					<Col xs={12} sm={12} lg={4} md={4}>
						<Card className='mb-2 p-2 h-md-100'>
							<Form>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridPublics'
									>
										<Form.Label className='fs--2'>
											Público
											<span className='text-danger'>*</span>
										</Form.Label>

										<Form.Select
											size='sm'
											value={selectPublic || ""}
											aria-label='publico'
											onChange={handleSelectPublic}
											disabled={edit}
										>
											<option value='0'>Seleccionar...</option>
											{publics.map((elem) => {
												return (
													<option key={elem.id} value={elem.id}>
														{elem.description}
													</option>
												);
											})}
										</Form.Select>
									</Form.Group>
								</Row>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridPublics'
									>
										<Form.Label className='fs--2'>
											Cantidad planificada
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											value={quantity}
											onChange={(e) =>
												isNaN(e.target.value)
													? ""
													: setQuantity(Number(e.target.value))
											}
											type='text'
											placeholder=''
										/>
									</Form.Group>

									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridPublics'
									>
										<Form.Label className='fs--2'>
											Apertura
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											value={opening}
											onChange={(e) =>
												isNaN(e.target.value)
													? ""
													: setOpening(Number(e.target.value))
											}
											type='text'
											placeholder=''
										/>
									</Form.Group>

									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridPublics'
									>
										<Form.Label className='fs--1'>
											Tiempo promedio <small>(hh:mm:ss)</small>
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											type={"text"}
											value={averagetime}
											onChange={handleTime}
											placeholder={"hh : mm : ss"}
										/>
									</Form.Group>
								</Row>
								<Row className='mt-3'>
									<Col className='d-flex justify-content-end fs--2'>
										<Button
											variant='success'
											className='ms-1 fs--2'
											size='sm'
											onClick={() => handleAddPublic()}
										>
											Guardar
										</Button>

										{edit ? (
											<Button
												size='sm'
												className='ms-1 fs--2'
												variant='danger'
												onClick={() => {
													setEdit(false);
													setIdEditing("");
													setSelectPublic("");
													setSelectPublicName("");
													setQuantity(0);
													setOpening(0);
													setAveragetime("");
												}}
											>
												Cancelar
											</Button>
										) : (
											""
										)}
									</Col>
								</Row>
							</Form>
						</Card>
					</Col>

					<Col xs={12} sm={12} lg={8} md={8}>
						<Card className='mb-2 p-2 h-md-100'>
							<div className='table-striped fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th
												style={{ width: "20%" }}
												className='border-0 text-start'
											>
												Público
											</th>
											<th className='border-0 text-start'>Cantidad</th>
											<th className='border-0 text-center'>Apertura</th>
											<th className='border-0 text-center'>Cant. x Aper.</th>
											<th className='border-0 text-center'>Tiempo</th>
											<th
												style={{ width: "30%" }}
												className='border-0 text-center'
											>
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{paginatedDetails && paginatedDetails.length ? (
											paginatedDetails.map((item) => (
												<tr key={`tr-${item.publicId}`} className='border-200'>
													<td className='text-start'>{item.name}</td>
													<td className='text-start'>{item.quantity}</td>
													<td className='text-center'>{item.opening}</td>
													<td className='text-center'>
														{item.quantity_opening}
													</td>
													<td className='text-center'>{item.averagetime}</td>
													<td className='text-end'>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Editar</Tooltip>}
														>
															<Button
																onClick={() => {
																	setEdit(true);
																	setIdEditing(item.id);
																	setSelectPublic(item.publicId);
																	setSelectPublicName(item.name);
																	setQuantity(item.quantity);
																	setOpening(item.opening);
																	setAveragetime(item.averagetime);
																}}
																variant='transparent'
																size='sm'
																style={{ fontSize: "11px" }}
																className='text-success'
															>
																<FontAwesomeIcon icon='edit' />
															</Button>
														</OverlayTrigger>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Eliminar</Tooltip>}
														>
															<Button
																onClick={() => handleDelete(item.id)}
																variant='transparent'
																size='sm'
																style={{ fontSize: "11px" }}
																className='text-danger me-2'
															>
																<FontAwesomeIcon icon='trash' />
															</Button>
														</OverlayTrigger>
													</td>
												</tr>
											))
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>

							<div className='d-flex justify-content-center'>
								<div>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canPreviousPage}
										onClick={prevPage}
										className='me-2'
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-left' />
									</Button>
								</div>

								<ul className='pagination mb-0'>
									{paginationArray.map((page) => (
										<li
											key={page}
											className={classNames({
												active: currentPage === page,
											})}
										>
											<Button
												size='sm'
												variant='falcon-default'
												className='page me-2'
												onClick={() => goToPage(page)}
											>
												{page}
											</Button>
										</li>
									))}
								</ul>
								<div>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canNextPage}
										onClick={nextPage}
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-right' />
									</Button>
								</div>
							</div>
						</Card>
					</Col>
				</Row>

				<Modal
					size='md'
					aria-labelledby='contained-modal-title-vcenter'
					centered
					show={showAlert} /*onHide={handleCloseEstructure}*/
				>
					<Modal.Header>
						<Modal.Title>
							<h6 className='mb-0 hover-actions-trigger'>
								¿Estás seguro de que deseas eliminar?
							</h6>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className='mt-1'>
							<span className='text-danger fs--2'>
								<strong>
									¡Se eliminaran detalles relacionados en las etapas siguientes
									si la familia lo tiene!
								</strong>
							</span>
						</Row>
						<Row className='mt-3'>
							<Col className='d-flex justify-content-end'>
								<Button variant='danger' size='sm' onClick={closeModalDelete}>
									Cancelar
								</Button>
								<Button
									className='ms-1'
									variant='success'
									size='sm'
									onClick={handleConfirmDelete}
								>
									Confirmar
								</Button>
							</Col>
						</Row>
					</Modal.Body>
				</Modal>

				<Row className='mt-3'>
					<Col className='d-flex justify-content-end'>
						<Button
							size='sm'
							className='ms-1 fs--2'
							variant='danger'
							onClick={() => setShow(false)}
						>
							Salir
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

MasterplanModalStageTwo.propTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	detailsFamilyStgOne: PropTypes.object,
	detail: PropTypes.array,
	setDetail: PropTypes.func,
};

export default MasterplanModalStageTwo;
