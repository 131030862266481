/** @format */

import React, { useRef, useEffect } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { getColor, rgbaColor } from "helpers/utils";
import { PieChart } from "echarts/charts";
import * as echarts from "echarts/core";
import {
	GridComponent,
	TooltipComponent,
	TitleComponent,
	LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	PieChart,
	CanvasRenderer,
	LegendComponent,
]);
import PropTypes from "prop-types";

const PieChartGraf = ({ data, height }) => {
	const chartRef = useRef(null);

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	//Responsive on window resize
	const updateDimensions = () => {
		if (window.innerWidth < 530) {
			chartRef.current.getEchartsInstance().setOption({
				series: [{ radius: "45%" }],
			});
		} else
			chartRef.current.getEchartsInstance().setOption({
				series: [{ radius: "60%" }],
			});
	};

	//individualCostGraf
	const getOptions = () => ({
		legend: {
			left: "left",
			textStyle: {
				color: getColor("600"),
			},
		},
		series: [
			{
				type: "pie",
				radius: window.innerWidth < 530 ? "45%" : "60%",
				label: {
					color: getColor("700"),
				},
				center: ["50%", "55%"],
				data: data,
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: rgbaColor(getColor("600"), 0.5),
					},
				},
			},
		],
		tooltip: {
			trigger: "item",
			padding: [7, 10],
			backgroundColor: getColor("100"),
			borderColor: getColor("300"),
			textStyle: { color: getColor("dark") },
			borderWidth: 1,
			transitionDuration: 0,
			axisPointer: {
				type: "none",
			},
		},
	});

	return (
		<ReactEChartsCore
			echarts={echarts}
			option={getOptions()}
			ref={chartRef}
			style={{ height: height }}
		/>
	);
};

PieChartGraf.propTypes = {
	data: PropTypes.array,
	height: PropTypes.string,
};

export default PieChartGraf;
