/** @format */

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { Row, Col, Button, Card, Table } from "react-bootstrap";
import { generarId } from "../../helpers/utils";
import StageThreeDetailPagination from "./StageThreeDetailPagination";
import MasterplanModalStageThree from "./MasterplanModalStageThree";
import { useMasterplanContext } from "hooks/useMasterplanContext";
import { formatTimeToMinutes } from "helpers/formatTimeToMinutes";

const MasterplanFormStageThree = ({ setKey }) => {
	//Detalle para pasar al modal familia-publico
	const [detailPublicFamilyStgTwo, setDetailPublicFamilyStgTwo] = useState({});
	const [showModal, setShowModal] = useState(false);

	const {
		masterplanContext,
		stageTwoDetails,
		handleStageThreeDetails,
		stageThreeDetails,
	} = useMasterplanContext();

	useEffect(() => {
		if (stageTwoDetails && Object.entries(stageTwoDetails).length > 0) {
			getStageThree();
		}
	}, [stageTwoDetails, masterplanContext]);

	useEffect(() => {
		if (stageThreeDetails && stageThreeDetails.length > 0) {
			const stage2TotalMinutes = [];
			stageTwoDetails.forEach((stageTwo) => {
				const stageThree = stageTwo.stage_three;
				if (stageThree.length > 0) {
					const accumMinutesStg3 = stageThree.reduce((acc, stgThree) => {
						console.log(stgThree);
						const time = formatTimeToMinutes(stageTwo.averagetime);
						const quantity = stgThree.planned_quantity;
						const total = time * quantity;
						return (acc += total);
					}, 0);

					//TODO ya acumulé los minutos. me faltan acumular las cantidades para machear con las cantidades de la etapa anterior

					const obj = {
						stageTwoFamilyName: stageTwo.familyName,
						stgTwoQuantity: stageTwo.quantity,
						stgTwoTime:
							formatTimeToMinutes(stageTwo.averagetime) * stageTwo.quantity,
						stgThreeTime: accumMinutesStg3,
					};
					stage2TotalMinutes.push(obj);
				}
			});

			console.log({ stage2TotalMinutes });
		}
	}, [stageThreeDetails]);

	const getStageThree = () => {
		if (masterplanContext?.stage_one?.length > 0) {
			const stagesThreeDetails = [];
			masterplanContext.stage_one.forEach((stageOne) => {
				let etapaDos = stageOne.stage_two;
				if (etapaDos.length > 0) {
					etapaDos.forEach((stageTwo) => {
						let etapaTres = stageTwo.stage_three;
						if (etapaTres && etapaTres.length > 0) {
							etapaTres.forEach((stageThree) => {
								const newOpeningSeason = {
									id: stageThree.id,
									familyPublicid: stageThree.sale_masterplan_stagetwo_id,
									idKey: generarId(),
									name: stageTwo.publico.description,
									nameId: stageTwo.public_id,
									familyName: stageOne.family.name,
									quantity: stageThree.planned_quantity,
									seasonId: stageThree.temporada_id,
									seasonName: stageThree.season.name,
									opening: stageThree.opening,
									stage_four: [...stageThree.stage_four],
								};
								stagesThreeDetails.push(newOpeningSeason);
							});
						}
					});
				}
			});

			handleStageThreeDetails(stagesThreeDetails);
		}
	};

	const handleShowModal = (item) => {
		setDetailPublicFamilyStgTwo(item);
		setShowModal(true);
	};

	return (
		<Row>
			{showModal && (
				<MasterplanModalStageThree
					show={showModal}
					setShow={setShowModal}
					detailPublicFamilyStgTwo={detailPublicFamilyStgTwo}
				/>
			)}
			<Col className='mb-2' xs={12} sm={12} lg={5} md={5}>
				<Card className='mb-2 p-2 h-md-100'>
					<Card.Body>
						<h6 className='mb-4 text-center fs--2'>
							Cantidades por rubro/familias planificadas
						</h6>

						<Row>
							<div className='table-responsive fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
									striped
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th
												style={{ width: "30%" }}
												className='border-0 text-start'
											>
												Familia
											</th>
											<th
												style={{ width: "30%" }}
												className='border-0 text-center'
											>
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{stageTwoDetails && stageTwoDetails.length > 0 ? (
											<>
												<StageThreeDetailPagination
													stageTwoDetails={stageTwoDetails}
													handleShowModal={handleShowModal}
												/>
											</>
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col className='mb-2' xs={12} sm={12} lg={7} md={7}>
				<Card className='mb-2 p-2 h-md-100'>
					<Card.Body>
						<h6 className='mb-4 text-center fs--2'>
							Info detalle por públicos - familias - temporadas
						</h6>

						<Row>
							<div className='table-responsive fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
									striped
									bordered
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th
												style={{ width: "20%" }}
												className='border-0 text-start'
											>
												Familia
											</th>
											<th
												style={{ width: "20%" }}
												className='border-0 text-start'
											>
												Público
											</th>

											<th
												style={{ width: "15%" }}
												className='border-0 text-start'
											>
												Temporada
											</th>
											<th className='border-0 text-center'>Apertura</th>
											<th
												style={{ width: "20%" }}
												className='border-0 text-center'
											>
												Cant.
											</th>
										</tr>
									</thead>
									<tbody>
										{stageThreeDetails && stageThreeDetails.length > 0 ? (
											stageThreeDetails.map((item, index) => (
												<tr key={`index-${index}`} className='border-200'>
													<td className='text-start'>{item.familyName}</td>
													<td className='text-start'>{item.name}</td>
													<td className='text-start'>{item.seasonName}</td>
													<td className='text-center'>{item.opening}</td>
													<td className='text-center'>{item.quantity}</td>
												</tr>
											))
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col
				xs={12}
				sm={12}
				lg={12}
				md={12}
				className='mb-2 mt-3 d-flex justify-content-end'
			>
				{stageThreeDetails && stageThreeDetails.length ? (
					<Button size='sm' variant='primary' onClick={() => setKey("stage4")}>
						Seguir
					</Button>
				) : (
					""
				)}
			</Col>
		</Row>
	);
};

MasterplanFormStageThree.propTypes = {
	setKey: PropTypes.func,
};

export default MasterplanFormStageThree;
