/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Row, Col, Button, Card, Tab, Accordion } from "react-bootstrap";
import Select from "react-select";
import { camelize } from "../../../helpers/utils";
import { useCentros } from "hooks/useCentros";
import { getEmployees } from "data/employee/employees";
import { getMachineTypes } from "data/machine/machines";
import { getTopLevelCenters } from "data/center/centers";
import Divider from "components/common/Divider";
import { useAuthContext } from "hooks/useAuthContext";

const FormManPower = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [description, setDescription] = useState("");
	const [dni, setDni] = useState("");
	const [address, setAddress] = useState("");
	const [phone, setPhone] = useState("");
	const [cod_recurso, setCodRecurso] = useState("");
	const [cod_tipo_mo, setCodTipoMO] = useState(0);
	const [checkIn, setCheckIn] = useState("");
	const [checkOut, setCheckOut] = useState("");
	const [fridayCheckOut, setFridayCheckOut] = useState("");
	const [desaCheckIn, setDesaCheckIn] = useState("");
	const [desaCheckOut, setDesaCheckOut] = useState("");
	const [almCheckIn, setAlmCheckIn] = useState("");
	const [almCheckOut, setAlmCheckOut] = useState("");
	const [referent, setReferent] = useState(0);
	const [status, setStatus] = useState(1);
	const [centerArray, setCenterArray] = useState([]);
	const [centers, setCenters] = useState([]);
	const [machinesType, setMachinesType] = useState([]);
	const [selectedMachineTypes, setSelectedMachineTypes] = useState([]);
	const [employees, setEmployees] = useState([]);

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	const { id_mo } = useParams();

	const { getAllManPowerTypes, manPower_types } = useCentros();

	const volver = () => {
		navigate(-1);
	};

	useEffect(async () => {
		const employeesApi = await getEmployees();
		setEmployees(employeesApi);

		const machineTypesApi = await getMachineTypes();
		setMachinesType(machineTypesApi);

		const topLevelCentersApi = await getTopLevelCenters();
		setCenters(topLevelCentersApi);

		getAllManPowerTypes();
	}, []);

	useEffect(() => {
		checkCenterInputs();
	}, [centers]);

	const checkCenterInputs = () => {
		centerArray.map((center) => {
			let code = center.toString();
			let input = document.querySelector(
				`[name="center_1" ][ value="${code}"]`
			);

			if (input) {
				input.checked = true;
			}
		});
	};

	const handleSelectMachineTypes = (value) => {
		setSelectedMachineTypes(value);
	};

	const selectedCenter = () => {
		//checkbox de maquinarias
		const centersSelected = Object.values(
			document.querySelectorAll('input[name="center_1"]:checked')
		);
		const obj = centersSelected.map((elem) => {
			const objeto = Number(elem.value);
			return objeto;
		});

		setCenterArray(obj);
	};

	const storeManPower = async (e) => {
		e.preventDefault();
		if ([description, cod_recurso].includes("")) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					"¡Por favor complete todos los campos!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			return;
		}

		if (status === undefined || status === null) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning("¡Debe seleccionar un Estado!", {
					role: "alert",
					theme: "dark",
				});
			}
			return;
		}

		//ADD MANPOWER
		if (id_mo === undefined) {
			try {
				const respuesta = await axiosWithToken.post(
					"/work-center/add-manpower",
					{
						description,
						dni,
						address,
						phone,
						cod_recurso,
						cod_tipo_mo,
						checkIn,
						checkOut,
						fridayCheckOut,
						desaCheckIn,
						desaCheckOut,
						almCheckIn,
						almCheckOut,
						referent,
						status,
						selectedMachineTypes, //machine's types
						centerArray,
					}
				);

				if (respuesta.status == 200) {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.success("¡Agregado correctamente!", {
							role: "alert",
							theme: "dark",
						});
					}

					setTimeout(() => {
						navigate("/work-center/manpower-list/");
					}, 1300);
				} else {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(`${respuesta.message}`, {
							role: "alert",
							theme: "dark",
						});
					}
				}
			} catch (error) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${error.response.data.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		} else {
			//EDIT MANPOWER
			try {
				const respuesta = await axiosWithToken.put(
					`/work-center/update-manpower/${id_mo}`,
					{
						description,
						dni,
						address,
						phone,
						cod_recurso,
						cod_tipo_mo,
						checkIn,
						checkOut,
						fridayCheckOut,
						desaCheckIn,
						desaCheckOut,
						almCheckIn,
						almCheckOut,
						referent,
						status,
						selectedMachineTypes, //machine's types
						centerArray,
					}
				);

				if (respuesta.status == 200) {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.success("Editado correctamente!", {
							role: "alert",
							theme: "dark",
						});
					}

					setTimeout(() => {
						navigate(`/work-center/manpower-list/view/${id_mo}`);
						//navigate(0)
					}, 1300);
				} else {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.error(`${respuesta.message}`, {
							role: "alert",
							theme: "dark",
						});
					}
				}
			} catch (error) {
				console.log(error);
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${error.response.data.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								{id_mo === undefined ? "Agregar" : "Editar"} Mano de Obra
							</h5>
							<p className='mt-2 mb-0'>Ingresá los datos correspondientes.</p>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={storeManPower}>
							<Row className='mb-3 g-3'>
								{/* NAME */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={4}
									lg={4}
									controlId='formGridDescriptionMan'
								>
									<Form.Label>Nombre y Apellido</Form.Label>
									<Form.Control
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										type='text'
										placeholder='Ingrese nombre completo'
										size='sm'
									/>
								</Form.Group>

								{/* DOCUMENTO */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={4}
									lg={4}
									controlId='formGridDocumento'
								>
									<Form.Label>Nro.Doc</Form.Label>
									<Form.Control
										value={dni}
										onChange={(e) => setDni(e.target.value)}
										type='text'
										placeholder='Ingrese documento'
										size='sm'
									/>
								</Form.Group>

								{/* ADDRESS */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={4}
									lg={4}
									controlId='formGridAddress'
								>
									<Form.Label>Dirección</Form.Label>
									<Form.Control
										value={address}
										onChange={(e) => setAddress(e.target.value)}
										type='text'
										placeholder='Ingrese una dirección'
										size='sm'
									/>
								</Form.Group>

								{/* PHONE */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={4}
									lg={4}
									controlId='formGridphone'
								>
									<Form.Label>Tel.</Form.Label>
									<Form.Control
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										type='text'
										placeholder='Ingrese un nÃºmero de teléfono'
										size='sm'
									/>
								</Form.Group>

								{/* LEGAJO */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={4}
									lg={4}
									controlId='formGridCodMan'
								>
									<Form.Label>N° legajo</Form.Label>
									<Form.Control
										value={cod_recurso}
										onChange={(e) => setCodRecurso(e.target.value)}
										type='text'
										placeholder='Ingrese legajo'
										size='sm'
									/>
								</Form.Group>

								{/* TIPO MO */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={4}
									lg={4}
									controlId='formGridTipoMan'
								>
									<Form.Label>Tipo Mano de Obra</Form.Label>
									<Form.Select
										aria-label='tiposManoDeObra'
										value={cod_tipo_mo}
										onChange={(e) => setCodTipoMO(e.target.value)}
										size='sm'
									>
										<option value={0} disabled>
											-- Seleccionar una opción --
										</option>
										{manPower_types.map((elem) => {
											return (
												<option key={elem.id} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								<Divider />

								{/* HORARIO DE ENTRADA */}
								<Form.Group
									as={Col}
									xs={12}
									sm={4}
									md={3}
									lg={3}
									controlId='formGridCheckin'
								>
									<Form.Label>Hora de entrada</Form.Label>
									<Form.Control
										value={checkIn}
										onChange={(e) => setCheckIn(e.target.value)}
										type='time'
										size='sm'
									/>
								</Form.Group>

								{/* HORARIO DE SALIDA */}
								<Form.Group
									as={Col}
									xs={12}
									sm={4}
									md={3}
									lg={3}
									controlId='formGridCheckOut'
								>
									<Form.Label>Hora de salida</Form.Label>
									<Form.Control
										value={checkOut}
										onChange={(e) => setCheckOut(e.target.value)}
										type='time'
										size='sm'
									/>
								</Form.Group>

								{/* HORARIO DE SALIDA VIERNES */}
								<Form.Group
									as={Col}
									xs={12}
									sm={4}
									md={3}
									lg={3}
									controlId='formGridFridayCheckOut'
								>
									<Form.Label>Hora Sal Vier</Form.Label>
									<Form.Control
										value={fridayCheckOut}
										onChange={(e) => setFridayCheckOut(e.target.value)}
										type='time'
										size='sm'
									/>
								</Form.Group>

								{/* HORARIO INI DESA */}
								<Form.Group
									as={Col}
									xs={12}
									sm={4}
									md={3}
									lg={3}
									controlId='formGridIniDesaCheckIn'
								>
									<Form.Label>Hora Ini Desa</Form.Label>
									<Form.Control
										value={desaCheckIn}
										onChange={(e) => setDesaCheckIn(e.target.value)}
										type='time'
										size='sm'
									/>
								</Form.Group>

								{/* HORARIO FIN DESA */}
								<Form.Group
									as={Col}
									xs={12}
									sm={4}
									md={3}
									lg={3}
									controlId='formGridFinDesaCheckOut'
								>
									<Form.Label>Hora Fin Desa</Form.Label>
									<Form.Control
										value={desaCheckOut}
										onChange={(e) => setDesaCheckOut(e.target.value)}
										type='time'
										size='sm'
									/>
								</Form.Group>

								{/* HORARIO INI ALM */}
								<Form.Group
									as={Col}
									xs={12}
									sm={4}
									md={3}
									lg={3}
									controlId='formGridIniAlmCheckIn'
								>
									<Form.Label>Hora Ini Alm</Form.Label>
									<Form.Control
										value={almCheckIn}
										onChange={(e) => setAlmCheckIn(e.target.value)}
										type='time'
										size='sm'
									/>
								</Form.Group>

								{/* HORARIO FIN ALM */}
								<Form.Group
									as={Col}
									xs={12}
									sm={4}
									md={3}
									lg={3}
									controlId='formGridFinAlmCheckOut'
								>
									<Form.Label>Hora Fin Alm</Form.Label>
									<Form.Control
										value={almCheckOut}
										onChange={(e) => setAlmCheckOut(e.target.value)}
										type='time'
										size='sm'
									/>
								</Form.Group>

								{/* REFERENTE*/}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={3}
									lg={3}
									controlId='formGridReferent'
								>
									<Form.Label>Referente</Form.Label>
									<Form.Select
										aria-label='referente'
										value={referent}
										onChange={(e) => setReferent(e.target.value)}
										size='sm'
									>
										<option value={0}> - </option>
										{employees.map((elem) => {
											return (
												<option key={elem.id} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								<Divider />

								{/* EFICIENCIA */}
								{/* <Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={6}
									lg={6}
									controlId='formEficMan'
								>
									<Form.Label>Eficiencia</Form.Label>
									<Form.Control
										value={eficiencia}
										onChange={(e) => setEficiencia(e.target.value)}
										type='text'
										placeholder='Ej: 0.9 = 90%'
									/>
								</Form.Group> */}

								{/* TIPO MAQ */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridManPowerPadre'
								>
									<Col xs={12} sm={12} md={12} lg={12}>
										<Form.Label className='fs--1'>Tipo de Máquina</Form.Label>
										<Select
											options={machinesType}
											value={selectedMachineTypes}
											isMulti
											onChange={handleSelectMachineTypes}
											placeholder={"Seleccione un tipo de máquina"}
										/>
									</Col>
								</Form.Group>

								{/* CENTERS */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridManPowerPadreCenter'
								>
									<Form.Label>Centro de trabajo</Form.Label>
									<Accordion>
										<Accordion.Item eventKey='0'>
											<Accordion.Header>Centros de trabajo</Accordion.Header>
											<Accordion.Body>
												{centers.map((elem) => {
													return (
														<Form.Check
															key={elem.id}
															inline
															label={`#${elem.id} ${elem.description}`}
															name='center_1'
															value={elem.id}
															type={"checkbox"}
															id={elem.id}
															onClick={() => selectedCenter()}
														/>
													);
												})}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Form.Group>

								{/* ESTADO */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridEstadoMan'
								>
									<Form.Label>Estado</Form.Label>
									<Form.Select
										value={status}
										onChange={(e) => setStatus(e.target.value)}
										size='sm'
									>
										<option disabled>-- Seleccionar Estado --</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Form.Select>
								</Form.Group>
							</Row>

							<Row>
								<Col>
									<Button variant='warning' onClick={() => volver()}>
										Volver
									</Button>
								</Col>

								<Col>
									<Button className='float-end' variant='primary' type='submit'>
										{id_mo === undefined ? "Guardar" : "Editar"}
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

export default FormManPower;
