/** @format */

import React, { useContext } from "react";
import NavbarDropdown from "./NavbarDropdown";
import {
	Productos,
	Proveedores,
	Configurador,
	CentroTrabajo,
	Operations,
	HojaRuta,
	Estamperia,
	Configuraciones,
	Calendario,
	SalesMasterPlan,
} from "routes/routes";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppContext from "context/Context";
import NavbarDropdownProducts from "./NavbarDropdownProducts";

const NavbarTopDropDownMenus = () => {
	const {
		config: { navbarCollapsed, showBurgerMenu },
		setConfig,
	} = useContext(AppContext);

	const handleDropdownItemClick = () => {
		if (navbarCollapsed) {
			setConfig("navbarCollapsed", !navbarCollapsed);
		}
		if (showBurgerMenu) {
			setConfig("showBurgerMenu", !showBurgerMenu);
		}
	};

	return (
		<>
			<NavbarDropdown title='Ingenieria de productos'>
				<NavbarDropdownProducts items={Productos.children} />
			</NavbarDropdown>
			<NavbarDropdown title='proveedores'>
				{Proveedores.children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='configurador'>
				{Configurador.children[0].children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='centro de trabajo'>
				{CentroTrabajo.children[0].children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='operations'>
				{Operations.children[0].children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='Hoja de Ruta'>
				{HojaRuta.children[0].children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='Estamperia'>
				{Estamperia.children[0].children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='configuraciones'>
				{Configuraciones.children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='calendario'>
				{Calendario.children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
			<NavbarDropdown title='Plan maestro'>
				{SalesMasterPlan.children[0].children.map((route) => (
					<Dropdown.Item
						key={route.name}
						as={Link}
						className={route.active ? "link-800" : "text-500"}
						to={route.to}
						onClick={handleDropdownItemClick}
					>
						<span className='fs--2'>{route.name}</span>
					</Dropdown.Item>
				))}
			</NavbarDropdown>
		</>
	);
};

export default NavbarTopDropDownMenus;
