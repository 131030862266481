/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
	Row,
	Col,
	Form,
	Button,
	Badge,
	Table,
	OverlayTrigger,
	Tooltip,
	Card,
} from "react-bootstrap";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { toast } from "react-toastify";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { generarId } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { useRawmaterialContext } from "hooks/useRawmaterialContext";

const Formulario = ({ setDisabledHdr, setKey }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Se envía al back el id de la estructura para poder modificarla
	const [idEstructura, setIdEstructura] = useState(null);

	//Estado objeto de materia prima devuelto por el buscador
	const [materiaPrima, setMateriaPrima] = useState({});

	//Estado para saber si se está modificando un dato de la mp
	const [edit, setEdit] = useState(0);

	//Validaciones
	const [disabledUM, setDisabledUM] = useState(true);
	const [disabledSearchMp, setDisabledSearchMp] = useState(false);

	//Tipo de hilo
	const [showThreadupType, setShowThreadupType] = useState(false);
	//Tipo de máquina
	const [selectMachtype, setSelectMachtype] = useState("");

	//Estados para datos del modal
	const [unitsMP, setUnitsMP] = useState([]);
	const [estructura, setEstructura] = useState([]);
	const [codeMP, setCodeMP] = useState("");
	const [idkeyEdit, setIdkeyEdit] = useState("");
	const [cantidad, setCantidad] = useState(0);
	const [orden, setOrder] = useState(0);
	const [selectUnitmp, setSelectUnitmp] = useState(0);

	const { rawmaterial, structure, handleStructureByRawmaterialId } =
		useRawmaterialContext();

	useEffect(() => {
		if (structure && Object.entries(structure).length > 0) {
			setIdEstructura(structure.id);
			setEstructura(
				structure.structure_rawmaterials.map((detail) => {
					return {
						idkey: detail.id,
						id: detail.rawmaterial_id,
						description: detail.rawmaterial.name,
						codeMP: detail.rawmaterial.code,
						cantidad: detail.quantity,
						orden: detail.technical_order,
						unidadmp: detail.unit_id,
						unidades: detail.rawmaterial.units.map((unit) => {
							return {
								value: unit.id,
								label: unit.description,
							};
						}),
						tipohilo: detail.rawmaterial.thread,
						tipomaquina: detail.machinetype,
					};
				})
			);
			setDisabledHdr(false);
		} else {
			//Limpiamos los estados
			setIdEstructura(null);
			setEstructura([]);
		}
	}, [structure]);

	useEffect(() => {
		if (Object.entries(materiaPrima).length > 0) {
			if (rawmaterial.code == materiaPrima.code) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						"No puede agregar el mismo producto a su estructura",
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				setTimeout(() => {
					setCodeMP("");
				}, 1000);

				// error
				return;
			}

			setCodeMP(materiaPrima.code);

			let units = Object.values(materiaPrima.units);
			setUnitsMP(
				units.map((unit) => {
					return {
						value: unit.id,
						label: unit.description,
					};
				})
			);

			setDisabledUM(false);

			let codeMAterial = materiaPrima.code.slice(0, 3);

			if (codeMAterial == "002") {
				setShowThreadupType(true);
			} else {
				setShowThreadupType(false);
			}
		}
	}, [materiaPrima]);

	const handleAddEstructure = async () => {
		if (codeMP == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo código es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		//Códigos individuales
		let codeMAterial = codeMP.slice(0, 3);

		if (codeMAterial == "002") {
			if (selectMachtype == "") {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El seleccionar un tipo de máquina es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		if (cantidad == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo cantidad es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (orden == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo orden es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (selectUnitmp == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar una unidad de medida es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.get(
				`/rawmaterial/bycode/${codeMP}`
			);

			const newEs = {
				idkey: edit ? idkeyEdit : generarId(),
				id: data.data.id,
				description: data.data.description,
				codeMP: codeMP,
				cantidad: cantidad,
				orden: orden,
				unidadmp: selectUnitmp,
				unidades: unitsMP,
				tipohilo: data.data.thread,
				tipomaquina: selectMachtype != "" ? selectMachtype : null,
			};

			if (edit == 1) {
				console.log("editando existente");
				//Editando existente
				const estructuraAct = estructura.map((estructuraState) =>
					estructuraState.idkey === newEs.idkey ? newEs : estructuraState
				);
				setEstructura(estructuraAct);
				setEdit(0);
			} else {
				//1ero tomar el código para saber que material estoy queriendo meter a la estructura, esto porque para hilo tiene validaciones distintas

				//Código de material en estado, el que se está por agregar a la estructura
				let codeMaterial1 = codeMP.slice(0, 3);

				//2do si es distinto de hilo hacer una validación simple que se fije si lo que estoy queriendo agregar ya está en la estructura
				if (codeMaterial1 != "002") {
					//Buscamos que la materia prima no esté guardada ya para poder agregarla
					const mprimaBuscada = estructura.find((str) => str.codeMP === codeMP);

					if (mprimaBuscada) {
						//La materia prima ya está en el arreglo
						if (!toast.isActive(toastId.current)) {
							toastId.current = toast.error(
								"La materia prima ya está cargada",
								{
									role: "alert",
									theme: "dark",
								}
							);
						}
					} else {
						//La materia prima no está cargada, por lo que la agregamos
						setEstructura([...estructura, newEs]);
					}
				} else {
					//3ro si es hilo, tengo que recorrer la estructura ya en estado para ver si ya se ingresó hilo de arriba y de abajo para el tipo de máquina
					//que estoy queriendo agregar a la estructura

					let hiloarriba = false;
					let hiloabajo = false;

					estructura.forEach((str) => {
						if (newEs.tipohilo == 1) {
							if (
								newEs.tipohilo == str.tipohilo &&
								newEs.tipomaquina == str.tipomaquina
							) {
								hiloarriba = true;
							}
						} else if (newEs.tipohilo == 0) {
							if (
								newEs.tipohilo == str.tipohilo &&
								newEs.tipomaquina == str.tipomaquina
							) {
								hiloarriba = true;
							}
						}
					});

					//4to mostrar mensajes dependiendo de las validaciones

					if (hiloarriba) {
						if (!toast.isActive(toastId.current)) {
							toastId.current = toast.error(
								"Ya se cargó un hilo de arriba para el tipo de máquina que intenta cargar",
								{
									role: "alert",
									theme: "dark",
								}
							);
						}
					} else if (hiloabajo) {
						if (!toast.isActive(toastId.current)) {
							toastId.current = toast.error(
								"Ya se cargó un hilo de abajo para el tipo de máquina que intenta cargar",
								{
									role: "alert",
									theme: "dark",
								}
							);
						}
					} else {
						setEstructura([...estructura, newEs]);
					}
				}
			}

			setCodeMP("");
			setCantidad(0);
			setOrder(0);
			setSelectUnitmp("");
			setDisabledUM(true);
			setShowThreadupType(false);
			setSelectMachtype("");
			setDisabledSearchMp(false);
			setMateriaPrima({});
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
	};

	const handleCancelar = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/rawmaterials/list`);
	};

	const handleDelete = (codeitem) => {
		const newEstructura = estructura.filter(
			(estructura) => estructura.codeMP !== codeitem
		);
		setEstructura(newEstructura);
	};

	const handleSaveEstructure = async () => {
		if (!estructura.length) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`Debe cargar datos en la estructura.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.post(
				"/rawmaterial/insertUpdateStructure",
				{
					idEstructura: idEstructura,
					code: rawmaterial.code,
					objStructure: JSON.stringify(estructura),
				}
			);

			handleStructureByRawmaterialId(data.data.id);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				setDisabledHdr(false);
				setKey("hdr");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
	};

	/* 	const handleDeleteStructure = async (structure_id) => {
		try {
			await axios.delete(
				`${process.env.REACT_APP_ENDPOINT_API}/rawmaterial/deleteStructure/${structure_id}`
			);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
		//Cerrar modal
		setShow(false);
	}; */

	return (
		<>
			<Row className='mb-3'>
				<h6 className='mb-4 text-center fs--2'>
					Estructura para la materia prima:{" "}
					{rawmaterial.name && (
						<span>
							<Badge bg='secondary'>{rawmaterial.name}</Badge>
						</span>
					)}
				</h6>
				<Col className='mb-2' xs={12} sm={12} lg={4} md={4}>
					<Card className='mb-2 p-2 h-md-100'>
						<Row className='mb-3'>
							<Form.Group as={Col} md={12} lg={12} controlId='formGridCode'>
								<Form.Label className='fs--2'>
									Código<span className='text-danger'>*</span>
								</Form.Label>{" "}
								{""}{" "}
								{codeMP && codeMP != "" && (
									<span
										style={{ fontSize: "12px" }}
										className='float-end text-dark'
									>
										<Badge bg='secondary'>{codeMP}</Badge>
									</span>
								)}
								<SearchAutocomplete
									setSelect={setMateriaPrima}
									modelSearch={"rawmaterial"}
									disabled={disabledSearchMp}
								/>
								{codeMP && codeMP != "" && (
									<span style={{ fontSize: "9px" }}>
										<strong>{materiaPrima.name}</strong>{" "}
										{materiaPrima.thread != null ? (
											materiaPrima.thread == 1 ? (
												<Badge bg='secondary' style={{ fontSize: "5px" }}>
													H. arriba
												</Badge>
											) : (
												<Badge bg='secondary' style={{ fontSize: "8px" }}>
													H. abajo
												</Badge>
											)
										) : (
											""
										)}{" "}
									</span>
								)}
							</Form.Group>

							{showThreadupType && (
								<Form.Group
									as={Col}
									md={12}
									lg={12}
									controlId='formGridMachtype'
								>
									<Form.Label className='fs--2'>
										Tipo de máquina
										<span className='text-danger'>*</span>
									</Form.Label>
									<Form.Select
										size='sm'
										aria-label='select-code'
										value={selectMachtype}
										onChange={(e) => setSelectMachtype(e.target.value)}
									>
										<option value='' disabled>
											Seleccionar...
										</option>
										<option value={1}>Común</option>
										<option value={2}>Flatseamer</option>
									</Form.Select>
								</Form.Group>
							)}

							<Form.Group as={Col} md={12} lg={12} controlId='formGridCantidad'>
								<Form.Label className='fs--2'>
									Cantidad<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Control
									size='sm'
									value={cantidad}
									onChange={(e) => setCantidad(e.target.value)}
									type='text'
									placeholder=''
								/>
							</Form.Group>

							<Form.Group as={Col} md={12} lg={12} controlId='formGridOrder'>
								<Form.Label className='fs--2'>
									Orden<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Control
									size='sm'
									value={orden}
									onChange={(e) => setOrder(e.target.value)}
									type='text'
									placeholder=''
								/>
							</Form.Group>

							<Form.Group as={Col} md={12} lg={12} controlId='formGridUnitsMp'>
								<Form.Label className='fs--2'>
									Unidad de medida<span className='text-danger'>*</span>
								</Form.Label>
								<Form.Select
									size='sm'
									value={selectUnitmp || ""}
									aria-label='uniadmp'
									disabled={disabledUM}
									onChange={(e) => setSelectUnitmp(e.target.value)}
								>
									<option value='0'>Seleccionar...</option>
									{unitsMP.map((elem) => {
										return (
											<option key={elem.value} value={elem.value}>
												{elem.label}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
						</Row>

						<Row className='mt-3'>
							<Col className='d-flex justify-content-end'>
								<Button
									className='ms-1 fs--2'
									variant='success'
									size='sm'
									onClick={() => handleAddEstructure()}
								>
									{!edit ? "Agregar" : "Editar"}
								</Button>

								{edit ? (
									<Button
										size='sm'
										className='ms-1 fs--2'
										variant='danger'
										onClick={() => {
											setCodeMP("");
											setSelectMachtype("");
											setCantidad(0);
											setOrder(0);
											setEdit(0);
											setSelectUnitmp("");
											setDisabledUM(true);
											setDisabledSearchMp(false);
											setShowThreadupType(false);
										}}
									>
										Cancelar
									</Button>
								) : (
									""
								)}
							</Col>
						</Row>
					</Card>
				</Col>
				<Col className='mb-2' xs={12} sm={12} lg={8} md={8}>
					<Card className='mb-2 p-2 h-md-100'>
						<Row>
							<div className='table-responsive fs--1'>
								<h6 className='mb-3 fs--2 d-flex justify-content-center'>
									Detalle de la Estructura de la Materia Prima
								</h6>
								<Table className='border-bottom' style={{ fontSize: "9px" }}>
									<thead className='bg-200 text-900'>
										<tr>
											<th className='border-0 text-start'>Código</th>
											<th
												style={{ width: "50%" }}
												className='border-0 text-start'
											>
												Descripción
											</th>
											<th className='border-0 text-center'>Cantidad</th>
											<th className='border-0 text-center'>Orden</th>
											<th
												style={{ width: "25%" }}
												className='border-0 text-start'
											>
												U. medida
											</th>
											<th
												style={{ width: "35%" }}
												className='border-0 text-start'
											>
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{estructura && estructura.length ? (
											estructura.map((item) => (
												<tr key={item.codeMP} className='border-200'>
													<td className='align-middle text-start'>
														{item.codeMP}
													</td>

													<td className='align-middle text-start'>
														{item.description}{" "}
														{item.tipohilo != null ? (
															item.tipohilo == 1 ? (
																<Badge
																	bg='secondary'
																	style={{ fontSize: "8px" }}
																>
																	H. arriba
																</Badge>
															) : (
																<Badge
																	bg='secondary'
																	style={{ fontSize: "8px" }}
																>
																	H. abajo
																</Badge>
															)
														) : (
															""
														)}{" "}
														{item.tipomaquina != null ? (
															item.tipomaquina == 2 ? (
																<Badge bg='danger' style={{ fontSize: "8px" }}>
																	Flat
																</Badge>
															) : (
																""
															)
														) : (
															""
														)}
													</td>

													<td className='align-middle text-center'>
														{item.cantidad}
													</td>

													<td className='align-middle text-center'>
														<Badge className='rounded-pill' bg='primary'>
															{item.orden}
														</Badge>
													</td>

													<td className='align-middle text-start'>
														{item.unidadmp}
													</td>

													<td className='align-middle text-start'>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Editar</Tooltip>}
														>
															<Button
																style={{ fontSize: "9px" }}
																onClick={() => {
																	setIdkeyEdit(item.idkey);
																	setCodeMP(item.codeMP);
																	setCantidad(item.cantidad);
																	setOrder(item.orden);
																	setUnitsMP(item.unidades);
																	setSelectUnitmp(item.unidadmp);
																	setEdit(1);
																	setDisabledUM(false);
																	setDisabledSearchMp(true);
																	item.tipohilo != null
																		? setShowThreadupType(true)
																		: setShowThreadupType(false);
																	item.tipohilo != null
																		? setSelectMachtype(item.tipomaquina)
																		: setSelectMachtype("");
																}}
																variant='transparent'
																size='sm'
																className='text-success'
															>
																<FontAwesomeIcon icon='edit' />
															</Button>
														</OverlayTrigger>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Eliminar</Tooltip>}
														>
															<Button
																style={{ fontSize: "9px" }}
																onClick={() => handleDelete(item.codeMP)}
																variant='transparent'
																size='sm'
																className='text-danger'
															>
																<FontAwesomeIcon icon='trash' />
															</Button>
														</OverlayTrigger>
													</td>
												</tr>
											))
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Row>
					</Card>
				</Col>
			</Row>

			<Row className='mt-3'>
				<Col className='d-flex justify-content-end'>
					<Button
						className='me-2'
						variant='danger'
						size='sm'
						onClick={() => handleCancelar()}
					>
						Cancelar
					</Button>

					<Button
						variant='success'
						size='sm'
						onClick={() => handleSaveEstructure()}
					>
						Guardar
					</Button>
				</Col>
			</Row>
		</>
	);
};

Formulario.propTypes = {
	setDisabledHdr: PropTypes.func,
	setKey: PropTypes.func,
};

export default Formulario;
