/** @format */

import React from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const ModalAssignRoadmap = ({
	show,
	setShow,
	nameStructure,
	setNameStructure,
	descriptionStructure,
	setDescriptionStructure,
}) => {
	const handleHideEstructure = () => {
		setShow(false);
	};

	return (
		<Modal
			size='md'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			show={show} /*onHide={handleCloseEstructure}*/
		>
			<Modal.Header>
				<Modal.Title>
					<h6 className='mb-0 hover-actions-trigger'>Datos de la estructura</h6>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className='mb-3'>
					<Form.Group
						as={Col}
						xs={12}
						sm={12}
						lg={12}
						md={12}
						controlId='formGridName'
					>
						<Form.Label className='fs--2'>
							Nombre estructura<span className='text-danger'>*</span>
						</Form.Label>
						<Form.Control
							size='sm'
							value={nameStructure}
							onChange={(e) => setNameStructure(e.target.value)}
							type='text'
							placeholder='Ingrese nombre'
						/>
					</Form.Group>
				</Row>

				<Row className='mb-3'>
					<Form.Group
						as={Col}
						xs={12}
						sm={12}
						lg={12}
						md={12}
						controlId='formGridDescription'
					>
						<Form.Label className='fs--2'>Descripción estructura</Form.Label>
						<Form.Control
							size='sm'
							value={descriptionStructure}
							onChange={(e) => setDescriptionStructure(e.target.value)}
							type='text'
							placeholder='Ingrese descripción'
						/>
					</Form.Group>
				</Row>

				<Row className='mt-3'>
					<Col className='d-flex justify-content-end'>
						<Button
							variant='success'
							size='sm'
							onClick={() => handleHideEstructure()}
						>
							Aceptar
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

ModalAssignRoadmap.propTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	nameStructure: PropTypes.string,
	setNameStructure: PropTypes.func,
	descriptionStructure: PropTypes.string,
	setDescriptionStructure: PropTypes.func,
};

export default ModalAssignRoadmap;
