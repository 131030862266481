/** @format */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Row,
	Col,
	Form,
	Button,
	Modal,
	Table,
	OverlayTrigger,
	Tooltip,
	Card,
} from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import Divider from "components/common/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import { generarId } from "../../helpers/utils";
import { dateFormatter } from "helpers/dateFormatter";
import { updateEventsList } from "data/calendar/eventos";
import { tipoEventosDataObj } from "components/calendario/data/tipoEventos";
import { getGenericProducts } from "data/product/products";
import { useMasterplanContext } from "hooks/useMasterplanContext";
import { useAuthContext } from "hooks/useAuthContext";

const MasterplanModalStageFour = ({
	show,
	setShow,
	detailPublicSeasonStgFour,
}) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [detalles, setDetalles] = useState([]);

	//Estado para saber si editar un registro
	const [edit, setEdit] = useState(false);
	const [idToDelete, setIdToDelete] = useState("");
	const [itemToEdit, setItemToEdit] = useState({});
	const [selectedEvent, setSelectedEvent] = useState("");
	const [eventOptions, setEventOptions] = useState([]);
	const [opening, setOpening] = useState(0);
	const [genericProductsOptions, setGenericProductsOptions] = useState([]);
	const [genericProdutsSelected, setGenericProdutsSelected] = useState([]);
	const [showAlert, setShowAlert] = useState(false);

	const { stageFourDetails, masterplanContext, handleSetMasterplan } =
		useMasterplanContext();

	const toastId = React.useRef(null);
	//objeto de errores
	const errors = {
		event: {
			used: "El evento ya fue utilizado!",
		},
		opening: {
			insufficient: "La cantidad de aperturas no puede ser 0!",
			exceeded: "Sobrepasó la cantidad de aperturas!",
		},
		genericProducts: "Debe seleccionar al menos un producto genérico!",
		detalles: "No hay datos cargados!",
	};

	useEffect(async () => {
		//Form reset on opening
		setEdit(false);
		setSelectedEvent("");
		setEventOptions([]);
		setOpening(0);
		setGenericProductsOptions([]);
		setGenericProdutsSelected([]);
		//Traemos todos los eventos
		const eventsApi = await updateEventsList();
		//Filtramos eventos por temporada y dia laboral
		const eventsBySeason = eventsApi.filter(
			(event) =>
				event.tipo_evento != tipoEventosDataObj.feriado.name &&
				event.tipo_evento != tipoEventosDataObj.finde.name &&
				Number(event.temporada_id) ===
					Number(detailPublicSeasonStgFour.seasonId)
		);

		//Formateamos los eventos para poder pasarlos como opciones del select
		const eventsBySeasonFormatted = eventsBySeason.map((event) => {
			const obj = {
				label: `${event.title} > ${dateFormatter(
					event.start
				)} - ${dateFormatter(event.end)}`,
				value: event.id,
				evento: event,
			};
			return obj;
		});
		setEventOptions(eventsBySeasonFormatted);
		//Funcion para traer los productos genéricos (products->situation = 4)
		const genericProductsApi = await getGenericProducts();
		const genericProductsFormatted = await genericProductsApi.map((gp) => {
			const obj = {
				label: `${gp.code} - ${gp.description}`,
				value: gp.id,
				product: gp,
			};
			return obj;
		});

		setGenericProductsOptions(genericProductsFormatted);
	}, []);

	useEffect(() => {
		//Check if detail isn't empty and update Detalles table
		if (stageFourDetails && stageFourDetails.length > 0) {
			const updateDetallesTable = stageFourDetails.filter(
				(dt) =>
					dt.familyPublicid === detailPublicSeasonStgFour.id &&
					dt.seasonId === detailPublicSeasonStgFour.seasonId
			);
			setDetalles(updateDetallesTable);
		}
	}, [masterplanContext, stageFourDetails]);

	//Funcion para guardar evento seleccionado
	const handleSelectedEvent = (event) => {
		setSelectedEvent(event);
	};

	//Funcion para guardar los productos genericos seleccionados
	const handleSetGenericProductsSelected = (product) => {
		setGenericProdutsSelected(product);
	};

	//Paso 1 guardado de datos
	const handleSetDetalles = () => {
		//Agregamos un row
		if (!edit) {
			const newDetalles = {
				idFamilyPublicSeason: detailPublicSeasonStgFour.id,
				idKey: generarId(),
				event: selectedEvent,
				opening,
				genericProducts: genericProdutsSelected,
				quantity: (
					detailPublicSeasonStgFour.quantity / detailPublicSeasonStgFour.opening
				).toFixed(0),
				public: detailPublicSeasonStgFour.name,
				familyName: detailPublicSeasonStgFour.familyName,
				familyPublicid: detailPublicSeasonStgFour.familyPublicid,
				seasonId: detailPublicSeasonStgFour.seasonId,
			};

			handleSaveStageFour(newDetalles);
			return;
		}

		if (edit) {
			const updtDetalles = {
				id: itemToEdit.id,
				idFamilyPublicSeason: detailPublicSeasonStgFour.id,
				idKey: generarId(),
				event: selectedEvent,
				opening,
				genericProducts: genericProdutsSelected,
				quantity: (
					detailPublicSeasonStgFour.quantity / detailPublicSeasonStgFour.opening
				).toFixed(0),
				public: detailPublicSeasonStgFour.name,
				familyName: detailPublicSeasonStgFour.familyName,
				familyPublicid: detailPublicSeasonStgFour.familyPublicid,
				seasonId: detailPublicSeasonStgFour.seasonId,
			};

			handleSaveStageFour(updtDetalles);
		}

		//TODO por el momento sacamos las validaciones de cantidad y apertura
		// --- Check opening's quantity > 0 --- //
		// if (opening <= 0) return handleErrors(errors.opening.insufficient);
		// const quantityDetalles = detalles.reduce((acc, dt) => {
		// 	return acc + Number(dt.opening);
		// }, 0);
		//Check accum opening's quantity > opening's quantity
		// const quantityOpeningsAccum = quantityDetalles + Number(opening);

		// if (quantityOpeningsAccum > detailPublicSeasonStgFour.opening)
		// 	return handleErrors(errors.opening.exceeded);
	};

	//Paso 2 guardado de datos
	const handleSaveStageFour = async (detalle) => {
		const validation = isValidated();
		if (!validation) return;

		try {
			const { data } = await axiosWithToken.post(
				"/masterplanStagefour/stageFour",
				{
					masterplan_id: masterplanContext.id,
					detalle,
				}
			);

			handleSetMasterplan(data.data);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(
					"¡Detalle por rubro-familia-temporada guardado correctamente!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			setEdit(false);
			setSelectedEvent("");
			setEventOptions([]);
			setOpening(0);
			setGenericProductsOptions([]);
			setGenericProdutsSelected([]);
		} catch (error) {
			console.log("error", error);
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			return;
		}
	};

	//Eliminamos un row de la tabla
	const handleDelete = (id) => {
		setIdToDelete(id);
		setShowAlert(true);
	};

	const closeModalDelete = () => {
		setShowAlert(false);
	};

	const handleConfirmDelete = async () => {
		try {
			const { data } = await axiosWithToken.delete(
				`/masterplanStagefour/masterplan/${masterplanContext.id}/deleteRow/${idToDelete}`
			);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Detalle eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			handleSetMasterplan(data.data);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("¡Hubo un error!", {
					role: "alert",
					theme: "dark",
				});
			}
			console.log(error);
		}

		setEdit(false);
		setSelectedEvent("");
		setEventOptions([]);
		setOpening(0);
		setGenericProductsOptions([]);
		setGenericProdutsSelected([]);
	};

	const handleErrors = (error) => {
		setTimeout(() => {
			toast.error(error, {
				role: "alert",
				theme: "dark",
			});
		}, 0);
	};

	const isValidated = () => {
		if (!edit) {
			//Comprobamos si ya se encuentra el evento en el array de detalles
			const detalleExist = detalles.find(
				(dt) => dt.event.value === selectedEvent.value
			);

			//si existe un evento en el array de detalles, se muestra un mensaje de error
			if (detalleExist) return handleErrors(errors.event.used);
		}

		//Check if openings aren't null
		if (opening <= 0) return handleErrors(errors.opening.insufficient);

		//Check if generic products aren't empty
		if (genericProdutsSelected.length <= 0)
			return handleErrors(errors.genericProducts);

		return true;
	};

	//Cantidad de registros que se muestran en la paginación
	const perPage = 6;

	const {
		paginationState: {
			data: paginatedDetails,
			canNextPage,
			canPreviousPage,
			paginationArray,
			currentPage,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(detalles, perPage);

	return (
		<Modal
			size='xl'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			show={show}
		>
			<Modal.Header>
				<Modal.Title>
					<h6 className='mb-0 hover-actions-trigger'>
						Familia - Público: {detailPublicSeasonStgFour.familyName} -{" "}
						{detailPublicSeasonStgFour.name}{" "}
					</h6>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className='mb-3'>
					<Col xs={12} sm={12} lg={12} md={12}>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Temporada:</strong>{" "}
							<b>{detailPublicSeasonStgFour.seasonName}</b>
						</p>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Cantidad x apertura:</strong>{" "}
							<b>
								{(
									detailPublicSeasonStgFour.quantity /
									detailPublicSeasonStgFour.opening
								).toFixed(0)}
							</b>
						</p>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Aperturas totales: </strong>{" "}
							<b>{detailPublicSeasonStgFour.opening}</b>
						</p>
					</Col>

					<Divider />

					<Col xs={12} sm={12} lg={5} md={5}>
						<Card className='mb-2 p-2 h-md-100'>
							<Form>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridSelectEventDay'
									>
										<Col xs={12} sm={12} md={12} lg={12}>
											<Form.Label className='fs--2'>Evento</Form.Label>
											<Select
												className='fs--2'
												options={eventOptions}
												value={selectedEvent}
												onChange={handleSelectedEvent}
												placeholder={"Seleccione un evento"}
											/>
										</Col>
									</Form.Group>
								</Row>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridAperturas'
									>
										<Form.Label className='fs--2'>
											Cantidad aperturas
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											value={opening}
											onChange={(e) =>
												isNaN(Number(e.target.value))
													? ""
													: setOpening(Number(e.target.value))
											}
											type='text'
											placeholder=''
										/>
									</Form.Group>
								</Row>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridSelectEventDay'
									>
										<Col xs={12} sm={12} md={12} lg={12}>
											<Form.Label className='fs--2'>
												Producto genérico
											</Form.Label>
											<Select
												className='fs--2'
												options={genericProductsOptions}
												isMulti
												value={genericProdutsSelected}
												onChange={handleSetGenericProductsSelected}
												placeholder={"Seleccione un genérico"}
											/>
										</Col>
									</Form.Group>
								</Row>
								<Row className='mt-3'>
									<Col className='d-flex justify-content-end fs--2'>
										<Button
											variant='success'
											className='ms-1 fs--2'
											size='sm'
											onClick={() => handleSetDetalles()}
										>
											{!edit ? "Agregar" : "Guardar"}
										</Button>

										{edit ? (
											<Button
												size='sm'
												className='ms-1 fs--2'
												variant='danger'
												onClick={() => {
													setEdit(false);
													setItemToEdit({});
													setSelectedEvent({});
													setOpening(0);
													setGenericProdutsSelected([]);
												}}
											>
												Cancelar
											</Button>
										) : (
											""
										)}
									</Col>
								</Row>
							</Form>
						</Card>
					</Col>

					<Col xs={12} sm={12} lg={7} md={7}>
						<Card className='mb-2 p-2 h-md-100'>
							<div className='table-striped fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
									striped
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th className='border-0 text-center'>Evento</th>
											<th className='border-0 text-center'>Apertura</th>
											<th className='border-0 text-center'>Genéricos</th>
											<th
												style={{ width: "30%" }}
												className='border-0 text-center'
											>
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{paginatedDetails && paginatedDetails.length ? (
											paginatedDetails.map((item, index) => {
												return (
													<tr key={`tr-${index}`} className='border-200'>
														<td className='text-start'>{item.event.label}</td>
														<td className='text-start'>{item.opening}</td>
														<td className='text-start'>
															{item.genericProducts.map(
																(prod) => `${prod.product.code},`
															)}
														</td>
														<td className='text-end'>
															<OverlayTrigger
																placement='top'
																overlay={<Tooltip>Editar</Tooltip>}
															>
																<Button
																	onClick={() => {
																		setEdit(true);
																		setItemToEdit(item);
																		setSelectedEvent(item.event);
																		setOpening(item.opening);
																		setGenericProdutsSelected(
																			item.genericProducts
																		);
																	}}
																	variant='transparent'
																	size='sm'
																	style={{ fontSize: "11px" }}
																	className='text-success'
																>
																	<FontAwesomeIcon icon='edit' />
																</Button>
															</OverlayTrigger>
															<OverlayTrigger
																placement='top'
																overlay={<Tooltip>Eliminar</Tooltip>}
															>
																<Button
																	onClick={() => handleDelete(item.id)}
																	variant='transparent'
																	size='sm'
																	style={{ fontSize: "11px" }}
																	className='text-danger me-2'
																>
																	<FontAwesomeIcon icon='trash' />
																</Button>
															</OverlayTrigger>
														</td>
													</tr>
												);
											})
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>

							<div className='d-flex justify-content-center'>
								<div>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canPreviousPage}
										onClick={prevPage}
										className='me-2'
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-left' />
									</Button>
								</div>

								<ul className='pagination mb-0'>
									{paginationArray.map((page) => (
										<li
											key={page}
											className={classNames({
												active: currentPage === page,
											})}
										>
											<Button
												size='sm'
												variant='falcon-default'
												className='page me-2'
												onClick={() => goToPage(page)}
											>
												{page}
											</Button>
										</li>
									))}
								</ul>
								<div>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canNextPage}
										onClick={nextPage}
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-right' />
									</Button>
								</div>
							</div>
						</Card>
					</Col>
				</Row>

				<Modal
					size='md'
					aria-labelledby='contained-modal-title-vcenter'
					centered
					show={showAlert} /*onHide={handleCloseEstructure}*/
				>
					<Modal.Header>
						<Modal.Title>
							<h6 className='mb-0 hover-actions-trigger'>
								¿Estás seguro de que deseas eliminar?
							</h6>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Row className='mt-1'>
							<span className='text-danger fs--2'>
								<strong>
									¡Se eliminaran detalles relacionados en las etapas siguientes
									si la familia lo tiene!
								</strong>
							</span>
						</Row>
						<Row className='mt-3'>
							<Col className='d-flex justify-content-end'>
								<Button variant='danger' size='sm' onClick={closeModalDelete}>
									Cancelar
								</Button>
								<Button
									className='ms-1'
									variant='success'
									size='sm'
									onClick={handleConfirmDelete}
								>
									Confirmar
								</Button>
							</Col>
						</Row>
					</Modal.Body>
				</Modal>

				{/* BOTONES FOOTER */}
				<Row className='mt-3'>
					<Col className='d-flex justify-content-end'>
						<Button
							size='sm'
							className='ms-1 fs--2'
							variant='danger'
							onClick={() => setShow(false)}
						>
							Cancelar
						</Button>
						{/* <Button
							size='sm'
							className='ms-1 fs--2'
							variant='success'
							onClick={() => handleCheckDetalles()}
						>
							Guardar
						</Button> */}
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

MasterplanModalStageFour.propTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	detailPublicSeasonStgFour: PropTypes.object,
	detail: PropTypes.array,
	handleSetDetails: PropTypes.func,
	handleEmptyDetails: PropTypes.func,
};

export default MasterplanModalStageFour;
