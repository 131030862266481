/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col, Form, Button, Tab } from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	const toastId = React.useRef(null);

	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [titulo, setTitulo] = useState("");
	const [accion, setAccion] = useState("");

	//Campos para tubo
	const [linealmeters, setLinealMeters] = useState("");
	const [tubewidth, setTubewidth] = useState("");

	//Campos para telas
	const [squaremeters, setSquaremeters] = useState("");

	//Campos para hilos
	const [perimeter, setPerimeter] = useState("");
	const [perimeterflat, setPerimeterFlat] = useState("");

	//Perimetro de cintura
	const [perimeterwaist, setPerimeterwaist] = useState("");

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			setTitulo("Modificar");
			setAccion("Editar");
			getMoldById();
		} else {
			setTitulo("Agregar");
			setAccion("Guardar");
		}
	}, []);

	const getMoldById = async () => {
		const { data } = await axiosWithToken.get(`/mold/${id}`);

		setCode(data.data.code);
		setDescription(data.data.description);
		setLinealMeters(data.data.linealmeters);
		setTubewidth(data.data.tubewidth);
		setSquaremeters(data.data.squaremeters);
		setPerimeter(data.data.perimeter);
		setPerimeterFlat(data.data.perimeterflat);
		setPerimeterwaist(data.data.perimeterwaist);
	};

	const store = async (e) => {
		e.preventDefault();

		if (code == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo código es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (linealmeters == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo metros lineales es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (tubewidth == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo ancho de tubo es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (squaremeters == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo metros cuadrados es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (perimeter == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo perimetro es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		try {
			const respuesta = await axiosWithToken.post("/mold", {
				code: code,
				description: description,
				linealmeters: linealmeters,
				tubewidth: tubewidth,
				squaremeters: squaremeters,
				perimeter: perimeter,
				perimeterflat: perimeterflat,
				perimeterwaist: perimeterwaist,
			});

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("Molde agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setTimeout(() => {
					navigate("/molds/moldslist");
				}, 1000);
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	const update = async (e) => {
		e.preventDefault();

		if (code == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo código es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (linealmeters == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo metros lineales es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (tubewidth == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo ancho de tubo es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (squaremeters == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El campo metros cuadrados es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		if (perimeter == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo perimetro es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		const respuesta = await axiosWithToken.put(`/mold/${id}`, {
			code: code,
			description: description,
			linealmeters: linealmeters,
			tubewidth: tubewidth,
			squaremeters: squaremeters,
			perimeter: perimeter,
			perimeterflat: perimeterflat,
			perimeterwaist: perimeterwaist,
		});

		if (respuesta.status == 200) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Molde modificado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/molds/moldslist");
			}, 1000);
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${respuesta.message}`, {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/molds/moldslist");
			}, 1000);
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario alta de moldes
							</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={id ? update : store}>
							<Row>
								<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
									<Card className='mb-2 p-4 h-md-100'>
										<h6 className='mb-4 text-center'>
											{titulo} datos del molde
										</h6>

										<Row className='mb-3 g-3'>
											<Col xs={12} sm={12} lg={12} md={12}>
												<Row className='mb-2'>
													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={6}
														lg={6}
														controlId='formGridCod'
													>
														<Form.Label>
															Código<span className='text-danger'>*</span>
														</Form.Label>
														<Form.Control
															size='sm'
															value={code}
															onChange={(e) => setCode(e.target.value)}
															type='text'
															placeholder='Ingrese código'
														/>
													</Form.Group>

													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={6}
														lg={6}
														controlId='formGridDescription'
													>
														<Form.Label>
															Descripción<span className='text-danger'>*</span>
														</Form.Label>
														<Form.Control
															size='sm'
															value={description}
															onChange={(e) => setDescription(e.target.value)}
															type='text'
															placeholder='Ingrese descripción'
														/>
													</Form.Group>
												</Row>
											</Col>
										</Row>
									</Card>
								</Col>

								<Col className='mb-2' xs={12} sm={12} lg={6} md={6}>
									<Card className='mb-2 p-2 h-md-30'>
										<h6 className='mb-2 text-center'>Campos para tubos</h6>
										<Card.Body>
											<Row className='mb-2'>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formGridLinearMeters'
												>
													<Form.Label>
														Metros lineales (tubo)
														<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Control
														size='sm'
														value={linealmeters}
														onChange={(e) => setLinealMeters(e.target.value)}
														type='number'
														placeholder='Ingrese metros lineales'
													/>
												</Form.Group>

												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formGridTubeWidth'
												>
													<Form.Label>
														Ancho (tubo)<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Control
														size='sm'
														value={tubewidth}
														onChange={(e) => setTubewidth(e.target.value)}
														type='number'
														placeholder='Ingrese ancho de tubo'
													/>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
								</Col>

								<Col className='mb-2' xs={12} sm={12} lg={6} md={6}>
									<Card className='mb-2 p-2 h-md-30'>
										<h6 className='mb-2 text-center'>Campos para telas</h6>
										<Card.Body>
											<Row className='mb-2'>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formGridLinearMeters'
												>
													<Form.Label>
														Metros cuadrados (tela)
														<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Control
														size='sm'
														value={squaremeters}
														onChange={(e) => setSquaremeters(e.target.value)}
														type='number'
														placeholder='Ingrese metros cuadrados'
													/>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
								</Col>

								<Col className='mb-2' xs={12} sm={12} lg={6} md={6}>
									<Card className='mb-2 p-2 h-md-30'>
										<h6 className='mb-2 text-center'>Campos para hilos</h6>
										<Card.Body>
											<Row className='mb-2'>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formGridPerimeter'
												>
													<Form.Label>
														Perímetro<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Control
														size='sm'
														value={perimeter}
														onChange={(e) => setPerimeter(e.target.value)}
														type='number'
														placeholder='Ingrese perímetro'
													/>
												</Form.Group>

												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formGridPerimeter'
												>
													<Form.Label>Perímetro Flat</Form.Label>
													<Form.Control
														size='sm'
														value={perimeterflat}
														onChange={(e) => setPerimeterFlat(e.target.value)}
														type='number'
														placeholder='Ingrese perímetro flat'
													/>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
								</Col>

								<Col className='mb-2' xs={12} sm={12} lg={6} md={6}>
									<Card className='mb-2 p-2 h-md-30'>
										<h6 className='mb-2 text-center'>Cintura</h6>
										<Card.Body>
											<Row className='mb-2'>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formGridPerimeterwaist'
												>
													<Form.Label>Perímetro cintura</Form.Label>
													<Form.Control
														size='sm'
														value={perimeterwaist}
														onChange={(e) => setPerimeterwaist(e.target.value)}
														type='number'
														placeholder='Ingrese perímetro de cintura'
													/>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
								</Col>

								<Col
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									className='d-flex justify-content-end mt-2'
								>
									<Button size='sm' variant='primary' type='submit'>
										{accion}
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
