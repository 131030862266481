/** @format */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Card, Tab, Button, Badge, Stack } from "react-bootstrap";
import Background from "components/common/Background";
import { camelize } from "../../../helpers/utils";
import { useCentros } from "hooks/useCentros";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/customCss.css";
import ManPowerTableList from "../manpower/ManPowerTable";
import FormMachine from "./FormMachine";

const MachineView = () => {
	const [editMode, setEditMode] = useState(false);

	const { cod_maq } = useParams();

	const { machine, getMachine } = useCentros();

	const navigate = useNavigate();

	useEffect(() => {
		getMachine(cod_maq);
	}, []);

	useEffect(() => {
		if (!editMode) {
			getMachine(cod_maq);
		}
	}, [editMode]);

	const volver = () => {
		navigate(-1);
	};

	return (
		<>
			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col className='d-flex justify-content-between'>
							<h5 className='mb-0 hover-actions-trigger' id={camelize(`View`)}>
								Máquina : <Badge bg='primary'>#{machine.cod_maq}</Badge>{" "}
								<Badge bg='primary'>{machine.description}</Badge>{" "}
							</h5>
							<Stack
								className='d-flex justify-content-end'
								direction='horizontal'
								gap={2}
							>
								<Button
									/* as={Link}
                  to={`/centros-de-trabajo/listado-centros/formCentroEdit/${center.id}`} */
									onClick={() => setEditMode(!editMode)}
									variant='warning'
									size='sm'
									className='border-0 float-end'
								>
									<FontAwesomeIcon icon='edit' /> {!editMode ? "EDITAR" : "X"}
								</Button>

								<Button
									className='border'
									variant='transparent'
									onClick={() => volver()}
								>
									Volver
								</Button>
							</Stack>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Row>
						<Col xs={12} sm={12} md={editMode ? 12 : 4} lg={editMode ? 12 : 4}>
							<Tab.Content>
								<Card className='mb-2'>
									<Background
										image=''
										className='bg-card'
										style={{
											borderTopRightRadius: "0.375rem",
											borderBottomRightRadius: "0.375rem",
										}}
									/>
									<Card.Header className='border-bottom border-200'>
										{!editMode ? (
											<>
												<h6 className='text-center'>Datos</h6>
												<h6 className='mt-3'>
													Centro al que pertenece:{" "}
													<span
														className='fs-0 card-text float-end'
														style={{ fontWeight: "200" }}
													>
														{machine.center?.description
															? machine.center.description
															: "-"}
													</span>
												</h6>
												<h6 className='mt-3'>
													N° Máquina:{" "}
													<span
														className='fs-0 card-text float-end'
														style={{ fontWeight: "200" }}
													>
														{machine.nro_maq}
													</span>
												</h6>
												<h6 className='mt-3'>
													Tipo de máquina:{" "}
													<span
														className='fs-0 card-text float-end'
														style={{ fontWeight: "200" }}
													>
														{machine.machine_type?.description}
													</span>
												</h6>
												<h6 className='mt-3'>
													Estado:
													<span
														className='fs-0 card-text float-end'
														style={{ fontWeight: "200" }}
													>
														{" "}
														{machine.status === 1 ? "Activo" : "Inactivo"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Día de Alta:{" "}
													<span
														className='fs-0 card-text float-end'
														style={{ fontWeight: "200" }}
													>
														{machine?.date_of_admission
															? machine.date_of_admission
															: "-"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Día de baja:{" "}
													<span
														className='fs-0 card-text float-end'
														style={{ fontWeight: "200" }}
													>
														{machine?.discharge_date
															? machine.discharge_date
															: "-"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Cod.Alfa Presea:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine?.cod_alfa_presea
															? machine.cod_alfa_presea
															: "-"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Aire:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine?.aire === 1 ? "Si" : "No"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Corta Hilo:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine?.corta_hilo === 1 ? "Si" : "No"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Fila:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine?.fila ? machine.fila : "-"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Luz:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine?.luz === 1 ? "Si" : "No"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Modelo:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine?.modelo ? machine.modelo : "-"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Motor:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine?.motor ? machine.motor : "-"}
													</span>
												</h6>
												<h6 className='mt-3'>
													Observaciones:{" "}
													<span
														className='fs-0 card-text float-end mt-1'
														style={{ fontWeight: "200" }}
													>
														{machine.obs ? machine.obs : ""}
													</span>
												</h6>
											</>
										) : (
											<>
												<h6 className='text-center'>Editar datos</h6>
												<FormMachine
													id={Number(machine.cod_maq)}
													setEditMode={setEditMode}
												/>
											</>
										)}
									</Card.Header>
								</Card>
							</Tab.Content>
						</Col>
						<Col xs={12} sm={12} md={8} lg={8}>
							{machine.machine_type?.manpower.length > 0 ? (
								<ManPowerTableList manpower={machine.machine_type.manpower} />
							) : (
								<p>No cuenta con mano de obra asociada...</p>
							)}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

export default MachineView;
