/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Button, Badge, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePagination from "hooks/usePagination";
import classNames from "classnames";

const EstamperiaPagination = ({ data }) => {
	const perPage = 5;

	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(data, perPage);

	return (
		<>
			<Row className='mt-3'>
				<Col xs={12} sm={12} md={12} lg={12}>
					{paginatedDetails.map((dt, index) => (
						<div key={index}>
							{dt != undefined && dt.length > 0 ? (
								<Card
									key={index}
									className='mb-2 square border-start border-start-2 border-primary'
								>
									<span>
										<Row>
											<Col xs={12} sm={12} lg={12} md={12}>
												<p className='m-1 mb-0 p-1 gap-1 fs--2'>
													{" "}
													<strong>Nombre:</strong> <br />{" "}
													{dt[0].product.description}{" "}
													<Badge bg='primary'>{dt[0].product.code}</Badge>
												</p>
											</Col>
											<Col xs={12} sm={12} lg={12} md={12}>
												<div className='px-2'>
													<Table responsive bordered>
														<thead>
															<tr className='m-1 mb-0 p-1 gap-1 fs--2'>
																<th scope='col'>Ubicación</th>
																<th scope='col'>Cant.Colores</th>
																<th scope='col'>Materia Prima</th>
																<th scope='col'>Cant.MP</th>
															</tr>
														</thead>
														<tbody>
															{dt.map((elem) => {
																return (
																	<tr
																		key={elem.id}
																		className='m-1 mb-0 p-1 gap-1 fs--2'
																	>
																		<td className='p-1'>
																			{elem.ubicacion.name}
																		</td>
																		<td className='p-1'>{elem.cantColor}</td>
																		<td className='p-1'>
																			{elem.materia_prima.description}
																		</td>
																		<td className='p-1'>{elem.cantidad}</td>
																	</tr>
																);
															})}
														</tbody>
													</Table>
												</div>
											</Col>
										</Row>
									</span>
								</Card>
							) : (
								""
							)}
						</div>
					))}
				</Col>
			</Row>
			<div className='d-flex justify-content-center'>
				<div>
					<Button
						variant='falcon-default'
						size='sm'
						disabled={!canPreviousPage}
						onClick={prevPage}
						className='me-2'
						trigger='focus'
					>
						<FontAwesomeIcon icon='chevron-left' />
					</Button>
				</div>

				<ul className='pagination mb-0'>
					{paginationArray.map((page) => (
						<li
							key={page}
							className={classNames({ active: currentPage === page })}
						>
							<Button
								size='sm'
								variant='falcon-default'
								className='page me-2'
								onClick={() => goToPage(page)}
							>
								{page}
							</Button>
						</li>
					))}
				</ul>
				<div>
					<Button
						variant='falcon-default'
						size='sm'
						disabled={!canNextPage}
						onClick={nextPage}
						trigger='focus'
					>
						<FontAwesomeIcon icon='chevron-right' />
					</Button>
				</div>
			</div>
		</>
	);
};

EstamperiaPagination.propTypes = {
	data: PropTypes.array,
};

export default EstamperiaPagination;
