/** @format */

import React, { useState } from "react";
import { InputGroup, Col, Form, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const SingleCodesListTableFilterCodeType = ({ setData, dataBackup }) => {
	const [filtro, setFiltro] = useState("");
	const [filtroValueDat, setFiltroValueDat] = useState("");

	const setFiltroValue = (value) => {
		const filtroOptions = {
			1: {
				filtroValueDat: "1",
				filtro: 'Código de material "MP"',
			},
			2: {
				filtroValueDat: "2",
				filtro: 'Código de submaterial "MP"',
			},
			3: {
				filtroValueDat: "3",
				filtro: 'Código de color "MP"',
			},
			4: {
				filtroValueDat: "4",
				filtro: 'Código de producto "P"',
			},
			5: {
				filtroValueDat: "5",
				filtro: 'Código de modelo "P"',
			},
			6: {
				filtroValueDat: "6",
				filtro: 'Código de tela "P"',
			},
			7: {
				filtroValueDat: "7",
				filtro: 'Código de talle "P"',
			},
			8: {
				filtroValueDat: "8",
				filtro: 'Código de color "P"',
			},
		};

		const { filtroValueDat, filtro } = filtroOptions[value];
		setFiltroValueDat(filtroValueDat);
		setFiltro(filtro);
		const newData = dataBackup.filter((item) => item.tipoid == parseInt(value));
		setData(newData);
	};

	const deleteFiltro = () => {
		setData(dataBackup);
		setFiltro("");
		setFiltroValueDat("");
	};

	return (
		<>
			<InputGroup className='d-flex'>
				<Col xs={12} sm={12} lg={4} md={4}>
					<Form.Select
						className='mb-2'
						size='sm'
						onChange={(e) => setFiltroValue(e.target.value)}
						value={filtroValueDat}
					>
						<option disabled value=''>
							Filtrar por códigos
						</option>
						<option value='1'>Código de material "MP"</option>
						<option value='2'>Código de submaterial "MP"</option>
						<option value='3'>Código de color "MP"</option>
						<option value='4'>Código de producto "P"</option>
						<option value='5'>Código de modelo "P"</option>
						<option value='6'>Código de tela "P"</option>
						<option value='7'>Código de talle "P"</option>
						<option value='8'>Código de color "P"</option>
					</Form.Select>
				</Col>

				<Col xs={12} sm={12} lg={4} md={4}>
					<Badge className='mb-2 ms-2' bg='secondary'>
						{filtro}
					</Badge>
					{filtro ? (
						<FontAwesomeIcon
							icon='times'
							size='lg'
							className='cursor-pointer text-danger ms-2'
							onClick={() => deleteFiltro()}
						/>
					) : (
						""
					)}
				</Col>
			</InputGroup>
		</>
	);
};

SingleCodesListTableFilterCodeType.propTypes = {
	setData: PropTypes.func,
	dataBackup: PropTypes.array,
};

export default SingleCodesListTableFilterCodeType;
