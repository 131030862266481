/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, Button, Card, Tab } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { camelize } from "../../helpers/utils";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();
	const toastId = React.useRef(null);
	const [description, setDescription] = useState("");
	const [titulo, setTitulo] = useState("");
	const [accion, setAccion] = useState("");

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			setTitulo("Modificar");
			setAccion("Editar");
			getSupplierById(id);
		} else {
			setTitulo("Agregar");
			setAccion("Guardar");
		}
	}, []);

	const getSupplierById = async (id) => {
		try {
			const { data } = await axiosWithToken.get(`/supplier/${id}`);
			setDescription(data.data.description);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const store = async (e) => {
		e.preventDefault();
		try {
			await axiosWithToken.post("/supplier", {
				description: description,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Agregado correctamente!", {
					role: "alert",
					theme: "success",
				});
			}

			setTimeout(() => {
				navigate("/suppliers/listado");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const update = async (e) => {
		e.preventDefault();
		try {
			await axiosWithToken.put(`/supplier/${id}`, {
				description: description,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Modificado correctamente!", {
					role: "alert",
					theme: "success",
				});
			}

			setTimeout(() => {
				navigate("/suppliers/listado");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario de proveedores
							</h5>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={id ? update : store}>
							<Row className='mb-3 g-3'>
								<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
									<Card className='mb-2 p-4 h-md-100'>
										<h6 className='mb-4 text-center'>
											{titulo} datos del proveedor
										</h6>

										<Row className='mb-3 g-3'>
											<Col xs={12} sm={12} lg={12} md={12}>
												<Row className='mb-2'>
													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={6}
														lg={6}
														controlId='formGridDescription'
													>
														<Form.Label>Descripción</Form.Label>
														<Form.Control
															size='sm'
															value={description}
															onChange={(e) => setDescription(e.target.value)}
															type='text'
															placeholder='Ingrese descripción'
														/>
													</Form.Group>
												</Row>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>

							<Col
								as={Col}
								xs={12}
								sm={12}
								md={12}
								lg={12}
								className='d-flex justify-content-end mt-2'
							>
								<Button variant='primary' type='submit'>
									{accion}
								</Button>
							</Col>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
