/** @format */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
	Card,
	Row,
	Col,
	Form,
	Button,
	Tabs,
	Tab,
	Badge,
	Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { useAuthContext } from "hooks/useAuthContext";
import { useProductContext } from "hooks/useProductContext";

const FormularioSemi = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	//Select producto semi elaborado
	const [selectPs, setSelectPs] = useState({});

	//Operaciones hoja de ruta
	const [roadmap, setRoadmap] = useState({});
	const [roadmapOperations, setRoadmapOperations] = useState([]);

	//Estructura semielaborado
	const [structure, setStructure] = useState({});
	const [structureProducts, setStructureProducts] = useState([]);

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Valida botón, habilita/deshabilita
	const [disabled, setDisabled] = useState(true);

	const { product, selectSemi, handleSemiByProductId } = useProductContext();

	useEffect(() => {
		if (Object.entries(selectSemi).length > 0) {
			setSelectPs(selectSemi);
		} else {
			setSelectPs({});
			setRoadmap({});
			setRoadmapOperations([]);
			setStructure({});
			setStructureProducts([]);
			setDisabled(true);
		}
	}, [selectSemi]);

	const handleCancel = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/products/list`);
	};

	useEffect(() => {
		if (Object.entries(selectPs).length > 0) {
			setDisabled(false);

			if (selectPs.hdr) {
				if (selectPs.hdr.length) {
					selectPs.hdr.forEach((hdr, index) => {
						if (index == 0) {
							setRoadmap(hdr);
						}
					});

					setRoadmapOperations(
						selectPs.hdr[0].operations.map((operation) => {
							return {
								id: operation.id,
								denomination: operation.denomination,
								description: operation.description,
								time: operation.time,
							};
						})
					);
				}
			}

			if (selectPs.structures) {
				if (selectPs.structures.length) {
					selectPs.structures.forEach((str, index) => {
						if (index == 0) {
							setStructure(str);
						}
					});

					setStructureProducts(
						selectPs.structures[0].structure_rawmaterials.map((rawmaterial) => {
							return {
								id: rawmaterial.rawmaterial.id,
								name: rawmaterial.rawmaterial.name,
								description: rawmaterial.rawmaterial.description,
								quantity: rawmaterial.quantity,
								technical_order: rawmaterial.technical_order,
								thread: rawmaterial.rawmaterial.thread,
								machinetype: rawmaterial.machinetype,
							};
						})
					);
				}
			}
		} else {
			setDisabled(true);
		}
	}, [selectPs]);

	const deletePs = () => {
		//Borramos la selección de hoja de ruta
		setSelectPs({});
	};

	const handleAddPs = async () => {
		if (Object.entries(selectPs).length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`Debe seleccionar un producto semielaborado.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.post(
				"/productProductsemi/assignSemi",
				{
					product_id: product.id,
					semi_id: selectPs.id,
				}
			);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			handleSemiByProductId(data.data.product_semi_id);

			setTimeout(() => {
				navigate(`/products/products/view/${product.id}`);
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	return (
		<>
			<Row>
				<Col className='mb-2' xs={12} sm={12} lg={5} md={5}>
					<Card className='mb-2 p-2 h-md-100'>
						<span>
							<p className='mb-1 fs--2 text-center'>
								¡Asignar un semielaborado al producto!
							</p>
						</span>

						<Card.Body>
							<Row>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridRoadmap'
								>
									<Form.Label className='fs--2'>
										Buscar producto semielaborado
										<span className='text-danger'>*</span>
									</Form.Label>
									<SearchAutocomplete
										setSelect={setSelectPs}
										modelSearch={"productPs"}
										disabled={false}
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridRoadmapDenomination'
								>
									<span className='fs--2'>
										<Badge className='mt-1' bg='secondary'>
											{selectPs.name}
										</Badge>
										{selectPs.name ? (
											<FontAwesomeIcon
												icon='times'
												size='sm'
												className='cursor-pointer text-danger ms-2'
												onClick={() => deletePs()}
											/>
										) : (
											""
										)}
									</span>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Col>

				<Col className='mb-2' xs={12} sm={12} lg={7} md={7}>
					<Card className='mb-2 p-1 h-md-100'>
						<Card.Body>
							{Object.entries(selectPs).length > 0 && (
								<Row>
									<span>
										<p className='mb-3 fs--2 text-center'>
											¡Datos del producto semielaborado!
										</p>
									</span>

									<Col xs={12} sm={12} lg={12} md={12}>
										<p className='mb-2 fs--1'>
											<strong className='fs--2 text-600'>Nombre:</strong>{" "}
											{selectPs.name}
										</p>

										<p className='mb-2 fs--1'>
											<strong className='fs--2 text-600'>Descripción:</strong>{" "}
											{selectPs.description}
										</p>
									</Col>

									<Col className='mt-1 mb-2' xs={12} sm={12} lg={12} md={12}>
										<Tabs defaultActiveKey='estructura' id='tabs-viewmp'>
											<Tab eventKey='estructura' title='Estructura'>
												<p className='mt-2 mb-2 fs--1'>
													<strong className='fs--2 text-600'>Nombre:</strong>{" "}
													{structure.name}
												</p>

												<p className='mt-2 mb-2 fs--1'>
													<strong className='fs--2 text-600'>
														Descripción:
													</strong>{" "}
													{structure.description}
												</p>

												<div className='table-responsive fs--2 mt-3'>
													<Table striped className='border-bottom'>
														<thead className='bg-200 text-900'>
															<tr>
																{/* <th className="border-0 text-start">Código</th> */}
																<th className='border-0 text-start'>
																	Materia Prima
																</th>
																<th className='border-0 text-start'>
																	Cantidad
																</th>
																<th className='border-0 text-start'>Orden</th>
															</tr>
														</thead>
														<tbody>
															{!!structureProducts &&
																structureProducts.map((product) => (
																	<tr key={product.id} className='border-200'>
																		<td className='align-middle text-start'>
																			{product.name}{" "}
																			{product.thread != null ? (
																				product.thread == 1 ? (
																					<Badge bg='secondary'>
																						H. arriba
																					</Badge>
																				) : (
																					<Badge bg='secondary'>H. abajo</Badge>
																				)
																			) : (
																				""
																			)}{" "}
																			{product.machinetype != null ? (
																				product.machinetype == 2 ? (
																					<Badge bg='danger'>Flatseamer</Badge>
																				) : (
																					""
																				)
																			) : (
																				""
																			)}
																		</td>

																		<td className='align-middle text-start'>
																			{product.quantity}
																		</td>

																		<td className='align-middle text-start'>
																			<Badge
																				className='rounded-pill'
																				bg='primary'
																			>
																				{product.technical_order}
																			</Badge>
																		</td>
																	</tr>
																))}
														</tbody>
													</Table>
												</div>
											</Tab>
											{Object.entries(roadmap).length > 0 && (
												<Tab eventKey='hdr' title='Hoja de ruta'>
													<p className='mt-2 mb-2 fs--1'>
														<strong className='fs--2 text-600'>Código:</strong>{" "}
														{roadmap.codigo}
													</p>

													<p className='mt-2 mb-2 fs--1'>
														<strong className='fs--2 text-600'>Nombre:</strong>{" "}
														{roadmap.denominacion}
													</p>

													<div className='table-responsive fs--2 mt-3'>
														<Table striped className='border-bottom'>
															<thead className='bg-200 text-900'>
																<tr>
																	<th className='border-0 text-start'>
																		Denominación
																	</th>
																	<th className='border-0 text-start'>
																		Descripción
																	</th>
																	<th className='border-0 text-start'>
																		Tiempos
																	</th>
																</tr>
															</thead>
															<tbody>
																{!!roadmapOperations &&
																	roadmapOperations.map((operation) => (
																		<tr
																			key={operation.id}
																			className='border-200'
																		>
																			<td className='align-middle text-start'>
																				<strong>
																					{operation.denomination}
																				</strong>
																			</td>

																			<td className='align-middle text-start'>
																				{operation.description}
																			</td>

																			<td className='align-middle text-start'>
																				{operation.time}
																			</td>
																		</tr>
																	))}
															</tbody>
														</Table>
													</div>
												</Tab>
											)}
										</Tabs>
									</Col>
								</Row>
							)}

							{Object.entries(selectPs).length == 0 && (
								<Row>
									<Col xs={12} sm={12} lg={12} md={12}>
										<span>
											<p className='mb-1 fs--2 text-center'>
												¡No se seleccionó un producto semielaborado!
											</p>
										</span>
									</Col>
								</Row>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col className='d-flex justify-content-end'>
					<Button size='sm' variant='danger' onClick={() => handleCancel()}>
						Cancelar
					</Button>

					<Button
						className='ms-1'
						variant='primary'
						size='sm'
						disabled={disabled}
						onClick={() => {
							handleAddPs();
						}}
					>
						Guardar
					</Button>
				</Col>
			</Row>
		</>
	);
};

export default FormularioSemi;
