/** @format */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Card, Stack, Image } from "react-bootstrap";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { useEstamperia } from "hooks/useEstamperia";
import Divider from "components/common/Divider";

const buttonDeleteStyle = {
	position: "absolute",
	right: "-20px",
	lineHeight: "0",
	borderRadius: "100%",
	height: "15px",
	width: "15px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	marginTop: "10px",
	marginLeft: "5px",
};

const InputsCargaAPrendas = ({ keyId, eliminarRowUbicaciones, rubro }) => {
	const [estampas, setEstampas] = useState([]);
	const [materiaPrima, setMateriaPrima] = useState([]);

	const {
		ubicaciones,
		getUbicaciones,
		ubicacionesByRubro,
		getUbicacionesByRubro,
	} = useEstamperia();

	useEffect(() => {
		if (rubro === 0) {
			getUbicaciones();
		} else {
			getUbicacionesByRubro(rubro);
			//TODO para corregir error de esLint
			console.log(estampas, materiaPrima);
		}
	}, [rubro]);

	//Función para guardar las estampas seleccionadas
	const handleSearchEstamp = (key, searchObject) => {
		const est = document.querySelectorAll(
			'input[name="estampaStack-' + key + '"]'
		);
		if (est.length > 0) {
			const estArray = Array.from(est);
			const inArray = estArray.some(
				({ value }) => Number(value) === searchObject.id
			);
			if (!inArray) {
				const stack = document.querySelector(`#stack-${key}`);
				const div = document.createElement("div");
				div.innerHTML = searchObject.nombre;
				div.className = "badge badge-soft-primary";
				div.style = "position:relative";
				const input = document.createElement("input");
				input.type = "hidden";
				input.id = "estampaStack-" + key;
				input.name = "estampaStack-" + key;
				input.value = searchObject.id;
				const divRemove = document.createElement("div");
				divRemove.innerHTML = "x";
				divRemove.style =
					"margin-left:5px; cursor:pointer; color:red; z-index:1; position:absolute;top: 0; bottom: 0; right: -10px; display: flex; align-items: center";
				divRemove.addEventListener("click", () => {
					div.remove();
				});
				div.appendChild(input);
				div.appendChild(divRemove);
				stack.appendChild(div);
			} else {
				return;
			}
		} else {
			const stack = document.querySelector(`#stack-${key}`);
			const div = document.createElement("div");
			div.innerHTML = searchObject.nombre;
			div.className = "badge badge-soft-primary";
			div.style = "position:relative";
			const input = document.createElement("input");
			input.type = "hidden";
			input.id = "estampaStack-" + key;
			input.name = "estampaStack-" + key;
			input.value = searchObject.id;
			const divRemove = document.createElement("div");
			divRemove.innerHTML = "x";
			divRemove.style =
				"margin-left:5px; cursor:pointer; color:red; z-index:1; position:absolute;top: 0; bottom: 0; right: -10px; display: flex; align-items: center";
			divRemove.addEventListener("click", () => {
				//Elimino l imagen de la estampa
				document.querySelector(`#imagen-${key}`).src = ``;
				div.remove();
			});
			div.appendChild(input);
			div.appendChild(divRemove);
			stack.appendChild(div);

			//Si la estampa tiene Materias Primas asociadas por defecto las traigo
			if (searchObject.products_mp.length > 0) {
				searchObject.products_mp.map((elem) => {
					handleSearchMP(key, elem);
				});
			}
			//Traigo la cantidad de colores que tiene asociado
			document.querySelector(`#cantColor-${key}`).value = Number(
				searchObject.cant_color
			);

			//traigo imagen dela estampa
			document.querySelector(
				`#imagen-${key}`
			).src = `${process.env.REACT_APP_ENDPOINT_BASE}/img-estamperia/${searchObject.imagen}`;
		}
	};

	//Función para guardar las MP seleccionadas
	const handleSearchMP = (key, searchObject) => {
		const mp = document.querySelectorAll('input[name="mpStack-' + key + '"]');
		if (mp.length > 0) {
			const mpArray = Array.from(mp);
			const inArray = mpArray.some(
				({ value }) => Number(value) === searchObject.id
			);
			if (!inArray) {
				const stack = document.querySelector(`#stackMp-${key}`);
				const div = document.createElement("div");
				div.innerHTML = searchObject.description;
				div.className =
					"badge badge-soft-primary d-flex align-items-center g-2";
				div.style = "position:relative";
				const input = document.createElement("input");
				input.type = "hidden";
				input.name = "mpStack-" + key;
				input.value = searchObject.id;
				const divRemove = document.createElement("div");
				divRemove.innerHTML = "x";
				divRemove.style =
					"margin-left:5px; cursor:pointer; color:red; z-index:1; position:absolute;top: 0; bottom: 0; right: -10px; display: flex; align-items: center";
				divRemove.addEventListener("click", () => {
					div.remove();
				});

				const cantInput = document.createElement("input");
				cantInput.type = "number";
				cantInput.className = "form-control fs--2 px-2 text-center";
				cantInput.id = "cantMp-" + key;
				cantInput.name = "cantMp-" + key;
				cantInput.value = searchObject.pivot ? searchObject.pivot.cantidad : 1;
				cantInput.style = "width:65px";
				div.appendChild(input);
				div.appendChild(cantInput);
				div.appendChild(divRemove);
				stack.appendChild(div);
			} else {
				return;
			}
		} else {
			const stack = document.querySelector(`#stackMp-${key}`);
			const div = document.createElement("div");
			div.innerHTML = searchObject.description;
			div.className = "badge badge-soft-primary d-flex align-items-center g-2";
			div.style = "position:relative";
			const input = document.createElement("input");
			input.type = "hidden";
			input.name = "mpStack-" + key;
			input.value = searchObject.id;
			const divRemove = document.createElement("div");
			divRemove.innerHTML = "x";
			divRemove.style =
				"margin-left:5px; cursor:pointer; color:red; z-index:1; position:absolute;top: 0; bottom: 0; right: -10px; display: flex; align-items: center";
			divRemove.addEventListener("click", () => {
				div.remove();
			});

			const cantInput = document.createElement("input");
			cantInput.type = "number";
			cantInput.className = "form-control fs--2 px-2 text-center";
			cantInput.id = "cantMp-" + key;
			cantInput.name = "cantMp-" + key;
			cantInput.value = searchObject.pivot ? searchObject.pivot.cantidad : 1;
			cantInput.style = "width:65px";

			div.appendChild(input);
			div.appendChild(cantInput);
			div.appendChild(divRemove);
			stack.appendChild(div);
		}
	};

	const eliminarRow = (key) => {
		eliminarRowUbicaciones(key);
	};

	return (
		<Row id={`row-${keyId}`}>
			{/* Boton para eliminar row */}
			<Col
				xs={12}
				sm={12}
				md={12}
				lg={12}
				className='fw-bold'
				style={{ cursor: "pointer", position: "relative" }}
			>
				<button
					type='button'
					className='btn btn-danger p-2'
					style={buttonDeleteStyle}
					onClick={() => eliminarRow(`${keyId}`)}
				>
					<div className='fs--1'>x</div>
				</button>
			</Col>

			<Col xs={12} sm={12} lg={8} md={8}>
				<Row>
					{/* UBICACIONES */}
					<Form.Group
						className='p-1'
						as={Col}
						xs={12}
						sm={12}
						md={4}
						lg={4}
						controlId={`formGridUbicacion-${keyId}`}
					>
						<Form.Label className='fs--2'>Ubicación</Form.Label>
						<Form.Select
							defaultValue={0}
							aria-label={`ubicacion-${keyId}`}
							id={`ubicacion-${keyId}`}
							size='sm'
						>
							<option value={0} disabled>
								-- Seleccione ubicación --
							</option>
							{rubro === 0
								? ubicaciones?.map((ubi) => {
										return (
											<option key={ubi.id} value={ubi.id}>
												{ubi.name}
											</option>
										);
								  })
								: ubicacionesByRubro?.map((ubi) => {
										return (
											<option key={ubi.id} value={ubi.ubicaciones_estampas.id}>
												{ubi.ubicaciones_estampas.name}
											</option>
										);
								  })}
						</Form.Select>
					</Form.Group>

					{/* BUSCADOR ESTAMPA */}
					<Form.Group
						className='p-1'
						as={Col}
						xs={12}
						sm={12}
						md={6}
						lg={6}
						controlId={`formGridEstampa-${keyId}`}
					>
						<Form.Label className='fs--2'>Estampa</Form.Label>
						<SearchAutocomplete
							keyId={keyId}
							setSelect={setEstampas}
							handleSearchEstamp={handleSearchEstamp}
							modelSearch={"estampas"}
							//estampasSeleccionadas={estampasSeleccionadas}
						/>
						<Stack
							direction='horizontal'
							gap={3}
							className='d-flex estampas-stack'
							style={{ flexWrap: "wrap" }}
							id={`stack-${keyId}`}
						></Stack>
					</Form.Group>

					{/* CANT COLORES */}
					<Form.Group
						className='p-1'
						as={Col}
						xs={12}
						sm={12}
						md={2}
						lg={2}
						controlId={`cantColor-${keyId}`}
					>
						<Form.Label className='fs--2'>Cant.Colores</Form.Label>
						<Form.Control
							defaultValue={0}
							type='number'
							placeholder='Ingrese cantidad de colores'
							size='sm'
							min={0}
						/>
					</Form.Group>

					{/* BUSCADOR MP */}
					<Form.Group
						className='p-1'
						as={Col}
						xs={12}
						sm={12}
						md={12}
						lg={12}
						controlId='formGridCode'
					>
						<Form.Label className='fs--2'>Mat.Prima</Form.Label>
						<SearchAutocomplete
							keyId={keyId}
							setSelect={setMateriaPrima}
							handleSearchMP={handleSearchMP}
							modelSearch={"rawmaterial"}
							// mpSeleccionada={mpSeleccionada}
						/>
						<Stack
							direction='horizontal'
							gap={3}
							className='d-flex'
							style={{ flexWrap: "wrap" }}
							id={`stackMp-${keyId}`}
						></Stack>
					</Form.Group>

					{/* CANT MP */}
					{/* <Form.Group
						className='p-1'
						as={Col}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						controlId={`cantMp-${keyId}`}
					>
						<Form.Label className='fs--2'>Cant.MP</Form.Label>
						<Form.Control
							defaultValue={0}
							type='number'
							placeholder='Ingrese cantidad'
							size='sm'
							min={0}
						/>
					</Form.Group> */}
				</Row>
			</Col>

			{/* Imagen */}
			<Col className='mt-3 mb-2' xs={12} sm={12} lg={4} md={4}>
				<Card className='mb-4 h-md-100 p-1'>
					<Card.Body className='position-relative'>
						<Row>
							<Col
								className='d-flex justify-content-center'
								xs={12}
								sm={12}
								lg={12}
								md={12}
							>
								<Image
									id={`imagen-${keyId}`}
									width={"100%"}
									src={""}
									rounded
									// alt={`imagen - ${keyId}`}
									loading='lazy'
								/>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Divider />
		</Row>
	);
};

InputsCargaAPrendas.propTypes = {
	keyId: PropTypes.number,
	eliminarRowUbicaciones: PropTypes.func,
	rubro: PropTypes.number,
};

export default InputsCargaAPrendas;
