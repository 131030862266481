/** @format */

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import classNames from "classnames";
// import usePagination from "hooks/usePagination";

const StageFourDetailPagination = ({ stage3Detail, handleShowModal }) => {
	const [data, setData] = useState([]);

	// const perPage = 5;
	// const data = stage3Detail;

	useEffect(() => {
		if (stage3Detail?.length > 0) {
			setData(stage3Detail);
		}
	}, [stage3Detail]);

	useEffect(() => {
		if (data?.length) {
			//ordenamos por temporadas la data
			data.sort((a, b) => {
				return a.seasonId - b.seasonId;
			});
		}
	}, [data]);

	const renderTooltip = (data) => {
		return (
			<Tooltip id='button-tooltip'>
				<span className='text-left fs--2'>
					<strong>Temporada: {data.seasonName}</strong>
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>
						Cantidad x apertuda: {(data.quantity / data.opening).toFixed(0)}
					</strong>
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Apertura: {data.opening}</strong>
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Total: {data.quantity}</strong>
				</span>
				<br />
			</Tooltip>
		);
	};

	// const {
	// 	paginationState: {
	// 		data: paginatedDetails,
	// 		currentPage,
	// 		canNextPage,
	// 		canPreviousPage,
	// 		paginationArray,
	// 	},
	// 	nextPage,
	// 	prevPage,
	// 	goToPage,
	// } = usePagination(data, perPage);

	return (
		<>
			{data.map((dt, index) => {
				return (
					<tr key={`indexId-${index}`} className='border-200'>
						<td className='text-center'>
							<OverlayTrigger
								placement='top'
								overlay={<Tooltip>Abrir en quincenas</Tooltip>}
							>
								<Button
									onClick={() => handleShowModal(dt)}
									variant='transparent'
									size='sm'
									style={{
										fontSize: "11px",
									}}
									className='text-primary'
								>
									<FontAwesomeIcon icon='plus' />
								</Button>
							</OverlayTrigger>
						</td>
						<td className='text-start'>
							<OverlayTrigger
								placement='top'
								delay={{ show: 100, hide: 200 }}
								overlay={renderTooltip(dt)}
							>
								<span className='fs--2'>{dt.familyName}</span>
							</OverlayTrigger>
						</td>
						<td className='text-start'>
							<OverlayTrigger
								placement='top'
								delay={{ show: 100, hide: 200 }}
								overlay={renderTooltip(dt)}
							>
								<span className='fs--2'>{dt.seasonName}</span>
							</OverlayTrigger>
						</td>
						<td className='text-start'>
							<OverlayTrigger
								placement='top'
								delay={{ show: 100, hide: 200 }}
								overlay={renderTooltip(dt)}
							>
								<span className='fs--2'>{dt.opening}</span>
							</OverlayTrigger>
						</td>
						<td className='text-start'>
							<OverlayTrigger
								placement='top'
								delay={{ show: 100, hide: 200 }}
								overlay={renderTooltip(dt)}
							>
								<span className='fs--2'>
									{(dt.quantity / dt.opening).toFixed(0)}
								</span>
							</OverlayTrigger>
						</td>
					</tr>
				);
			})}
			{/* <tr className='d-flex justify-content-center mt-3 w-100'>
					<td>
						<Button
							variant='falcon-default'
							size='sm'
							disabled={!canPreviousPage}
							onClick={prevPage}
							className='me-2'
							trigger='focus'
						>
							<FontAwesomeIcon icon='chevron-left' />
						</Button>
					</td>
					<td>
						<ul className='pagination mb-0'>
							{paginationArray.map((page) => (
								<li
									key={page}
									className={classNames({ active: currentPage === page })}
								>
									<Button
										size='sm'
										variant='falcon-default'
										className='page me-2'
										onClick={() => goToPage(page)}
									>
										{page}
									</Button>
								</li>
							))}
						</ul>
					</td>
					<td>
						<Button
							variant='falcon-default'
							size='sm'
							disabled={!canNextPage}
							onClick={nextPage}
							trigger='focus'
						>
							<FontAwesomeIcon icon='chevron-right' />
						</Button>
					</td>
				</tr> */}
		</>
	);
};

StageFourDetailPagination.propTypes = {
	stage3Detail: PropTypes.array,
	handleShowModal: PropTypes.func,
};

export default StageFourDetailPagination;
