/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Offcanvas, Button, ButtonGroup, Form } from "react-bootstrap";
import defaultModeImg from "assets/img/img-dashboard/icons-settings/falcon-mode-default.jpg";
import darkModeImg from "assets/img/img-dashboard/icons-settings/falcon-mode-dark.jpg";
import invertedImg from "assets/img/img-dashboard/icons-settings/inverted.png";
import cardImg from "assets/img/img-dashboard/icons-settings/card.png";
import vibrantImg from "assets/img/img-dashboard/icons-settings/vibrant.png";
import transparentImg from "assets/img/img-dashboard/icons-settings/default.png";
import Flex from "components/common/Flex";
import AppContext from "context/Context";
import RadioItem from "./RadioItem";

const SettingsPanel = () => {
	const {
		config: { isDark, navbarPosition, navbarStyle, showSettingPanel },
		setConfig,
		configDispatch,
	} = useContext(AppContext);

	const [navbars] = useState([
		{
			name: "transparent",
			image: transparentImg,
		},
		{
			name: "inverted",
			image: invertedImg,
		},
		{
			name: "card",
			image: cardImg,
		},
		{
			name: "vibrant",
			image: vibrantImg,
		},
	]);

	return (
		<Offcanvas
			show={showSettingPanel}
			onHide={() => setConfig("showSettingPanel", false)}
			placement='end'
			style={{ maxWidth: "22rem" }}
			className='border-0'
		>
			<Offcanvas.Header
				closeButton
				closeVariant='white'
				className='bg-primary settings-panel-header'
			>
				<Offcanvas.Title as='div' className='py-1 z-index-1 light'>
					<div className='d-flex justify-content-between align-items-center mb-1'>
						<h6 className='text-white'>
							<FontAwesomeIcon icon='tools' className='me-1 fs-0' />
							Configuración
						</h6>
						<Button
							variant='primary'
							size='sm'
							className='ms-1 rounded-pill mt-0 mb-1'
							style={{ fontSize: "12px" }}
							onClick={() => {
								configDispatch({ type: "RESET" });
							}}
						>
							<FontAwesomeIcon
								icon='redo-alt'
								style={{ fontSize: "10px" }}
								className='me-2'
							/>
							Resetear
						</Button>
					</div>
					<p className='mb-0 fs--1 text-white opacity-75'>
						Panel de configuración de estilos.
					</p>
				</Offcanvas.Title>
			</Offcanvas.Header>

			<Offcanvas.Body className='scrollbar'>
				<h5 className='fs-0'>Color del tema</h5>

				<ButtonGroup className='btn-group-navbar-style'>
					<RadioItem
						name='theme-mode'
						label='Claro'
						active={!isDark}
						onChange={({ target }) => setConfig("isDark", !target.checked)}
						image={defaultModeImg}
					/>
					<RadioItem
						name='theme-mode'
						label='Oscuro'
						active={isDark}
						onChange={({ target }) => setConfig("isDark", target.checked)}
						image={darkModeImg}
					/>
				</ButtonGroup>

				<hr />
				<Flex>
					<div>
						<Flex alignItems='center' tag='h5' className='fs-0'>
							<FontAwesomeIcon icon='list' className='text-primary me-1 fs-0' />
							Posición del menú
						</Flex>

						<Form.Check
							type='radio'
							id='verticalNav-radio'
							label='Vertical'
							name='NavBarPositionRadioButton'
							checked={navbarPosition === "vertical"}
							onChange={() => setConfig("navbarPosition", "vertical")}
							inline
						/>
						<Form.Check
							type='radio'
							id='topNav-radio'
							label='Top'
							name='NavBarPositionRadioButton'
							checked={navbarPosition === "top"}
							onChange={() => setConfig("navbarPosition", "top")}
							inline
						/>
						<Form.Check
							type='radio'
							id='combo-radio'
							label='Combo'
							name='NavBarPositionRadioButton'
							checked={navbarPosition === "combo"}
							onChange={() => setConfig("navbarPosition", "combo")}
							inline
						/>
					</div>
				</Flex>

				<hr />
				<h5 className='fs-0 d-flex align-items-center'>
					Estilo del menú vertical{" "}
				</h5>
				<ButtonGroup className='btn-group-navbar-style'>
					{navbars.slice(0, 2).map((item) => (
						<RadioItem
							key={item.name}
							name='navbar-style'
							label={item.name}
							active={navbarStyle === item.name}
							onChange={() => setConfig("navbarStyle", item.name)}
							image={item.image}
						/>
					))}
				</ButtonGroup>
				<ButtonGroup className='btn-group-navbar-style'>
					{navbars.slice(2, 4).map((item) => (
						<RadioItem
							key={item.name}
							name='navbar-style'
							label={item.name}
							active={navbarStyle === item.name}
							onChange={() => setConfig("navbarStyle", item.name)}
							image={item.image}
						/>
					))}
				</ButtonGroup>
			</Offcanvas.Body>
		</Offcanvas>
	);
};

export default SettingsPanel;
