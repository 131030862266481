/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useEstamperia } from "hooks/useEstamperia";
import Offcanvas from "react-bootstrap/Offcanvas";
import MyForm from "./MyForm";
import "../../css/customCss.css";

const EstamperiaFormEdit = ({ editionMode, handleEditionMode }) => {
	const [show, setShow] = useState(false);

	const { estampa } = useEstamperia();

	useEffect(() => {
		if (editionMode) {
			handleShow();
		}
	}, [editionMode]);

	const handleShow = () => setShow(true);
	const handleClose = () => {
		setShow(false);
		handleEditionMode();
	};

	return (
		<Offcanvas
			className='offcanvas-custom pb-2'
			show={show}
			onHide={handleClose}
			placement={"end"}
		>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Formulario de Edición</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<MyForm estampa={estampa} handleClose={handleClose} />
			</Offcanvas.Body>
		</Offcanvas>
	);
};

EstamperiaFormEdit.propTypes = {
	editionMode: PropTypes.bool,
	handleEditionMode: PropTypes.func,
};

export default EstamperiaFormEdit;
