/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Row, Col, Button, Card, Tab } from "react-bootstrap";
import { camelize } from "../../helpers/utils";
import { useCentros } from "hooks/useCentros";
import { days } from "data/center/days";
import { useAuthContext } from "hooks/useAuthContext";

const FormCentro = ({ id = null }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [description, setDescription] = useState("");
	const [padre, setPadre] = useState(0);
	const [padres, setPadres] = useState([]);
	const [estado, setEstado] = useState(1);
	const [costo, setCosto] = useState(0);
	const [centerType, setCenterType] = useState(0);
	const [checkTop, setCheckTop] = useState(false);
	const [selectedDays, setSelectedDays] = useState([]);

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	const volver = () => {
		navigate("/centros-de-trabajo/listado-centros/");
	};

	const { centros, getAllCentros } = useCentros();

	useEffect(() => {
		getAllCentros();
	}, []);

	useEffect(() => {
		if (id != null) {
			const nuevoPadre = centros.filter((elem) => elem.id === Number(id));
			setPadre(nuevoPadre[0]);
		} else {
			setPadres(centros);
			//console.log('CENTROS', centros)
		}
	}, [centros]);

	useEffect(() => {
		handleCheckTop(padre);
	}, [padre]);

	const storeCentro = async (e) => {
		e.preventDefault();
		try {
			const respuesta = await axiosWithToken.post(
				"/centros-de-trabajo/listado-centros/nuevo-centro",
				{
					description: description.toUpperCase(),
					padre,
					estado,
					costo,
					centerType,
					workDays: selectedDays,
				}
			);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setTimeout(() => {
					navigate("/centros-de-trabajo/listado-centros/");
				}, 1300);
			} else {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${respuesta.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	const handleCheckTop = (top) => {
		const centerData = centros.filter((elem) => elem.id === Number(top));
		if (Number(top) > 0) {
			setCosto(centerData[0].costo);
			setCheckTop(true);
		} else {
			setCheckTop(false);
		}
	};

	const handleSelectChange = (selectedOption) => {
		setSelectedDays(selectedOption);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Agregar Centro
							</h5>
							<p className='mt-2 mb-0'>Ingresá los datos correspondientes.</p>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={storeCentro}>
							<Row className='mb-3 g-3'>
								<Form.Group
									as={Col}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridDescription'
								>
									<Form.Label>Descripción</Form.Label>
									<Form.Control
										value={description.toUpperCase()}
										onChange={(e) => setDescription(e.target.value)}
										type='text'
										placeholder='Ingrese descripción'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridSelectPadre'
									//className="overflow-scroll"
									//style={{ height: '300px' }}
								>
									<Form.Label>Padre</Form.Label>
									<Form.Select
										value={id === null ? padre : 0}
										aria-label='Padre'
										onChange={(e) => setPadre(e.target.value)}
									>
										{id === null ? (
											<>
												<option value={0}> Utilizar como padre </option>
												{padres.map((elem) => {
													return (
														<option key={elem.id} value={elem.id}>
															#{elem.id} {elem.description}
														</option>
													);
												})}
											</>
										) : (
											<>
												<option value={0}> -- Seleccionar -- </option>
												<option value={id}>{padre.description}</option>
											</>
										)}
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridSelectTipo'
								>
									<Form.Label>Estado</Form.Label>
									<Form.Select
										value={estado}
										aria-label='Estado'
										onChange={(e) => setEstado(Number(e.target.value))}
									>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridCenterType'
								>
									<Form.Label>Tipo</Form.Label>
									<Form.Select
										value={centerType}
										aria-label='Tipo'
										onChange={(e) => setCenterType(e.target.value)}
									>
										<option disabled value={0}>
											-- Seleccione tipo de centro --
										</option>
										<option value={"interno"}>Interno</option>
										<option value={"externo"}>Externo</option>
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									sm={12}
									md={6}
									lg={6}
									controlId='formGridCost'
								>
									<Form.Label>Costo</Form.Label>
									<Form.Control
										value={costo}
										onChange={(e) => setCosto(e.target.value)}
										type='number'
										placeholder='Ingrese Costo'
										disabled={checkTop}
									/>
								</Form.Group>

								{/* DIAS DE TRABAJO */}
								<Col xs={12} sm={12} md={12} lg={12}>
									<Form.Label className='fs--1'>Días de trabajo</Form.Label>
									<Select
										options={days}
										value={selectedDays}
										isMulti
										onChange={handleSelectChange}
										placeholder={"Seleccione los días de trabajo"}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Button variant='warning' onClick={() => volver()}>
										Volver
									</Button>
								</Col>

								<Col>
									<Button className='float-end' variant='primary' type='submit'>
										Guardar
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

FormCentro.propTypes = {
	id: PropTypes.number,
};

export default FormCentro;
