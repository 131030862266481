/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePagination from "hooks/usePagination";
import classNames from "classnames";

const HojaRutaOperationPagination = ({ data }) => {
	const perPage = 10;

	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(data, perPage);

	return (
		<>
			<Row className='mt-3'>
				<Col xs={12} sm={12} md={12} lg={12}>
					{paginatedDetails.map((dt, index) => (
						<div key={index}>
							{
								<Card
									key={index}
									className='mb-2 square border-start border-start-2 border-primary'
								>
									<span>
										<Row>
											<Col xs={12} sm={12} lg={12} md={12}>
												<p className='m-1 mb-0 p-1 gap-1 fs--2'>
													{" "}
													{/* <strong>Nombre:</strong> <br/> {dt[0].product.description} <Badge bg="primary">{dt[0].product.code}</Badge> */}
												</p>
											</Col>
											<Col xs={12} sm={12} lg={12} md={12}>
												<div className='px-2'>
													<Table responsive>
														<thead>
															<tr className='m-1 mb-0 p-1 gap-1 fs--2'>
																<th className='p-0' scope='col'>
																	id
																</th>
																<th className='p-0' scope='col'>
																	Nombre
																</th>
																<th className='p-0' scope='col'>
																	Descripción
																</th>
															</tr>
														</thead>
														<tbody>
															<tr className='m-1 mb-0 p-1 gap-1 fs--2 align-middle'>
																<td className='p-0'>{dt.id}</td>
																<td className='p-0'>{dt.denomination}</td>
																<td className='p-0'>{dt.description}</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</Col>
										</Row>
									</span>
								</Card>
							}
						</div>
					))}
				</Col>
			</Row>
			<div className='d-flex justify-content-center'>
				<div>
					<Button
						variant='falcon-default'
						size='sm'
						disabled={!canPreviousPage}
						onClick={prevPage}
						className='me-2'
						trigger='focus'
					>
						<FontAwesomeIcon icon='chevron-left' />
					</Button>
				</div>

				<ul className='pagination mb-0'>
					{paginationArray.map((page) => (
						<li
							key={page}
							className={classNames({ active: currentPage === page })}
						>
							<Button
								size='sm'
								variant='falcon-default'
								className='page me-2'
								onClick={() => goToPage(page)}
							>
								{page}
							</Button>
						</li>
					))}
				</ul>
				<div>
					<Button
						variant='falcon-default'
						size='sm'
						disabled={!canNextPage}
						onClick={nextPage}
						trigger='focus'
					>
						<FontAwesomeIcon icon='chevron-right' />
					</Button>
				</div>
			</div>
		</>
	);
};

HojaRutaOperationPagination.propTypes = {
	data: PropTypes.array,
};

export default HojaRutaOperationPagination;
