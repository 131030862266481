/**
 * eslint-disable no-constant-condition
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, Button, Card, Tab } from "react-bootstrap";
import { camelize } from "../../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	const [description, setDescription] = useState("");
	const [selectTorelate, setSelectTorelate] = useState(null);
	const [orden, setOrden] = useState(0);
	const [titulo, setTitulo] = useState("");
	const [accion, setAccion] = useState("");

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			setTitulo("Modificar");
			setAccion("Editar");
			getAttributeById();
		} else {
			setTitulo("Agregar");
			setAccion("Guardar");
			//getOrden();
		}
	}, []);

	/* const getOrden = async () => {
    
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_API}/attributes/order`
    );

    if(response.data.data == null){
      setOrden(1);
    }else{
      setOrden(response.data.data + 1) 
    }
  } */

	const getAttributeById = async () => {
		const response = await axiosWithToken.get(`/attribute/${id}`);

		console.log(
			"imprimimos select to relate valor",
			response.data.data.torelate
		);

		setDescription(response.data.data.description);
		setOrden(response.data.data.technical_order);
		setSelectTorelate(response.data.data.torelate == 0 ? 2 : 1);
	};

	const store = async (e) => {
		e.preventDefault();

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}
			//Error
			return;
		}
		if (orden == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo orden es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}
			//Error
			return;
		}
		if (selectTorelate == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar si sus valores pueden relacionarse es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			//Error
			return;
		}

		const respuesta = await axiosWithToken.post("/attribute", {
			description: description,
			technical_order: orden,
			torelate: selectTorelate == 2 ? 0 : 1,
		});

		if (respuesta.status == 200) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Agregado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}
			setTimeout(() => {
				navigate("/configurador/atributos/listado");
			}, 1000);
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${respuesta.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	const update = async (e) => {
		e.preventDefault();

		if (description == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo descripción es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}
			return;
		}

		if (orden == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo orden es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (selectTorelate == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(
					`El seleccionar si sus valores pueden relacionarse es obligatorio.`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			//Error
			return;
		}

		const respuesta = await axiosWithToken.put(`/attribute/${id}`, {
			description: description,
			technical_order: orden,
			torelate: selectTorelate == 2 ? 0 : 1,
		});

		if (respuesta.status == 200) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Modificado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate("/configurador/atributos/listado");
			}, 1000);
		} else {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${respuesta.message}`, {
					role: "alert",
					theme: "dark",
				});
			}
		}
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200 p-2'>
					<Row>
						<Col className='d-flex justify-content-center'>
							<h6
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Formulario de atributos
							</h6>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={id ? update : store}>
							<Row>
								<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
									<Card className='mb-2 p-2 h-md-100'>
										<h6 className='mb-4 text-center'>{titulo} atributo</h6>
										<Card.Body>
											<Row className='mb-3'>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													lg={8}
													md={8}
													controlId='formGridDescription'
												>
													<Form.Label>
														Descripción<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Control
														size='sm'
														value={description}
														onChange={(e) => setDescription(e.target.value)}
														type='text'
														placeholder='Ingrese descripción'
													/>
												</Form.Group>

												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={4}
													lg={4}
													controlId='formGridCod'
												>
													<Form.Label>
														Orden jerárquico
														<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Control
														size='sm'
														value={orden}
														onChange={(e) => setOrden(e.target.value)}
														type='text'
														placeholder='Ingrese un número de orden'
													/>
												</Form.Group>
											</Row>

											<Row className='mb-3'>
												<Form.Group
													as={Col}
													xs={12}
													sm={12}
													md={6}
													lg={6}
													controlId='formTorelate'
												>
													<Form.Label>
														Seleccionar si sus valores se pueden relacionar
														<span className='text-danger'>*</span>
													</Form.Label>
													<Form.Select
														size='sm'
														value={selectTorelate || ""}
														aria-label='mp'
														onChange={(e) => setSelectTorelate(e.target.value)}
													>
														<option value='' disabled>
															Seleccionar...
														</option>
														<option value={2}>No relacionar valores</option>
														<option value={1}>Relacionar valores</option>
													</Form.Select>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
								</Col>

								<Col
									className='d-flex justify-content-end'
									xs={12}
									sm={12}
									lg={12}
									md={12}
								>
									<Button variant='primary' size='sm' type='submit'>
										{accion}
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

export default Formulario;
