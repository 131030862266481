/** @format */

import React, { useState, useEffect } from "react";
import { Row, Button, Card, Col, Nav, Tab } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link } from "react-router-dom";

//Import formularios
import FormInsertUpdateCode from "components/products/products/FormInsertUpdateCode";
import FormInsertUpdateProducto from "components/products/products/FormInsertUpdateProducto";
import FormInsertUpdateMold from "components/products/products/FormInsertUpdateMold";
import FormInsertUpdateStructure from "components/products/products/FormInsertUpdateStructure";
import FormInsertUpdateHdr from "components/products/products/FormInsertUpdateHdr";
import FormInsertUpdateSemi from "components/products/products/FormInsertUpdateSemi";

import { useProductContext } from "hooks/useProductContext";

const Formulario = () => {
	//Estados habilita/deshabilita diferentes pestañas(altas)
	const [disabledProduct, setDisabledProduct] = useState(true);
	const [disabledMold, setDisabledMold] = useState(true);
	const [disabledStructure, setDisabledStructure] = useState(true);
	const [disabledHdr, setDisabledHdr] = useState(true);

	//Estados para mostrar/ocultar y habilitar/deshabilitar la relación con producto semielaborado
	const [visibleSemi, setVisibleSemi] = useState(false);
	const [disabledSemi, setDisabledSemi] = useState(true);

	//Estado para activar tabs
	const [key, setKey] = useState("code");

	const { id } = useParams();

	const {
		cleanContext,
		product,
		handleCodeByProductId,
		handleProductById,
		handleMoldByProductId,
		handleStructureByProductId,
		handleRoadmapByProductId,
		handleSemiByProductId,
	} = useProductContext();

	useEffect(() => {
		//LIMPIAMOS EL CONTEXT
		cleanContext();

		if (!id || id === undefined) {
			setKey("code");
			setDisabledProduct(true);
			setDisabledMold(true);
			setDisabledStructure(true);
			setDisabledHdr(true);
			setVisibleSemi(false);
			setDisabledSemi(true);
			return;
		}

		const fetchData = async () => {
			await Promise.all([
				handleCodeByProductId(id),
				handleProductById(id),
				handleMoldByProductId(id),
				handleStructureByProductId(id),
				handleRoadmapByProductId(id),
				handleSemiByProductId(id),
			]);
		};

		fetchData();
	}, [id]);

	useEffect(() => {
		if (product && Object.entries(product).length > 0) {
			const { stage, situation } = product;
			setVisibleSemi(situation === 1);

			const stageConfigurations = {
				0: {
					product: false,
					mold: true,
					structure: true,
					hdr: true,
					semi: true,
				},
				1: {
					product: false,
					mold: false,
					structure: true,
					hdr: true,
					semi: true,
				},
				2: {
					product: false,
					mold: false,
					structure: false,
					hdr: true,
					semi: true,
				},
				3: {
					product: false,
					mold: false,
					structure: false,
					hdr: false,
					semi: true,
				},
				4: {
					product: false,
					mold: false,
					structure: false,
					hdr: false,
					semi: false,
				},
			};

			const stageConfiguration = stageConfigurations[stage] || {};
			const {
				product: disabledProduct = false,
				mold: disabledMold = false,
				structure: disabledStructure = false,
				hdr: disabledHdr = false,
				semi: disabledSemi = false,
			} = stageConfiguration;

			setDisabledProduct(disabledProduct);
			setDisabledMold(disabledMold);
			setDisabledStructure(disabledStructure);
			setDisabledHdr(disabledHdr);
			setDisabledSemi(disabledSemi);
		}
	}, [product]);

	const handleTabSelect = (tab) => {
		setKey(tab);
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='border-bottom border-200 p-2'>
					<Row>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0 hover-actions-trigger'>Alta de productos</h6>
						</Col>

						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								className='float-end me-2'
								to='/codes/SingleCodesForm'
								variant='primary'
								style={{ fontSize: "10px" }}
								target='_blank'
							>
								<FontAwesomeIcon style={{ fontSize: "8px" }} icon='plus' /> Cód.
								individuales
							</Button>

							<Button
								as={Link}
								className='float-end me-2'
								to='/codes/compositeProductscodesform'
								variant='primary'
								style={{ fontSize: "10px" }}
								target='_blank'
							>
								<FontAwesomeIcon style={{ fontSize: "8px" }} icon='plus' /> Cód.
								compuestos
							</Button>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Tab.Content>
						<Row>
							<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
								<Tab.Container
									activeKey={key}
									defaultActiveKey={key}
									onSelect={handleTabSelect}
								>
									<Nav variant='tabs' className='mb-3'>
										<Nav.Item>
											<Nav.Link eventKey='code'>Código</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey='product' disabled={disabledProduct}>
												Producto
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey='mold' disabled={disabledMold}>
												Asignar molde
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link
												eventKey='structure'
												disabled={disabledStructure}
											>
												Estructura
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey='hdr' disabled={disabledHdr}>
												Hoja de ruta
											</Nav.Link>
										</Nav.Item>

										{visibleSemi && (
											<Nav.Item>
												<Nav.Link
													eventKey='semifinished'
													disabled={disabledSemi}
												>
													Semielaborado
												</Nav.Link>
											</Nav.Item>
										)}
									</Nav>

									<Tab.Content>
										<Tab.Pane eventKey='code'>
											<FormInsertUpdateCode
												setDisabledProduct={setDisabledProduct}
												setKey={setKey}
												setVisibleSemi={setVisibleSemi}
											/>
										</Tab.Pane>

										<Tab.Pane eventKey='product'>
											<FormInsertUpdateProducto
												setDisabledMold={setDisabledMold}
												setKey={setKey}
											/>
										</Tab.Pane>

										<Tab.Pane eventKey='mold'>
											<FormInsertUpdateMold
												setDisabledStructure={setDisabledStructure}
												setKey={setKey}
											/>
										</Tab.Pane>

										<Tab.Pane eventKey='structure'>
											<FormInsertUpdateStructure
												setDisabledHdr={setDisabledHdr}
												setKey={setKey}
											/>
										</Tab.Pane>

										<Tab.Pane eventKey='hdr'>
											<FormInsertUpdateHdr
												setDisabledSemi={setDisabledSemi}
												setKey={setKey}
											/>
										</Tab.Pane>

										{visibleSemi && (
											<Tab.Pane eventKey='semifinished'>
												<FormInsertUpdateSemi />
											</Tab.Pane>
										)}
									</Tab.Content>
								</Tab.Container>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
