import { useMemo } from "react";

export default function useColumnsOperations() {
  const columns = useMemo(
    () => [
        {
            accessor: 'id',
            Header: '#'
        },
        {
            accessor: 'denomination',
            Header: 'Denominación'
        },
        {
            accessor: 'monitoring_control',
            Header: 'Seguimiento'
        },
        {
          accessor: 'acciones',
          Header: 'Acciones'
        }
    ],
    []
  );

  return columns;
}
