/** @format */

import { useMemo } from "react";

export default function useColumnsHojasRuta() {
	const columns = useMemo(
		() => [
			{
				accessor: "codigo",
				Header: "Código",
			},
			{
				accessor: "denominacion",
				Header: "Denominacion",
			},
			{
				accessor: "desde",
				Header: "Desde",
			},
			{
				accessor: "acciones",
				Header: "Acciones",
			},
		],
		[]
	);

	return columns;
}
