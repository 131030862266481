/** @format */
import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

export const getHojaRuta = async (ruta_id) => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get(`/hoja-ruta/${ruta_id}`);
	return data.data;
};

export const getHojasRuta = async () => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { data } = await axiosWithToken.get("/hoja-ruta");
	return data.data;
};

export const handleDelete = async (id) => {
	const { token } = useAuthContext();
	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	await axiosWithToken.delete(`/hoja-ruta/delete/${id}`);
	const status = true;
	return status;
};
