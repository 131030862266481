/** @format */

import React, { useState, useEffect } from "react";
import { Row, Button, Card, Col, Tab, Nav } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link } from "react-router-dom";

//Import formularios
import FormInsertUpdateCode from "components/products/rawmaterials/FormInsertUpdateCode";
import FormInsertUpdateRawmaterial from "components/products/rawmaterials/FormInsertUpdateRawmaterial";
import FormInsertUpdateStructure from "components/products/rawmaterials/FormInsertUpdateStructure";
import FormInsertUpdateHdr from "components/products/rawmaterials/FormInsertUpdateHdr";

import { useRawmaterialContext } from "hooks/useRawmaterialContext";

const Formulario = () => {
	//Estados habilita/deshabilita diferentes pestañas(altas)
	const [disabledRawmaterial, setDisabledRawmaterial] = useState(true);
	const [disabledStructure, setDisabledStructure] = useState(true);
	const [disabledHdr, setDisabledHdr] = useState(true);

	//Estado para activar tabs
	const [key, setKey] = useState("code");

	const { id } = useParams();

	const {
		rawmaterial,
		cleanContext,
		handleCodeByRawmaterialId,
		handleRawmaterialById,
		handleStructureByRawmaterialId,
		handleHdrByRawmaterialId,
	} = useRawmaterialContext();

	useEffect(() => {
		//LIMPIAMOS EL CONTEXT
		cleanContext();

		if (!id || id === undefined) {
			setKey("code");
			setDisabledRawmaterial(true);
			setDisabledStructure(true);
			setDisabledHdr(true);
			return;
		}
		const fetchData = async () => {
			await Promise.all([
				handleCodeByRawmaterialId(id),
				handleRawmaterialById(id),
				handleStructureByRawmaterialId(id),
				handleHdrByRawmaterialId(id),
			]);
		};

		fetchData();
	}, [id]);

	useEffect(() => {
		if (rawmaterial && Object.entries(rawmaterial).length > 0) {
			const { stage } = rawmaterial;

			const stageConfigurations = {
				0: {
					rawmaterial: false,
					structure: true,
					hdr: true,
				},
				1: {
					rawmaterial: false,
					structure: false,
					hdr: true,
				},
				2: {
					rawmaterial: false,
					structure: false,
					hdr: false,
				},
			};

			const stageConfiguration = stageConfigurations[stage] || {};
			const {
				rawmaterial: disabledRawmaterial = false,
				structure: disabledStructure = false,
				hdr: disabledHdr = false,
			} = stageConfiguration;

			setDisabledRawmaterial(disabledRawmaterial);
			setDisabledStructure(disabledStructure);
			setDisabledHdr(disabledHdr);
		}
	}, [rawmaterial]);

	const handleTabSelect = (tab) => {
		setKey(tab);
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200 p-2'>
					<Row>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0 hover-actions-trigger'>
								Alta de materias primas
							</h6>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								className='float-end me-2'
								to='/codes/SingleCodesForm'
								variant='primary'
								style={{ fontSize: "10px" }}
								target='_blank'
							>
								<FontAwesomeIcon style={{ fontSize: "8px" }} icon='plus' /> Cód.
								individuales
							</Button>

							<Button
								as={Link}
								className='float-end me-2'
								to='/codes/compositeRawmaterialscodesform'
								variant='primary'
								style={{ fontSize: "10px" }}
								target='_blank'
							>
								<FontAwesomeIcon style={{ fontSize: "8px" }} icon='plus' /> Cód.
								compuestos
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body>
					<Tab.Content>
						<Row>
							<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
								<Tab.Container
									activeKey={key}
									defaultActiveKey={key}
									onSelect={handleTabSelect}
								>
									<Nav variant='tabs' className='mb-3'>
										<Nav.Item>
											<Nav.Link eventKey='code'>Código</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link
												eventKey='rawmaterial'
												disabled={disabledRawmaterial}
											>
												Materia prima
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link
												eventKey='structure'
												disabled={disabledStructure}
											>
												Estructura
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey='hdr' disabled={disabledHdr}>
												Hoja de ruta
											</Nav.Link>
										</Nav.Item>
									</Nav>

									<Tab.Content>
										<Tab.Pane eventKey='code'>
											<FormInsertUpdateCode
												setDisabledRawmaterial={setDisabledRawmaterial}
												setKey={setKey}
											/>
										</Tab.Pane>

										<Tab.Pane eventKey='rawmaterial'>
											<FormInsertUpdateRawmaterial
												setDisabledStructure={setDisabledStructure}
												setKey={setKey}
											/>
										</Tab.Pane>

										<Tab.Pane eventKey='structure'>
											<FormInsertUpdateStructure
												setDisabledHdr={setDisabledHdr}
												setKey={setKey}
											/>
										</Tab.Pane>

										<Tab.Pane eventKey='hdr'>
											<FormInsertUpdateHdr />
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
