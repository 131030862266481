/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
	Row,
	Col,
	Card,
	Tab,
	OverlayTrigger,
	Tooltip,
	Button,
} from "react-bootstrap";
import { camelize } from "../../../helpers/utils";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { id } = useParams();

	{
		/* COMBINACION */
	}

	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [fechaalta, setFechaalta] = useState("");
	const [fechamodificación, setFechamodificación] = useState("");
	const [status, setStatus] = useState("");
	const [combinationDetail, setCombinationDetail] = useState([]);

	const perPage = 6;
	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(combinationDetail, perPage);

	useEffect(() => {
		getCombinationById();
	}, []);

	const getCombinationById = async () => {
		const response = await axiosWithToken.get(`/combination/view/${id}`);

		console.log("prueba imprimir COMBINACION", response);

		setCode(response.data.data.combination.code);
		setDescription(response.data.data.combination.description);
		setFechaalta(
			dayjs(response.data.data.combination.created_at).format(
				"DD/MM/YYYY HH:mm:ss"
			)
		);
		setFechamodificación(
			dayjs(response.data.data.combination.updated_at).format(
				"DD/MM/YYYY HH:mm:ss"
			)
		);
		setStatus(response.data.data.combination.status);

		if (response.data.data.detail) {
			setCombinationDetail(Object.values(response.data.data.detail));
		}
	};
	return (
		<>
			{/* VIEW */}
			<Row className='align-items-end g-2 mb-2'>
				<Col>
					<Card>
						<h5
							className='p-2 mb-2 hover-actions-trigger'
							id={camelize(`Formulario`)}
						>
							Vista de combinación
						</h5>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={6} sm={6} lg={3} md={3}>
					<Card bg='primary' className='mb-2 border border-400'>
						<h5 className='text-center text-white'>Código:</h5>
						<h5 className='text-center text-white'># {code}</h5>
					</Card>
				</Col>
				<Col xs={6} sm={6} lg={3} md={3}>
					<Card
						bg={status ? "success" : "danger"}
						className='mb-2 border border-400'
					>
						<h5 className='text-center text-white'>Estado:</h5>
						<h5 className='text-center text-white'>
							{status ? "Habilitado" : "Deshabilitado"}
						</h5>
					</Card>
				</Col>
				<Col xs={6} sm={6} lg={3} md={3}>
					<Card bg='warning' className='mb-2 border border-400'>
						<h5 className='text-center text-white'>Alta:</h5>
						<h5 className='text-center text-white'>{fechaalta}</h5>
					</Card>
				</Col>
				<Col xs={6} sm={6} lg={3} md={3}>
					<Card bg='secondary' className='mb-2 border border-400'>
						<h5 className='text-center text-white'>Modificación:</h5>
						<h5 className='text-center text-white'>{fechamodificación}</h5>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col xs={12} sm={12} lg={4} md={4}>
					<Card className='mb-3 border-bottom border-200'>
						<Card.Body className='bg-light'>
							<Tab.Content>
								<Row className='mb-2'>
									<Col xs={12} sm={12} lg={12} md={12}>
										<h5 className='mb-3'>Combinación: #{code}</h5>
										<h5>{description}</h5>
									</Col>
								</Row>
								{/* <Row>
                <Col xs={12} sm={12} lg={12} md={12}>
                  <Image
                    width="250" height="150"
                    src={`${img}/${image}`}
                    fluid
                    rounded
                  />
                </Col>
              </Row> */}
							</Tab.Content>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} sm={12} lg={8} md={8}>
					<Card className='mb-3 border-bottom border-200'>
						<Card.Header className='bg-light border-bottom border-200'>
							<Row className='align-items-end g-2'>
								<Col>
									<h5
										className='mb-0 hover-actions-trigger'
										id={camelize(`Formulario`)}
									>
										Detalle de la combinación
									</h5>
								</Col>
							</Row>
						</Card.Header>
						<Card.Body className='bg-light'>
							<Tab.Content>
								<Row as={Flex} justifyContent='between'>
									<Col xs={12} sm={12} lg={12} md={12}>
										<Col md={12} lg={12} className='mb-4 mb-lg-0'>
											{combinationDetail &&
												paginatedDetails.map((detail, index) => (
													<Card
														key={`card-${index}`}
														className='mb-3 p-3 square border-start border-start-2 border-primary'
													>
														<span>
															<p className='mb-1 fs--1'>
																<strong className='fs--1'>Atributo:</strong>{" "}
																{detail.attribute.description} &nbsp;&nbsp;{" "}
																<strong className='fs--1'>Campo:</strong>{" "}
																{detail.field.description} &nbsp;&nbsp;{" "}
																<strong className='fs--1'>Valor A-C:</strong>{" "}
																{detail.value.description}
															</p>
														</span>
													</Card>
												))}
										</Col>
									</Col>
								</Row>
							</Tab.Content>
						</Card.Body>
						<Card.Footer className='d-flex justify-content-center'>
							<div>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip>Prev</Tooltip>}
								>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canPreviousPage}
										onClick={prevPage}
										className='me-2'
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-left' />
									</Button>
								</OverlayTrigger>
							</div>

							<ul className='pagination mb-0'>
								{paginationArray.map((page) => (
									<li
										key={page}
										className={classNames({ active: currentPage === page })}
									>
										<Button
											size='sm'
											variant='falcon-default'
											className='page me-2'
											onClick={() => goToPage(page)}
										>
											{page}
										</Button>
									</li>
								))}
							</ul>
							<div>
								<OverlayTrigger
									trigger='click'
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Next</Tooltip>}
								>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canNextPage}
										onClick={nextPage}
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-right' />
									</Button>
								</OverlayTrigger>
							</div>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default Formulario;
