/** @format */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
	Form,
	Row,
	Card,
	Col,
	Badge,
	OverlayTrigger,
	Popover,
	Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";
import { useProductContext } from "hooks/useProductContext";

const FormularioMold = ({ setDisabledStructure, setKey }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Validaciones disabled
	const [disabledContinue, setDisabledContinue] = useState(true);

	//Molde seleccionado
	const [selectedMold, setSelectedMold] = useState({});

	const {
		product,
		selectMold,
		handleMoldByProductId,
		handleStructureByProductId,
	} = useProductContext();

	useEffect(() => {
		if (Object.entries(selectMold).length > 0 && product.stage > 0) {
			//Seteamos el molde desde el context
			setSelectedMold(selectMold);
			//Habilitamos la pestaña structure
			setDisabledStructure(false);
		} else {
			setDisabledContinue(true);
			setSelectedMold({});
		}
	}, [selectMold, product]);

	useEffect(() => {
		//Este useEffect tiene que estar porque viene de otro componente
		if (Object.entries(selectedMold).length > 0) {
			setDisabledContinue(false);
		}
	}, [selectedMold]);

	const handleCancel = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/products/list`);
	};

	const deleteMold = () => {
		setSelectedMold({});
		setDisabledContinue(true);
	};

	const storeUpdate = async () => {
		if (Object.entries(selectedMold).length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El asignar un molde es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.post("/moldproduct/assignMold", {
				product_id: product.id,
				mold_id: selectedMold.id,
			});

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			//Seteamos el mold y la estructura para tener todo actualizado en el context
			handleMoldByProductId(data.data.mold_id);
			handleStructureByProductId(data.data.product_id);

			setTimeout(() => {
				setDisabledStructure(false);
				setKey("structure");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	const renderTooltip = (
		<Popover id='popover-mold'>
			<Popover.Header>
				<span className='fs--1'>{selectedMold.description}</span>
			</Popover.Header>
			<Popover.Body>
				<span className='text-left fs--2'>
					<strong>Metros lineales: </strong> {selectedMold.linealmeters}
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>M2: </strong> {selectedMold.squaremeters}
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Perímetro: </strong> {selectedMold.perimeter}
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Perímetro flat: </strong> {selectedMold.perimeterflat}
				</span>
				<br />
				<span className='text-left fs--2'>
					<strong>Perímetro cintura: </strong> {selectedMold.perimeterwaist}
				</span>
			</Popover.Body>
		</Popover>
	);

	return (
		<Row>
			<Col className='mt-3 mb-2' xs={12} sm={12} lg={4} md={4}>
				<Card className='mb-2 p-2 h-md-100'>
					<h6 className='mb-4 text-center fs--2'>Asignar molde al producto</h6>

					<span>
						<p className='mb-1 fs--2 text-center'>
							¡Al seleccionar un molde y continuar, los datos en la estructura
							se actualizarán acorde al mismo!
						</p>
					</span>

					<Card.Body>
						<Row>
							<Form.Group
								as={Col}
								xs={12}
								sm={12}
								md={12}
								lg={12}
								controlId='formGridUnitsMp'
							>
								<Form.Label className='fs--2'>
									Asignar un molde
									<span className='text-danger'>*</span>
								</Form.Label>
								<SearchAutocomplete
									setSelect={setSelectedMold}
									modelSearch={"molds"}
									disabled={false}
								/>
							</Form.Group>

							<Form.Group
								as={Col}
								xs={12}
								sm={12}
								md={12}
								lg={12}
								controlId='formGridUnitsMp'
							>
								<OverlayTrigger
									placement='top'
									delay={{ show: 100, hide: 200 }}
									overlay={renderTooltip}
								>
									<span className='fs--1'>
										<Badge className='mt-1' bg='secondary'>
											{selectedMold.code}
										</Badge>
									</span>
								</OverlayTrigger>
								<span className='fs--2'>
									{selectedMold.code ? (
										<FontAwesomeIcon
											icon='times'
											size='sm'
											className='cursor-pointer text-danger ms-2'
											onClick={() => deleteMold()}
										/>
									) : (
										""
									)}
								</span>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
			</Col>
			<Col className='mt-3 mb-2' xs={12} sm={12} lg={8} md={8}>
				<Card className='mb-2 p-2 h-md-100'>
					<Row>
						<h6 className='mb-4 text-center fs--2'>Información del molde</h6>

						<Col className='mt-3 ms-3' xs={12} sm={12} lg={5} md={5}>
							<p className='mb-2 fs--2'>
								<strong className='fs--0 text-600'>Código:</strong>{" "}
								{selectedMold.code}
							</p>

							<p className='mb-2 fs--2'>
								<strong className='fs--0 text-600'>Descripción:</strong>{" "}
								{selectedMold.description}
							</p>

							<p className='mb-2 fs--2'>
								<strong className='fs--0 text-600'>Metros lineales:</strong>{" "}
								{selectedMold.linealmeters}
							</p>

							<p className='mb-2 fs--2'>
								<strong className='fs--0 text-600'>Metros cuadrados:</strong>{" "}
								{selectedMold.squaremeters}
							</p>
						</Col>

						<Col className='mt-3' xs={12} sm={12} lg={5} md={5}>
							<p className='mb-2 fs--2'>
								<strong className='fs--0 text-600'>Perímetro:</strong>{" "}
								{selectedMold.perimeter}
							</p>

							<p className='mb-2 fs--2'>
								<strong className='fs--0 text-600'>Perímetro flat:</strong>{" "}
								{selectedMold.perimeterflat}
							</p>

							<p className='mb-2 fs--2'>
								<strong className='fs--0 text-600'>Perímetro cinturera:</strong>{" "}
								{selectedMold.perimeterwaist}
							</p>
						</Col>
					</Row>
				</Card>
			</Col>
			<Col
				xs={12}
				sm={12}
				lg={12}
				md={12}
				className='mb-2 mt-3 d-flex justify-content-end'
			>
				<Button size='sm' variant='danger' onClick={() => handleCancel()}>
					Cancelar
				</Button>

				<Button
					className='ms-1'
					size='sm'
					variant='primary'
					disabled={disabledContinue}
					onClick={() => storeUpdate()}
				>
					Guardar
				</Button>
			</Col>
		</Row>
	);
};

FormularioMold.propTypes = {
	setDisabledStructure: PropTypes.func,
	setKey: PropTypes.func,
};

export default FormularioMold;
