/** @format */

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//components
import InputsCargaMPCant from "./InputsCargaMPCant";
import Divider from "components/common/Divider";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Form,
	Row,
	Col,
	Button,
	Card,
	Tab,
	FloatingLabel,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { camelize } from "../../helpers/utils";
import { useAuthContext } from "hooks/useAuthContext";

const EstamperiaForm = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [nombre, setNombre] = useState("");
	const [carpeta, setCarpeta] = useState("");
	const [folio, setFolio] = useState("");
	const [pelicula, setPelicula] = useState("");
	const [obs, setObs] = useState("");
	const [color, setColor] = useState(0);
	const [shablon, setShablon] = useState(0);
	const [selectedFile, setSelectedFile] = useState(null);
	const [numInputs, setNumInputs] = useState(0); // inputs oara agregar materia prima + cantidad
	const [mpSelect, setMpSelect] = useState([]);

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	const inputMP = [];

	const handleMpSeleccionada = (mpItems) => {
		setMpSelect([...mpSelect, mpItems]);
	};

	const handleDeleteMpSeleccionada = (mp_id) => {
		const newMpSeleccionada = mpSelect.filter((mp) => mp.id != mp_id);
		setMpSelect(newMpSeleccionada);
	};

	//Funcion para borrar un row específico de ubicaciones
	const eliminarRowUbicaciones = (rowId) => {
		// const mpItems = document.querySelector(`#row-${rowId}`);
		// handleDeleteMpSeleccionada(mpItems);
		inputMP.splice(Number(rowId), 1);
		document.querySelector(`#row-${rowId}`).remove();
	};

	for (let i = 0; i < numInputs; i++) {
		inputMP.push(
			<InputsCargaMPCant
				key={i}
				keyId={i}
				handleMpSeleccionada={handleMpSeleccionada}
				mpSelect={mpSelect}
				handleDeleteMpSeleccionada={handleDeleteMpSeleccionada}
				eliminarRowUbicaciones={eliminarRowUbicaciones}
			/>
		);
	}

	//Funcion para agregar un row más de ubicaciones
	const agregarRowMP = () => {
		setNumInputs(numInputs + 1);
	};

	const storeEstamperia = async (e) => {
		e.preventDefault();

		//Por cada MP seleccionada le agrego la cantidad de colores y shablones que le seteamos.
		for (let i = 0; i < inputMP.length; i++) {
			mpSelect.map((mp) => {
				if (mp.keyId === Number(i)) {
					mp.cantidad = Number(
						document.querySelector(`#formGridCantidad-${i}`).value
					);
				}
			});
		}

		//Reemplazamos los espacios en el nombre por guion bajo
		const newName = nombre.replace(/\s+/g, "_");

		//creamos un formData para poder enviar la imagen
		const formData = new FormData();
		formData.append("nombre", newName);
		formData.append("image", selectedFile);
		formData.append("carpeta", carpeta);
		formData.append("folio", folio);
		formData.append("pelicula", pelicula);
		formData.append("cant_color", color);
		formData.append("cant_shablon", shablon);
		formData.append("obs", obs);
		formData.append(
			"products",
			mpSelect.length > 0 ? JSON.stringify(mpSelect) : ""
		);

		try {
			const respuesta = await axiosWithToken.post(
				"/estamperia/new-estamperia",
				formData
			);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("¡Agregado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setTimeout(() => {
					navigate(`/estamperia/${respuesta.data.id}`);
				}, 2000);
			}
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
					autoClose: 4000,
				});
			}
		}
	};

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const volver = () => {
		navigate(-1);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								Agregar Estamperìa
							</h5>
							<p className='mt-2 mb-0'>Ingresá los datos correspondientes.</p>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={storeEstamperia}>
							<Row className='mb-3 g-3'>
								{/* NOMBRE */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={6}
									lg={3}
									controlId='formGridNombre'
								>
									<Form.Label className='fs--1'>Nombre</Form.Label>
									<Form.Control
										value={nombre}
										onChange={(e) => setNombre(e.target.value)}
										type='text'
										placeholder='Ingrese Nombre'
										size='sm'
									/>
								</Form.Group>

								{/* CARPETA */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={6}
									lg={3}
									controlId='formGridCarpeta'
								>
									<Form.Label className='fs--1'>Carpeta</Form.Label>
									<Form.Control
										value={carpeta}
										onChange={(e) => setCarpeta(e.target.value)}
										type='text'
										placeholder='Ingrese Carpeta'
										size='sm'
									/>
								</Form.Group>

								{/* FOLIO */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={6}
									lg={3}
									controlId='formGridFolio'
								>
									<Form.Label className='fs--1'>Folio</Form.Label>
									<Form.Control
										value={folio}
										onChange={(e) => setFolio(e.target.value)}
										type='text'
										placeholder='Ingrese Folio'
										size='sm'
									/>
								</Form.Group>

								{/* PELICULA */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={6}
									lg={3}
									controlId='formGridPelicula'
								>
									<Form.Label className='fs--1'>Pelicula</Form.Label>
									<Form.Control
										value={pelicula}
										onChange={(e) => setPelicula(e.target.value)}
										type='text'
										placeholder='Ingrese Pelicula'
										size='sm'
									/>
								</Form.Group>

								{/* COLORES */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={6}
									lg={2}
									controlId='formGridCantColores'
								>
									<Form.Label className='fs--1'>Cant.Colores</Form.Label>
									<Form.Control
										value={color}
										onChange={(e) => setColor(e.target.value)}
										type='number'
										placeholder='Ingrese cantidad de colores'
										size='sm'
										min={0}
									/>
								</Form.Group>

								{/* SHABLON */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={6}
									lg={2}
									controlId='formGridCantShablon'
								>
									<Form.Label className='fs--1'>Cant.Shablon</Form.Label>
									<Form.Control
										value={shablon}
										onChange={(e) => setShablon(e.target.value)}
										type='number'
										placeholder='Ingrese cantidad de shablones'
										size='sm'
										min={0}
									/>
								</Form.Group>

								{/* IMAGEN */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={8}
									controlId='formFile'
									className='mb-3'
									size='sm'
								>
									<Form.Label className='fs--1'>
										Ingrese imagen de estampa
									</Form.Label>
									<Form.Control type='file' onChange={handleFileChange} />
								</Form.Group>

								{/* MATERIA PRIMA */}
								{/* <Form.Group as={Col} xs={12} sm={12} md={12} lg={12} controlId="formGridCode">
										<Form.Label className="fs--2">Buscar un código</Form.Label>
										<SearchAutocomplete 
											setSelect={setMateriaPrima}
											handleSearchMP={handleSearchMP}
											modelSearch={'rawmaterial'}
											mpSeleccionada={mpSeleccionada}
										/>
									</Form.Group>
										<Stack direction="horizontal" gap={3} className="d-flex" style={{flexWrap:'wrap'}}>
										{
										mpSeleccionada?.length > 0 ?
											(
											mpSeleccionada.map(mp => {
												console.log(mp);
												return(
												<div key={mp.id}>
													<SoftBadge variant="secondary">
													{mp.name}
													</SoftBadge>{" "}<span style={{ cursor:"pointer"}} onClick={e => handleMP(mp.id)}>x</span>
												</div>
												)
											})
											) : ""
										}
										</Stack> */}

								<Divider />
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									className='mb-3'
									size='sm'
								>
									<Form.Label className='fs--1'>Materia Prima</Form.Label>
									{inputMP}
								</Form.Group>

								<Col>
									<Button onClick={agregarRowMP} className='float-end fs--1'>
										<FontAwesomeIcon icon='plus' /> Agregar Materia Prima
									</Button>
								</Col>

								<Divider />

								{/* OBSERVACIONES */}
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridObservaciones'
								>
									<FloatingLabel
										controlId='floatingTextarea'
										label='Breve descripción'
										className='mb-3'
									>
										<Form.Control
											as='textarea'
											placeholder='Ingrese una breve descripción'
											value={obs}
											onChange={(e) => setObs(e.target.value)}
										/>
									</FloatingLabel>
								</Form.Group>
							</Row>

							<Divider />

							<Row>
								<Col>
									<Button
										className='float-start'
										variant='warning'
										onClick={volver}
									>
										Volver
									</Button>
								</Col>

								<Col>
									<Button className='float-end' variant='primary' type='submit'>
										Guardar
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

export default EstamperiaForm;
