/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Row, Col, Button, Card, Tab } from "react-bootstrap";
import Select from "react-select";
import { camelize } from "../../../helpers/utils";
import { useCentros } from "hooks/useCentros";
import { getProveedores, getMachine } from "data/machine/machines";
import { useAuthContext } from "hooks/useAuthContext";

const FormMachine = ({ id = null, setShow = null, setEditMode = null }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [description, setDescription] = useState("");
	const [cod_maq, setCodMaq] = useState("");
	const [tipo_maq, setTipoMaq] = useState("");
	const [nro_maq, setNroMaq] = useState("");
	const [fechaAlta, setFechaAlta] = useState("");
	const [fechaBaja, setFechaBaja] = useState("");
	const [status, setStatus] = useState(1);
	const [cod_alfa_presea, setCodAlfaPresea] = useState("");
	const [selectedCentro, setSelectedCentro] = useState(0);
	const [aire, setAire] = useState(0);
	const [cortaHilo, setCortaHilo] = useState(0);
	const [fila, setFila] = useState("");
	const [luz, setLuz] = useState(0);
	const [proveedores, setProveedores] = useState([]);
	const [selectedProveedor, setSelectedProveedor] = useState(0);
	const [modelo, setModelo] = useState("");
	const [motor, setMotor] = useState("");
	const [obs, setObs] = useState("");
	const [machine, setMachine] = useState({});
	const [machineToEdit, setMachineToEdit] = useState("");

	const navigate = useNavigate();
	const toastId = React.useRef(null);

	const volver = () => {
		navigate("/work-center/machine-list");
	};

	const { cod_maq_id } = useParams();

	const {
		lastLevels,
		getLastLevels,
		machine_types,
		getAllMachineTypes,
		getAllMachines,
	} = useCentros();

	useEffect(async () => {
		if (id || cod_maq_id) {
			setMachineToEdit(id ? id : cod_maq_id);
		} else {
			setMachine({});
			setDescription("");
			setCodMaq("");
			setTipoMaq("");
			setNroMaq("");
			setFechaAlta("");
			setFechaBaja("");
			setStatus(1);
			setCodAlfaPresea("");
			setSelectedCentro(0);
			setAire(0);
			setCortaHilo(0);
			setFila("");
			setLuz(0);
			setSelectedProveedor(0);
			setModelo("");
			setMotor("");
			setObs("");
			setMachineToEdit("");
		}

		const getAllProveedores = await getProveedores();
		setProveedores(getAllProveedores);

		getAllMachineTypes();
		getLastLevels();
	}, []);

	useEffect(async () => {
		if (machineToEdit !== "") {
			const machine = await getMachine(machineToEdit);
			setMachine(machine);
		}
	}, [machineToEdit]);

	useEffect(() => {
		if (machine?.cod_maq) {
			setDescription(machine.description);
			setCodMaq(machine.cod_maq);
			setTipoMaq(machine.tipo_maq);
			setNroMaq(machine.nro_maq);
			setFechaAlta(machine?.date_of_admission ? machine.date_of_admission : "");
			setFechaBaja(machine?.discharge_date ? machine.discharge_date : "");
			setStatus(machine.status);
			setCodAlfaPresea(machine.cod_alfa_presea);
			if (machine.center?.id) {
				const center = {
					label: machine.center.description,
					value: machine.center.id,
				};
				setSelectedCentro(center);
			} else {
				setSelectedCentro(0);
			}
			setAire(machine?.aire ? machine.aire : 0);
			setCortaHilo(machine?.corta_hilo ? machine.corta_hilo : 0);
			setFila(machine?.fila ? machine.fila : "");
			setLuz(machine?.luz ? machine.luz : 0);
			if (machine.supplier?.id) {
				const supplier = {
					label: machine.supplier.description,
					value: machine.supplier.id,
				};
				setSelectedProveedor(supplier);
			} else {
				setSelectedProveedor(0);
			}
			setModelo(machine?.modelo ? machine.modelo : "");
			setMotor(machine?.motor ? machine.motor : "");
			setObs(machine.obs);
		}
	}, [machine]);

	const handleChangeCentroTrabajo = (selectedCenter) => {
		setSelectedCentro(selectedCenter);
	};

	const handleChangeProveedor = (proveedor) => {
		setSelectedProveedor(proveedor);
	};

	//ADD or EDIT machines
	const storeMachine = async (e) => {
		e.preventDefault();

		if ([description, cod_maq, tipo_maq].includes("")) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					"¡Por favor complete todos los campos!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			return;
		}

		//ADD NEW MACHINE
		if (machineToEdit === "") {
			try {
				const { data } = await axiosWithToken.post("/work-center/add-machine", {
					description,
					cod_maq,
					tipo_maq,
					nro_maq,
					fechaAlta,
					fechaBaja,
					status,
					cod_alfa_presea,
					selectedCentro,
					aire,
					cortaHilo,
					fila,
					luz,
					selectedProveedor,
					modelo,
					motor,
					obs,
				});

				if (data.status === "ok") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.success(`Agregado correctamente!`, {
							role: "alert",
							theme: "dark",
						});
					}

					setTimeout(() => {
						navigate("/work-center/machine-list/");
					}, 1300);

					return;
				}
			} catch (error) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`${error.response.data.message}`, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		}

		if (machineToEdit) {
			try {
				const { data } = await axiosWithToken.put(
					`/work-center/update-machine/${machineToEdit}`,
					{
						description,
						cod_maq,
						tipo_maq,
						nro_maq,
						fechaAlta,
						fechaBaja,
						status,
						cod_alfa_presea,
						selectedCentro,
						aire,
						cortaHilo,
						fila,
						luz,
						selectedProveedor,
						modelo,
						motor,
						obs,
					}
				);

				if (data.status === "ok") {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast.success(`¡Editado correctamente!`, {
							role: "alert",
							theme: "dark",
						});
					}
				}

				setTimeout(() => {
					machineToEdit && setEditMode === null
						? getAllMachines()
						: getMachine(machineToEdit);

					if (setShow != null) {
						setShow(false);
					}

					//Si el setEditionMode es distinto de nulo significa que estamos modificando una máquina desde su vista individual
					if (setEditMode != null) {
						getMachine(machineToEdit);
						setEditMode(false);
					}
				}, 1300);

				return;
			} catch (error) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(error.response.data.message, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		}
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* FORM */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5
								className='mb-0 hover-actions-trigger'
								id={camelize(`Formulario`)}
							>
								{cod_maq_id === undefined ? "Agregar" : "Editar"} Máquina
							</h5>
							<p className='mt-2 mb-0'>Ingresá los datos correspondientes.</p>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Form onSubmit={storeMachine}>
							<Row className='mb-3 g-3'>
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={3}
									lg={3}
									controlId='formGridDescriptionMaq'
								>
									<Form.Label>Descripción</Form.Label>
									<Form.Control
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										type='text'
										placeholder='Ingrese marca de máquina'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={2}
									lg={2}
									controlId='formGridCodMaq'
								>
									<Form.Label>Cod.Maq</Form.Label>
									<Form.Control
										value={cod_maq}
										onChange={(e) => setCodMaq(e.target.value)}
										type='text'
										placeholder='Ingrese código de máquina'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={2}
									lg={2}
									controlId='formGridNroMaq'
								>
									<Form.Label>Nro. Maq.</Form.Label>
									<Form.Control
										value={nro_maq}
										onChange={(e) => setNroMaq(e.target.value)}
										type='text'
										placeholder='Ingrese número de máquina'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={2}
									lg={2}
									controlId='formGridCodMaq'
								>
									<Form.Label>Cod_alfa Presea</Form.Label>
									<Form.Control
										value={cod_alfa_presea}
										onChange={(e) => setCodAlfaPresea(e.target.value)}
										type='text'
										placeholder='Ingrese código lafa de Presea'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={2}
									lg={2}
									controlId='formGridFila'
								>
									<Form.Label>Fila</Form.Label>
									<Form.Control
										value={fila}
										onChange={(e) => setFila(e.target.value)}
										type='text'
										placeholder='Ingrese fila'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={3}
									lg={3}
									controlId='formGridModelo'
								>
									<Form.Label>Modelo</Form.Label>
									<Form.Control
										value={modelo}
										onChange={(e) => setModelo(e.target.value)}
										type='text'
										placeholder='Ingrese Modelo'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={3}
									lg={3}
									controlId='formGridMotor'
								>
									<Form.Label>Motor</Form.Label>
									<Form.Control
										value={motor}
										onChange={(e) => setMotor(e.target.value)}
										type='text'
										placeholder='Ingrese Motor'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={3}
									lg={3}
									controlId='formGridTipoMaq'
								>
									<Form.Label>Tipo Maq.</Form.Label>
									<Form.Select
										aria-label='tiposMaquina'
										value={tipo_maq}
										onChange={(e) => setTipoMaq(e.target.value)}
									>
										<option value=''>-- Seleccionar una opción --</option>
										{machine_types.map((elem) => {
											return (
												<option key={elem.id} value={elem.id}>
													{elem.description}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={2}
									lg={2}
									controlId='formGridAire'
								>
									<Form.Label>Aire</Form.Label>
									<Form.Select
										aria-label='aire'
										value={aire}
										onChange={(e) => setAire(Number(e.target.value))}
									>
										<option value={1}>Si</option>
										<option value={0}>No</option>
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={2}
									lg={2}
									controlId='formGridCoraHilo'
								>
									<Form.Label>Corta Hilo</Form.Label>
									<Form.Select
										aria-label='corta hilo'
										value={cortaHilo}
										onChange={(e) => setCortaHilo(Number(e.target.value))}
									>
										<option value={1}>Si</option>
										<option value={0}>No</option>
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={2}
									lg={2}
									controlId='formGridLuz'
								>
									<Form.Label>Luz</Form.Label>
									<Form.Select
										aria-label='luz'
										value={luz}
										onChange={(e) => setLuz(Number(e.target.value))}
									>
										<option value={1}>Si</option>
										<option value={0}>No</option>
									</Form.Select>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={4}
									lg={4}
									controlId='formGridFechaAlta'
								>
									<Form.Label>Fecha Ingreso</Form.Label>
									<Form.Control
										value={fechaAlta}
										onChange={(e) => setFechaAlta(e.target.value)}
										type='date'
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={3}
									lg={3}
									controlId='formGridEstadoMaq'
								>
									<Form.Label>Estado</Form.Label>
									<Form.Select
										aria-label='estadoMaquina'
										value={status}
										onChange={(e) => setStatus(Number(e.target.value))}
									>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Form.Select>
								</Form.Group>

								{/* PROVEEDOR */}
								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={4}
									lg={4}
									controlId='formGridProveedor'
								>
									<Form.Label>Proveedor</Form.Label>

									<Select
										options={proveedores}
										value={selectedProveedor}
										onChange={handleChangeProveedor}
										styles={{ marginTop: "0" }}
										placeholder={"Seleccione Proveedor"}
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={6}
									md={4}
									lg={4}
									controlId='formGridCenter'
								>
									<Form.Label>Centro de trabajo</Form.Label>

									<Select
										options={lastLevels}
										value={selectedCentro}
										onChange={handleChangeCentroTrabajo}
										styles={{ marginTop: "0" }}
										placeholder={"Seleccione centro de trabajo"}
									/>
								</Form.Group>

								{!status ? (
									<Form.Group
										as={Col}
										xs={12}
										sm={6}
										md={3}
										lg={3}
										controlId='formGridFechaBaja'
									>
										<Form.Label>Fecha Baja</Form.Label>
										<Form.Control
											value={fechaBaja}
											onChange={(e) => setFechaBaja(e.target.value)}
											type='date'
										/>
									</Form.Group>
								) : (
									""
								)}

								<Form.Group className='mb-3' controlId='observaciones'>
									<Form.Label>Observaciones</Form.Label>
									<Form.Control
										as='textarea'
										rows={3}
										value={obs}
										onChange={(e) => setObs(e.target.value)}
									/>
								</Form.Group>
							</Row>

							<Row>
								{id === null || cod_maq_id === null ? (
									<Col>
										<Button variant='warning' onClick={() => volver()}>
											Volver
										</Button>
									</Col>
								) : (
									""
								)}

								<Col>
									<Button className='float-end' variant='primary' type='submit'>
										{cod_maq_id === undefined ? "Guardar" : "Editar"}
									</Button>
								</Col>
							</Row>
						</Form>
					</Tab.Content>
				</Card.Body>
			</Card>

			{/* FORM */}
		</>
	);
};

FormMachine.propTypes = {
	id: PropTypes.number,
	setShow: PropTypes.func,
	setEditMode: PropTypes.func,
};

export default FormMachine;
