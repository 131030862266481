/** @format */

import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

export const getFamilyParents = async () => {
	try {
		const { token } = useAuthContext();
		axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const { data } = await axiosWithToken.get("/productfamilies/parents");
		const families = data.data;
		return families;
	} catch (error) {
		throw error.response.data.message;
	}
};
