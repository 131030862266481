import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Tab,
  Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import EstamperiaTable from './EstamperiaTable'
import { useEstamperia } from 'hooks/useEstamperia';

const EstamperiaList = () => {

    const { estampas, getEstampas  } = useEstamperia();

    useEffect(() => {
        getEstampas();
    }, [])

  return (
    <Card className="mb-3 border-bottom border-200">
        <Card.Header className="bg-light border-bottom border-200">
            <Row>
                <Col>
                    <h5 className="mb-0">Listado de Estampas</h5>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button
                        as={Link}
                        to="/estamperia/new-estamperia"
                        variant="success"
                        size="sm"
                        className="border-300"
                    >
                        <FontAwesomeIcon icon="plus" /> Nuevo
                    </Button>
                </Col>
            </Row>
        </Card.Header>

        <Card.Body className="bg-light">
            <Tab.Content>
                <EstamperiaTable estampas={estampas} />
            </Tab.Content>
        </Card.Body>
    </Card>
  )
}

export default EstamperiaList