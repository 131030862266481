/** @format */

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import SearchAutocompleteCodes from "components/searchs/SearchAutocompleteCodes";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useAuthContext } from "hooks/useAuthContext";
import { useProductContext } from "hooks/useProductContext";

const FormularioCode = ({ setDisabledProduct, setKey, setVisibleSemi }) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	//Navigate para navegar a otra pantalla
	const navigate = useNavigate();

	//Producto genérico prefix
	const prefixCode = "G";

	//Disabled enabled inputs
	const [disabled, setDisabled] = useState(false);

	//Estado para habilitar deshabilitar boton guardar
	const [disabledStore, setDisabledStore] = useState(false);

	//Tipo de producto a dar de alta
	const [selectProductType, setSelectProductType] = useState(1);

	//Objeto código de producto desde el buscador
	const [codeinsert, setCodeinsert] = useState("");

	//Códigos
	const [code, setCode] = useState("");
	const [productCode, setProductCode] = useState({});
	const [modelCode, setModelCode] = useState({});
	const [fabricCode, setFabricCode] = useState({});
	const [colorCode, setColorCode] = useState({});
	const [sizeCode, setSizeCode] = useState({});

	const toastId = useRef(null);

	const {
		codeContext,
		handleCodeByProductId,
		product,
		handleProductById,
		handleProductByCode,
	} = useProductContext();

	useEffect(() => {
		if (codeContext && Object.entries(codeContext).length > 0) {
			const {
				code,
				productcode,
				modelcode,
				fabriccode,
				productcolorcode,
				sizecode,
			} = codeContext;

			const situationActions = {
				1: () => {
					//Cargamos los datos del código
					setCode(code);
					setProductCode(productcode);
					setModelCode(modelcode);
					setFabricCode(fabriccode);
					setColorCode(productcolorcode);
					setSizeCode(sizecode);
					setVisibleSemi(true);
				},
				2: () => {
					//Cargamos los datos del código
					setCode(code);
					setProductCode(productcode);
					setModelCode(modelcode);
					setFabricCode(fabriccode);
					setColorCode(productcolorcode);
					setSizeCode(sizecode);
				},
				4: () => {
					//Cargamos los datos del código
					setCode(code);
					setProductCode(productcode);
				},
			};

			const situationAction = situationActions[product.situation];
			if (situationAction) {
				situationAction();
			}

			//Seteamos el tipo de producto
			setSelectProductType(product.situation);
			//Habilitamos la pestaña de alta de producto
			setDisabledProduct(false);
			//Deshabilitamos el botón guardar
			setDisabledStore(true);
			//Disabled enabled inputs
			setDisabled(true);
		} else {
			if (product && Object.entries(product).length > 0) {
				//Seteamos el tipo de producto
				setSelectProductType(product.situation);
				//Habilitamos la pestaña de alta de producto
				setDisabledProduct(false);
				//Deshabilitamos el botón guardar
				setDisabledStore(true);
				//Disabled enabled inputs
				setDisabled(true);
			} else {
				//Limpiamos los estados
				setCode("");
				setProductCode({});
				setModelCode({});
				setFabricCode({});
				setColorCode({});
				setSizeCode({});
				setVisibleSemi(false);
				setDisabled(false);
				setDisabledStore(false);
				setSelectProductType(1);
				setCodeinsert("");
			}
		}
	}, [codeContext, product]);

	useEffect(() => {
		//Este useEffect tiene que estar porque viene de otro componente y tiene el objeto código de producto
		if (codeinsert != "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(
					"¡Buscando datos relacionados al código!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}

			setCode(codeinsert.code);
			setProductCode(codeinsert.productcode);
			setModelCode(codeinsert.modelcode);
			setFabricCode(codeinsert.fabriccode);
			setColorCode(codeinsert.productcolorcode);
			setSizeCode(codeinsert.sizecode);
			setDisabled(true);

			if (selectProductType == 1) {
				setVisibleSemi(true);
			}

			//Guardarmos el producto por el código en el context
			handleProductByCode(codeinsert.code);

			setTimeout(() => {
				setDisabledStore(true);
				setDisabledProduct(false);
				setKey("product");
			}, 1000);
		}
	}, [codeinsert]);

	const handleCancel = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/products/list`);
	};

	const buildPostData = () => {
		const postData = {
			selectProductType,
			usability: 1,
			productcode_id: productCode.id,
			code: "",
			description: "",
		};

		if (selectProductType == "1" || selectProductType == "2") {
			postData.model_id = modelCode.id;
			postData.fabric_id = fabricCode.id;
			postData.size_id = sizeCode.id;
			postData.color_id = colorCode.id;
			postData.code = `${productCode.code}${modelCode.code}${fabricCode.code}${sizeCode.code}${colorCode.code}`;
			postData.description = `${productCode.description} ${modelCode.description} ${fabricCode.description} ${sizeCode.description} ${colorCode.description}`;
		} else if (selectProductType == "4") {
			postData.code = `${productCode.code}${prefixCode}`;
			postData.description = `${productCode.description} generico`;
		}

		return postData;
	};

	const storeUpdate = async () => {
		//Validación si el tipo de producto es final o básico

		if (selectProductType == 1 || selectProductType == 2) {
			if (Object.entries(productCode).length == 0) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El código de producto es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}

			if (Object.entries(modelCode).length == 0) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El código de modelo es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}

				//Error
				return;
			}

			if (Object.entries(fabricCode).length == 0) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El código de tela es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}

				//Error
				return;
			}

			if (Object.entries(colorCode).length == 0) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El código de color es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}

				//Error
				return;
			}

			if (Object.entries(sizeCode).length == 0) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(`El código de talle es obligatorio.`, {
						role: "alert",
						theme: "dark",
					});
				}

				//Error
				return;
			}
		}

		if (selectProductType == 4) {
			if (Object.entries(productCode).length == 0) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						`El código de producto es obligatorio.`,
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Error
				return;
			}
		}

		try {
			const postData = buildPostData();

			const { data } = await axiosWithToken.post(
				"/code/storeSimpleCode",
				postData
			);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			//Seteamos el código
			if (data.data.situation !== 3 && data.data.code != null) {
				setCode(data.data.code);
			}

			//Guardamos el código si tiene por product id en el context
			handleCodeByProductId(data.data.id);

			//Guardamos el producto por su id en el context
			handleProductById(data.data.id);

			//Deshabilitamos los inputs
			setDisabled(true);

			setTimeout(() => {
				setDisabledStore(true);
				setDisabledProduct(false);
				setKey("product");
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			// error
			return;
		}
	};

	return (
		<>
			<Row>
				<Col xs={12} sm={12} lg={12} md={12}>
					<Card className='mb-2 p-4 h-md-100'>
						<h6 className='mb-4 text-center fs--2'>
							¡Agregar código de producto!
						</h6>

						<Form>
							<Row className='mb-3 g-3'>
								<Col xs={12} sm={12} lg={12} md={12}>
									<Row className='mb-3'>
										<Form.Group
											as={Col}
											xs={12}
											sm={12}
											md={6}
											lg={6}
											controlId='formGridProduct'
										>
											<Form.Label className='fs--2'>
												Seleccionar producto a dar de alta
												<span className='text-danger'>*</span>
											</Form.Label>
											<Form.Select
												size='sm'
												aria-label='select-product'
												value={selectProductType}
												onChange={(e) => setSelectProductType(e.target.value)}
												disabled={disabled}
											>
												<option value={0} disabled>
													Seleccionar...
												</option>
												<option value={1}>Producto Final</option>
												<option value={2}>Producto Básico</option>
												<option value={3}>Semielaborado</option>
												<option value={4}>Genérico</option>
											</Form.Select>
										</Form.Group>
									</Row>

									{selectProductType != "" &&
										(selectProductType == 1 || selectProductType == 2) && (
											<>
												<Row className='mb-3'>
													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={8}
														lg={8}
														controlId='formGridUnitsMp'
													>
														<Form.Label className='fs--2'>
															Buscar un código de producto
														</Form.Label>
														<SearchAutocomplete
															setSelect={setCodeinsert}
															modelSearch={"productsCodes"}
															disabled={disabled}
														/>
													</Form.Group>

													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={4}
														lg={4}
														controlId='formGridCod'
													>
														<Form.Label className='fs--2'>
															Código
															<span className='text-danger'>*</span>
														</Form.Label>
														<Form.Control
															size='sm'
															value={code}
															type='text'
															placeholder=''
															disabled
															readOnly
														/>
													</Form.Group>
												</Row>

												<Row className='mb-2 g-3'>
													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={3}
														lg={3}
														controlId='formGridProduct'
													>
														<Form.Label>
															Producto
															<span className='text-danger'>*</span>
														</Form.Label>
														<SearchAutocompleteCodes
															setSelect={setProductCode}
															modelSearch={"productsCodes"}
															disabled={disabled}
														/>
														<span style={{ fontSize: "12px" }}>
															<strong>{productCode.code}</strong>
														</span>

														{Object.entries(productCode).length > 0 &&
														disabled == false ? (
															<FontAwesomeIcon
																icon='times'
																style={{ fontSize: "10px" }}
																className='cursor-pointer text-danger ms-1'
																onClick={() => setProductCode({})}
															/>
														) : (
															""
														)}
													</Form.Group>

													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={3}
														lg={3}
														controlId='formGridModel'
													>
														<Form.Label>
															Modelo
															<span className='text-danger'>*</span>
														</Form.Label>
														<SearchAutocompleteCodes
															setSelect={setModelCode}
															modelSearch={"modelsCodes"}
															disabled={disabled}
														/>
														<span style={{ fontSize: "12px" }}>
															<strong>{modelCode.code}</strong>
														</span>

														{Object.entries(modelCode).length > 0 &&
														disabled == false ? (
															<FontAwesomeIcon
																icon='times'
																style={{ fontSize: "10px" }}
																className='cursor-pointer text-danger ms-1'
																onClick={() => setModelCode({})}
															/>
														) : (
															""
														)}
													</Form.Group>

													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={2}
														lg={2}
														controlId='formGridFabric'
													>
														<Form.Label>
															Tela<span className='text-danger'>*</span>
														</Form.Label>

														<SearchAutocompleteCodes
															setSelect={setFabricCode}
															modelSearch={"uniqueFabricsCodes"}
															disabled={disabled}
														/>
														<span style={{ fontSize: "12px" }}>
															<strong>{fabricCode.code}</strong>
														</span>

														{Object.entries(fabricCode).length > 0 &&
														disabled == false ? (
															<FontAwesomeIcon
																icon='times'
																style={{ fontSize: "10px" }}
																className='cursor-pointer text-danger ms-1'
																onClick={() => setFabricCode({})}
															/>
														) : (
															""
														)}
													</Form.Group>

													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={2}
														lg={2}
														controlId='formGridPSize'
													>
														<Form.Label>
															Talle
															<span className='text-danger'>*</span>
														</Form.Label>
														<SearchAutocompleteCodes
															setSelect={setSizeCode}
															modelSearch={"sizesCodes"}
															disabled={disabled}
														/>
														<span style={{ fontSize: "12px" }}>
															<strong>{sizeCode.code}</strong>
														</span>

														{Object.entries(sizeCode).length > 0 &&
														disabled == false ? (
															<FontAwesomeIcon
																icon='times'
																style={{ fontSize: "10px" }}
																className='cursor-pointer text-danger ms-1'
																onClick={() => setSizeCode({})}
															/>
														) : (
															""
														)}
													</Form.Group>

													<Form.Group
														as={Col}
														xs={12}
														sm={12}
														md={2}
														lg={2}
														controlId='formGridPColor'
													>
														<Form.Label>
															Color
															<span className='text-danger'>*</span>
														</Form.Label>
														<SearchAutocompleteCodes
															setSelect={setColorCode}
															modelSearch={"uniqueColorsCodes"}
															disabled={disabled}
														/>
														<span style={{ fontSize: "12px" }}>
															<strong>{colorCode.code}</strong>
														</span>

														{Object.entries(colorCode).length > 0 &&
														disabled == false ? (
															<FontAwesomeIcon
																icon='times'
																style={{ fontSize: "10px" }}
																className='cursor-pointer text-danger ms-1'
																onClick={() => setColorCode({})}
															/>
														) : (
															""
														)}
													</Form.Group>
												</Row>
											</>
										)}

									{selectProductType != "" && selectProductType == 4 && (
										<Row className='mb-2 g-3'>
											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={3}
												lg={3}
												controlId='formGridProduct'
											>
												<Form.Label>
													Producto
													<span className='text-danger'>*</span>
												</Form.Label>
												<SearchAutocompleteCodes
													setSelect={setProductCode}
													modelSearch={"productsCodes"}
													disabled={disabled}
												/>
												<span style={{ fontSize: "12px" }}>
													<strong>{productCode.code}</strong>
												</span>

												{Object.entries(productCode).length > 0 &&
												disabled == false ? (
													<FontAwesomeIcon
														icon='times'
														style={{ fontSize: "10px" }}
														className='cursor-pointer text-danger ms-1'
														onClick={() => setProductCode({})}
													/>
												) : (
													""
												)}
											</Form.Group>

											<Form.Group
												as={Col}
												xs={12}
												sm={12}
												md={3}
												lg={3}
												controlId='formGridModel'
											>
												<Form.Label>
													Prefigo producto genérico
													<span className='text-danger'>*</span>
												</Form.Label>

												<Form.Control
													type={"text"}
													size='sm'
													value={prefixCode}
													disabled
												/>
											</Form.Group>
										</Row>
									)}
								</Col>
							</Row>

							<Row>
								<Col className='d-flex justify-content-end'>
									<Button
										size='sm'
										variant='danger'
										onClick={() => handleCancel()}
									>
										Cancelar
									</Button>

									<Button
										size='sm'
										variant='primary'
										className='ms-1'
										disabled={disabledStore}
										onClick={() => storeUpdate()}
									>
										Guardar
									</Button>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		</>
	);
};

FormularioCode.propTypes = {
	setDisabledProduct: PropTypes.func,
	setKey: PropTypes.func,
	setVisibleSemi: PropTypes.func,
};

export default FormularioCode;
