/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

//Styles
import {
	Button,
	Card,
	Col,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "components/common/IconButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "css/customCss.css";
//Calendar's component
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import { updateEventsList } from "data/calendar/eventos";
import AddScheduleModal from "./AddScheduleModal";
import CalendarEventModal from "./CalendarEventModal";
import DropdownFilter from "components/common/DropdownFilter";
import { useAuthContext } from "hooks/useAuthContext";

//Config context
import AppContext from "context/Context";

const Calendar = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const {
		config: { isRTL },
	} = useContext(AppContext);

	const [title, setTitle] = useState("");
	const [calendarApi, setCalendarApi] = useState({});
	const [currentFilter, setCurrentFilter] = useState("Vista Mes");
	const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
	const [isOpenEventModal, setIsOpenEventModal] = useState(false);
	const [modalEventContent, setModalEventContent] = useState({});
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [eventos, setEventos] = useState([]); //eventos que traigo del back y les agrego el atributo allDay = true

	const calendarRef = useRef();
	const toastId = React.useRef(null);

	const handleEventClick = (info) => {
		if (info.event.url) {
			window.open(info.event.url);
			info.jsEvent.preventDefault();
		} else {
			setModalEventContent(info);
			setIsOpenEventModal(true);
		}
	};

	const [initialEvents, setInitialEvents] = useState([]);

	useEffect(async () => {
		setCalendarApi(calendarRef.current.getApi());

		//Traemos todos los eventos
		handleEventEdited();
	}, []);

	useEffect(() => {
		//agrego el atributo "allDay" a la variable eventos para despues pasarlo a initial events
		eventos.map((event) => (event.allDay = true));
		setInitialEvents(eventos);
	}, [eventos]);

	const handleEventEdited = async () => {
		const eventsBackground = await updateEventsList();
		setEventos(eventsBackground);
	};

	const viewName = [
		"Vista Mes",
		"Vista Semana",
		"Vista Dia",
		"Vista Lista",
		"Vista Año",
	];

	const handleFilter = (filter) => {
		setCurrentFilter(filter);
		switch (filter) {
			case "Vista Mes":
				calendarApi.changeView("dayGridMonth");
				setTitle(calendarApi.getCurrentData().viewTitle);
				break;
			case "Vista Semana":
				calendarApi.changeView("timeGridWeek");
				setTitle(calendarApi.getCurrentData().viewTitle);
				break;
			case "Vista Dia":
				calendarApi.changeView("timeGridDay");
				setTitle(calendarApi.getCurrentData().viewTitle);
				break;
			case "Vista Lista":
				calendarApi.changeView("listWeek");
				setTitle(calendarApi.getCurrentData().viewTitle);
				break;
			default:
				calendarApi.changeView("listYear");
				setTitle(calendarApi.getCurrentData().viewTitle);
		}
	};

	const handleOverlap = (event) => {
		let flag = true;
		initialEvents.forEach((existingEvent) => {
			let existingStartDateString = existingEvent.start;
			let existingEndDateString = existingEvent.end;
			let existingStartDate = new Date(existingStartDateString);
			let existingEndDate = new Date(existingEndDateString);

			if (
				(event.start >= existingStartDate && event.start <= existingEndDate) ||
				(event.end >= existingStartDate && event.end <= existingEndDate) ||
				(event.start <= existingStartDate &&
					event.end >= existingEvent.start) ||
				(event.start <= existingStartDate && event.end >= existingEndDate)
			) {
				return (flag = false);
			}
		});

		return !flag;
	};

	//Función que se dispara para guardar los datos del calendario en la db
	const handleSaveCalendar = async (newEvent) => {
		//Creamos la funcion para guardar los datos del calendario
		const saveCalendar = async () => {
			try {
				await axiosWithToken.post("/calendario/save", {
					data: newEvent,
				});

				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success(
						"Calendario guardado correctamente!",
						{
							role: "alert",
							theme: "dark",
						}
					);
				}

				//Traemos todos los eventos
				handleEventEdited();
			} catch (error) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(error.response.data.message, {
						role: "alert",
						theme: "dark",
					});
				}
			}
		};

		//Llamamos a la funcion de arriba para guardar los datos del calendario
		saveCalendar();
		return;
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Card>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row>
						<Col>
							<h5 className='mb-0'>Calendario</h5>
						</Col>
					</Row>
				</Card.Header>
				<Card.Header>
					<Row className='align-items-center gx-0'>
						<Col xs='auto' className='d-flex justify-content-end order-md-1'>
							<OverlayTrigger
								placement='bottom'
								overlay={<Tooltip id='nextTooltip'>Anterior</Tooltip>}
							>
								<Button
									variant='link'
									className='icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2'
									onClick={() => {
										calendarApi.prev();
										setTitle(calendarApi.getCurrentData().viewTitle);
									}}
								>
									<FontAwesomeIcon icon='arrow-left' />
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								placement='bottom'
								overlay={<Tooltip id='previousTooltip'>Siguiente</Tooltip>}
							>
								<Button
									variant='link'
									className='icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2'
									onClick={() => {
										calendarApi.next();
										setTitle(calendarApi.getCurrentData().viewTitle);
									}}
								>
									<FontAwesomeIcon icon='arrow-right' />
								</Button>
							</OverlayTrigger>
						</Col>
						<Col xs='auto' className='d-flex justify-content-end order-md-2'>
							<h4 className='mb-0 fs-0 fs-sm-1 fs-lg-2'>
								{title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
							</h4>
						</Col>
						<Col xs md='auto' className='d-flex justify-content-end order-md-3'>
							<Button
								size='sm'
								variant='falcon-primary'
								onClick={() => {
									calendarApi.today();
									setTitle(calendarApi.getCurrentData().viewTitle);
								}}
							>
								Hoy
							</Button>
						</Col>
						<Col md='auto' className='d-md-none'>
							<hr />
						</Col>
						<Col xs='auto' className='d-flex order-md-0'>
							<IconButton
								variant='primary'
								iconClassName='me-2'
								icon='plus'
								// transform="shrink-3"
								size='sm'
								onClick={() => {
									setIsOpenScheduleModal(!isOpenScheduleModal);
								}}
							>
								Agregar Evento
							</IconButton>
						</Col>
						<Col className='d-flex justify-content-end order-md-2'>
							<DropdownFilter
								className='me-2'
								filters={viewName}
								currentFilter={currentFilter}
								handleFilter={handleFilter}
								icon='sort'
								right
							/>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className='p-0'>
					<FullCalendar
						ref={calendarRef}
						headerToolbar={false}
						plugins={[
							dayGridPlugin,
							// timeGridPlugin,
							interactionPlugin,
							listPlugin,
						]}
						initialView='dayGridMonth'
						locale={esLocale}
						themeSystem='bootstrap'
						dayMaxEvents={2}
						direction={isRTL ? "rtl" : "ltr"}
						height={550}
						stickyHeaderDates={false}
						editable={false}
						selectable
						selectMirror
						select={(info) => {
							setIsOpenScheduleModal(true);
							setStartDate(info.start);
							setEndDate(info.end);
						}}
						eventClick={handleEventClick}
						events={initialEvents}
						eventOverlap={false}
						selectOverlap={(event) => handleOverlap(event)}
					/>
				</Card.Body>
			</Card>

			<AddScheduleModal
				isOpenScheduleModal={isOpenScheduleModal}
				setIsOpenScheduleModal={setIsOpenScheduleModal}
				initialEvents={initialEvents}
				setInitialEvents={setInitialEvents}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				handleOverlap={handleOverlap}
				handleSaveCalendar={handleSaveCalendar}
			/>

			<CalendarEventModal
				isOpenEventModal={isOpenEventModal}
				setIsOpenEventModal={setIsOpenEventModal}
				modalEventContent={modalEventContent}
				eventos={eventos}
				handleOverlap={handleOverlap}
				setInitialEvents={setInitialEvents}
				handleEventEdited={handleEventEdited}
			/>
		</>
	);
};

export default Calendar;
