/** @format */

import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import is from "is_js";
import AuthSimpleLayout from "./AuthSimpleLayout";
import MainLayout from "./MainLayout";
import SettingsToggle from "components/settings-panel/SettingsToggle";
import SettingsPanel from "components/settings-panel/SettingsPanel";
import { useAuthContext } from "hooks/useAuthContext";

{
	/* NUEVAS RUTAS SONDER360 */
}

import SimpleLogin from "components/authentication/simple/Login";
import SimpleLogout from "components/authentication/simple/Logout";

{
	/* PRODUCTOS */
}
import ProductList from "components/products/products/Listado";
import ProductInsertUpdate from "components/products/products/FormInsertUpdate";
import ProductViewP from "components/products/products/ViewP";

{
	/* MATERIAS PRIMAS */
}

import RawmaterialList from "components/products/rawmaterials/Listado";
import RawmaterialInsertUpdate from "components/products/rawmaterials/FormInsertUpdate";
import RawmaterialView from "components/products/rawmaterials/ViewMp";

{
	/* PROVEEDOR */
}

import SupplierList from "components/suppliers/Listado";
import SupplierInsertUpdate from "components/suppliers/FormInsertUpdateProveedor";
import SupplierView from "components/suppliers/View";

{
	/* FAMILIAS DE PRODUCTOS */
}

import ProductFamiliesForm from "components/products/products/families/FamiliesForm";
import ProductFamiliesList from "components/products/products/families/FamiliesList";
import ProductFamiliesView from "components/products/products/families/View";

{
	/* FAMILIAS DE MATERIAS PRIMAS */
}

import RawmaterialFamiliesForm from "components/products/rawmaterials/families/FamiliesForm";
import RawmaterialFamiliesList from "components/products/rawmaterials/families/FamiliesList";
import RawmaterialFamiliesView from "components/products/rawmaterials/families/View";

{
	/* CODIGOS SIMPLES */
}

import SingleCodesForm from "components/codes/singleCodes/SingleCodesForm";
import SingleCodesList from "components/codes/singleCodes/SingleCodesList";

{
	/* CODIGOS COMPUESTOS */
}

import CompositeProductsCodesForm from "components/codes/compositeCodes/CompositeProductsCodesForm";
import CompositeRawmaterialsCodesForm from "components/codes/compositeCodes/CompositeRawmaterialsCodesForm";
import CompositeProductsCodesList from "components/codes/compositeCodes/CompositeProductsCodesList";
import CompositeRawmaterialsCodesList from "components/codes/compositeCodes/CompositeRawmaterialsCodesList";

{
	/* CONFIGURADOR - ATRIBUTOS */
}

import Atributo from "components/configurador/atributos/Listado";
import AtributoInsertUpdate from "components/configurador/atributos/FormInsertUpdateAtributo";
import AtributoView from "components/configurador/atributos/View";
import AtributoCampos from "components/configurador/atributos/FormInsertUpdateAtributoCampo";
import AtributoCamposValores from "components/configurador/atributos/FormInsertUpdateValuesAC";

{
	/* CENTROS DE TRABAJO -LISTADO */
}
import ListadoCentros from "components/centros-de-trabajo/ListadoCentros";
import CentroInsertUpdate from "components/centros-de-trabajo/FormCentro";
import CentroUpdate from "components/centros-de-trabajo/FormCentroEdit";
import CentrosView from "components/centros-de-trabajo/CenterView";

{
	/* CENTROS DE TRABAJO - LISTADO MÁQUINAS */
}

import MachineList from "components/centros-de-trabajo/machines/MachineList";
import MachineInsertUpdate from "components/centros-de-trabajo/machines/FormMachine";
import MachineView from "components/centros-de-trabajo/machines/MachineView";

{
	/* CENTROS DE TRABAJO - MANO DE OBRA */
}
import ManPowerList from "components/centros-de-trabajo/manpower/ManPowerList";
import ManPowerInsertUpdate from "components/centros-de-trabajo/manpower/FormManPower";
import ManPowerView from "components/centros-de-trabajo/manpower/ManPowerView";

{
	/* OPERACIONES */
}
import OperationsList from "components/operations/OperationsList";
import OperationForm from "components/operations/OperationForm";
import OperationView from "components/operations/OperationView";

{
	/* CONFIGURADOR - COMBINACIONES */
}

import Combinacion from "components/configurador/combinaciones/Listado";
import CombinationInsertUpdate from "components/configurador/combinaciones/FormInsertUpdateCombination";
import CombinationView from "components/configurador/combinaciones/ViewCombination";

{
	/* CONFIGURADOR - CONFIGURADOR DE PRODUCTO */
}

//import Configurador from 'components/configurador/configurador/FormInsertUpdateConfig';
//import CombinationInsertUpdate from 'components/configurador/combinaciones/FormInsertUpdateCombination';
//import CombinationView from 'components/configurador/combinaciones/ViewCombination';

{
	/* MOLDES */
}

import MoldsForm from "components/molds/MoldsForm";
import MoldsList from "components/molds/MoldsList";

{
	/* NUEVAS RUTAS SONDER360 */
}

{
	/* HOJA DE RUTA */
}
import HojaRutaList from "components/hojaRuta/HojaRutaList";
import HojaRutaForm from "components/hojaRuta/HojaRutaForm";
import HojaRutaView from "components/hojaRuta/HojaRutaView";

{
	/* ESTAMPERIA */
}
import EstamperiaList from "components/estamperia/EstamperiaList";
import EstamperiaView from "components/estamperia/EstamperiaView";
import EstamperiaForm from "components/estamperia/EstamperiaForm";
import EstamperiaProductForm from "components/estamperia/EstamperiaProductForm";
import EstamperiaProductList from "components/estamperia/EstamperiaProductList";
import EstamperiaProductView from "components/estamperia/EstamperiaProductView";

{
	/* CONFIGURACIONES */
}

import SettingForm from "components/compsettings/settingForm";

{
	/* CALENDARIO */
}
import Calendario from "components/calendario/Calendario";
import CalendarioList from "components/calendario/CalendarioList";
import CalendarioView from "components/calendario/CalendarioView";

{
	/* PLAN MAESTRO DE VENTAS */
}

import MasterPlanForm from "components/salesmasterplan/MasterplanForm";
import MasterplanListado from "components/salesmasterplan/MasterplanListado";
import MasterplanView from "components/salesmasterplan/View";

{
	/* dashboard default del software */
}
import Dashboard from "components/dashboards/default";
{
	/* dashboard default del software */
}

import AppContext from "context/Context";

const Layout = () => {
	const { token } = useAuthContext();

	const HTMLClassList = document.getElementsByTagName("html")[0].classList;
	useContext(AppContext);

	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add("windows");
		}
		if (is.chrome()) {
			HTMLClassList.add("chrome");
		}
		if (is.firefox()) {
			HTMLClassList.add("firefox");
		}
	}, [HTMLClassList]);

	return (
		<>
			<Routes>
				{token && token !== "" ? (
					<Route element={<MainLayout />}>
						{/* DASHBOARD */}
						<Route path='/' element={<Dashboard />} />
						{/* PRODUCTOS */}
						<Route path='products/products/list' element={<ProductList />} />
						<Route
							path='products/products/form'
							element={<ProductInsertUpdate />}
						/>
						<Route
							path='products/products/form/:id'
							element={<ProductInsertUpdate />}
						/>
						<Route
							path='products/products/view/:id'
							element={<ProductViewP />}
						/>
						{/* MATERIAS PRIMAS */}
						<Route
							path='products/rawmaterials/list'
							element={<RawmaterialList />}
						/>
						<Route
							path='products/rawmaterials/form'
							element={<RawmaterialInsertUpdate />}
						/>
						<Route
							path='products/rawmaterials/form/:id'
							element={<RawmaterialInsertUpdate />}
						/>
						<Route
							path='products/rawmaterials/view/:id'
							element={<RawmaterialView />}
						/>

						{/* PROVEEDORES */}
						<Route path='suppliers/listado' element={<SupplierList />} />
						<Route path='suppliers/form' element={<SupplierInsertUpdate />} />
						<Route
							path='suppliers/form/:id'
							element={<SupplierInsertUpdate />}
						/>
						<Route path='suppliers/view/:id' element={<SupplierView />} />
						{/* CODIGOS */}
						{/* codes INDIVIDUALES */}
						<Route path='codes/singlecodeslist' element={<SingleCodesList />} />
						<Route path='codes/singlecodesform' element={<SingleCodesForm />} />
						<Route
							path='codes/singlecodesform/:id'
							element={<SingleCodesForm />}
						/>
						{/* codes COMPUESTOS */}
						<Route
							path='codes/compositeProductscodesform'
							element={<CompositeProductsCodesForm />}
						/>
						<Route
							path='codes/compositeProductscodeslist'
							element={<CompositeProductsCodesList />}
						/>
						<Route
							path='codes/compositeRawmaterialscodeslist'
							element={<CompositeRawmaterialsCodesList />}
						/>
						<Route
							path='codes/compositeRawmaterialscodesform'
							element={<CompositeRawmaterialsCodesForm />}
						/>
						{/* CONFIGURADOR */}
						{/* ATRIBUTOS */}
						<Route
							path='configurador/atributos/listado'
							element={<Atributo />}
						/>
						<Route
							path='configurador/atributos/formInsertUpdateAtributo'
							element={<AtributoInsertUpdate />}
						/>
						<Route
							path='configurador/atributos/formInsertUpdateAtributo/:id'
							element={<AtributoInsertUpdate />}
						/>
						<Route
							path='configurador/atributos/view/:id'
							element={<AtributoView />}
						/>
						<Route
							path='configurador/atributos/FormInsertUpdateAtributoCampo/:id'
							element={<AtributoCampos />}
						/>
						<Route
							path='configurador/atributos/AtributoCamposValores'
							element={<AtributoCamposValores />}
						/>
						{/* COMBINACIONES */}
						<Route
							path='configurador/combinaciones/listado'
							element={<Combinacion />}
						/>
						<Route
							path='configurador/combinaciones/formInsertUpdateCombination'
							element={<CombinationInsertUpdate />}
						/>
						<Route
							path='configurador/combinaciones/formInsertUpdateCombination/:id'
							element={<CombinationInsertUpdate />}
						/>
						<Route
							path='configurador/combinaciones/viewCombination/:id'
							element={<CombinationView />}
						/>
						{/* CONFIGURADOR DE PRODUCTO */}
						{/*<Route path="configurador/configurador/form" element={<Configurador />} />
					<Route path="configurador/combinaciones/formInsertUpdateCombination" element={<CombinationInsertUpdate />} />
					<Route path="configurador/combinaciones/formInsertUpdateCombination/:id" element={<CombinationInsertUpdate />} />
					<Route path="configurador/combinaciones/viewCombination/:id" element={<CombinationView />} /> */}
						{/* CENTROS DE TRABAJO */}
						<Route
							path='centros-de-trabajo/listado-centros'
							element={<ListadoCentros />}
						/>
						<Route
							path='centros-de-trabajo/listado-centros/formCentro'
							element={<CentroInsertUpdate />}
						/>
						<Route
							path='centros-de-trabajo/listado-centros/formCentroEdit/:id'
							element={<CentroUpdate />}
						/>
						<Route
							path='centros-de-trabajo/listado-centros/view/:id'
							element={<CentrosView />}
						/>
						{/* MAQUINAS */}
						<Route path='work-center/machine-list' element={<MachineList />} />
						<Route
							path='work-center/machine-list/add-new-machine'
							element={<MachineInsertUpdate />}
						/>
						<Route
							path='work-center/machine-list/edit-machine/:cod_maq_id'
							element={<MachineInsertUpdate />}
						/>
						<Route
							path='work-center/machine-list/view/:cod_maq'
							element={<MachineView />}
						/>
						{/* MANO DE OBRA */}
						<Route
							path='work-center/manpower-list'
							element={<ManPowerList />}
						/>
						<Route
							path='work-center/manpower-list/add-new-manpower'
							element={<ManPowerInsertUpdate />}
						/>
						<Route
							path='work-center/manpower-list/edit-manpower/:id_mo'
							element={<ManPowerInsertUpdate />}
						/>
						{/* <Route path="work-center/manpower-list/view/:id" element={<ManPowerView />} /> */}
						<Route
							path='work-center/manpower-list/view/:id_mo'
							element={<ManPowerView />}
						/>
						{/* OPERACIONES */}
						<Route path='operations' element={<OperationsList />} />
						<Route path='operations/opForm' element={<OperationForm />} />
						<Route path='operations/:id' element={<OperationView />} />
						{/* MOLDES */}
						<Route path='molds/moldslist' element={<MoldsList />} />
						<Route path='molds/moldsform' element={<MoldsForm />} />
						<Route path='molds/moldsform/:id' element={<MoldsForm />} />
						{/* HOJA DE RUTA */}
						<Route path='hoja-ruta/list' element={<HojaRutaList />} />
						<Route path='hoja-ruta/form' element={<HojaRutaForm />} />
						<Route path='hoja-ruta/:id' element={<HojaRutaView />} />
						{/* ESTAMPERIA */}
						<Route path='estamperia/list' element={<EstamperiaList />} />
						<Route path='estamperia/:id' element={<EstamperiaView />} />
						<Route
							path='estamperia/new-estamperia'
							element={<EstamperiaForm />}
						/>
						<Route
							path='estamperia/relacion-producto-estampa'
							element={<EstamperiaProductForm />}
						/>
						<Route
							path='estamperia/list/relacion-producto-estampa'
							element={<EstamperiaProductList />}
						/>
						<Route
							path='estamperia/relacion-producto-estampa/:id'
							element={<EstamperiaProductView />}
						/>
						{/* CONFIGURACIONES */}
						<Route path='settings/settingform' element={<SettingForm />} />
						{/* CALENDARIO */}
						<Route path='calendario/' element={<Calendario />} />
						<Route path='calendario/listado' element={<CalendarioList />} />
						<Route path='calendario/listado/:id' element={<CalendarioView />} />
						{/* FAMILIAS DE PRODUCTOS */}
						<Route
							path='products/products/families/familieslist'
							element={<ProductFamiliesList />}
						/>
						<Route
							path='products/products/families/familiesform'
							element={<ProductFamiliesForm />}
						/>
						<Route
							path='products/products/families/familiesform/:id'
							element={<ProductFamiliesForm />}
						/>

						<Route
							path='products/products/families/view/:id'
							element={<ProductFamiliesView />}
						/>

						{/* FAMILIAS DE MATERIAS PRIMAS */}
						<Route
							path='products/rawmaterials/families/familieslist'
							element={<RawmaterialFamiliesList />}
						/>
						<Route
							path='products/rawmaterials/families/familiesform'
							element={<RawmaterialFamiliesForm />}
						/>
						<Route
							path='products/rawmaterials/families/familiesform/:id'
							element={<RawmaterialFamiliesForm />}
						/>
						<Route
							path='products/rawmaterials/families/view/:id'
							element={<RawmaterialFamiliesView />}
						/>

						{/* PLAN MAESTRO */}
						<Route
							path='salesmasterplan/masterplanlist'
							element={<MasterplanListado />}
						/>
						<Route
							path='salesmasterplan/masterplanform'
							element={<MasterPlanForm />}
						/>
						<Route
							path='salesmasterplan/masterplanform/:id'
							element={<MasterPlanForm />}
						/>
						<Route
							path='salesmasterplan/view/:id'
							element={<MasterplanView />}
						/>
					</Route>
				) : (
					<>
						<Route element={<AuthSimpleLayout />}>
							<Route path='/' element={<SimpleLogin />} />
							<Route
								path='authentication/simple/logout'
								element={<SimpleLogout />}
							/>
						</Route>
					</>
				)}
			</Routes>
			{token && token !== "" && (
				<>
					{/* Botón configuración flotante */}
					<SettingsToggle />

					{/* Panel de configuración flotante */}
					<SettingsPanel />
				</>
			)}
			;
		</>
	);
};

export default Layout;
