/** @format */

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useEstamperia } from "hooks/useEstamperia";
import Form from "react-bootstrap/Form";
import { Col, Stack, Button } from "react-bootstrap";
import DragAndDrop from "./DragAndDrop";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import SoftBadge from "components/common/SoftBadge";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";
function MyForm({ estampa, handleClose }) {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [nombre, setNombre] = useState("");
	const [carpeta, setCarpeta] = useState("");
	const [folio, setFolio] = useState("");
	const [pelicula, setPelicula] = useState("");
	const [imagen, setImagen] = useState(null);
	const [color, setCantColor] = useState(0);
	const [shablon, setCantShablon] = useState(0);
	const [obs, setObs] = useState("");
	const [materiaPrima, setMateriaPrima] = useState([]);
	const [mpSeleccionada, setMpSeleccionada] = useState([]);

	const { getEstampa } = useEstamperia();

	const toastId = React.useRef(null);

	useCallback(() => {
		//TODO ver que hacer con esta variable porque no se usa. hotFix para solucionar error en esLint
		console.log({ materiaPrima });
	}, []);

	useEffect(() => {
		setNombre(estampa.nombre);
		setCarpeta(estampa.carpeta);
		setFolio(estampa.folio);
		setPelicula(estampa.pelicula);
		setCantColor(estampa.cant_color);
		setCantShablon(estampa.cant_shablon);
		setObs(estampa.obs);
		setMpSeleccionada(estampa.products_pivot);
	}, [estampa]);

	const editEstamperia = async (e) => {
		e.preventDefault();

		const datosCant = [
			{
				nombre: "colores",
				value: color,
			},
			{
				nombre: "shablones",
				value: shablon,
			},
		];

		checkCantidad(datosCant);

		//creamos un formData para poder enviar la imagen
		const formData = new FormData();
		formData.append("id", estampa.id);
		formData.append("nombre", nombre);
		formData.append("image", imagen);
		formData.append("carpeta", carpeta);
		formData.append("folio", folio);
		formData.append("pelicula", pelicula);
		formData.append("color", color);
		formData.append("shablon", shablon);
		formData.append("obs", obs);
		formData.append(
			"products",
			mpSeleccionada.length > 0 ? JSON.stringify(mpSeleccionada) : ""
		);

		try {
			const respuesta = await axiosWithToken.post(
				`/estamperia/edit-estamperia/${estampa.id}`,
				formData
			);

			if (respuesta.status == 200) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.success("Editado correctamente!", {
						role: "alert",
						theme: "dark",
					});
				}

				setTimeout(() => {
					handleClose();
					getEstampa(estampa.id);
					// navigate(`/estamperia/${respuesta.data.id}`)
				}, 2000);
			}
		} catch (error) {
			console.log(error);
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`${error.response.data.message}`, {
					role: "alert",
					theme: "dark",
					autoClose: 4000,
				});
			}
		}
	};

	//Chequeamos si las cantidades en color y shablon estàn seteadas
	const checkCantidad = (datos) => {
		datos.map((dato) => {
			if (dato.value < 0) {
				if (!toast.isActive(toastId.current)) {
					toastId.current = toast.error(
						"¡Por favor ingrese cantidad de " + dato.nombre + "!",
						{
							theme: "colored",
							autoClose: 3000,
						}
					);
				}
				return;
			}
		});
	};

	const handleImage = (img) => {
		setImagen(img);
	};

	//Funcion para eliminar mp agregados
	const handleMP = (mp_id) => {
		const newMP = mpSeleccionada.filter((mp) => mp.id != mp_id);
		setMpSeleccionada(newMP);
	};

	//Función para guardar las MP seleccionadas
	const handleSearchMP = (key, searchObject) => {
		mpSeleccionada.push(searchObject);
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Form onSubmit={editEstamperia}>
				<Form.Group controlId='formNombre'>
					<Form.Label>Nombre</Form.Label>
					<Form.Control
						value={nombre}
						onChange={(e) => setNombre(e.target.value)}
						type='text'
						placeholder='Ingrese Nombre'
						size='sm'
					/>
				</Form.Group>
				<Form.Group controlId='formCarpeta'>
					<Form.Label>Carpeta</Form.Label>
					<Form.Control
						value={carpeta}
						onChange={(e) => setCarpeta(e.target.value)}
						type='text'
						placeholder='Ingrese Carpeta'
						size='sm'
					/>
				</Form.Group>
				<Form.Group controlId='formFolio'>
					<Form.Label>Folio</Form.Label>
					<Form.Control
						value={folio}
						onChange={(e) => setFolio(e.target.value)}
						type='text'
						placeholder='Ingrese Folio'
						size='sm'
					/>
				</Form.Group>
				<Form.Group controlId='formPelicula'>
					<Form.Label>Película</Form.Label>
					<Form.Control
						value={pelicula}
						onChange={(e) => setPelicula(e.target.value)}
						type='text'
						placeholder='Ingrese Pelicula'
						size='sm'
					/>
				</Form.Group>
				<Form.Group controlId='formImagen'>
					<Form.Label>Imagen</Form.Label>
					<DragAndDrop imagen={estampa.imagen} handleImage={handleImage} />
				</Form.Group>
				<Form.Group controlId='formCantColor'>
					<Form.Label>Cantidad de colores</Form.Label>
					<Form.Control
						value={color}
						onChange={(e) => setCantColor(e.target.value)}
						type='number'
						placeholder='Ingrese cantidad de colores'
						size='sm'
						min={0}
					/>
				</Form.Group>
				<Form.Group controlId='formCantShablon'>
					<Form.Label>Cantidad de shablones</Form.Label>
					<Form.Control
						value={shablon}
						onChange={(e) => setCantShablon(e.target.value)}
						type='number'
						placeholder='Ingrese cantidad de shablones'
						size='sm'
						min={0}
					/>
				</Form.Group>
				<Form.Group
					as={Col}
					xs={12}
					sm={12}
					md={12}
					lg={12}
					controlId='formGridCode'
				>
					<Form.Label className='fs--2'>Buscar un código</Form.Label>
					<SearchAutocomplete
						setSelect={setMateriaPrima}
						handleSearchMP={handleSearchMP}
						modelSearch={"rawmaterial"}
						mpSeleccionada={mpSeleccionada}
					/>
				</Form.Group>
				<Stack
					direction='horizontal'
					gap={3}
					className='d-flex'
					style={{ flexWrap: "wrap" }}
				>
					{mpSeleccionada?.length > 0
						? mpSeleccionada.map((mp) => {
								return (
									<div key={mp.id}>
										<SoftBadge variant='secondary'>{mp.name}</SoftBadge>{" "}
										<span
											style={{ cursor: "pointer" }}
											onClick={() => handleMP(mp.id)}
										>
											x
										</span>
									</div>
								);
						  })
						: ""}
				</Stack>
				<Form.Group controlId='formObs'>
					<Form.Label>Observaciones</Form.Label>
					<Form.Control
						as='textarea'
						rows={3}
						value={obs}
						onChange={(e) => setObs(e.target.value)}
						placeholder='Ingrese una breve descripción'
					/>
				</Form.Group>
				<Button variant='primary' className='mt-3 float-end' type='submit'>
					Editar
				</Button>
			</Form>
		</>
	);
}

MyForm.propTypes = {
	estampa: PropTypes.object,
	handleClose: PropTypes.func,
};

export default MyForm;
