/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
	Row,
	Col,
	Card,
	Tab,
	Tabs,
	Badge,
	Table,
	Image,
	Button,
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import Divider from "components/common/Divider";
import { generarId } from "../../../helpers/utils";
import imgdefault from "assets/img/img-dashboard/images/product.jpg";
const img = `${process.env.REACT_APP_ENPOINT_BASE}/img-rawmaterials`;
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const { id } = useParams();
	const perPage = 4;
	const [description, setDescription] = useState("");
	const [name, setName] = useState("");
	const [image, setImage] = useState("");
	const [anchoreal, setAnchoreal] = useState("");
	const [rindereal, setRindereal] = useState("");
	const [supplier, setSupplier] = useState("");
	const [anchosupplier, setAnchosupplier] = useState("");
	const [rindesupplier, setRindesupplier] = useState("");
	const [fechaalta, setFechaalta] = useState("");
	const [quantity, setQuantity] = useState("");
	const [tubular, setTubular] = useState("");
	const [termoestampado, setTermoestampado] = useState("");
	const [suppliersrawmaterials, setSuppliersrawmaterials] = useState([]);
	//const [image, setImage] = useState('')
	const [units, setUnits] = useState([]);
	const [weightConv, setWeightConv] = useState("");
	const [quantityConv, setQuantityConv] = useState("");
	const [tubeweight, setTubeweight] = useState("");
	const [thread, setThread] = useState(null);

	//Último precio del producto en el proveedor
	const [lastPrice, setLastPrice] = useState(0);
	const [lastDatePrice, setLastDatePrice] = useState("");

	//código compuesto
	const [compoundcode, setCompoundcode] = useState({});

	//códigos individuales
	const [materialcode, setMaterialcode] = useState({});
	const [submaterialcode, setSubmaterialcode] = useState({});
	const [materialcolorcode, setMaterialcolorcode] = useState({});

	const [structure, setStructure] = useState({});
	const [hdr, setHdr] = useState({});
	const [family, setFamily] = useState("");
	const [fabricType, setFabricType] = useState("");
	const [stage, setStage] = useState("");
	const [comment, setComment] = useState("");
	const [bath, setBath] = useState("");
	const [composition, setComposition] = useState("");
	const [tolerance, setTolerance] = useState("");
	const [resettingtime, setResettingtime] = useState("");
	const [dollarcost, setDollarcost] = useState("");

	useEffect(() => {
		getRawmaterialById();
	}, []);

	const getRawmaterialById = async () => {
		const { data } = await axiosWithToken.get(`/rawmaterial/${id}`);

		setDescription(data.data.description);
		setName(data.data.name);
		setAnchoreal(data.data.width);
		setRindereal(data.data.rinde);
		setFechaalta(dayjs(data.data.created_at).format("DD/MM/YYYY HH:mm:ss"));
		setQuantity(data.data.quantity);
		setTubular(data.data.tubular);
		setTermoestampado(data.data.hot_stamping);
		if (data.data.image) {
			setImage(data.data.image);
		}
		setWeightConv(data.data.weight_conversion);
		setQuantityConv(data.data.quantity_conversion);
		setTubeweight(data.data.tube_weight);
		setComment(data.data.comment);
		if (data.data.thread !== null) setThread(data.data.thread);

		setUnits(
			data.data.units.map((unit) => {
				return {
					idkey: generarId(),
					id: unit.id,
					main: unit.pivot.main,
					description: unit.description,
				};
			})
		);

		data.data.suppliers_rawmaterials.forEach((supplier, indice) => {
			if (indice == 0) {
				setSupplier(supplier.description);
				setAnchosupplier(supplier.pivot.ancho_supplier);
				setRindesupplier(supplier.pivot.rinde_supplier);
				setLastPrice(parseFloat(supplier.pivot.price_supplier).toFixed(2));
				setLastDatePrice(
					dayjs(supplier.pivot.created_at).format("DD/MM/YYYY HH:mm:ss")
				);
			}
		});

		setSuppliersrawmaterials(
			data.data.suppliers_rawmaterials.map((rawmaterial) => {
				return {
					id: id,
					price: Number(rawmaterial.pivot.price_supplier),
					ancho: rawmaterial.pivot.ancho_supplier,
					rinde: rawmaterial.pivot.rinde_supplier,
					fecha: dayjs(rawmaterial.pivot.created_at).format(
						"DD/MM/YYYY HH:mm:ss"
					),
					proveedor: rawmaterial.description,
				};
			})
		);

		if (data.data.structures.length > 0) {
			data.data.structures.forEach((estructura) => {
				if (estructura.main == 1) {
					const newStructureMain = {
						name: estructura.name,
						description: estructura.description,
						structure_rawmaterials: estructura.structure_rawmaterials.map(
							(str) => {
								return {
									idkey: generarId(),
									machinetype: str.machinetype,
									rawmaterial: str.rawmaterial,
									price: str.rawmaterial.suppliers_rawmaterials.map(
										(supplier, indice) => {
											if (indice == 0) {
												return supplier.pivot.price_supplier;
											}
										}
									),
									quantity: str.quantity,
									technical_order: str.technical_order,
									created_at: str.created_at,
								};
							}
						),
					};

					setStructure(newStructureMain);
				}
			});
		}

		if (data.data.hdr.length > 0) {
			data.data.hdr.forEach((hdr, indice) => {
				if (indice == 0) {
					const hdrMain = {
						codigo: hdr.codigo,
						denominacion: hdr.denominacion,
						operations: hdr.operations.map((op) => {
							return {
								idkey: generarId(),
								denomination: op.denomination,
								description: op.description,
								time: op.time,
							};
						}),
					};
					setHdr(hdrMain);
				}
			});
		}

		//Código compuesto
		setCompoundcode(() => {
			return {
				code: data.data.codeobject.code,
				description: data.data.codeobject.description,
			};
		});

		//códigos individuales
		setMaterialcode(data.data.codeobject.materialcode);
		setSubmaterialcode(data.data.codeobject.submaterialcode);
		setMaterialcolorcode(data.data.codeobject.materialcolorcode);

		setFamily(data?.data?.family?.name ? data.data.family.name : "");
		setFabricType(
			data?.data?.fabrictype?.description
				? data.data.fabrictype.description
				: ""
		);

		setBath(data.data.bath);
		setComposition(data.data.composition);
		setTolerance(data.data.tolerance);
		setResettingtime(data.data.resetting_time);
		setDollarcost(data.data.dollar_cost);

		const rawmaterialsStageData = {
			0: "Código",
			1: "Código y datos",
			2: "Estructura generada",
			3: "Hoja de ruta asignada",
		};

		setStage(
			`Etapa ${data.data.stage}: ${rawmaterialsStageData[data.data.stage]}`
		);
	};

	const {
		paginationState: {
			data: paginatedDetails,
			canNextPage,
			canPreviousPage,
			paginationArray,
			currentPage,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(suppliersrawmaterials, perPage);

	return (
		<>
			<Card className='mb-3'>
				<Card.Header className='p-2'>
					<Row className='align-items-end g-2'>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0 hover-actions-trigger'>
								<Badge bg='secondary'># {id}</Badge>{" "}
								<Badge bg='primary'>Materia prima</Badge>{" "}
								<Badge bg='secondary'>{stage}</Badge>{" "}
							</h6>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body>
					<Tab.Content>
						<Row>
							<Col xs={12} sm={12} lg={12} md={12}>
								<Tabs defaultActiveKey='general' id='tabs-viewmp'>
									<Tab eventKey='general' title='Datos'>
										<Row>
											<Col className='mt-3 mb-2' xs={12} sm={12} lg={8} md={8}>
												<Card className='h-md-100 p-1'>
													<Card.Body className='position-relative'>
														<Row>
															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={8}
																md={8}
															>
																<h6 className='mb-3 fs-0'>
																	Información general
																</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Nombre:
																	</strong>{" "}
																	{name}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Descripción:
																	</strong>{" "}
																	{description ? description : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Cantidad:
																	</strong>{" "}
																	{quantity ? quantity : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Baño:
																	</strong>{" "}
																	{bath ? bath : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Composición:
																	</strong>{" "}
																	{composition ? composition : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Tolerancia:
																	</strong>{" "}
																	{tolerance ? tolerance : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Días anticipación:
																	</strong>{" "}
																	{resettingtime ? resettingtime : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Costo en dolares:
																	</strong>{" "}
																	{dollarcost ? dollarcost : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Observaciones:
																	</strong>{" "}
																	{comment ? comment : "--"}
																</p>

																<p className='mb-4 fs--1'>
																	<strong className='fs--0 text-900'>
																		Fecha de alta:
																	</strong>{" "}
																	{fechaalta}
																</p>
															</Col>

															{thread !== null &&
																(thread == 1 || thread == 0) && (
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={4}
																		md={4}
																	>
																		<h6 className='mb-3 fs-0'>Tipo de hilo</h6>

																		<p className='mb-2 fs--1'>
																			<strong className='fs--0 text-900'>
																				Hilo de{" "}
																				{thread == 1 ? "arriba" : "abajo"} :
																			</strong>{" "}
																			<Badge
																				className='rounded-pill'
																				bg='success'
																			>
																				<FontAwesomeIcon
																					icon='check'
																					className='fs--2'
																				/>
																			</Badge>
																		</p>
																	</Col>
																)}

															<Divider />

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<h6 className='mb-3 fs-0'>Clasificadores</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Familia:
																	</strong>{" "}
																	{family ? family : "--"}
																</p>

																{fabricType && (
																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Tipo de tela:
																		</strong>{" "}
																		{fabricType ? fabricType : "--"}
																	</p>
																)}
															</Col>

															<Divider />

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={4}
																md={4}
															>
																<h6 className='mb-3 fs-0'>Detalles</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Ancho:
																	</strong>{" "}
																	{anchoreal ? anchoreal : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Rinde:
																	</strong>{" "}
																	{rindereal ? rindereal : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Peso del tubo:
																	</strong>{" "}
																	{tubeweight ? tubeweight : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Tubular:
																	</strong>{" "}
																	{tubular ? (
																		<Badge
																			className='rounded-pill'
																			bg='success'
																		>
																			<FontAwesomeIcon
																				icon='check'
																				className='fs--2'
																			/>
																		</Badge>
																	) : (
																		<Badge className='rounded-pill' bg='danger'>
																			<FontAwesomeIcon
																				icon='ban'
																				className='fs--2'
																			/>
																		</Badge>
																	)}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Termoestampado:
																	</strong>{" "}
																	{termoestampado ? (
																		<Badge
																			className='rounded-pill'
																			bg='success'
																		>
																			<FontAwesomeIcon
																				icon='check'
																				className='fs--2'
																			/>
																		</Badge>
																	) : (
																		<Badge className='rounded-pill' bg='danger'>
																			<FontAwesomeIcon
																				icon='ban'
																				className='fs--2'
																			/>
																		</Badge>
																	)}
																</p>
															</Col>

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={4}
																md={4}
															>
																<h6 className='mb-3 fs-0'>
																	Unidades de medidas
																</h6>

																{units.map((unit) => (
																	<p key={unit.idkey} className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			{unit.main ? "Principal" : "Secundaria"}:
																		</strong>{" "}
																		{unit.description}
																	</p>
																))}
															</Col>

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={4}
																md={4}
															>
																<h6 className='mb-3 fs-0'>
																	Unidades de conversión
																</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Peso conversión:
																	</strong>{" "}
																	{weightConv ? weightConv : "--"}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Cant. de conversión:
																	</strong>{" "}
																	{quantityConv ? quantityConv : "--"}
																</p>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</Col>

											<Col className='mt-3 mb-2' xs={12} sm={12} lg={4} md={4}>
												<Card className='mb-4 h-md-100 p-1'>
													<Card.Body className='position-relative'>
														<Row>
															<Col
																className='d-flex justify-content-center'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																{image != "" ? (
																	<Image
																		width='270'
																		height='270'
																		src={`${img}/${image}`}
																		rounded
																	/>
																) : (
																	// Imagen por defecto si no existe 'image'
																	<Image
																		width='270'
																		height='270'
																		src={imgdefault}
																		rounded
																	/>
																)}
															</Col>

															<Col
																className='mt-4 d-flex justify-content-center'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<strong>
																	<span className='mb-3 fs--2 text-800'>
																		{name}
																	</span>
																</strong>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Tab>

									<Tab eventKey='history' title='Proveedor'>
										<Row>
											<Col className='mt-3 mb-1' xs={12} sm={12} lg={6} md={6}>
												<Card className='mb-3'>
													<Card.Body>
														<Row>
															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={6}
																md={6}
															>
																<h6 className='mb-3 fs-0'>
																	Datos del proveedor
																</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Razón social:
																	</strong>{" "}
																	{supplier}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Ancho proveedor:
																	</strong>{" "}
																	{anchosupplier}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Rinde proveedor:
																	</strong>{" "}
																	{rindesupplier}
																</p>
															</Col>

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={6}
																md={6}
															>
																<h6 className='mb-3 fs-0'>Precio actual</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Precio:
																	</strong>{" "}
																	${lastPrice}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Fecha:
																	</strong>{" "}
																	{lastDatePrice}
																</p>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</Col>
											<Col className='mt-3 mb-1' xs={12} sm={12} lg={6} md={6}>
												<Card className='mb-3'>
													<Card.Body>
														<h6 className='mb-3 fs-0'>Historial de precios</h6>

														<div className='table-responsive fs--1'>
															<Table
																size='sm'
																striped
																className='border-bottom'
															>
																<thead className='bg-200 text-900'>
																	<tr>
																		<th className='border-0 text-left'>
																			Precio
																		</th>
																		<th className='border-0 text-end'>
																			Fecha de alta
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{paginatedDetails.map((rawmaterial) => (
																		<tr
																			key={`tr-${rawmaterial.id}`}
																			className='border-200'
																		>
																			<td className='align-middle text-left'>
																				${rawmaterial.price.toFixed(2)}
																			</td>
																			<td className='text-end'>
																				{rawmaterial.fecha}
																			</td>
																		</tr>
																	))}
																</tbody>
															</Table>
														</div>

														<div className='d-flex justify-content-center'>
															<div>
																<Button
																	variant='falcon-default'
																	size='sm'
																	disabled={!canPreviousPage}
																	onClick={prevPage}
																	className='me-2'
																	trigger='focus'
																>
																	<FontAwesomeIcon icon='chevron-left' />
																</Button>
															</div>

															<ul className='pagination mb-0'>
																{paginationArray.map((page) => (
																	<li
																		key={`li-${page}`}
																		className={classNames({
																			active: currentPage === page,
																		})}
																	>
																		<Button
																			size='sm'
																			variant='falcon-default'
																			className='page me-2'
																			onClick={() => goToPage(page)}
																		>
																			{page}
																		</Button>
																	</li>
																))}
															</ul>
															<div>
																<Button
																	variant='falcon-default'
																	size='sm'
																	disabled={!canNextPage}
																	onClick={nextPage}
																	trigger='focus'
																>
																	<FontAwesomeIcon icon='chevron-right' />
																</Button>
															</div>
														</div>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Tab>

									{Object.entries(structure).length > 0 && (
										<Tab eventKey='structure' title='Estructura'>
											<Col
												className='mt-3 mb-2'
												xs={12}
												sm={12}
												lg={12}
												md={12}
											>
												<Card className='mb-3'>
													<Card.Body>
														<Row>
															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={12}
																md={12}
															>
																<div className='table-responsive fs--1'>
																	<h5 className='mb-3 fs-0'>
																		Detalle de la estructura
																	</h5>
																	<Table striped className='border-bottom'>
																		<thead className='bg-200 text-900'>
																			<tr>
																				<th className='border-0 text-start'>
																					Código
																				</th>
																				<th className='border-0 text-start'>
																					Materia Prima
																				</th>
																				<th className='border-0 text-start'>
																					Cantidad
																				</th>
																				<th className='border-0 text-start'>
																					Orden
																				</th>
																				<th className='border-0 text-start'>
																					Fecha de alta
																				</th>
																			</tr>
																		</thead>
																		<tbody>
																			{!!structure.structure_rawmaterials &&
																				structure.structure_rawmaterials.map(
																					(rawmaterial) => (
																						<tr
																							key={`trstr-${rawmaterial.idkey}`}
																							className='border-200'
																						>
																							<td className='align-middle text-start'>
																								<strong>
																									{rawmaterial.rawmaterial.code}
																								</strong>
																							</td>

																							<td className='align-middle text-start'>
																								{rawmaterial.rawmaterial.name}
																							</td>

																							<td className='align-middle text-start'>
																								{rawmaterial.quantity}
																							</td>

																							<td className='align-middle text-start'>
																								<Badge
																									className='rounded-pill'
																									bg='primary'
																								>
																									{rawmaterial.technical_order}
																								</Badge>
																							</td>

																							<td className='align-middle text-start'>
																								{dayjs(
																									rawmaterial.created_at
																								).format("DD/MM/YYYY HH:mm:ss")}
																							</td>
																						</tr>
																					)
																				)}
																		</tbody>
																	</Table>
																</div>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</Col>
										</Tab>
									)}

									<Tab eventKey='info' title='Info'>
										<Row>
											<Col className='mt-3' xs={12} sm={12} lg={12} md={12}>
												<Card className='mb-4 h-md-100 p-1'>
													<Card.Body className='position-relative'>
														<Row>
															<h6 className='mt-3 mb-3 fs-0'>
																Información del código
															</h6>

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={6}
																md={6}
															>
																<h6 className='mb-3 fs-0'>Código compuesto</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Código:
																	</strong>{" "}
																	#{compoundcode.code}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Descripción:
																	</strong>{" "}
																	{compoundcode.description}
																</p>
															</Col>

															<Col
																className='mt-3'
																xs={12}
																sm={12}
																lg={6}
																md={6}
															>
																<h6 className='mb-3 fs-0'>
																	Códigos individuales
																</h6>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Material:
																	</strong>{" "}
																	#{materialcode.code} {""}{" "}
																	{materialcode.description}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Submaterial:
																	</strong>{" "}
																	#{submaterialcode.code} {""}{" "}
																	{submaterialcode.description}
																</p>

																<p className='mb-2 fs--1'>
																	<strong className='fs--0 text-900'>
																		Color:
																	</strong>{" "}
																	#{materialcolorcode.code} {""}{" "}
																	{materialcolorcode.description}
																</p>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Tab>

									{Object.entries(hdr).length > 0 && (
										<Tab eventKey='hdr' title='Hoja de ruta'>
											<Row>
												<Col
													className='mt-3 mb-1'
													xs={12}
													sm={12}
													lg={12}
													md={12}
												>
													<Card className='mb-3'>
														<Card.Body>
															<Row>
																<Col
																	className='mt-3'
																	xs={12}
																	sm={12}
																	lg={12}
																	md={12}
																>
																	<h6 className='mb-3 fs-0'>Hoja de ruta</h6>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Código:
																		</strong>{" "}
																		{hdr.codigo}
																	</p>

																	<p className='mb-2 fs--1'>
																		<strong className='fs--0 text-900'>
																			Denominación:
																		</strong>{" "}
																		{hdr.denominacion}
																	</p>
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Col>
												<Col
													className='mt-1 mb-2'
													xs={12}
													sm={12}
													lg={12}
													md={12}
												>
													<Card className='mb-3'>
														<Card.Body>
															<h6 className='mb-3 fs-0'>
																Detalle de operaciones
															</h6>
															<div className='table-responsive fs--1'>
																<Table striped className='border-bottom'>
																	<thead className='bg-200 text-900'>
																		<tr>
																			<th className='border-0 text-start'>
																				Denominación
																			</th>
																			<th className='border-0 text-start'>
																				Descripción
																			</th>
																			<th className='border-0 text-start'>
																				Tiempo
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{!!hdr.operations &&
																			hdr.operations.map((operation) => (
																				<tr
																					key={`trhdr-${operation.idkey}`}
																					className='border-200'
																				>
																					<td className='align-middle text-start'>
																						<strong>
																							{operation.denomination}
																						</strong>
																					</td>

																					<td className='align-middle text-start'>
																						{operation.description}
																					</td>

																					<td className='align-middle text-start'>
																						{operation.time}
																					</td>
																				</tr>
																			))}
																	</tbody>
																</Table>
															</div>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Tab>
									)}
								</Tabs>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
