/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Button, Card, Table } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import FamilyAccordionStage4 from "./FamilyAccordionStage4";
import MasterplanModalStageFour from "./MasterplanModalStageFour";
import { generarId } from "../../helpers/utils";
import { dateFormatter } from "helpers/dateFormatter";
import { useMasterplanContext } from "hooks/useMasterplanContext";

const MasterplanFormStageFour = () => {
	//Arreglo para guardar los detalles
	const [detail, setDetail] = useState([]);
	//Arreglo con los detalles de la etapa 3
	const [stageThreeDetail, setStageThreeDetail] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [detailPublicSeasonStgFour, setDetailPublicSeasonStgFour] = useState(
		[]
	);

	const navigate = useNavigate();

	const {
		masterplanContext,
		stageThreeDetails,
		handleStageFourDetails,
		stageFourDetails,
	} = useMasterplanContext();

	useEffect(() => {
		if (stageThreeDetails && Object.entries(stageThreeDetails).length > 0) {
			getStageFourDetails();
			handleSetStageThreeDetail(stageThreeDetails);
		}
	}, [stageThreeDetails, masterplanContext]);

	useEffect(() => {
		if (stageFourDetails.length > 0) {
			stageFourDetails.sort((a, b) => {
				if (a.public === b.public) {
					// Si los atributos "public" son iguales, ordenar por "name"
					return a.familyName.localeCompare(b.familyName);
				} else {
					// Ordenar por "publico"
					return a.public.localeCompare(b.public);
				}
			});
		}
	}, [stageFourDetails]);

	const getStageFourDetails = () => {
		if (masterplanContext?.stage_one?.length > 0) {
			const stagesFourDetails = [];
			masterplanContext.stage_one.forEach((stageOne) => {
				let etapaDos = stageOne.stage_two;
				if (etapaDos.length > 0) {
					etapaDos.forEach((stageTwo) => {
						let etapaTres = stageTwo.stage_three;
						if (etapaTres && etapaTres.length > 0) {
							etapaTres.forEach((stageThree) => {
								let etapaCuatro = stageThree.stage_four;
								if (etapaCuatro && etapaCuatro.length > 0) {
									etapaCuatro.forEach((stageFour) => {
										const detailObj = {
											id: stageFour.id,
											idKey: generarId(),
											event: {
												label: `${stageFour.event.title} > ${dateFormatter(
													stageFour.event.start
												)} - ${dateFormatter(stageFour.event.end)}`,
												value: stageFour.event.id,
												evento: stageFour.event,
											},
											opening: stageFour.opening,
											genericProducts: stageFour.generic_products.map((gp) => {
												const gpObject = {
													label: `${gp.product.code} - ${gp.product.name}`,
													value: gp.product_id,
												};
												return { ...gpObject, ...gp };
											}),
											quantity: stageFour.quantity,
											public: stageTwo.publico.description,
											familyName: stageOne.family.name,
											familyPublicid: stageFour.sale_masterplan_stagethree_id,
											seasonId: stageThree.season.id,
											seasonName: stageThree.season.name,
										};
										stagesFourDetails.push(detailObj);
									});
								}
							});
						}
					});
				}
			});

			handleStageFourDetails(stagesFourDetails);
		}
	};
	//Funcion para ordenar datos de la Etapa 3 por Familia
	const handleSetStageThreeDetail = (details) => {
		const detailsOrderByPublic = {};
		setDetail([]);
		details.map((item) => {
			const name = item.name;

			if (!detailsOrderByPublic[name]) {
				detailsOrderByPublic[name] = [];
			}
			detailsOrderByPublic[name].push(item);

			//chequeo si tiene Etapa 4 cargada
			if (item.stage_four?.length > 0) {
				const newStageFourDetail = item.stage_four.map((dt) => {
					const obj = {
						id: item.id,
						idKey: generarId(),
						event: {
							label: `${dt.event.title} > ${dateFormatter(
								dt.event.start
							)} - ${dateFormatter(dt.event.end)}`,
							value: dt.event.id,
							evento: dt.event,
						},
						opening: dt.opening,
						genericProducts: dt.generic_products.map((gp) => {
							const gpObject = {
								label: `${gp.product.code} - ${gp.product.name}`,
								value: gp.product_id,
							};
							return { ...gpObject, ...gp };
						}),
						quantity: dt.quantity,
						public: item.name,
						familyName: item.familyName,
						familyPublicid: item.familyPublicid,
						seasonId: item.seasonId,
					};
					return obj;
				});

				setDetail((prevDetail) => [...prevDetail, ...newStageFourDetail]);
			}
		});

		const objToArray = Object.values(detailsOrderByPublic);
		setStageThreeDetail(objToArray);
	};

	const handleShowModal = (item) => {
		setDetailPublicSeasonStgFour(item);
		setShowModal(true);
	};

	const handleSetDetails = (item) => {
		//Actualizamos array Detail
		if (detail.length > 0) {
			//Actualizo details existentes
			const updateDetails = detail.map((dt) => {
				const detailExist = item.find((it) => dt.idKey === it.idKey);
				if (detailExist) return detailExist;
				if (
					dt.id != item[0].id ||
					(dt.id === undefined && item[0].id === undefined)
				)
					return dt;
			});

			const updateDetailsNonUndefined = updateDetails.filter(
				(udt) => udt != undefined
			);

			//Agrego details inexistentes en el array Detail
			const addDetails = item.map((it) => {
				const detailExist = updateDetailsNonUndefined.find(
					(dt) => dt.idKey === it.idKey
				);
				if (!detailExist) return it;
			});

			let newUpdateDetails = [];

			if (addDetails.length > 0) {
				newUpdateDetails = [...updateDetailsNonUndefined, ...addDetails];
			} else {
				newUpdateDetails = updateDetailsNonUndefined;
			}

			const newUpdateDetailsNonUndefined = newUpdateDetails.filter(
				(dts) => dts !== undefined
			);

			setDetail(newUpdateDetailsNonUndefined);

			setShowModal(false);
			return;
		}

		const newDetail = [...detail, ...item];
		setDetail(newDetail);
		setShowModal(false);
	};

	const handleEmptyDetails = (item, detalles) => {
		if (detail.length > 0) {
			const updateDetails = detail.map((dt) => {
				const itemToDelete = item.find((it) => it === dt.idKey);

				if (!itemToDelete) return dt;
			});

			const updateDetailsNonUndefined = updateDetails.filter(
				(udt) => udt != undefined
			);

			setDetail(updateDetailsNonUndefined);

			handleSetDetails(detalles);

			setShowModal(false);
		}
	};

	const endMasterPlanFormEdition = () => {
		setTimeout(() => {
			navigate(`/salesmasterplan/view/${masterplanContext.id}`);
		}, 200);
	};

	return (
		<Row>
			{showModal && (
				<MasterplanModalStageFour
					show={showModal}
					setShow={setShowModal}
					detailPublicSeasonStgFour={detailPublicSeasonStgFour}
					detail={detail}
					handleSetDetails={handleSetDetails}
					handleEmptyDetails={handleEmptyDetails}
				/>
			)}
			<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
				<Card className='mb-2 p-2 h-md-100'>
					<Card.Body>
						<h6 className='mb-4 text-center fs--2'>
							Cantidades por temporada planificadas
						</h6>

						<Row>
							<div className='table-responsive fs--2'>
								{stageThreeDetail && stageThreeDetail.length > 0 ? (
									<Accordion>
										{stageThreeDetail.map((item, index) => {
											return (
												<FamilyAccordionStage4
													index={index}
													key={index}
													item={item}
													handleShowModal={handleShowModal}
												/>
											);
										})}
									</Accordion>
								) : (
									""
								)}
							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col className='mb-2' xs={12} sm={12} lg={12} md={12}>
				<Card className='mb-2 p-2 h-md-100'>
					<Card.Body>
						<h6 className='mb-4 text-center fs--2'>
							Info detalle dividido por eventos
						</h6>

						<Row>
							<div className='table-responsive fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
									striped
									bordered
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th
												// style={{ width: "auto" }}
												className='border-0 text-start mx-auto'
											>
												Público
											</th>
											<th
												// style={{ width: "auto" }}
												className='border-0 text-start mx-auto'
											>
												Familia
											</th>

											<th
												// style={{ width: "15%" }}
												className='border-0 text-start mx-auto'
											>
												Evento
											</th>

											<th
												style={{ width: "15%" }}
												className='border-0 text-start mx-auto'
											>
												Temporada
											</th>

											<th className='border-0 text-center'>Apertura</th>
											<th
												// style={{ width: "auto" }}
												className='border-0 text-center'
											>
												Cant. x aper.
											</th>
											<th
												// style={{ width: "auto" }}
												className='border-0 text-center'
											>
												Total
											</th>
										</tr>
									</thead>
									<tbody>
										{stageFourDetails && stageFourDetails.length > 0 ? (
											stageFourDetails.map((item, index) => {
												return (
													<tr key={`index-${index}`} className='border-200'>
														<td className='text-start'>{item.public}</td>
														<td className='text-start'>{item.familyName}</td>
														<td className='text-start'>{item.event.label}</td>
														<td className='text-start'>{item.seasonName}</td>
														<td className='text-start'>{item.opening}</td>
														<td className='text-start'>{item.quantity}</td>
														<td className='text-start'>
															{Number(item.quantity) * Number(item.opening)}
														</td>
													</tr>
												);
											})
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>

			<Col
				xs={12}
				sm={12}
				lg={12}
				md={12}
				className='mb-2 mt-3 d-flex justify-content-end'
			>
				<Button
					size='sm'
					variant='success'
					onClick={() => endMasterPlanFormEdition()}
				>
					Finalizar
				</Button>
			</Col>
		</Row>
	);
};

MasterplanFormStageFour.propTypes = {
	masterPlan: PropTypes.object,
	setMasterPlan: PropTypes.func,
	stage3Details: PropTypes.array,
};

export default MasterplanFormStageFour;
