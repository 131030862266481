/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Tab } from "react-bootstrap";
import { useAuthContext } from "hooks/useAuthContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [description, setDescription] = useState("");
	const { id } = useParams();

	useEffect(() => {
		const getSupplierById = async () => {
			const { data } = await axiosWithToken.get(`/supplier/${id}`);
			setDescription(data.data.description);
		};
		getSupplierById();
	}, []);

	return (
		<>
			{/* VIEW */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='bg-light border-bottom border-200'>
					<Row className='align-items-end g-2'>
						<Col>
							<h5 className='mb-0 hover-actions-trigger'>Vista de proveedor</h5>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body className='bg-light'>
					<Tab.Content>
						<Row>
							<Col className='mt-3' xs={12} sm={12} lg={6} md={6}>
								<p className='mb-2 fs--1'>
									<strong className='fs--0 text-600'>Descripción:</strong>{" "}
									{description}
								</p>
							</Col>
						</Row>
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Formulario;
