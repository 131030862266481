/** @format */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

//components
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import SoftBadge from "components/common/SoftBadge";

//styles
import { Form, Row, Col, Stack } from "react-bootstrap";
const buttonDeleteStyle = {
	position: "absolute",
	right: "0",
	top: "-6px",
	lineHeight: "0",
	borderRadius: "100%",
	height: "15px",
	width: "15px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	marginTop: "10px",
	marginLeft: "5px",
};

const InputsCargaMPCant = ({
	keyId,
	handleMpSeleccionada,
	mpSelect,
	handleDeleteMpSeleccionada,
	eliminarRowUbicaciones,
}) => {
	const [materiaPrima, setMateriaPrima] = useState([]);
	const [mpSeleccionada, setMpSeleccionada] = useState([]);

	useCallback(() => {
		console.log(materiaPrima);
	}, []);

	const handleSearchMP = (key, searchObject) => {
		searchObject.keyId = key;
		mpSeleccionada.push(searchObject);
		handleMpSeleccionada(searchObject);
	};

	//Funcion para eliminar mp agregados
	const handleDeleteMP = (mp_id) => {
		const newMP = mpSeleccionada.filter((mp) => mp.id != mp_id);
		setMpSeleccionada(newMP);
		handleDeleteMpSeleccionada(mp_id);
	};

	//Funcion para eliminar row y ademas llama a la funcion para eliminar la mp seleccionada
	const eliminarRow = (key) => {
		const mpToDelete = document.querySelector(`#mp-${keyId}`);

		if (mpToDelete != undefined) {
			mpToDelete.click();
		}

		eliminarRowUbicaciones(key);
	};

	return (
		<Row id={`row-${keyId}`} className='mt-2'>
			{/* Boton para eliminar row */}
			<Col
				xs={12}
				sm={12}
				md={12}
				lg={12}
				className='fw-bold'
				style={{ cursor: "pointer", position: "relative" }}
			>
				<button
					type='button'
					className='btn btn-danger p-2'
					style={buttonDeleteStyle}
					onClick={() => eliminarRow(`${keyId}`)}
				>
					<div className='fs--1'>x</div>
				</button>
			</Col>
			{/* MATERIA PRIMA */}
			<Form.Group
				as={Col}
				xs={12}
				sm={12}
				md={8}
				lg={8}
				controlId={`formGridCode-${keyId}`}
			>
				<Form.Label className='fs--2'>Buscar un código</Form.Label>
				<SearchAutocomplete
					setSelect={setMateriaPrima}
					handleSearchMP={handleSearchMP}
					modelSearch={"rawmaterial"}
					mpSeleccionada={mpSelect}
					keyId={keyId}
					disabled={!!mpSeleccionada.length > 0}
				/>
				<Stack
					direction='horizontal'
					gap={3}
					className='d-flex'
					style={{ flexWrap: "wrap" }}
				>
					{mpSeleccionada?.length > 0
						? mpSeleccionada.map((mp) => {
								return (
									<div key={mp.id}>
										<SoftBadge variant='secondary'>{mp.name}</SoftBadge>{" "}
										<span
											id={`mp-${keyId}`}
											style={{ cursor: "pointer" }}
											onClick={() => handleDeleteMP(mp.id)}
										>
											x
										</span>
									</div>
								);
						  })
						: ""}
				</Stack>
			</Form.Group>

			{/* CANTIDAD */}
			<Form.Group
				as={Col}
				xs={12}
				sm={12}
				md={4}
				lg={4}
				controlId={`formGridCantidad-${keyId}`}
			>
				<Form.Label className='fs--2'>Cantidad</Form.Label>
				<Form.Control
					defaultValue={0}
					type='number'
					placeholder='Ingrese cantidad de materia prima'
					size='sm'
					min={1}
				/>
			</Form.Group>

			{/* CANTIDAD COLORES */}
			{/* <Form.Group
        as={Col}
        xs={12}
        sm={6}
        md={2}
        lg={2}
        controlId={`formGridCantColores-${keyId}`}
      >
        <Form.Label className="fs--2">Cant.Colores</Form.Label>
        <Form.Control
          defaultValue={0}
          type="number"
          placeholder="Ingrese cantidad de materia prima"
          size="sm"
          min={1}
        />
      </Form.Group> */}

			{/* CANTIDAD SHABLON*/}
			{/* <Form.Group
        as={Col}
        xs={12}
        sm={6}
        md={2}
        lg={2}
        controlId={`formGridCantShablones-${keyId}`}
      >
        <Form.Label className="fs--2">Cant.shablones</Form.Label>
        <Form.Control
          defaultValue={0}
          type="number"
          placeholder="Ingrese cantidad de materia prima"
          size="sm"
          min={1}
        />
      </Form.Group> */}
		</Row>
	);
};

InputsCargaMPCant.propTypes = {
	keyId: PropTypes.number,
	handleMpSeleccionada: PropTypes.func,
	mpSelect: PropTypes.array,
	handleDeleteMpSeleccionada: PropTypes.func,
	eliminarRowUbicaciones: PropTypes.func,
};

export default InputsCargaMPCant;
