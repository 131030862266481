/** @format */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	Row,
	Col,
	Card,
	Tab,
	Button,
	Badge,
	Stack,
	Tabs,
} from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import HojaRutaForm from "./HojaRutaForm";
import { getHojaRuta } from "data/hojaRuta/hojaRuta";
import Spinner from "components/spinner/Spinner";
import HojaRutaOperationPagination from "./HojaRutaOperation";

const HojaRutaView = () => {
	const [hojaRuta, setHojaRuta] = useState({});
	// const [editionMode, setEditionMode] = useState(false);
	const [errorExist, setErrorExist] = useState({});
	const [opRelation, setOpRelation] = useState([]);
	const [loading, setLoading] = useState(true);

	const { id } = useParams();

	const navigate = useNavigate();

	useEffect(async () => {
		try {
			const hojaRutaApi = await getHojaRuta(id);
			setHojaRuta(hojaRutaApi);
		} catch (error) {
			setErrorExist({
				error: true,
				message: "La hoja de ruta solicitada no existe!",
			});
		}
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	useEffect(() => {
		if (hojaRuta?.operations) setOpRelation(hojaRuta.operations);
	}, [hojaRuta]);

	const volver = () => {
		navigate("/hoja-ruta/list/");
	};

	// const handleClose = () => {
	// 	setEditionMode(!editionMode);
	// };

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<>
					{errorExist.error ? (
						<>
							<h6 className='text-center'>{errorExist.message}</h6>
							<div className='text-center'>
								<Button variant='warning' onClick={() => volver()}>
									Volver
								</Button>
							</div>
						</>
					) : (
						<>
							<Card className='mb-3'>
								<Card.Header className='p-2'>
									<Row className='align-items-baseline g-2'>
										<Col className='d-flex justify-content-center mt-0'>
											<h6 className='mb-0 mt-3 hover-actions-trigger'>
												Hoja de ruta:{" "}
												<Badge bg='secondary'># {hojaRuta.id}</Badge>{" "}
												<Badge bg='secondary'>{hojaRuta.denominacion}</Badge>
											</h6>
										</Col>
									</Row>
								</Card.Header>

								<Card.Body className='bg-light'>
									<Tab.Content>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Tabs
													defaultActiveKey='general'
													id='tabs-operationView'
												>
													<Tab eventKey='general' title='1 - Datos'>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<h6 className='mb-3 fs-0'>
																			Información general
																		</h6>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Nombre:</b>{" "}
																			{hojaRuta.denominacion}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Código:</b>{" "}
																			{hojaRuta.codigo}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Tipo:</b>{" "}
																			{hojaRuta.tipo}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>Desde:</b>{" "}
																			{hojaRuta.desde}
																		</p>

																		<p className='mb-2 fs--1'>
																			<b className='fs--0 text-600'>
																				Operación estandar:
																			</b>{" "}
																			{hojaRuta.op_estandar === 0 ? "NO" : "SI"}
																		</p>
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Tab>
													<Tab
														eventKey='operationRelations'
														title='2 - Operaciones'
													>
														<Card className='h-md-100 p-1'>
															<Card.Body className='position-relative'>
																<Row>
																	<Col
																		className='mt-3'
																		xs={12}
																		sm={12}
																		lg={12}
																		md={12}
																	>
																		<h6 className='mb-3 fs-0'>
																			Operaciones relacionadas
																		</h6>

																		<HojaRutaOperationPagination
																			data={opRelation}
																		/>
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													</Tab>
												</Tabs>
											</Col>

											<Col className='mt-2'>
												<Stack
													className='d-flex justify-content-end'
													direction='horizontal'
													gap={2}
												>
													{/* 
														//TODO oculta boton de edicion de hdr por tema de modificar operaciones
														<Button
															//as={Link}
															//to={`/centros-de-trabajo/listado-centros/formCentroEdit/${center.id}`}
															onClick={() => setEditionMode(!editionMode)}
															variant='warning'
															size='sm'
															className='border-0 float-end'
														>
															<FontAwesomeIcon icon='edit' />{" "}
															{!editionMode ? "EDITAR" : "X"}
														</Button> */}
													<Button
														className='border'
														variant='transparent'
														onClick={() => volver()}
													>
														Volver
													</Button>
												</Stack>
											</Col>
										</Row>

										{/* <Col lg={8} md={8} sm={12} xs={12}>
												{editionMode && (
													<HojaRutaForm
														ruta_id={hojaRuta.id}
														handleClose={handleClose}
													/>
												)}
											</Col> */}
									</Tab.Content>
								</Card.Body>
							</Card>
						</>
					)}
				</>
			)}
		</>
	);
};

export default HojaRutaView;
