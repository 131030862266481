/** @format */

import React from "react";
import { Tab, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	useTable,
	useGlobalFilter,
	useSortBy,
	usePagination,
} from "react-table";
import useColumnsRawmaterials from "../../../hooks/useColumnsRawmaterials";
import { Link } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import PropTypes from "prop-types";
import ListadoTableFilter from "components/products/rawmaterials/ListadoTableFilter";
import ListadoTablePagination from "components/products/rawmaterials/ListadoTablePagination";

const ListadoTable = ({ data }) => {
	const columns = useColumnsRawmaterials();
	const table = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 15,
				pageIndex: 0,
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		rowsPerPageOptions = [15, 20, 25],
		setGlobalFilter,
	} = table;

	return (
		<>
			{/* CARD TABLA LISTADO */}
			<Tab.Content>
				<SimpleBarReact>
					{/* BUSCADOR */}

					<ListadoTableFilter
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>

					{/* TABLA */}

					<table
						className='fs--1 mb-0 overflow-hidden table table-striped table-bordered mt-2'
						{...getTableProps()}
					>
						<thead className='bg-200 text-900 text-nowrap align-middle'>
							{
								// Loop over the header rows
								headerGroups.map((headerGroup, index) => (
									// Apply the header row props
									<tr
										key={`tr-${index}`}
										{...headerGroup.getHeaderGroupProps()}
									>
										{
											// Loop over the headers in each row
											headerGroup.headers.map((column, index) => (
												// Apply the header cell props
												<th
													key={`th-${index}`}
													style={{ width: "30%" }}
													{...column.getHeaderProps(
														column.id != "acciones"
															? column.getSortByToggleProps(column.headerProps)
															: ""
													)}
												>
													{
														// Render the header
														column.render("Header")
													}

													{column.id != "acciones" ? (
														column.canSort ? (
															column.isSorted ? (
																column.isSortedDesc ? (
																	<span className='sort desc' />
																) : (
																	<span className='sort asc' />
																)
															) : (
																<span className='sort' />
															)
														) : (
															""
														)
													) : (
														""
													)}
												</th>
											))
										}
									</tr>
								))
							}
						</thead>
						{/* Apply the table body props */}
						<tbody {...getTableBodyProps()}>
							{
								// Loop over the table rows
								page.map((row, index) => {
									// Prepare the row for display
									prepareRow(row);
									return (
										// Apply the row props
										<tr key={`tr2-${index}`} {...row.getRowProps()}>
											{
												// Loop over the rows cells
												row.cells.map((cell, index) => {
													return (
														<td key={`td2-${index}`} {...cell.getCellProps()}>
															{
																// Render the cell contents
																cell.column.id == "acciones" ? (
																	<>
																		<OverlayTrigger
																			placement='top'
																			overlay={<Tooltip>Ver</Tooltip>}
																		>
																			<Button
																				as={Link}
																				to={`/products/rawmaterials/view/${cell.row.values.id}`}
																				variant='transparent'
																				size='sm'
																				className='text-primary me-2'
																			>
																				<FontAwesomeIcon icon='eye' />
																			</Button>
																		</OverlayTrigger>

																		<OverlayTrigger
																			placement='top'
																			overlay={<Tooltip>Editar</Tooltip>}
																		>
																			<Button
																				as={Link}
																				to={`/products/rawmaterials/form/${cell.row.values.id}`}
																				variant='transparent'
																				size='sm'
																				className='text-success me-2'
																			>
																				<FontAwesomeIcon icon='edit' />
																			</Button>
																		</OverlayTrigger>

																		{/* <OverlayTrigger
																			placement='top'
																			overlay={<Tooltip>Eliminar</Tooltip>}
																		>
																			<Button
																				variant='transparent'
																				size='sm'
																				className=' text-danger me-2'
																			>
																				<FontAwesomeIcon icon='trash' />
																			</Button>
																		</OverlayTrigger> */}
																	</>
																) : (
																	cell.render("Cell")
																)
															}
														</td>
													);
												})
											}
										</tr>
									);
								})
							}
						</tbody>
					</table>

					{/* PAGINACIÓN */}
					<ListadoTablePagination
						pageSize={parseInt(pageSize)}
						pageIndex={pageIndex}
						page={page}
						pageCount={pageCount}
						setPageSize={setPageSize}
						rowsPerPageOptions={rowsPerPageOptions}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						previousPage={previousPage}
						nextPage={nextPage}
					/>
				</SimpleBarReact>
			</Tab.Content>
		</>
	);
};

ListadoTable.propTypes = {
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func,
	data: PropTypes.array,
};

export default ListadoTable;
