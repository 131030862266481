/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */
import Modal from "react-bootstrap/Modal";

const FormularioModal = ({ show, setShow, size, title, children }) => {
	return (
		<Modal
			show={show}
			fullscreen={false}
			size={size}
			onHide={() => setShow(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<b>{title}</b>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
};

export default FormularioModal;
