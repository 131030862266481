/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Form, Button, Badge, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import SearchAutocomplete from "components/searchs/SearchAutocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { useRawmaterialContext } from "hooks/useRawmaterialContext";

const Formulario = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const navigate = useNavigate();

	const toastId = React.useRef(null);

	//Select hoja de ruta
	const [selectRoadmap, setSelectRoadmap] = useState({});
	const [roadmapOperations, setRoadmapOperations] = useState([]);

	//Valida botón, habilita/deshabilita
	const [disabled, setDisabled] = useState(true);

	//Paginación de operaciones
	const perPage = 5;

	const { rawmaterial, hdr, handleHdrByRawmaterialId } =
		useRawmaterialContext();

	useEffect(() => {
		if (hdr && Object.entries(hdr).length > 0) {
			setSelectRoadmap(hdr);
			setRoadmapOperations(
				hdr.operations.map((operation) => {
					//console.log('cada operacion para ver si esto se estÃ¡ haciendo', operation);

					return {
						id: operation.id,
						denomination: operation.denomination,
						description: operation.description,
						time: operation.time,
					};
				})
			);
		} else {
			//Limpiamos los estados
			setSelectRoadmap({});
			setRoadmapOperations([]);
		}
	}, [hdr]);

	useEffect(() => {
		if (selectRoadmap && Object.entries(selectRoadmap).length > 0) {
			setDisabled(false);

			setRoadmapOperations(
				selectRoadmap.operations.map((operation) => {
					//console.log('cada operacion para ver si esto se está haciendo', operation);

					return {
						id: operation.id,
						denomination: operation.denomination,
						description: operation.description,
						time: operation.time,
					};
				})
			);
		} else {
			setDisabled(true);
		}
	}, [selectRoadmap]);

	const handleCancelar = () => {
		//Cancelar la edición, simplemente lleva al listado nuevamente
		navigate(`/products/rawmaterials/list`);
	};

	const deleteRoadmap = () => {
		//Borramos la selección de hoja de ruta
		setSelectRoadmap({});
		setRoadmapOperations([]);
	};

	const handleAddHdr = async () => {
		if (Object.entries(selectRoadmap).length == 0) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`Debe seleccionar una hoja de ruta.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		try {
			const { data } = await axiosWithToken.post(
				"/hoja-ruta/addtorawmaterial",
				{
					rawmaterial_id: parseInt(rawmaterial.id),
					hdr_id: selectRoadmap.id,
				}
			);

			handleHdrByRawmaterialId(data.data.rawmaterial_id);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Operación exitosa!", {
					role: "alert",
					theme: "dark",
				});
			}

			setTimeout(() => {
				navigate(`/products/rawmaterials/view/${rawmaterial.id}`);
			}, 1000);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
		}
	};

	//Paginación de operaciones
	const {
		paginationState: {
			data: paginatedDetails,
			currentPage,
			canNextPage,
			canPreviousPage,
			paginationArray,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(roadmapOperations, perPage);

	return (
		<>
			{/* FORM */}
			<Row>
				<h6 className='mb-4 text-center fs--2'>
					H. de ruta para la materia prima:{" "}
					{rawmaterial.name && (
						<span>
							<Badge bg='secondary'>{rawmaterial.name}</Badge>
						</span>
					)}
				</h6>

				<Col className='mb-2' xs={12} sm={12} lg={5} md={5}>
					<Card className='mb-2 p-1 h-md-100'>
						<span>
							<p className='mb-1 fs--2 text-center'>
								¡Asigna una hoja de ruta a la materia prima!
							</p>
						</span>

						<Card.Body>
							<Row>
								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridRoadmap'
								>
									<Form.Label className='fs--2'>
										Buscar hoja de ruta
										<span className='text-danger'>*</span>
									</Form.Label>
									<SearchAutocomplete
										setSelect={setSelectRoadmap}
										modelSearch={"roadmap"}
										disabled={false}
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									controlId='formGridRoadmapDenomination'
								>
									<span className='fs--2'>
										<Badge className='mt-1' bg='secondary'>
											{selectRoadmap.denominacion}
										</Badge>
										{selectRoadmap.denominacion ? (
											<FontAwesomeIcon
												icon='times'
												size='sm'
												className='cursor-pointer text-danger ms-2'
												onClick={() => deleteRoadmap()}
											/>
										) : (
											""
										)}
									</span>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Col>

				<Col className='mb-2' xs={12} sm={12} lg={7} md={7}>
					<Card className='mb-2 p-1 h-md-100'>
						<Card.Body>
							{Object.entries(selectRoadmap).length > 0 && (
								<Row>
									<span>
										<p className='mb-3 fs--2 text-center'>
											¡Datos de la hoja de ruta seleccionada!
										</p>
									</span>

									<Col xs={12} sm={12} lg={12} md={12}>
										<p className='mb-2 fs--1'>
											<strong className='fs--2 text-600'>Código:</strong>{" "}
											{selectRoadmap.codigo}
										</p>

										<p className='mb-2 fs--1'>
											<strong className='fs--2 text-600'>Denominación:</strong>{" "}
											{selectRoadmap.denominacion}
										</p>
									</Col>

									<Col className='mt-3 mb-2' xs={12} sm={12} lg={12} md={12}>
										<>
											<p className='mb-2 fs--1'>
												<strong className='mb-3 fs--2 text-600'>
													Detalle de operaciones
												</strong>
											</p>
											<div className='table-responsive fs--2'>
												<Table striped className='border-bottom'>
													<thead className='bg-200 text-900'>
														<tr>
															<th className='border-0 text-start'>
																Denominación
															</th>
															<th className='border-0 text-start'>
																Descripción
															</th>
															<th className='border-0 text-start'>Tiempo</th>
														</tr>
													</thead>
													<tbody>
														{paginatedDetails.map((operation) => (
															<tr key={operation.id} className='border-200'>
																<td className='align-middle text-start'>
																	<strong>{operation.denomination}</strong>
																</td>

																<td className='align-middle text-start'>
																	{operation.description}
																</td>

																<td className='align-middle text-start'>
																	{operation.time}
																</td>
															</tr>
														))}
													</tbody>
												</Table>
											</div>
											<div className='d-flex justify-content-center'>
												<div>
													<Button
														style={{ fontSize: "8px" }}
														variant='falcon-default'
														size='sm'
														disabled={!canPreviousPage}
														onClick={prevPage}
														className='me-2'
														trigger='focus'
													>
														<FontAwesomeIcon icon='chevron-left' />
													</Button>
												</div>
												<ul className='pagination mb-0'>
													{paginationArray.map((page) => (
														<li
															key={page}
															className={classNames({
																active: currentPage === page,
															})}
														>
															<Button
																style={{ fontSize: "8px" }}
																size='sm'
																variant='falcon-default'
																className='page me-2'
																onClick={() => goToPage(page)}
															>
																{page}
															</Button>
														</li>
													))}
												</ul>
												<div>
													<Button
														style={{ fontSize: "8px" }}
														variant='falcon-default'
														size='sm'
														disabled={!canNextPage}
														onClick={nextPage}
														trigger='focus'
													>
														<FontAwesomeIcon icon='chevron-right' />
													</Button>
												</div>
											</div>
										</>
									</Col>
								</Row>
							)}

							{Object.entries(selectRoadmap).length == 0 && (
								<Row>
									<Col xs={12} sm={12} lg={12} md={12}>
										<span>
											<p className='mb-1 fs--2 text-center'>
												¡No se seleccionó una hoja de ruta!
											</p>
										</span>
									</Col>
								</Row>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col className='d-flex justify-content-end'>
					<Button size='sm' variant='danger' onClick={() => handleCancelar()}>
						Cancelar
					</Button>

					<Button
						className='ms-1'
						variant='primary'
						size='sm'
						disabled={disabled}
						onClick={() => {
							handleAddHdr();
						}}
					>
						Guardar
					</Button>
				</Col>
			</Row>
		</>
	);
};
export default Formulario;
