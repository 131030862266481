/** @format */
import React from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import { Table } from "react-bootstrap";
import StageFourDetailPagination from "./StageFourDetailPagination";

const FamilyAccordionStage4 = ({ index, item, handleShowModal }) => {
	return (
		<Accordion.Item eventKey={index}>
			<Accordion.Header as={"h4"}>{item[0].name}</Accordion.Header>
			<Accordion.Body>
				<Table
					className='p-0 border-bottom fs--2'
					style={{ padding: "0.45" }}
					striped
					bordered
					responsive
				>
					<thead className='bg-200 text-900'>
						<tr className='border-200'>
							<th style={{ width: "10%" }} className='border-0 text-center'>
								Acciones
							</th>
							<th style={{ width: "30%" }} className='border-0 text-start'>
								Familia
							</th>
							<th style={{ width: "30%" }} className='border-0 text-start'>
								Temporada
							</th>
							<th style={{ width: "10%" }} className='border-0 text-start'>
								Aper.
							</th>
							<th style={{ width: "30%" }} className='border-0 text-start'>
								Cant. x aper.
							</th>
						</tr>
					</thead>
					<tbody>
						{item && item.length ? (
							<>
								<StageFourDetailPagination
									stage3Detail={item}
									handleShowModal={handleShowModal}
								/>
							</>
						) : (
							<tr className='border-200'>
								<td colSpan='6' className='align-middle text-center'>
									<h6 className='font-black text-3xl text-center fs--2'>
										¡No hay datos cargados!
									</h6>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</Accordion.Body>
		</Accordion.Item>
	);
};

FamilyAccordionStage4.propTypes = {
	index: PropTypes.number,
	item: PropTypes.array,
	handleShowModal: PropTypes.func,
};

export default FamilyAccordionStage4;
