/** @format */

export const days = [
	{ label: "Lunes", value: "lunes" },
	{ label: "Martes", value: "martes" },
	{ label: "Miercoles", value: "miercoles" },
	{ label: "Jueves", value: "jueves" },
	{ label: "Viernes", value: "viernes" },
	{ label: "Sabado", value: "sabado" },
	{ label: "Domingo", value: "domingo" },
];
