/** @format */

import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";

const axiosWithToken = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT_API,
});

export const getProductCodesByFamily = async (families) => {
	try {
		const { token } = useAuthContext();
		axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const { data } = await axiosWithToken.get("/productcodes");
		//guardo todos los productos en una variable
		const productCodes = data.data;

		//creo un array que va a contener todas las familias de productos seleccionadas
		const concatFamilies = [];
		//guardo familias seleccionadas en el array
		await families.map((family) => {
			if (family.children.length > 0) {
				family.children.map((child) => concatFamilies.push(child));
			}
		});

		//filtro todos los productos por familia seleccionada
		const productCodesByFamilyFiltered = productCodes.filter((product) =>
			concatFamilies.find((family) => family.id === product.product_family_id)
		);

		//Formateamos el filtrado de productos para poder usarlo en el componente de select
		const productCodesByFamilyFilteredFormatted =
			productCodesByFamilyFiltered.map((product) => {
				const productObj = {
					label: product.code + "-" + product.description,
					value: product.id,
				};
				return productObj;
			});

		return productCodesByFamilyFilteredFormatted;
	} catch (error) {
		throw error.response.data.message;
	}
};
