/** @format */

import React, { useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "hooks/useAuthContext";
import loadingImg from "assets/img/img-dashboard/icons/loading.gif";
import Flex from "components/common/Flex";

const LoginForm = () => {
	const { login } = useAuthContext();

	//Estado para manejar toastity no repetidos
	const toastId = useRef(null);

	//Estados email y password
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		if (email == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo email es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		if (password == "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`El campo password es obligatorio.`, {
					role: "alert",
					theme: "dark",
				});
			}

			//Error
			return;
		}

		const userData = {
			email: email,
			password: password,
		};

		login(userData).then((data) => {
			// La promesa se resolvió correctamente, el usuario inició sesión
			console.log("Inicio de sesión exitoso:", data.user);
			setTimeout(() => {
				setLoading(false);
			}, 800);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(`Bienvenido: ${data.user.name}`, {
					role: "alert",
					theme: "dark",
				});
			}
		});
	};

	return (
		<>
			{/* TOAST MENSAJE */}

			<ToastContainer
				position='top-right'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<Form onSubmit={handleSubmit}>
				<Form.Group className='mb-3'>
					<Form.Label>Email</Form.Label>
					<Form.Control
						placeholder={"Ingresar email"}
						value={email}
						name='email'
						onChange={(e) => setEmail(e.target.value)}
						type='email'
					/>
				</Form.Group>

				<Form.Group className='mb-3'>
					<Form.Label>Password</Form.Label>
					<Form.Control
						placeholder={"Ingresar password"}
						value={password}
						name='password'
						onChange={(e) => setPassword(e.target.value)}
						type='password'
					/>
				</Form.Group>

				{loading ? (
					<Form.Group className='mb-3 mt-3'>
						<Flex justifyContent='center' alignItems='center' className='mb-2'>
							<img src={loadingImg} width={60} alt='loading sonder' />
						</Flex>
					</Form.Group>
				) : (
					<Form.Group className='mb-3'>
						<Button
							type='submit'
							color='primary'
							className='mt-3 w-100'
							disabled={!email || !password}
						>
							Ingresar
						</Button>
					</Form.Group>
				)}
			</Form>
		</>
	);
};

export default LoginForm;
