/** @format */

import React, { useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsyncDebounce } from "react-table";
import PropTypes from "prop-types";

const ListTableFilter = ({ globalFilter, setGlobalFilter }) => {
	const [value, setValue] = useState(globalFilter);

	const onFilterChange = useAsyncDebounce(
		(value) => setGlobalFilter(value || undefined),
		200
	);

	const handleInputChange = (e) => {
		setValue(e.target.value);
		onFilterChange(e.target.value);
	};

	return (
		<>
			<InputGroup className='position-relative'>
				<FormControl
					value={value || ""}
					onChange={handleInputChange}
					size='sm'
					id='search'
					placeholder='Buscar...'
					type='search'
					className='shadow-none'
				/>
				<InputGroup.Text className='bg-transparent'>
					<FontAwesomeIcon icon='search' className='text-600' />
				</InputGroup.Text>
			</InputGroup>
		</>
	);
};

ListTableFilter.propTypes = {
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func,
};

export default ListTableFilter;
