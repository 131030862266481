/** @format */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Row,
	Col,
	Form,
	Button,
	Modal,
	Table,
	OverlayTrigger,
	Tooltip,
	Card,
} from "react-bootstrap";
import PropTypes from "prop-types";
import Divider from "components/common/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { generarId } from "../../helpers/utils";
import usePagination from "hooks/usePagination";
import classNames from "classnames";
import { useMasterplanContext } from "hooks/useMasterplanContext";
import { useAuthContext } from "hooks/useAuthContext";

import { getTemporadas } from "data/calendar/temporadas";

const MasterplanModalStageThree = ({
	show,
	setShow,
	detailPublicFamilyStgTwo,
}) => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [detalles, setDetalles] = useState([]);

	//Estado para saber si editar un registro
	const [edit, setEdit] = useState(false);
	const [idToDelete, setIdToDelete] = useState("");
	const [quantity, setQuantity] = useState(0);
	const [opening, setOpening] = useState(0);
	const [itemEdit, setItemEdit] = useState({});

	const [showAlert, setShowAlert] = useState(false);

	//Array de todas las temporads
	const [seasons, setSeasons] = useState([]);
	const [seasonSelected, setSeasonSelected] = useState("");

	const toastId = React.useRef(null);

	const { stageThreeDetails, masterplanContext, handleSetMasterplan } =
		useMasterplanContext();

	useEffect(async () => {
		const seasonsApi = await getTemporadas();
		setSeasons(seasonsApi);
	}, []);

	useEffect(() => {
		if (stageThreeDetails?.length > 0) {
			const details = stageThreeDetails.filter(
				(detail) => detail.familyPublicid === detailPublicFamilyStgTwo.id
			);
			setDetalles(details);
		} else {
			setDetalles([]);
		}
	}, [stageThreeDetails, masterplanContext]);

	//Cantidad de registros que se muestran en la paginación
	const perPage = 6;

	const {
		paginationState: {
			data: paginatedDetails,
			canNextPage,
			canPreviousPage,
			paginationArray,
			currentPage,
		},
		nextPage,
		prevPage,
		goToPage,
	} = usePagination(detalles, perPage);

	//Agregamos un row a la tabla
	const handleSetDetalles = () => {
		//Obtengo el nombre de la temporada
		const seasonName = seasons.filter(
			(season) => Number(season.id) === Number(seasonSelected)
		);
		//Agregamos un registro
		if (!edit) {
			const seasonExist = detalles.find(
				(detalle) => detalle.seasonName === seasonName[0].name
			);

			//evito agregar temporada duplicada
			if (seasonExist?.name) {
				setTimeout(() => {
					toast.error(`La temporada ${seasonName[0].name} ya existe!.`, {
						role: "alert",
						theme: "dark",
					});
				}, 0);

				// Error
				return;
			}

			//Creo objeto de nueva temporada
			const newOpeningSeason = {
				familyPublicid: detailPublicFamilyStgTwo.id,
				idKey: generarId(),
				name: detailPublicFamilyStgTwo.name,
				familyName: detailPublicFamilyStgTwo.familyName,
				quantity,
				seasonId: seasonSelected,
				seasonName: seasonName[0].name,
				opening,
				stage_four: [],
			};

			handleSaveStageThree(newOpeningSeason);
			return;
		}

		//Editamos un registro
		if (edit) {
			const updtOpeningSeason = {
				id: itemEdit.id,
				familyPublicid: detailPublicFamilyStgTwo.id,
				idKey: generarId(),
				name: detailPublicFamilyStgTwo.name,
				familyName: detailPublicFamilyStgTwo.familyName,
				quantity,
				seasonId: seasonSelected,
				seasonName: seasonName[0].name,
				opening,
				stage_four: [],
			};

			handleSaveStageThree(updtOpeningSeason);
			return;
		}

		//TODO por el momento sacamos validación de cantidad planificada y cantidad de aperturas
		// const totalQuantity = detailPublicFamilyStgTwo.quantity;
		// const totalOpeningQuantity = detailPublicFamilyStgTwo.opening;

		// -- controlo que la cantidad a agregar no supere la cantidad total -- //
		// const acumulatedQuantity = detalles.reduce((acc, seasonObj) => {
		// 	return Number(seasonObj.quantity) + acc;
		// }, 0);

		// const quantityRemaining = Number(totalQuantity) - acumulatedQuantity;

		// const newQuantity = quantityRemaining - Number(quantity);

		// if (newQuantity < 0) {
		// 	setTimeout(() => {
		// 		toast.error(
		// 			`La sumatoria de cantidades de publicos no puede superar la cantidad planificada de la familia.`,
		// 			{
		// 				role: "alert",
		// 				theme: "dark",
		// 			}
		// 		);
		// 	}, 0);

		// 	// Error
		// 	return;
		// }
		// --- fin control cantidad --- //

		// --- controlo que la cantidad de aperturas a agregar no supere la cantidad total ---//
		// const acumulatedOpeningQuantity = detalles.reduce((acc, seasonObj) => {
		// 	return Number(seasonObj.opening) + acc;
		// }, 0);

		// const quantityOpeningRemaining =
		// 	Number(totalOpeningQuantity) - acumulatedOpeningQuantity;

		// const newOpeningQuantity = quantityOpeningRemaining - Number(opening);

		// if (newOpeningQuantity < 0) {
		// 	setTimeout(() => {
		// 		toast.error(
		// 			`La sumatoria de cantidades apertura no puede superar la cantidad total de aperturas.`,
		// 			{
		// 				role: "alert",
		// 				theme: "dark",
		// 			}
		// 		);
		// 	}, 0);

		// 	// Error
		// 	return;
		// }
		//---- fin control cantidad ----//
	};

	//Agregamos un registro
	const handleSaveStageThree = async (detalle) => {
		const validation = isValidated();
		if (!validation) return;

		try {
			const { data } = await axiosWithToken.post(
				"/masterplanStagethree/stageThree",
				{
					masterplan_id: masterplanContext.id,
					detalle,
				}
			);

			handleSetMasterplan(data.data);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success(
					"¡Detalle por rubro y familia guardado, puede continuar!",
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			setEdit(false);
			setIdToDelete("");
			setQuantity(0);
			setOpening(0);
			setItemEdit({});
			setSeasonSelected("");
		} catch (error) {
			console.log(error);
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.warning(
					`${error.response?.data?.message ?? "Ha ocurrido un error"}`,
					{
						role: "alert",
						theme: "dark",
					}
				);
			}
			return;
		}
	};

	//Eliminamos un row de la tabla
	const handleDelete = (id) => {
		setIdToDelete(id);
		setShowAlert(true);
	};

	const closeModalDelete = () => {
		setShowAlert(false);
	};

	const handleConfirmDelete = async () => {
		try {
			const { data } = await axiosWithToken.delete(
				`/masterplanStagethree/masterplan/${masterplanContext.id}/deleteRow/${idToDelete}`
			);

			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success("¡Detalle eliminado correctamente!", {
					role: "alert",
					theme: "dark",
				});
			}

			handleSetMasterplan(data.data);
		} catch (error) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error("¡Hubo un error!", {
					role: "alert",
					theme: "dark",
				});
			}
			console.log(error);
		}

		setEdit(false);
		setIdToDelete("");
		setQuantity(0);
		setOpening(0);
		setItemEdit({});
		setSeasonSelected("");
		setShowAlert(false);
	};

	const isValidated = () => {
		if (seasonSelected === "") {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`Debe seleccionar una temporada!`, {
					role: "alert",
					theme: "dark",
				});
			}
			return false;
		}

		if (quantity === "" || quantity === 0 || isNaN(quantity)) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`La cantidad no puede ser nula!`, {
					role: "alert",
					theme: "dark",
				});
			}
			return false;
		}

		if (opening === "" || opening === 0 || isNaN(opening)) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error(`La apertura no puede ser nula!`, {
					role: "alert",
					theme: "dark",
				});
			}
			return false;
		}

		return true;
	};

	return (
		<Modal
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			show={show}
		>
			<Modal.Header>
				<Modal.Title>
					<h6 className='mb-0 hover-actions-trigger'>
						Familia - Público: {detailPublicFamilyStgTwo.familyName} -{" "}
						{detailPublicFamilyStgTwo.name}
					</h6>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className='mb-3'>
					<Col xs={12} sm={12} lg={12} md={12}>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Cantidad:</strong>{" "}
							<b>{detailPublicFamilyStgTwo.quantity}</b>
						</p>
						<p className='mb-2 fs--1'>
							<strong className='fs--0 text-600'>Aperturas totales: </strong>{" "}
							<b>{detailPublicFamilyStgTwo.opening}</b>
						</p>
					</Col>

					<Divider />

					<Col xs={12} sm={12} lg={4} md={4}>
						<Card className='mb-2 p-2 h-md-100'>
							<Form>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridPublics'
									>
										<Form.Label className='fs--2'>
											Temporada
											<span className='text-danger'>*</span>
										</Form.Label>

										<Form.Select
											size='sm'
											value={seasonSelected}
											aria-label='temporada'
											onChange={(e) => setSeasonSelected(e.target.value)}
											disabled={edit}
										>
											<option value='0'>Seleccionar...</option>
											{seasons.length &&
												seasons.map((season) => (
													<option key={season.id} value={season.id}>
														{season.name}
													</option>
												))}
										</Form.Select>
									</Form.Group>
								</Row>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridPublics'
									>
										<Form.Label className='fs--2'>
											Cantidad planificada
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											value={quantity}
											onChange={(e) => setQuantity(e.target.value)}
											type='text'
											placeholder=''
										/>
									</Form.Group>
								</Row>
								<Row className='mb-1'>
									<Form.Group
										as={Col}
										xs={12}
										sm={12}
										md={12}
										lg={12}
										controlId='formGridaAperturas'
									>
										<Form.Label className='fs--2'>
											Aperturas
											<span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											size='sm'
											value={opening}
											onChange={(e) => setOpening(e.target.value)}
											type='text'
											placeholder=''
										/>
									</Form.Group>
								</Row>
								<Row className='mt-3'>
									<Col className='d-flex justify-content-end fs--2'>
										<Button
											variant='success'
											className='ms-1 fs--2'
											size='sm'
											onClick={() => handleSetDetalles()}
										>
											{!edit ? "Agregar" : "Guardar"}
										</Button>

										{edit ? (
											<Button
												size='sm'
												className='ms-1 fs--2'
												variant='danger'
												onClick={() => {
													setEdit(false);
													setIdToDelete("");
													setItemEdit({});
													setSeasonSelected(0);
													setQuantity(0);
													setOpening(0);
												}}
											>
												Cancelar
											</Button>
										) : (
											""
										)}
									</Col>
								</Row>
							</Form>
						</Card>
					</Col>

					<Col xs={12} sm={12} lg={8} md={8}>
						<Card className='mb-2 p-2 h-md-100'>
							<div className='table-striped fs--2'>
								<Table
									className='p-0 border-bottom fs--2'
									style={{ padding: "0.45" }}
								>
									<thead className='bg-200 text-900'>
										<tr>
											<th className='border-0 text-center'>Temporada</th>
											<th className='border-0 text-center'>
												Cant. Planificada
											</th>
											<th className='border-0 text-center'>Aperturas</th>
											<th
												style={{ width: "30%" }}
												className='border-0 text-center'
											>
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{paginatedDetails && paginatedDetails.length > 0 ? (
											paginatedDetails.map((item, index) => (
												<tr key={`tr-${index}`} className='border-200'>
													<td className='text-start'>{item.seasonName}</td>
													<td className='text-start'>{item.quantity}</td>
													<td className='text-center'>{item.opening}</td>
													<td className='text-end'>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Editar</Tooltip>}
														>
															<Button
																onClick={() => {
																	setEdit(true);
																	setSeasonSelected(item.seasonId);
																	setIdToDelete(item.idKey);
																	setQuantity(item.quantity);
																	setOpening(item.opening);
																	setItemEdit(item);
																}}
																variant='transparent'
																size='sm'
																style={{ fontSize: "11px" }}
																className='text-success'
															>
																<FontAwesomeIcon icon='edit' />
															</Button>
														</OverlayTrigger>
														<OverlayTrigger
															placement='top'
															overlay={<Tooltip>Eliminar</Tooltip>}
														>
															<Button
																onClick={() => handleDelete(item.id)}
																variant='transparent'
																size='sm'
																style={{ fontSize: "11px" }}
																className='text-danger me-2'
															>
																<FontAwesomeIcon icon='trash' />
															</Button>
														</OverlayTrigger>
													</td>
												</tr>
											))
										) : (
											<tr className='border-200'>
												<td colSpan='6' className='align-middle text-center'>
													<h6 className='font-black text-3xl text-center fs--2'>
														¡No hay datos cargados!
													</h6>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>

							<div className='d-flex justify-content-center'>
								<div>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canPreviousPage}
										onClick={prevPage}
										className='me-2'
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-left' />
									</Button>
								</div>

								<ul className='pagination mb-0'>
									{paginationArray.map((page) => (
										<li
											key={page}
											className={classNames({
												active: currentPage === page,
											})}
										>
											<Button
												size='sm'
												variant='falcon-default'
												className='page me-2'
												onClick={() => goToPage(page)}
											>
												{page}
											</Button>
										</li>
									))}
								</ul>
								<div>
									<Button
										variant='falcon-default'
										size='sm'
										disabled={!canNextPage}
										onClick={nextPage}
										trigger='focus'
									>
										<FontAwesomeIcon icon='chevron-right' />
									</Button>
								</div>
							</div>
						</Card>
					</Col>
				</Row>

				<Modal
					size='md'
					aria-labelledby='contained-modal-title-vcenter'
					centered
					show={showAlert} /*onHide={handleCloseEstructure}*/
				>
					<Modal.Header>
						<Modal.Title>
							<h6 className='mb-0 hover-actions-trigger'>
								¿Estás seguro de que deseas eliminar?
							</h6>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className='mt-1'>
							<span className='text-danger fs--2'>
								<strong>
									¡Se eliminaran detalles relacionados en las etapas siguientes
									si la familia lo tiene!
								</strong>
							</span>
						</Row>
						<Row className='mt-3'>
							<Col className='d-flex justify-content-end'>
								<Button variant='danger' size='sm' onClick={closeModalDelete}>
									Cancelar
								</Button>
								<Button
									className='ms-1'
									variant='success'
									size='sm'
									onClick={handleConfirmDelete}
								>
									Confirmar
								</Button>
							</Col>
						</Row>
					</Modal.Body>
				</Modal>

				<Row className='mt-3'>
					<Col className='d-flex justify-content-end'>
						<Button
							size='sm'
							className='ms-1 fs--2'
							variant='danger'
							onClick={() => setShow(false)}
						>
							Cancelar
						</Button>
						{/* <Button
							size='sm'
							className='ms-1 fs--2'
							variant='success'
							onClick={() => handleSetDetails(detalles)}
						>
							Guardar
						</Button> */}
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

MasterplanModalStageThree.propTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	detailPublicFamilyStgTwo: PropTypes.object,
	detail: PropTypes.array,
	handleSetDetails: PropTypes.func,
};

export default MasterplanModalStageThree;
