import { useMemo } from "react";

export default function useColumnsEstamperiaProduct() {
  const columns = useMemo(
    () => [
        {
        accessor: 'attr_id',
        Header: '#'
        },
        {
          accessor: 'estampaName',
          Header: 'Estampas'
        },
        {
        accessor: 'prodName',
        Header: 'Producto'
        },
        {
          accessor: 'cantColor',
          Header: 'Total Colores'
        },
        {
          accessor: 'cantidad',
          Header: 'Total MP'
        },
        {
          accessor: 'acciones',
          Header: 'Acciones'
        },
    ],
    []
  );

  return columns;
}
