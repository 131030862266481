/** @format */

import React from "react";
import { useParams } from "react-router-dom";

const CalendarioView = () => {
	const { id } = useParams();
	return (
		<div>
			CalendarioView id {"->"} {id}
		</div>
	);
};

export default CalendarioView;
