/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Button } from "react-bootstrap";
import dayjs from "dayjs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ListadoTable from "components/products/rawmaterials/ListadoTable";
import { useAuthContext } from "hooks/useAuthContext";

const Listado = () => {
	const { token } = useAuthContext();

	const axiosWithToken = axios.create({
		baseURL: process.env.REACT_APP_ENDPOINT_API,
	});

	axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const [rawmaterials, setRawmaterials] = useState([]);

	useEffect(() => {
		getAllRawmaterials();
	}, []);

	const getAllRawmaterials = async () => {
		const { data } = await axiosWithToken.get("/rawmaterials");

		setRawmaterials(
			data.data.map((rawmaterial) => {
				const stageMapping = {
					0: "Código generado",
					1: "Materia prima generada",
					2: "Estructura asignada",
					3: "Hoja de ruta asignada",
				};

				const stage = stageMapping[rawmaterial.stage];

				return {
					id: rawmaterial.id,
					code: rawmaterial.code,
					name: rawmaterial.name,
					description: rawmaterial.description,
					stage: stage,
					created_at: dayjs(rawmaterial.created_at).format(
						"DD/MM/YYYY HH:mm:ss"
					),
					updated_at: dayjs(rawmaterial.updated_at).format(
						"DD/MM/YYYY HH:mm:ss"
					),
				};
			})
		);
	};

	return (
		<>
			{/* CARD TABLA LISTADO */}

			<Card className='mb-3 border-bottom border-200'>
				<Card.Header className='p-2'>
					<Row>
						<Col className='d-flex justify-content-center'>
							<h6 className='mb-0'>Listado de Materias Primas</h6>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button
								as={Link}
								to='/products/rawmaterials/form'
								variant='success'
								size='sm'
								className='border-300'
							>
								<FontAwesomeIcon icon='plus' /> Nuevo
							</Button>
						</Col>
					</Row>
				</Card.Header>

				<Card.Body>
					<Tab.Content>
						<ListadoTable data={rawmaterials} />
					</Tab.Content>
				</Card.Body>
			</Card>
		</>
	);
};

export default Listado;
