/** @format */

import { createContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useEffect } from "react";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	//Estado para guardar el usuario
	const [user, setUser] = useState({});
	const [token, setToken] = useState("");

	// Verifica si hay una sesión almacenada en localStorage
	useEffect(() => {
		const storedUser = localStorage.getItem("user");
		const storedToken = localStorage.getItem("token");

		if (storedUser && storedToken) {
			setUser(JSON.parse(storedUser));
			setToken(JSON.parse(storedToken));
		} else {
			setUser({});
			setToken("");
		}
	}, []);

	const login = async (userData) => {
		try {
			const { data } = await axios.post(
				`${process.env.REACT_APP_ENPOINT_BASE}/api/login`,
				{
					email: userData.email,
					password: userData.password,
				}
			);

			localStorage.setItem("user", JSON.stringify(data.user));
			localStorage.setItem("token", JSON.stringify(data.access_token));
			setUser(data.user);
			setToken(data.access_token);

			return data;
		} catch (error) {
			setUser({});
			setToken("");
		}
	};

	const logout = async () => {
		// Agregamos el token al encabezado 'Authorization' de Axios
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const { data } = await axios.post(
			`${process.env.REACT_APP_ENPOINT_BASE}/api/logout`
		);

		console.log(data.message);
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		setUser({});
		setToken("");
		return true;
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				token,
				login,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.any,
};

export { AuthProvider };
export default AuthContext;
